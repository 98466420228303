<template>
  <div class="creationClient">

    <h3>RECHERCHE UN RAPPORT PAR NOM</h3>



    <div class="formCreation">

      <div>
        <label for="Nom de fichier">Saisir Nom de fichier SVP</label>
        <input type="text" v-model="rapport.filename">
      </div>

      <div>
        <label for="Référence Rapport">Référence Rapport</label>
        <input type="text" v-model="rapport.referenceRapport" disabled>
      </div>

      <div>
        <label for="Référence Rapport">Numéro Affaire</label>
        <input type="text" v-model="rapport.numeroAffaire" disabled>
      </div>

      <div>
        <label for="Désignation">Désignation</label>
        <input type="text" v-model="rapport.designation">
      </div>

      <div>
        <label for="Date Intervention">Date Intervention</label>
        <input type="date" v-model="rapport.dateIntervention">
      </div>

      <div>
        <label for="Date Production Contrôle">Date Production Contrôle</label>
        <input type="date" v-model="rapport.dateProductionControle">
      </div>

      <div>
        <label for="Responsable Client">Responsable Client</label>
        <input type="text" v-model="rapport.responsableClient">
      </div>




      <div>
        <label for="Catégorie">Catégorie</label>
        <input type="text" v-model="rapport.category">
      </div>

      <div>
        <label for="Type de Rapport">Type de Rapport</label>
        <input type="text" v-model="rapport.typeRapport">
      </div>

       <div v-show="rapport.filename != null || ''">
        <label for="Type de Rapport">Apercu rapport</label>
        <input type="submit" value="Apercu" @click="display(rapport.filename)" style="background-color: green; color: white; border: 0px;">
      </div>

       <div v-show="rapport.confirmation == '1'">
        <label for="Type de Rapport">état de rapport</label>
        <input type="submit" value="Confirme" @click="confirme()" style="background-color: green; color: white; border: 0px;">
      </div>

       <div v-show="rapport.confirmation == '0'">
         <label for="Type de Rapport">état de rapport</label>       
        <input type="submit" value="Non Confirme" @click="nonConfirme()" style="background-color: red; color: white; border: 0px;">
      </div>

       <div v-show="rapport.sendLocal == true">
        <label for="Type de Rapport">état de rapport pour server (local)</label>
        <input type="submit" value="Confirme" @click="rapportLocal()" style="background-color: green; color: white; border: 0px;">
      </div>

       <div v-show="rapport.sendLocal == false">
         <label for="Type de Rapport">état de rapport pou server (local)</label>       
        <input type="submit" value="Non Confirme" @click="nonRapportLocal()" style="background-color: red; color: white; border: 0px;">
      </div>

       <div v-show="rapport.sendClient == true">
        <label for="Type de Rapport">état de rapport pour client</label>
        <input type="submit" value="Confirme" @click="rapportClient()" style="background-color: green; color: white; border: 0px;">
      </div>

       <div v-show="rapport.sendClient == false">
         <label for="Type de Rapport">état de rapport pour client</label>       
        <input type="submit" value="Non Confirme" @click="nonRapportClient()" style="background-color: red; color: white; border: 0px;">
      </div>

       <div v-show="rapport.disabled == true">
        <label for="Disabled">Disabled</label>
        <input type="submit" value="Confirme" @click="disabled()" style="background-color: green; color: white; border: 0px;">
      </div>

       <div v-show="rapport.disabled == false">
         <label for="Disabled">Disabled</label>       
        <input type="submit" value="Non Confirme" @click="enable()" style="background-color: red; color: white; border: 0px;">
      </div>

       <div>
        <label for="Type de Rapport">Class Un</label>
        <input type="text" v-model="rapport.typeRapport">
      </div>

       <div>
        <label for="Type de Rapport">Class Un</label>
        <input type="text" v-model="rapport.classOne">
      </div>

       <div>
        <label for="Type de Rapport">Class Deux</label>
        <input type="text" v-model="rapport.classTow">
      </div>

       <div>
        <label for="Type de Rapport">Class Trois</label>
        <input type="text" v-model="rapport.classTree">
      </div>

       <div>
        <label for="Type de Rapport">Class Quatre</label>
        <input type="text" v-model="rapport.classFour">
      </div>

       <div>
        <label for="Type de Rapport">Class Cinq</label>
        <input type="text" v-model="rapport.classFive">
      </div>

      <div>
        <p> {{ rapport }}</p>
      </div>

      <div>
        <input type="submit" value="Recherche" @click="recherche()">
      </div>

      <div>
        <input type="submit" value="Quitter" @click="quitter()">
      </div>
      
      <div>
        <input type="submit" value="Supprimer" @click="supprimer()">
      </div>


    </div>

    <!--  Start Info Delete Model   -->
      <DeleteModel v-if="flagModelDelete == true" :text="textDeleteModel" @closeDeleteModel="closeDeleteModel()" @validerDeleteModel="validerDeleteModel()" />
    <!--  End Info Delete Model   -->

  </div>
</template>

<script>
import DeleteModel from "../../../../views/components/DeleteModel.vue";
import Service from '../../../../Service';
export default {
  data() {
    return {
      flagModelDelete : false,
      textDeleteModel : null,
      traitement : false,
      matricule : null,
      msgRapport : null,
      rapport : {
            category: null,
            classFive: null,
            classFour: null,
            classTree: null,
            classTow: null,
            classOne: null,
            clientId: null,
            confirmation: null,
            date: null,
            dateIntervention: null,
            dateProductionControle: null,
            designation: null,
            disabled: null,
            filename: null,
            numeroAffaire: null,
            originalname: null,
            referenceRapport: null,
            responsableClient: null,
            sendClient: null,
            sendLocal: null,
            typeRapport: null,
            url: null,
            _id: null,
      }
    };
  },


  components : {
    DeleteModel
  },

  computed : {
  },

  methods: {

    recherche() {
        Service.getRapportWithFilename(this.rapport.filename)
          .then((result) => {
             this.rapport = result.data.rapport;
          })
          .catch((error) => {
              console.log(error)
        });
    },

    display(filename) {
        Service.displayRapport(filename)
          .then((result) => {
            console.log(result);
          })
          .catch((error) => {
              console.log(error)
        });
    },

    closeDeleteModel() {
      this.flagModelDelete = false;
    },

    validerDeleteModel() {
      this.flagModelDelete = false;
        Service.deleteRapport(this.rapport._id, this.rapport.filename)
          .then((result) => {

            if(result) {
                this.rapport.category = null;
                this.rapport.classFive = null;
                this.rapport.classFour = null;
                this.rapport.classTree = null;
                this.rapport.classTow = null;
                this.rapport.classOne = null;
                this.rapport.clientId = null;
                this.rapport.confirmation = 0;
                this.rapport.date = null;
                this.rapport.dateIntervention = null;
                this.rapport.dateProductionControle = null;
                this.rapport.designation = null;
                this.rapport.disabled = false;
                this.rapport.numeroAffaire = null;
                this.rapport.originalname = null;
                this.rapport.referenceRapport = null;
                this.rapport.responsableClient = null;
                this.rapport.sendClient = false;
                this.rapport.sendLocal = false;
                this.rapport.typeRapport = null;
                this.rapport.url = null;
                this.rapport._id = null;
            }

        })
        .catch((error) => {
              console.log(error);
        });
    },

    supprimer() {
        this.flagModelDelete = true;
        this.textDeleteModel = "Êtes-vous sûr de supprimer ?";
    }

  },

  created() {

      if(!sessionStorage.getItem("token"))
      {
        this.$router.push("/");
      } 

  }

}
</script>

<style scoped>


.creationClient {
  width: 100%;
  padding:5px;

}

.creationClient h3 {
  width: 100%;
  margin:auto;
  color: white;
  background: linear-gradient(346deg, rgba(207,31,33,1) 0%, rgba(24,86,161,1) 100%);    text-align: center;
  text-align: center;


}
.succes {
  background-color: #69cd5b;
  color: white;
  padding: 10px;
  width: 100%;
  height: fit-content;
}

.echec {
  background-color: RED;
  color: white;
  padding: 10px;
  width: 100%;
  height: fit-content;
}

.formCreation {
  padding:20px 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1,1fr);
  justify-items: center;
  align-items: center;


}

.formCreation div {
  display: flex;
  flex-direction: column;
  width: 60%;
}
.formCreation div label {
  margin-left:10px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
  color :#494949;
}
.formCreation div input,textarea {
  height: 40px;
  margin-left:10px;
  margin-bottom: 5px;
  border: 1px solid #243064;

}

.formCreation div select {
  height: 40px;
  margin-left:10px;
}

.formCreation div:nth-child(10) input{
  cursor: pointer;
}

.formCreation div:nth-child(26) input{
    background-color: green;
    color: white;
    border: 0;
    margin-top: 30px;
    cursor: pointer;
}

.formCreation div:nth-child(27) input {
    background-color: red;
    color: white;
    border: 0;
    margin-top: 30px;
    cursor: pointer;
}

.formCreation div:nth-child(28) input {
    background-color: blue;
    color: white;
    border: 0;
    margin-top: 30px;
    cursor: pointer;
}



#app > div > div > div.menu-content > div.content > div {
  background-color: white;
}

#app > div > div > div.menu-content > div.content > div > h3 {
    padding: 15px;
}

#app > div > div > div.menu-content > div.content > div > div > div > label {
  font-size: 15px;
}



.form-control {
    display: block;

    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.form-control::-webkit-file-upload-button {
    padding: 10px;
    margin: -.375rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end: .75rem;
    color: rgb(58, 58, 58);
    font-weight: 600;
    background-color: #e9e9e9;
    pointer-events: none;
    border:0;

    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}
.warningjrs{
  background-color: #ffc6c6;
  width: 100%;
  margin:10px 9px;
  padding:10px;
  border-radius: 10px;
  display: flex;
  color:red;
  align-items: center;
  font-weight: 600;
}

.warningjrs svg{
  margin-right: 5px;
}
</style>

