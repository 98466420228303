<template>
  <div class="indicateur">

    <div class="titre">
      <h2>LES INDICATEURS DES PRODUCTIONS</h2>
    </div>


    <!-- <div class="text">

       <span><i class="fa-regular fa-lightbulb"></i>Note :</span>
        Faisant suite aux exigences de GTHCONSULT. La gestion de la production est bossée sur les formations
        techniques en interne, maitrise des compétences sur les matières, conductions est la supervision de l’ensemble
        des inspecteurs techniques, elle sembler notamment :
        <ul>
            <li><i class="fa-regular fa-circle-check"></i> planification de production.</li>
            <li><i class="fa-regular fa-circle-check"></i> Affecter les personnes adéquates pour chaque intervention planifiée.</li>
            <li><i class="fa-regular fa-circle-check"></i> Assurée de la qualité de l’intervention, les procédures techniques de Faisant suite aux exigences de GTHCONSULT.</li>
            <li><i class="fa-regular fa-circle-check"></i> Éditions des rapports de vérification suivons exigées par GTHCONSULT.</li>
            <li><i class="fa-regular fa-circle-check"></i> Contrôle de la production suit les instructions validées par GTHCONSULT.</li>
            <li><i class="fa-regular fa-circle-check"></i> Envoi des rapports de vérification.</li>
        </ul>

</div> -->



      <div class="filter">

            <label for="year">Rechercher par des années: </label>
            <select v-model="annee" @change="onChangeYear">
                <option v-for="item in years" :key="item" :value="item" > {{ item }} </option>
            </select>

            <label for="mois">ou des mois: </label>
            <select v-model="mois" @change="onChangeMois">
                <option v-for="item in numberMois" :key="item" :value="item" > {{ item }} </option>
            </select>

      </div>



      <div class="statstique">
      
        <EtatProduction :selectYear="annee" :selectMois="mois"  v-if="flagEtatProduction == true"/>
        <!-- <EtatAffairesClient :selectYear="annee" :selectMois="mois" v-if="flagEtatAffairesClient == true"/> -->
        <EtatRendementInspecteurs :selectYear="annee" :selectMois="mois" v-if="flagEtatRendementInspecteurs == true"/>
        <EtatMissionsAffaires :selectYear="annee" :selectMois="mois" v-if="flagEtatMissionsAffaires == true"/>
        <EtatAffairesInspecteurs :selectYear="annee" :selectMois="mois" v-if="flagEtatAffairesInspecteurs == true"/>

        <!-- <div>
          <p v-if="currentMounths == 12" style="background-color: red;">Décembre</p>
          <p v-if="currentMounths != 12">Décembre</p> 
          <p><span><i class="fa-solid fa-spinner"></i> En attente:</span> {{ production.bcDecembre }} </p>
          <p><span><i class="fa-solid fa-hourglass-half"></i> En cours:</span> {{ production.interventionDecembre }}</p>
          <p><span><i class="fa-solid fa-list-check"></i> Réalisé:</span> {{ production.realiseDecembre }}</p>
        </div> -->

      </div>
  </div>
</template>

<script>
import Service from "../../../../Service"
import EtatProduction from "./charts/EtatProduction.vue"
import EtatMissionsAffaires from "./charts/EtatMissionsAffaires.vue"
// import EtatAffairesClient from "./charts/EtatAffairesClient.vue"
import EtatAffairesInspecteurs from "./charts/EtatAffairesInspecteurs.vue"
import EtatRendementInspecteurs from "./charts/EtatRendementInspecteurs.vue"
export default {
  name : "indicateur",
  data() {
    return {
        flagEtatProduction : false,
        flagEtatMissionsAffaires : false,
        // flagEtatAffairesClient : false,
        flagEtatAffairesInspecteurs : false,
        flagEtatRendementInspecteurs : false,
        numberMois: [],
        mois : null,
        years : [],
        annee : null,
        inspecteur : null,
        matricule : null,
        inspecteurs : []
    }
  },

  components : {
    EtatProduction,
    EtatMissionsAffaires,
    // EtatAffairesClient,
    EtatAffairesInspecteurs,
    EtatRendementInspecteurs
  },

  methods : {

      onChangeYear() {

          // Removing my-component from the DOM
          this.flagEtatProduction = false;
          this.$nextTick(() => {
            // Adding the component back in
            this.flagEtatProduction = true;
          });

          this.flagEtatMissionsAffaires = false;
          this.$nextTick(() => {
            // Adding the component back in
            this.flagEtatMissionsAffaires = true;
          });

          this.flagEtatAffairesClient = false;
          this.$nextTick(() => {
            // Adding the component back in
            this.flagEtatAffairesClient = true;
          });

          this.flagEtatAffairesInspecteurs = false;
          this.$nextTick(() => {
            // Adding the component back in
            this.flagEtatAffairesInspecteurs = true;
          });

          this.flagEtatRendementInspecteurs = false;
          this.$nextTick(() => {
            // Adding the component back in
            this.flagEtatRendementInspecteurs = true;
          });
      },

      onChangeMois() {

          // Removing my-component from the DOM
          this.flagEtatProduction = false;
          this.$nextTick(() => {
            // Adding the component back in
            this.flagEtatProduction = true;
          });

          this.flagEtatMissionsAffaires = false;
          this.$nextTick(() => {
            // Adding the component back in
            this.flagEtatMissionsAffaires = true;
          });

          this.flagEtatAffairesClient = false;
          this.$nextTick(() => {
            // Adding the component back in
            this.flagEtatAffairesClient = true;
          });

          this.flagEtatAffairesInspecteurs = false;
          this.$nextTick(() => {
            // Adding the component back in
            this.flagEtatAffairesInspecteurs = true;
          });

          this.flagEtatRendementInspecteurs = false;
          this.$nextTick(() => {
            // Adding the component back in
            this.flagEtatRendementInspecteurs = true;
          });
      }

    },

    mounted() {

        // get year anee current
        const today = new Date();
        this.annee = today.getUTCFullYear();
        this.mois = today.getMonth() + 1;
        this.inspecteur = sessionStorage.getItem("id");

        Service.readAdmin()
        .then((result) => {
          result.data.admins.forEach(element => {
            this.inspecteurs.push(element);
          });
        })
        .catch((error) => {
            console.log(error);
        });


        for(let i = this.annee; i >= 1970 ; i--) {
              this.years.push(i);
        }

        for(let i = 12; i >= 1 ; i--) {
              this.numberMois.push(i);
        }

        if(this.annee != null && this.mois != null) {
          this.flagEtatProduction = true;
          this.flagEtatMissionsAffaires = true;
          // this.flagEtatAffairesClient = true;
          this.flagEtatAffairesInspecteurs = true;
          this.flagEtatRendementInspecteurs = true;
        }

    }

}
</script>

<style scoped>
.indicateur {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.indicateur>.text {
  background-color: #fff27a;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}

.text span {
  font-weight: bold;
}

.titre h2 {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
  color: white;
  background: linear-gradient(346deg, rgba(207, 31, 33, 1) 0%, rgba(24, 86, 161, 1) 100%);
  text-align: center;
  padding: 10px;
  font-size: 25px;
}


.indicateur>.filter {

  background-color: rgb(238, 238, 238);
  display: flex;
  margin: 0 10px;
  padding: 10px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}



.indicateur>.filter>select {
  padding: 10px;
  margin-left: 10px;
  border: 1px solid #bebebe;
  border-radius: 20px;
}
.indicateur>.filter>label {
  margin-left: 10px;
}





.indicateur>.statstique  {

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;

}



</style>