<template>
  <div class="etat-apporteur-chart">
    <h4>ÉTAT D'APPORTEUR D'AFFAIRE</h4>
    <canvas id="myEtatApporteurAffaire"></canvas>
  </div>
</template>

<script>
import Service from "../../../../../Service";
import Chart from "chart.js";
export default {

  name: "affaire-apporteur-chart",

  data() {
    return {
      annee : null,
    };
  },

  props: {
    selectYear : Number,
    selectMois : Number
  },

  methods: {},

  created() {
  },

  mounted() {

      Service.IndicteurApporteurAffaire(this.selectYear, this.selectMois)
        .then((result) => {
            
            const label = ['Prospection', 'Retune'];
            const colors = ["Yellow", "Orange"];
            const datasets = [];
            const labels = [];
            const prosepection = [];
            const retune = [];

            result.data.forEach(element => {
              labels.push(element.name);
              prosepection.push(element.prospection);
              retune.push(element.retune);
            });
  
            const mergeProsepectionRetune = [...prosepection, ...retune];

            for(let i = 0; i < label.length; i++) {
              const values = mergeProsepectionRetune.slice(0, result.data.length);
              mergeProsepectionRetune.splice(0, result.data.length);
              datasets.push({
                 label: label[i],
                 backgroundColor: colors[i],
                 data: values
              });
            }




            var data = {
                      labels: labels,
                      datasets: datasets
            };

            const config = {
                type: 'bar',
                data: data,
                options: {
                    barValueSpacing: 5,
                    scales: {
                        yAxes: [{
                            ticks: {
                                min: 0,
                            }
                        }]
                    }
                }
            };

            // instance Chart CTX
            const ctx = document.getElementById("myEtatApporteurAffaire");
            const myEtatApporteurAffaire = new Chart(ctx, config);

            return myEtatApporteurAffaire;

        })
        .catch((error) => {
          console.log(error.message)
        });

  }


};
</script>

<style scoped>

  .etat-apporteur-chart {
    max-height: fit-content;
  }

  .etat-apporteur-chart > h4 {
      width: 100%;
      justify-content: center;
      display: flex;
      color: #1e1ed3;
      font-size: x-large;
      opacity: 0.6;
      font-weight: 500;
      margin-bottom: 10px;
      margin-top: 20px;
  }
  
</style>