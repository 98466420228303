<template>
  <div class="creationClient">


    <h3><i class="fa-solid fa-receipt"></i>CRÉATION BORDEREAU D'ENVOI</h3>

    <h3 class="titre-client">DATE BORDEREAU D'ENVOI :</h3>

    <div class="formCreation">
      <div>
        <input type="text" v-model="bd.dateCreation">
      </div>
    </div>



    <h3 class="titre-client">INFORMATION GÉNÉRAL D'OFFRE : </h3>

    <div class="formCreation">

      <div>
        <label for="adresse">Réf. Client</label>
        <input type="text" disabled v-model="bd.refClient">
      </div>

      <div>
        <label for="adresse">N°. Affaire</label>
        <input type="text" disabled v-model="bd.numeroAffaire">
      </div>


      <div>
        <label for="ville">Libellée – Mission</label>
        <input v-for="(mission, index) in bd.missions" :key="index" type="text" v-model="mission.codeMission">
      </div>

      <div>
        <label for="adresse"></label>
        <input type="hidden" disabled v-model="bd.affaireId">
      </div>

    </div>

  

    <h3 class="titre-interlocuteur"> BORDEREAU D’ENVOI N°:  {{ bd.numeroBD }}  </h3>

    <div class="formCreation">

        <div>
            <label for="nom">Bordereau d'Envoi</label>
            <input  type="text" v-model="bd.numeroBD">
        </div>

        <div>
            <label for="nom"></label>
            <input  type="hidden">
        </div>

        <div>
            <label for="nom">Date d'envoi</label>
            <input  type="date" v-model="bd.dateEnvoi">
        </div>

        <div>
            <label for="nom">Date Reçu</label>
            <input  type="date" v-model="bd.dateRecu">
        </div>

    </div>

    <div class="formCreation">
        <div>
            <label for="nom">Nom & Raison Sociale</label>
            <input type="text" v-model="bd.raisonSocial">
        </div>

        <div>
            <label for="nom">Adresse</label>
            <input disabled type="text" v-model="bd.adresse">
        </div>

        <div>
            <label for="nom">Téléphone</label>
            <input disabled type="text" v-model="bd.telephone">
        </div>

        <div>
            <label for="nom">Adresse d’Envoie</label>
            <input type="text" v-model="bd.adresseEnvoi">
        </div>

        <div>
            <label for="nom">N° du Bon Commande de Client :</label>
            <input type="text" v-model="bd.numeroBC">
        </div>

        <div>
            <label for="nom">N° ICE</label>
            <input type="text" v-model="bd.numeroICE">
        </div>

    </div>

    <h3 class="titre-interlocuteur"> Informations des missions : </h3>



 <table id="inspecteurs">
              <tr>
                <th>N°</th>
                <th>DESIGNATION</th>
                <th>Quantité</th>

              </tr>
              <tr v-for="(rapport, index) in bd.rapports" :key="index">
                <td>
                  {{ index + 1 }}
                </td>
                <td>
                    <ul>
                        <li>
                            <input class="designation" type="text" v-model="rapport.designation">
                        </li>
                    </ul>
                </td>

                <td>
                    <ul>
                      <li>
                            <input class="qte" type="text" v-model="rapport.qte">
                        </li>
                    </ul>
                </td>

              </tr>
            </table>

    <div class="ajouter-supprimer">
        <button class="ajouter" @click="ajouterDesignation()"> Ajouter </button>
        <button class="supprimer" @click="supprimerDesignation()"> Supprimer </button>
    </div>

    <div class="enregistre">
        <button @click="apercueBD()"> Aperçu </button>
        <button @click="enregistreBD()"><i class="fa-solid fa-download"></i> Enregistrer </button>
    </div>

  <ModalLanding v-if="flagModalLanding"/>
  <ModalErrors :errors="errors" v-if="flagModalErrors"/>

  </div>
</template>

<script>

import Service from "../../../../Service";
import ModalLanding from "@/views/components/ModalLanding.vue"
import ModalErrors from "@/views/components/ModalErrors.vue"

export default {
    props: {
      numeroAffaire: String,
    },
    components: {
      ModalLanding,
      ModalErrors
    },

    data() {
        return {
            errors : [],
            flagForm : true,
            flagModalLanding : false,
            flagModalErrors : false,
            flagAutreInspecteur : false,
            deDate: null,
            aDate: null,
            commentaire : null,
            inspecteur :null,
            chargeAffaire: null,
            missions: [],
            client: {
                    raisonSocial : null,
                    adresse : null,
                    ville : null,
                    codePostal : null,
                    pays : null,
                    email : null,
                    ice : null,
                    refClient : null,
                    _id : null
            },
            interlocuteur: {
                    nom : null,
                    prenom : null,
                    email : null,
                    codePostal : null,
                    fonction : null,
                    telephone : null,
                    clientId : null,
                    _id: null
            },
            mission: {
                    codeMission : null,
                    apparteurAffaire : null,
                    typeMission : null,
                    ht : null,
                    tva : null,
                    qte : null,
                    prix : null,
                    numeroAffaire : null,
            },
            affaire : {
                numeroAffaire : null,
                apporteurAffaire : null,
                bonCommande : null,
                renseignerIntervention: null,
                importRapport: null,
                be :null,
            },
            intervention :  {
                  nameInterlocuteur : null,
                  telephoneInterlocuteur : null,
                  raisonSocial : null,
                  adresseClient : null,
                  commentaire: null,
                  deDate: null,
                  aDate: null,
                  nameInspecteur: null,
                  chargeAffaire: null,
                  missions: null,
                  interlocuteurId: null,
                  clientId: null,
                  numeroAffaire : null
            },

            bd : {
                numeroBD: null,
                refClient : null,
                numeroAffaire : null,
                missions : [],
                rapports : [],
                dateEnvoi : null,
                dateRecu : null,
                raisonSocial : null,
                adresse : null,
                telephone : null,
                adresseEnvoi : null,
                numeroBC : null,
                numeroICE : null,
                dateCreation : null,
                affaireId : null
            }
    };
},

methods: {

ajouterDesignation() {
    this.bd.rapports.push({
      designation : "",
      qte : 1
    });
},

supprimerDesignation() {
  this.bd.rapports.pop();
},

// function finish Modal for delete landing or errors after message (init)
finishModal() {
  setTimeout(() => {
      this.flagModalLanding = false;
      this.flagModalErrors = false;
      this.errors = [];
      this.message = null;
  }, 8000);
},

downloadFile(response) {
        var newBlob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        link.download = "resume.docx";
        link.click();
        setTimeout(function() {
            window.URL.revokeObjectURL(data);
        }, 100);
},


apercueBD() {

  // check not empty
  for (const [key, value] of Object.entries(this.bd)) {
      if(value == null || value == "" || value == undefined) {
        console.log(key);
        this.flagForm = false;
        break;
      }
  } 

  // check not NaN in Numero de Bon de livrison
  if(this.bd.numeroBD.slice(2, 5) != "NaN") {
      this.flagForm = true;
  }

  if(!this.flagForm){
      this.flagModalErrors = true;
      this.errors.push("Veuillez saisir tout les champs");
      this.finishModal();
  } else {

            this.flagModalLanding = true;
            
            Service.apercueBD(this.bd)
            .then((response) => {
                // 
                 if(response.data.cas === true) {

                    this.flagModalLanding = false;
                    this.finishModal();

                    Service.downloadBD()
                    .then((response) => {
                      this.downloadFile(response);
                    })
                    .catch((error) => {
                      this.errors.push(error.message);
                      this.flagModalErrors = true;
                      this.finishModal();
                    });
                }
            })
            .catch((error) => {
              this.errors.push(error.message);
              this.flagModalErrors = true;
              this.finishModal();
            });

  }
},

enregistreBD() {
  
  // check not empty
  for (const [key, value] of Object.entries(this.bd)) {
      if(value == null || value == "" || value == undefined) {
        console.log(key);
        this.flagForm = false;
        break;
      }
  } 

  // check not NaN in Numero de Bon de livrison
  if(this.bd.numeroBD.slice(2, 5) != "NaN") {
      this.flagForm = true;
  }

  if(!this.flagForm){
      this.flagModalErrors = true;
      this.errors.push(`Veuillez saisir le champs`);
      this.finishModal();
  } else {
            
            Service.enregistreBD(this.bd)
            .then((response) => {
                // succes response
                if(response.data.cas === true) {

                      // handel landing Modal
                      this.flagModalLanding = true;
                      this.message = response.data.msg;

                      // for empty all inputs
                      this.bd.numeroBD = null;
                      this.bd.refClient = null;
                      this.bd.numeroAffaire = null;
                      this.bd.missions = [];
                      this.bd.rapports = [];
                      this.bd.dateEnvoi = null;
                      this.bd.dateRecu = null;
                      this.bd. raisonSocial = null;
                      this.bd.adresse = null;
                      this.bd.telephone = null;
                      this.bd.adresseEnvoi = null;
                      this.bd.numeroBC = null;
                      this.bd.numeroICE = null;
                      this.bd.dateCreation = null;
                      this.bd.affaireId = null

                      //for delete landing after succes
                      this.finishModal();
                      return this.$router.go(this.$router.currentRoute);
                }
            })
            .catch((error) => {
              this.errors.push(error.message);
              this.flagModalErrors = true;
              this.finishModal();
            });

  }
  
}

},

created() {

      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0');
      var yyyy = today.getFullYear();
      var numberBC = 211

      this.bd.dateCreation = `Le ${dd}-${mm}-${yyyy}`;

    // Get last Numero BD
    Service.getLastBD()
    .then((result) => {

        if(result.data.bordereaus.length == 0) {
           this.bd.numeroBD = `BL${numberBC}|${mm}|${yyyy}`;

        } else {
            // get last BD
            const lastBD = result.data.bordereaus.length - 1;
            // get count
            const count = result.data.bordereaus[lastBD].numeroBD.slice(
                result.data.bordereaus[lastBD].numeroBD.indexOf('E') + 1,
                result.data.bordereaus[lastBD].numeroBD.lastIndexOf('|'),
            );

            this.bd.numeroBD = `BL${(parseInt(count) + 1)}|${mm}|${yyyy}`;
        }
    })
    .catch((err) => {
        console.log(err);
    });



    // Get info affaire with ID
    Service.getAffaireById(this.numeroAffaire)
    .then((result) => {

        this.affaire = result.data.affaire;
        this.bd.numeroAffaire = result.data.affaire.numeroAffaire;
        this.bd.affaireId = result.data.affaire._id;
        this.bd.numeroBC = result.data.affaire.numeroBonCommande;
        this.chargeAffaire = result.data.affaire.apporteurAffaire;

        //Get les designations des rapports
        Service.selectRapports(this.bd.numeroAffaire)
        .then((result) => {
          result.rapports.forEach(element => {
            this.bd.rapports.push({
              designation : element.designation,
              qte : 1
            });
          });
        })
        .catch((error) => {
            console.log(error.message);
        });



        Service.getMission(result.data.affaire.numeroAffaire)
        .then((result) => {
          
              result.data.missions.forEach(element => {
                this.missions.push(element);
              });

              result.data.missions.forEach(element => {
                this.bd.missions.push(element);
              });

          

              Service.selectClient(result.data.missions[0].clientId)
              .then((result) => {
                this.client = result.data.client;


                this.bd.refClient = result.data.client.refClient
                this.bd.raisonSocial = result.data.client.raisonSocial;
                this.bd.adresse = result.data.client.adresse + ", " + result.data.client.codePostal +"  "+ result.data.client.ville +"  "+ result.data.client.pays;
                this.bd.telephone = result.data.client.telephone;
                this.bd.adresseEnvoi = result.data.client.adresse + ", " + result.data.client.codePostal +"  "+ result.data.client.ville +"  "+ result.data.client.pays;
                this.bd.numeroICE = result.data.client.ice;

              })
              .catch((error) => {
                console.log(error.msg);
              });

        })
        .catch((error) => {
             console.log(error.msg)
        })
    })
    .catch((error) => {
        console.log(error.msg)
    })
}

}
</script>

<style scoped>


.creationClient {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.creationClient h3:nth-child(1) {
  width: 100%;

  color: white;
  background: linear-gradient(346deg, rgba(207,31,33,1) 0%, rgba(24,86,161,1) 100%);    text-align: center;
  text-align: center;
  padding: 15px;


}
.creationClient .titre-client {
    width: fit-content;
    height: fit-content;
    padding: 5px;
    margin: 10px 0;
    color: rgb(41, 41, 41);
    text-align: center;
    width: 100%;
    background-color: #dadada;

}
.creationClient .titre-interlocuteur {
  width: fit-content;
    height: fit-content;
    padding: 5px;
    margin: 10px 0;
    color: rgb(41, 41, 41);
    text-align: center;
    width: 100%;
    background-color: #dadada;


}
.succes {
  background-color: #69cd5b;
  color: white;
  padding: 10px;
  width: 100%;
  height: fit-content;
}

.echec {
  background-color: RED;
  color: white;
  padding: 10px;
  width: 100%;
  height: fit-content;
}


.formCreation {
  padding:20px 0;
  width: 100%;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  flex-wrap: wrap;

}

.formCreation div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 48%;
}
.formCreation div label {
  margin-left:10px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
  color :#272727;
}
.formCreation div input {
  height: 40px;
  margin-left:10px;
  margin-bottom: 5px;
  border: 1px solid #243064;
  padding:5px;
}.formCreation div input:focus-within {
  outline: 1px solid #cf1f21 ;
  border:0;

}

svg{
  font-size: 14px;
}

.formCreation div input:focus-within {
  outline: 1px solid #cf1f21 ;
  border:0;

}


.formCreation div select {
  height: 40px;
  margin-left:10px;
}

hr {
  width: 100%;
  background-color: black;
  height: 10px;
}
.enregitreAffaire {
  background-color: green;
  color: white;
  cursor: pointer;
}
.file {
  background-color: white;
  color: black;
  cursor: pointer;
}

table{
  border-collapse: separate;
}

.enregistre {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.enregistre button:nth-child(1) {
  height: 40px;
  width: 500px;
  background-color: blue;
  color: white;
  border: 0;
  cursor: pointer;
  margin: 10px 0;
}

.enregistre button:nth-child(2) {
  height: 40px;
  width: 500px;
  background-color: green;
  color: white;
  border: 0;
  cursor: pointer;
  margin: 10px 0;
}



table  {
  width: 100%;
  border-collapse: collapse;
  margin:10px 0;
  font-weight: 500;
}
table tr  {
  width: 100%;
}
table tr th {
  background-color: #01a1d1;
    color: white;
    padding: 10px 5px;
    border-right: 1px solid white;
}

table tr td {
    color: black;
    border-bottom: 1px solid rgb(184, 184, 184);
    padding:3px 0;

}

table tr:nth-child(even) {
  background-color: #f5f5f5;
}


table tr td {
  cursor: pointer;
}
td ul li ul li {
  padding: 2px;
}


td:not(:nth-child(3)) ul li ul li {
  margin-left: 20px;
}

table tr td a {
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}

#app > div > div > div.menu-content > div.content > div > div > h3:nth-child(2){
  color: white;
}

#app > div > div > div.menu-content > div.content > div > div > h3:nth-child(4) {
  color: white;
}
#app > div > div > div.menu-content > div.content > div > div > h3:nth-child(6) {
  color: white;
}

#app > div > div > div.menu-content > div.content > div > div > h3:nth-child(9) {
  color: white;
}

.designation, .qte {
  height: 50px;
  width: -webkit-fill-available;
}

.ajouter {
  background-color: green;
  color: white;
  height: 50px;
  padding: 10px;
  border: 0px;
  margin: 5px;
  width: 100px;
  cursor: pointer;
  border-radius: 20px;
}

.supprimer {
  background-color: red;
  color: white;
  height: 50px;
  padding: 10px;
  border: 0px;
  margin: 5px;
  width: 100px;
  cursor: pointer;
  border-radius: 20px;
}

</style>