<template>
  <div class="admin">
    <div class="container">
      <div class="header">
        <Nav />
      </div>
      <div class="menu-content">
        <div class="menu">
          <Menu

            @gestionrh="handelGestionrh()"

            @creationSalarie="handelCreationSalarie()"
            @gestionSalarie="handelGestionSalarie()"
            @editSalarie="handelEditSalarie()"

            @demandeAbsence="handelDemandeAbsence()"
            @listeDemandeAbsence="handelDemandeGestionAbsence()"

            @nouveauCompetences="handelNouveauCompetences()"
            @listeCompetences="handelListeCompetences()"

            @nouveauAgrement="handelNouveauAgrement()"
            @listeAgrements="handelListeAgrements()"

            @gestionFraisSalaries="handelGestionFraisSalaries()"

            @gestionJoursFeries="handelGestionJoursFeries()"

            @createTicket="handelCreateTicket()"
            @gestionTicket="handelGestionTicket()"

          />
        </div>
        <div class="content">

          <!-- Start content Indicateur -->
          <Indicateur v-if="flagIndicateur" />
          <!-- End content Indicateur -->

          <!-- Start content Salarie -->
          <CreationSalarie v-if="flagCreationSalarie" />
          <GestionSalarie v-if="flagGestionSalarie" />
          <EditSalarie v-if="flagEditSalarie" />
          <!-- End content Salarie -->

          <!-- Start content Absence -->
          <DemandeAbsence v-if="flagDemandeAbsence" />
          <GestionAbsence v-if="flagGestionDemandeAbsence" />
          <!-- Fin content Absence -->

          <!-- Start content competence -->
          <CreationCompetence v-if="flagCreationCompetence" />
          <GestionCompetences v-if="flagGestionCompetences" />
          <!-- End content Competence -->

          <!-- Start content Agrement -->
          <CreationAgrement v-if="flagCreationAgrement" />
          <GestionAgrements v-if="flagGestionAgrements" />
          <!-- End content Agrement -->


          <!-- Start content Gestion Frais Salarie -->
          <GestionFraisSalarie v-if="flagGestionFraisSalarie" />
          <!-- End content Gestion Frais Salarie -->

          <!-- Start content Gestion Jours Feries -->
          <GestionJoursFeries v-if="flagGestionJoursFeries" />
          <!-- End content Gestion Jours Feries -->

          <!-- Start content Ticket -->
          <CreateTicket v-if="flagCreateTicket" />
          <GestionTicket v-if="flagGestionTicket" />
          <!-- End content Ticket -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from "@/components/Admin/Menu.vue";
import Nav from "@/components/Admin/Nav.vue";

import CreationSalarie from "../components/Admin/content/GestionRH/Salarie/CreationSalarie.vue";
import GestionSalarie from "../components/Admin/content/GestionRH/Salarie/GestionSalarie.vue";
import EditSalarie from "../components/Admin/content/GestionRH/Salarie/EditSalarie.vue";
import DemandeAbsence from "../components/Admin/content/GestionRH/Conge/absence.vue";
import GestionAbsence from "../components/Admin/content/GestionRH/Conge/GestionAbsence.vue";
import CreationAgrement from "../components/Admin/content/GestionRH/Agruments/CreationAgrement.vue";
import GestionAgrements from "../components/Admin/content/GestionRH/Agruments/GestionAgrements.vue";
import CreationCompetence from "../components/Admin/content/GestionRH/Competences/CreationCompetence.vue";
import GestionCompetences from "../components/Admin/content/GestionRH/Competences/GestionCompetences.vue";
import GestionFraisSalarie from "../components/Admin/content/GestionRH/FraisSalarie/GestionFraisSalarie.vue";
import GestionJoursFeries from "../components/Admin/content/GestionRH/JoursFeries/GestionJoursFeries.vue";
import Indicateur from "../components/Admin/content/GestionRH/Indicateur/Indicateur.vue";
import CreateTicket from "../components/Admin/content/GestionRH/Ticket/CreateTicket.vue";
import GestionTicket from "../components/Admin/content/GestionRH/Ticket/GestionTicket.vue";



export default {
  name: "admin",
  data() {

    return {
      flagIndicateur : false,
      flagCreationSalarie: false,
      flagGestionSalarie: false,
      flagEditSalarie: false,
      flagDemandeAbsence : false,
      flagGestionDemandeAbsence : false,
      flagCreationCompetence : false,
      flagGestionCompetences : false,
      flagCreationAgrement : false,
      flagGestionAgrements : false,
      flagGestionFraisSalarie : false,
      flagGestionJoursFeries	 : false,
      flagCreateTicket : false,
      flagGestionTicket : false,

    };

  },
  components: {
    Menu,
    Nav,
    CreationSalarie,
    GestionSalarie,
    EditSalarie,
    DemandeAbsence,
    GestionAbsence,
    CreationAgrement,
    GestionAgrements,
    CreationCompetence,
    GestionCompetences,
    GestionFraisSalarie,
    Indicateur,
    GestionJoursFeries,
    CreateTicket,
    GestionTicket
  },

  methods: {

    handelCreateTicket() {

      this.flagGestionJoursFeries = false;
      this.flagCreationSalarie = false;
      this.flagGestionSalarie = false;
      this.flagEditSalarie = false;
      this.flagDemandeAbsence  = false;
      this.flagGestionDemandeAbsence  = false;
      this.flagCreationCompetence  = false;
      this.flagGestionCompetences  = false;
      this.flagCreationAgrement  = false;
      this.flagGestionAgrements  = false;
      this.flagGestionFraisSalarie = false;
      this.flagIndicateur = false;
      this.flagCreateTicket = true;
      this.flagGestionTicket = false;

    },


    handelGestionTicket() {

      this.flagGestionJoursFeries = false;
      this.flagCreationSalarie = false;
      this.flagGestionSalarie = false;
      this.flagEditSalarie = false;
      this.flagDemandeAbsence  = false;
      this.flagGestionDemandeAbsence  = false;
      this.flagCreationCompetence  = false;
      this.flagGestionCompetences  = false;
      this.flagCreationAgrement  = false;
      this.flagGestionAgrements  = false;
      this.flagGestionFraisSalarie = false;
      this.flagIndicateur = false;
      this.flagCreateTicket = false;
      this.flagGestionTicket = true;

    },

    handelGestionJoursFeries() {

      this.flagGestionJoursFeries = true;
      this.flagCreationSalarie = false,
      this.flagGestionSalarie = false,
      this.flagEditSalarie = false,
      this.flagDemandeAbsence  = false,
      this.flagGestionDemandeAbsence  = false,
      this.flagCreationCompetence  = false,
      this.flagGestionCompetences  = false,
      this.flagCreationAgrement  = false,
      this.flagGestionAgrements  = false
      this.flagGestionFraisSalarie = false;
      this.flagIndicateur = false;
      this.flagCreateTicket = false;
      this.flagGestionTicket = false;
      
    },

    handelGestionrh() {
      this.flagGestionJoursFeries = false;
      this.flagCreationSalarie = false,
      this.flagGestionSalarie = false,
      this.flagEditSalarie = false,
      this.flagDemandeAbsence  = false,
      this.flagGestionDemandeAbsence  = false,
      this.flagCreationCompetence  = false,
      this.flagGestionCompetences  = false,
      this.flagCreationAgrement  = false,
      this.flagGestionAgrements  = false
      this.flagGestionFraisSalarie = false;
      this.flagIndicateur = true;
      this.flagCreateTicket = false;
      this.flagGestionTicket = false;
    },

    handelGestionFraisSalaries() {
      this.flagGestionJoursFeries = false;
      this.flagCreationSalarie = false,
      this.flagGestionSalarie = false,
      this.flagEditSalarie = false,
      this.flagDemandeAbsence  = false,
      this.flagGestionDemandeAbsence  = false,
      this.flagCreationCompetence  = false,
      this.flagGestionCompetences  = false,
      this.flagCreationAgrement  = false,
      this.flagGestionAgrements  = false
      this.flagGestionFraisSalarie = true;
      this.flagIndicateur = false;
      this.flagCreateTicket = false;
      this.flagGestionTicket = false;
    },

    handelNouveauCompetences() {
      this.flagGestionJoursFeries = false;
      this.flagCreationSalarie = false;
      this.flagGestionSalarie = false;
      this.flagEditSalarie = false;
      this.flagDemandeAbsence = false;
      this.flagGestionDemandeAbsence = false;
      this.flagCreationCompetence = true;
      this.flagGestionCompetences = false;
      this.flagCreationAgrement = false;
      this.flagGestionAgrements = false;
      this.flagGestionFraisSalarie = false;
      this.flagIndicateur = false;
      this.flagCreateTicket = false;
      this.flagGestionTicket = false;
    },

    handelListeCompetences() {
      this.flagGestionJoursFeries = false;
      this.flagCreationSalarie = false;
      this.flagGestionSalarie = false;
      this.flagEditSalarie = false;
      this.flagDemandeAbsence = false;
      this.flagGestionDemandeAbsence = false;
      this.flagCreationCompetence = false;
      this.flagGestionCompetences = true;
      this.flagCreationAgrement = false;
      this.flagGestionAgrements = false;
      this.flagGestionFraisSalarie = false;
      this.flagIndicateur = false;
      this.flagCreateTicket = false;
      this.flagGestionTicket = false;
      
    },

    handelCreationSalarie() {
      this.flagGestionJoursFeries = false;
      this.flagCreationSalarie = true,
      this.flagGestionSalarie = false,
      this.flagEditSalarie = false,
      this.flagDemandeAbsence  = false,
      this.flagGestionDemandeAbsence  = false,
      this.flagCreationCompetence  = false,
      this.flagGestionCompetences  = false,
      this.flagCreationAgrement  = false,
      this.flagGestionAgrements  = false
      this.flagGestionFraisSalarie = false;
      this.flagIndicateur = false;
      this.flagCreateTicket = false;
      this.flagGestionTicket = false;

    },

    handelGestionSalarie() {
      this.flagGestionJoursFeries = false;
      this.flagCreationSalarie = false,
      this.flagGestionSalarie = true,
      this.flagEditSalarie = false,
      this.flagDemandeAbsence  = false,
      this.flagGestionDemandeAbsence  = false,
      this.flagCreationCompetence  = false,
      this.flagGestionCompetences  = false,
      this.flagCreationAgrement  = false,
      this.flagGestionAgrements  = false
      this.flagGestionFraisSalarie = false;
      this.flagIndicateur = false;
      this.flagCreateTicket = false;
      this.flagGestionTicket = false;
    },

    handelEditSalarie() {
      this.flagGestionJoursFeries = false;
      this.flagCreationSalarie = false,
      this.flagGestionSalarie = false,
      this.flagEditSalarie = true,
      this.flagDemandeAbsence  = false,
      this.flagGestionDemandeAbsence  = false,
      this.flagCreationCompetence  = false,
      this.flagGestionCompetences  = false,
      this.flagCreationAgrement  = false,
      this.flagGestionAgrements  = false
      this.flagGestionFraisSalarie = false;
      this.flagIndicateur = false;
      this.flagCreateTicket = false;
      this.flagGestionTicket = false;
    },

    handelDemandeAbsence() {
      this.flagGestionJoursFeries = false;
      this.flagCreationSalarie = false,
      this.flagGestionSalarie = false,
      this.flagEditSalarie = false,
      this.flagDemandeAbsence  = true,
      this.flagGestionDemandeAbsence  = false,
      this.flagCreationCompetence  = false,
      this.flagGestionCompetences  = false,
      this.flagCreationAgrement  = false,
      this.flagGestionAgrements  = false
      this.flagGestionFraisSalarie = false;
      this.flagIndicateur = false;
      this.flagCreateTicket = false;
      this.flagGestionTicket = false;
    },

    handelDemandeGestionAbsence() {
      this.flagGestionJoursFeries = false;
      this.flagCreationSalarie = false,
      this.flagGestionSalarie = false,
      this.flagEditSalarie = false,
      this.flagDemandeAbsence  = false,
      this.flagGestionDemandeAbsence  = true,
      this.flagCreationCompetence  = false,
      this.flagGestionCompetences  = false,
      this.flagCreationAgrement  = false,
      this.flagGestionAgrements  = false
      this.flagGestionFraisSalarie = false;
      this.flagIndicateur = false;
      this.flagCreateTicket = false;
      this.flagGestionTicket = false;
    },

    handelNouveauAgrement() {
      this.flagGestionJoursFeries = false;
      this.flagCreationSalarie = false,
      this.flagGestionSalarie = false,
      this.flagEditSalarie = false,
      this.flagDemandeAbsence  = false,
      this.flagGestionDemandeAbsence  = false,
      this.flagCreationCompetence  = false,
      this.flagGestionCompetences  = false,
      this.flagCreationAgrement  = true,
      this.flagGestionAgrements  = false
      this.flagGestionFraisSalarie = false;
      this.flagIndicateur = false;
      this.flagCreateTicket = false;
      this.flagGestionTicket = false;
    },

    handelListeAgrements() {
      this.flagGestionJoursFeries = false;
      this.flagCreationSalarie = false,
      this.flagGestionSalarie = false,
      this.flagEditSalarie = false,
      this.flagDemandeAbsence  = false,
      this.flagGestionDemandeAbsence  = false,
      this.flagCreationCompetence  = false,
      this.flagGestionCompetences  = false,
      this.flagCreationAgrement  = false,
      this.flagGestionAgrements  = true
      this.flagGestionFraisSalarie = false;
      this.flagIndicateur = false;
      this.flagCreateTicket = false;
      this.flagGestionTicket = false;
    }

  },


created() {

      if(!sessionStorage.getItem("token"))
      {
        this.$router.push("/")
      }
}



};
</script>

<style scoped>
.admin .container .header {
  width: 100%;
  height: 80px;
  padding: 0px;
  margin: 0px;
}

.admin .container .menu-content{
display: grid;
grid-template-columns: 20% 80%;

}


.admin .container .menu-content menu {
width: 20%;

}
.admin .container .menu-content .content {
  width: 100%;




}



</style>