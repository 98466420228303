<template>
    <div class="admin">
      <div class="container">
  
        <div class="header">
          <Nav />
        </div>
  
        <div class="menu-content">
          <div class="menu">
            <Menu
              @indicateurProduction="handelIndicateurProduction()"
            />
          </div>
          <div class="content">
  
            <IndicateurProduction v-if="flagIndicateurProduction" />
  
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Menu from "@/components/Admin/Menu.vue";
  import Nav from "@/components/Admin/Nav.vue";
  
  import IndicateurProduction from "@/components/Admin/content/Production/IndicateurProduction.vue";
  
  
  
  export default {
    name: "admin",
    data() {
      return {
        flagIndicateurProduction : true,
      };
    },
    components: {
      Menu,
      Nav,
      IndicateurProduction
    },
  
    methods: {
      handelIndicateurProduction() {
        this.flagIndicateurProduction = true;
      }
    },
  
  
  created() {
  
    if(!sessionStorage.getItem("token")) {
          this.$router.push("/")
    }
  
  }
  
  
  
  };
  </script>
  
  <style scoped>
  
  
  .admin .container .header {
    width: 100%;
  }
  
  .admin .container .menu-content{
  display: grid;
  grid-template-columns: 20% 80%;
  }
  
  
  
  .admin .container .menu-content .content {
    width: 100%;
  
  
  }
  
  
  </style>