<template>
  <div class="etat-affaire-chart">
    <h4>ÉTAT D'AVANCEMENT D'AFFAIRE</h4>
    <canvas id="myEtatAvancementAffaire"></canvas>
  </div>
</template>

<script>
import Service from "../../../../../Service";
import Chart from "chart.js";
export default {

  name: "affaire-missions-chart",

  data() {
    return {
      annee : null,
    };
  },

  props: {
    selectYear : Number,
    selectMois : Number
  },

  methods: {},

  created() {
  },

  mounted() {

      Service.IndicteurAffaire(this.selectYear, this.selectMois)
        .then((result) => {



            const { retune, prospection,  sommeRetune, sommeProspection} = result.data;
            const sommes = retune + prospection;

            const pourcentageRetune = parseFloat((retune * 100) / sommes).toFixed(2);
            const pourcentagProspection = parseFloat((prospection * 100) / sommes).toFixed(2);

            const data = {
                labels: [
                  `Affaires Retenu :${parseInt(sommeRetune )} MAD`,
                  `Affaires Prospection :${parseInt(sommeProspection)} MAD`
                ],
                datasets: [{
                    label: 'My First Dataset',
                    data: [pourcentageRetune, pourcentagProspection],
                    backgroundColor: [
                    'rgb(255, 0, 0)',
                    'rgb(19, 209, 0)'
                    ],
                    hoverOffset: 4
                }]
            };

            const config = {
                type: 'doughnut',
                data: data,
            };

            // instance Chart CTX
            const ctx = document.getElementById("myEtatAvancementAffaire");
            const myEtatAvancementAffaire = new Chart(ctx, config);

            return myEtatAvancementAffaire;

        })
        .catch((error) => {
          console.log(error.message)
        });

  }


};
</script>

<style scoped>

  .etat-affaire-chart {
    max-height: fit-content;
  }
  .etat-affaire-chart > canvas{
      height: 100%;
  }

  .etat-affaire-chart > h4 {
    width: 100%;
      justify-content: center;
      display: flex;
      color: #1e1ed3;
      font-size: x-large;
      opacity: 0.6;
      font-weight: 500;
      margin-bottom: 10px;
      margin-top: 20px;
  }

</style>