<template>

    <div class="gestionInspecteur">

        <h3>RÉPARATION INTERLOCUTEURS & CLIENTS</h3>

        <p v-if="succes" :class="{ succes: succes }">
            {{ msg }}
        </p>

        <p v-if="echec" :class="{ echec: echec }">
            {{ msg }}
        </p>

        <div class="rechercher-table">

            <div>
                <div class="rechercher">
                    <input type="text" v-model="rechercherClient" placeholder="Recherche un client">
                </div>

                <div class="rechercher">
                    <input type="text" v-model="rechercherInterlocuteur" placeholder="Recherche une Interlocuteur">
                </div>

                <div class="rechercher">
                    <input type="text" v-model="rechercherAffaire" placeholder="Recherche un affaire">
                </div>

                <div class="rechercher">
                    <input type="text" v-model="rechercherRapport" placeholder="Recherche un rapport">
                </div>
            </div>
            <div>

                <div class="rechercher">
                  <ul class="pagination">
                        <button @click="selectPageLeftClient()"><i class="fa-solid fa-backward"></i></button>
                        <li v-for="n in numberPageClient" :key="n" @click="selectPageClient(n)">
                            <p v-show="numberPageSelectClient[n-1] == false">{{ n }}</p>
                            <p  v-show="numberPageSelectClient[n-1] == true" style="background-color: #0300db; color: white;">{{ n }}</p>
                        </li>
                        <button @click="selectPageRightClient()"><i class="fa-solid fa-forward"></i></button>
                  </ul>
                </div>

                <div class="rechercher">
                  <ul class="pagination">
                        <button @click="selectPageLeftInterlocuteur()"><i class="fa-solid fa-backward"></i></button>
                        <li v-for="n in numberPageInterlocuteur" :key="n" @click="selectPageInterlocuteur(n)">
                            <p v-show="numberPageSelectInterlocuteur[n-1] == false">{{ n }}</p>
                            <p  v-show="numberPageSelectInterlocuteur[n-1] == true" style="background-color: #0300db; color: white;">{{ n }}</p>
                        </li>
                        <button @click="selectPageRightInterlocuteur()"><i class="fa-solid fa-forward"></i></button>
                  </ul>
                </div>

                <div class="rechercher">
                  <ul class="pagination">
                        <button @click="selectPageLeftAffaire()"><i class="fa-solid fa-backward"></i></button>
                        <li v-for="n in numberPageAffaire" :key="n" @click="selectPageAffaire(n)">
                            <p v-show="numberPageSelectAffaire[n-1] == false">{{ n }}</p>
                            <p  v-show="numberPageSelectAffaire[n-1] == true" style="background-color: #0300db; color: white;">{{ n }}</p>
                        </li>
                        <button @click="selectPageRightAffaire()"><i class="fa-solid fa-forward"></i></button>
                  </ul>
                </div>

                <div class="rechercher">
                  <ul class="pagination">
                        <button @click="selectPageLeftRapport()"><i class="fa-solid fa-backward"></i></button>
                        <li v-for="n in numberPageRapport" :key="n" @click="selectPageRapport(n)">
                            <p v-show="numberPageSelectRapport[n-1] == false">{{ n }}</p>
                            <p  v-show="numberPageSelectRapport[n-1] == true" style="background-color: #0300db; color: white;">{{ n }}</p>
                        </li>
                        <button @click="selectPageRightRapport()"><i class="fa-solid fa-forward"></i></button>
                  </ul>
                </div>

            </div>

            <div>

                <table class="inspecteurs">
                    <tr>
                        <th></th>
                        <th></th>
                        <th> Clients {{ countClients }}</th>
                    </tr>
                    <tr v-for="(item, i) in filterClients" :key="i">
                        <td>{{ i + 1 }}</td>
                        <td><input type="checkbox" :value="item._id"></td>
                        <td>
                            <ul>
                                <li>id: {{ item._id }}</li>
                                <li>Nom: {{ item.nom }}</li>
                                <li>Prenom: {{ item.prenom }}</li>
                                <li>Ref Client: {{ item.refClient }}</li>
                                <li>Nom Societe: {{ item.nomSociete }}</li>
                                <li>Adresse: {{ item.adresse }}</li>
                                <li>Code postal: {{ item.codePostal }}</li>
                                <li>Ville: {{ item.ville }}</li>
                                <li>Email: {{ item.email }}</li>
                                <li>Password: {{ item.password }}</li>
                                <li>Telephone: {{ item.telephone }}</li>
                                <li>cas: {{ item.cas }}</li>
                                <li>associate: {{ item.associate }}</li>
                                <li>date: {{ item.date }}</li>
                                <li>-----------------</li>
                            </ul>
                        </td>
                    </tr>
                </table>

                <table class="inspecteurs">
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Interlocuteurs {{ countInterlocuteurs }}</th>
                    </tr>
                    <tr v-for="(item, i) in filterInterlocuteurs" :key="i">
                        <td>{{ i + 1 }}</td>
                        <td><input type="checkbox" :value="item._id"></td>
                        <td>
                            <ul>
                                <li>id: {{ item._id }}</li>
                                <li>Nom: {{ item.nom }}</li>
                                <li>Prenom: {{ item.prenom }}</li>
                                <li>clientId: {{ item.clientId }}</li>
                                <li>fonction: {{ item.fonction }}</li>
                                <li>pass: {{ item.pass }}</li>
                                <li>Satuts: {{ item.status }}</li>
                                <li>Send: {{ item.send }}</li>
                                <li>Interlocuteurs: {{ item.interlocuteurs }}</li>
                                <li>Email: {{ item.email }}</li>
                                <li>Password: {{ item.password }}</li>
                                <li>Telephone: {{ item.telephone }}</li>
                                <li>date: {{ item.date }}</li>
                                <li>-----------------</li>
                            </ul>
                        </td>
                    </tr>
                </table>

                <table class="inspecteurs">
                    <tr>
                        <th></th>
                        <th></th>
                        <th> Affaires {{ countAffaires }}</th>
                    </tr>
                    <tr v-for="(item, i) in filterAffaires" :key="i">
                        <td>{{ i + 1 }}</td>
                        <td><input type="checkbox" :value="item._id"></td>
                        <td>{{ item.numeroAffaire }}</td>
                    </tr>
                </table>

                <table class="inspecteurs">
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Rapports {{ countRapport }}</th>
                    </tr>
                    <tr v-for="(item, i) in filterRapports" :key="i">
                        <td>{{ i + 1 }}</td>
                        <td><input type="checkbox" :value="item._id"></td>
                        <td>{{ item.referenceRapport }}</td>
                    </tr>
                </table>

            </div>

        </div>

            <div>
                <button value="Reparer" @click="repareInterlocuteurClient()">Repare</button>
            </div>
  </div>

</template>

<script>
import Service from "../../../../Service";
export default {
  name: "gestionAdmin",
  components: {
  },
  data() {
    return {

      numberPageSelectClient : [],
      numberPageSelectInterlocuteur : [],
      numberPageSelectAffaire : [],
      numberPageSelectRapport : [],
      numberPageSelect : [],
      rapportDelete : null,
      rapportId : null,
      filename : null,
      flagModelDelete : false,
      textDeleteModel : null,
      pageIndex : 0,
      itemsPerPage : 10,
      numberPageClient: null,
      numberPageInterlocuteur: null,
      numberPageAffaire: null,
      numberPageRapport: null,
      succes: false,
      echec: false,
      msg: null,
      rechercherClient: null,
      rechercherInterlocuteur: null,
      rechercherAffaire: null,
      rechercherRapport: null,
      preRapports : [],
      rapports: [],
      preAffaires : [],
      affaires: [],
      preInterlocuteurs : [],
      interlocuteurs : [],
      preClients : [],
      clients : [],
      flagEditRapport: false,
      checkedRapports : [],
      maxAnnee : [],
      annee : null,
      countRapport : null,
      countAffaires : null,
      countClients : null,
      countInterlocuteurs : null
    };
  },

  computed : {

            filterClients() {

                return this.clients.filter((item) => {
                  if(!this.rechercherClient)
                  {
                    return item;
                  } else {
                    if(item != undefined) {
                     return !item.email.toString().toLowerCase().indexOf(this.rechercherClient.toLowerCase().toString());
                    }
                  }
                });

            },


            filterInterlocuteurs() {
            return this.interlocuteurs.filter((item) => {
                if(!this.rechercherInterlocuteur) {
                    return item;
                  } else {
                     return !item.email.toString().toLowerCase().indexOf(this.rechercherInterlocuteur.toLowerCase().toString());
                  }

                });
            },

            filterAffaires() {

                return this.affaires.filter((item) => {
                  if(!this.rechercherAffaire)
                  {
                    return item;
                  } else {
                    if(item != undefined) {
                        const numeroAffaire = String(this.rechercherAffaire).toLocaleLowerCase();
                        const selectNumeroAffaire = String(item.numeroAffaire).toLocaleLowerCase();
                        return !selectNumeroAffaire.toString().indexOf(numeroAffaire);
                    }
                  }
                });

            },


            filterRapports() {
            return this.rapports.filter((item) => {

              
              if(this.annee == "tout") {

                  if(!this.rechercherRapport) {
                    return item;
                  } else {

                    if(item != undefined) {

                      const numeroAffaire = item.numeroAffaire != undefined ? item.numeroAffaire : "";
                      const dateIntervention = item.dateIntervention != undefined ? new Date(item.dateIntervention).toLocaleDateString() : "";
                      const dateProductionControle = item.dateProductionControle != undefined ? new Date(item.dateProductionControle).toLocaleDateString() : "";
                      const referenceRapport = item.referenceRapport != undefined ? item.referenceRapport : "";
                      const designation = item.designation != undefined ? item.designation : "";
                      const category = item.category != undefined ? item.category : "";
                      const typeRapport = item.typeRapport != undefined ? item.typeRapport : "";
                      const responsableClient = item.responsableClient != undefined ? item.responsableClient : "";
                      const filename = item.filename != undefined ? item.filename : "";


                      return !numeroAffaire.toString().toLowerCase().indexOf(this.rechercherRapport.toLowerCase().toString()) ||
                      !referenceRapport.toString().toLowerCase().indexOf(this.rechercherRapport.toLowerCase().toString()) ||
                      !designation.toString().toLowerCase().indexOf(this.rechercherRapport.toLowerCase().toString()) ||
                      !category.toString().toLowerCase().indexOf(this.rechercherRapport.toLowerCase().toString()) ||
                      !filename.toString().toLowerCase().indexOf(this.rechercherRapport.toLowerCase().toString()) ||
                      !typeRapport.toString().toLowerCase().indexOf(this.rechercherRapport.toLowerCase().toString()) ||
                      !responsableClient.toString().toLowerCase().indexOf(this.rechercherRapport.toLowerCase().toString()) ||
                      !dateIntervention.indexOf(this.rechercherRapport) ||
                      !dateProductionControle.indexOf(this.rechercherRapport);

                    }

                  }
              } else {
                
                if(!this.rechercherRapport) {
                  if(item != undefined) {
                      if(this.annee === new Date(item.dateProductionControle).getUTCFullYear()) {
                          return item;
                      }
                  }
                } else {
                    if(item != undefined) {

                      const numeroAffaire = item.numeroAffaire != undefined ? item.numeroAffaire : "";
                      const dateIntervention = item.dateIntervention != undefined ? new Date(item.dateIntervention).toLocaleDateString() : "";
                      const dateProductionControle = item.dateProductionControle != undefined ? new Date(item.dateProductionControle).toLocaleDateString() : "";
                      const referenceRapport = item.referenceRapport != undefined ? item.referenceRapport : "";
                      const designation = item.designation != undefined ? item.designation : "";
                      const category = item.category != undefined ? item.category : "";
                      const typeRapport = item.typeRapport != undefined ? item.typeRapport : "";
                      const responsableClient = item.responsableClient != undefined ? item.responsableClient : "";
                      const filename = item.filename != undefined ? item.filename : "";


                      return !numeroAffaire.toString().toLowerCase().indexOf(this.rechercherRapport.toLowerCase().toString()) ||
                      !referenceRapport.toString().toLowerCase().indexOf(this.rechercherRapport.toLowerCase().toString()) ||
                      !designation.toString().toLowerCase().indexOf(this.rechercherRapport.toLowerCase().toString()) ||
                      !category.toString().toLowerCase().indexOf(this.rechercherRapport.toLowerCase().toString()) ||
                      !filename.toString().toLowerCase().indexOf(this.rechercherRapport.toLowerCase().toString()) ||
                      !typeRapport.toString().toLowerCase().indexOf(this.rechercherRapport.toLowerCase().toString()) ||
                      !responsableClient.toString().toLowerCase().indexOf(this.rechercherRapport.toLowerCase().toString()) ||
                      !dateIntervention.indexOf(this.rechercherRapport) ||
                      !dateProductionControle.indexOf(this.rechercherRapport);

                    }
                }
              
              }

            });
      }
  },
  methods: {

    repareInterlocuteurClient() {
        Service.RepareInterlocuteurClient()
        .then((result) => {
            console.log(result)
        })
        .catch((error) => {
            console.log(error)
        });
    },

    selectPageLeftRapport() {

        for(let i = 0; i < this.numberPageRapport; i++) {
              this.numberPageSelectRapport[i] = false;
        }

        this.numberPageSelectRapport[0] = true;

        this.rapports = [];

        for(let i = 0 * this.itemsPerPage; i < (0 * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.rapports.push(this.preRapports[i]);
        }
        
    },

    selectPageRightRapport() {

        for(let i = 0; i < this.numberPageRapport; i++) {
              this.numberPageSelectRapport[i] = false;
        }

        const indice = this.numberPage - 1;
        this.numberPageSelectRapport[indice] = true;
        this.rapports = [];

        for(let i = indice * this.itemsPerPage; i < (indice * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.rapports.push(this.preRapports[i]);
        }
    },

    selectPageRapport(value) {
        const select = value - 1;
        // change current select for change color
        for(let i = 0; i < this.numberPageRapport; i++) {
              this.numberPageSelectRapport[i] = false;
        }

        this.numberPageSelectRapport[select] = true;
        this.rapports = [];

        for(let i = select * this.itemsPerPage; i < (select * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.rapports.push(this.preRapports[i]);
        }
    },

    selectPageLeftAffaire() {

        for(let i = 0; i < this.numberPageAffaire; i++) {
              this.numberPageSelectAffaire[i] = false;
        }

        this.numberPageSelectAffaire[0] = true;

        this.affaires = [];

        for(let i = 0 * this.itemsPerPage; i < (0 * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.affaires.push(this.preAffaires[i]);
        }
        
    },

    selectPageRightAffaire() {

        for(let i = 0; i < this.numberPageRapport; i++) {
              this.numberPageSelectAffaire[i] = false;
        }

        const indice = this.numberPage - 1;
        this.numberPageSelectAffaire[indice] = true;
        this.affaires = [];

        for(let i = indice * this.itemsPerPage; i < (indice * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.affaires.push(this.preAffaires[i]);
        }
    },

    selectPageAffaire(value) {
        const select = value - 1;
        // change current select for change color
        for(let i = 0; i < this.numberPageAffaire; i++) {
              this.numberPageSelectAffaire[i] = false;
        }

        this.numberPageSelectRapport[select] = true;
        this.affaires = [];

        for(let i = select * this.itemsPerPage; i < (select * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.affaires.push(this.preAffaires[i]);
        }
    },

    selectPageLeftClient() {

        for(let i = 0; i < this.numberPageClient; i++) {
              this.numberPageSelectClient[i] = false;
        }

        this.numberPageSelectClient[0] = true;

        this.affaires = [];

        for(let i = 0 * this.itemsPerPage; i < (0 * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.clients.push(this.preClients[i]);
        }
        
    },

    selectPageRightClient() {

        for(let i = 0; i < this.numberPageClient; i++) {
              this.numberPageSelectClient[i] = false;
        }

        const indice = this.numberPage - 1;
        this.numberPageSelectClient[indice] = true;
        this.clients = [];

        for(let i = indice * this.itemsPerPage; i < (indice * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.clients.push(this.preClients[i]);
        }
    },

    selectPageClient(value) {
        const select = value - 1;
        // change current select for change color
        for(let i = 0; i < this.numberPageClient; i++) {
              this.numberPageSelectClient[i] = false;
        }

        this.numberPageSelectClient[select] = true;
        this.clients = [];

        for(let i = select * this.itemsPerPage; i < (select * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.clients.push(this.preClients[i]);
        }
    },

    selectPageLeftInterlocuteur() {

        for(let i = 0; i < this.numberPageClient; i++) {
              this.numberPageSelectClient[i] = false;
        }

        this.numberPageSelectClient[0] = true;

        this.affaires = [];

        for(let i = 0 * this.itemsPerPage; i < (0 * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.clients.push(this.preClients[i]);
        }
        
    },

    selectPageRightInterlocuteur() {

        for(let i = 0; i < this.numberPageInterlocuteur; i++) {
              this.numberPageSelectInterlocuteur[i] = false;
        }

        const indice = this.numberPage - 1;
        this.numberPageSelectInterlocuteur[indice] = true;
        this.interlocuteurs = [];

        for(let i = indice * this.itemsPerPage; i < (indice * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.interlocuteurs.push(this.preInterlocuteurs[i]);
        }
    },

    selectPageInterlocuteur(value) {
        const select = value - 1;
        // change current select for change color
        for(let i = 0; i < this.numberPageInterlocuteur; i++) {
              this.numberPageSelectInterlocuteur[i] = false;
        }

        this.numberPageSelectInterlocuteur[select] = true;
        this.interlocuteurs = [];

        for(let i = select * this.itemsPerPage; i < (select * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.interlocuteurs.push(this.preInterlocuteurs[i]);
        }
    },

    update() {
      Service.updateFournisseur(this.fournisseur, this.fournisseurId)
      .then((response) => {
          this.traitement = true;
          this.msgAdmin = "Veuillez patienter quelques secondes pour enregister";
          if(response) {
              setTimeout(() => {
                  return this.$router.go(this.$router.currentRoute);
              }, 10000)
          }
      })
      .catch((error) => {
          this.error = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
      });
    },

    closeDeleteModel() {
      this.flagModelDelete = false;
    },

    validerDeleteModel() {

      this.flagModelDelete = false;

      if(this.checkedRapports.length > 0) {

          // // Delete in Fron-end
          for(let i = 0; i < this.checkedRapports.length; i++ ) {

            const index = this.rapports.findIndex(object => {
              return object._id === this.checkedRapports[i][0];
            });
            // //delete in front end
            this.rapports.splice(index, 1);
          }

          // delete in db backend
          Service.deleteRapports(this.checkedRapports)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {

                this.msg = error.message;
                console.error(`HTTP error: ${error.name} => ${error.message}`);
                throw "fail request at: GET /refreshtime";

          });

      } else {
            
            this.rapports.splice(this.rapportDelete, 1);
            Service.deleteRapport(this.rapportId, this.filename)
            .then((result) => {
                this.msg = result.data.msg;
            })
            .catch((error) => {

                this.msg = error.message;
                console.error(`HTTP error: ${error.name} => ${error.message}`);
                throw "fail request at: GET /refreshtime";

            });

      }
    },

    resendEmail(rapportId) {

        Service.ResendEmail(rapportId)
          .then((result) => {
              console.log(result.data);
          })
          .catch((error) => {
              console.log(error)
        });
    },

    // delete more one admins
    notConfirm(rapportId, index) {
        Service.NotConfirm(rapportId)
          .then((result) => {
             if(result) {
              this.rapports[index].confirmation = false;
             }
          })
          .catch((error) => {
              console.log(error)
        });
    },

    // delete more one admins
    confirm(rapportId, index) {
        Service.Confirm(rapportId)
          .then((result) => {
            if(result) {
              this.rapports[index].confirmation = true;
            }
          })
          .catch((error) => {
              console.log(error)
          });
    },

    // delete more one admins
    deleteRapports() {
      this.flagModelDelete = true;
      this.textDeleteModel = "Êtes-vous sûr de supprimer tout ?";
    },

    // delete one admin
    deleteRapport(i, itemId, filename) {
      this.flagModelDelete = true;
      this.textDeleteModel = "Êtes-vous sûr de supprimer ?";
      this.rapportDelete = i;
      this.rapportId = itemId
      this.filename = filename
    },

    // edit one admin
    editRapport(rapportId) {
        this.flagEditRapport = true;
        this.rapportId = rapportId;
    },

    showPdf(filename) {
        Service.displayRapport(filename)
          .then((result) => {
             console.log(result);
          })
          .catch((error) => {
              console.log(error)
        });
    }


    },
//clients
  created() {

      // get year anee current
      const today = new Date();
      this.annee = today.getUTCFullYear();

      for (let i = this.annee; i >= 2019; i--) {
        this.maxAnnee.push(i);
      }

      this.annee = "tout";



      Service.readRapports()
      .then((result) => {

        this.countRapport =  result.data.rapports.length;
        // countAffaires : null,
          for(let i = 0; i < result.data.rapports.length; i++) {
            this.preRapports.push(result.data.rapports[i]);
          }

          this.numberPageRapport = Math.ceil(result.data.rapports.length / this.itemsPerPage);

          for(let i = 0; i < this.numberPageRapport; i++) {
              this.numberPageSelectRapport.push(false);
          }
          
          this.numberPageSelectRapport[0] = true;

          for(let i = this.pageIndex * this.itemsPerPage; i < (this.pageIndex * this.itemsPerPage) + this.itemsPerPage; i++) {
              this.rapports.push(this.preRapports[i]);
          }
      })
      .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
      });


      Service.readAllAffaires()
      .then((result) => {

          this.countAffaires = result.data.result.length;

          for(let i = 0; i < result.data.result.length; i++) {
            this.preAffaires.push(result.data.result[i]);
          }


          this.numberPageAffaire = Math.ceil(result.data.result.length / this.itemsPerPage);

          for(let i = 0; i < this.numberPageAffaire; i++) {
              this.numberPageSelectAffaire.push(false);
          }
          
          this.numberPageSelectAffaire[0] = true;

          for(let i = this.pageIndex * this.itemsPerPage; i < (this.pageIndex * this.itemsPerPage) + this.itemsPerPage; i++) {
              this.affaires.push(this.preAffaires[i]);
          }

      })
      .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
      });

      Service.readClient()
      .then((result) => {

          this.countClients = result.data.clients.length;

          for(let i = 0; i < result.data.clients.length; i++) {
            this.preClients.push(result.data.clients[i]);
          }


          this.numberPageClient = Math.ceil(result.data.clients.length / this.itemsPerPage);

          for(let i = 0; i < this.numberPageClient; i++) {
              this.numberPageSelectClient.push(false);
          }
          
          this.numberPageSelectClient[0] = true;

          for(let i = this.pageIndex * this.itemsPerPage; i < (this.pageIndex * this.itemsPerPage) + this.itemsPerPage; i++) {
              this.clients.push(this.preClients[i]);
          }

      })
      .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
      });


      Service.readInterlocuteur()
      .then((result) => {

        
          this.countInterlocuteurs = result.data.interlocuteurs.length;

          for(let i = 0; i < result.data.interlocuteurs.length; i++) {
            this.preInterlocuteurs.push(result.data.interlocuteurs[i]);
          }


          this.numberPageInterlocuteur = Math.ceil(result.data.interlocuteurs.length / this.itemsPerPage);

          for(let i = 0; i < this.numberPageInterlocuteur; i++) {
              this.numberPageSelectInterlocuteur.push(false);
          }
          
          this.numberPageSelectInterlocuteur[0] = true;

          for(let i = this.pageIndex * this.itemsPerPage; i < (this.pageIndex * this.itemsPerPage) + this.itemsPerPage; i++) {
              this.interlocuteurs.push(this.preInterlocuteurs[i]);
          }

      })
      .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
      });



      
  }


};
</script>

<style scoped>

.succes {
  background-color: green;
  color: white;
  padding: 10px;
  height: fit-content;
  width: 100%;
}

.echec {
  background-color: red;
  color: white;
  padding: 10px;
  height: fit-content;
  width: 100%;
}

.gestionInspecteur {
  width: 100%;
  height: 100%;
  padding-top: 0px;
  padding-left: 0px;
  margin: 0px;
  padding: 2px;
  position: relative;
}
.gestionInspecteur h3 {
  width: 100%;
  margin:auto;
  color: white;
  background: linear-gradient(346deg, rgba(207,31,33,1) 0%, rgba(24,86,161,1) 100%);    text-align: center;
  text-align: center;
  padding:15px;
  margin-bottom: 20px;
}
.rechercher-table {
  width: 100%;
}

.gestionInspecteur > .rechercher-table > div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
}

.gestionInspecteur > .rechercher-table > div > div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}


.gestionInspecteur > .rechercher-table > div > div > input {
  width: 80%;
  height: 50px;
}


.gestionInspecteur .rechercher-table table  {
  width: 100%;
}

.gestionInspecteur .rechercher-table table tr  {
  width: 100%;
}

.gestionInspecteur .rechercher-table table tr th {
    background-color: #243064;
    color: white;
    border-right: 1px solid white;
    padding: 10px 4px;
}

table{
  border-collapse: collapse;
}

.gestionInspecteur .rechercher-table table tr td {
    color: black;
    padding: 5px;
}

.gestionInspecteur .rechercher-table table tr:nth-child(even) {
  background-color: #f8f8f8;
}

.pagination {
  display: flex;
  flex-direction: row;
}

.pagination > li {
  margin-left: 4px;
  margin-right: 2px;
  cursor: pointer;
}




</style>