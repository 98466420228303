<template>
  <div class="facture-chart">
    <h4>ÉTAT D'AVANCEMENT FACTURE</h4>
    <canvas id="myEtatAvancementFacture"></canvas>
  </div>
</template>

<script>
import Service from "../../../../../Service";
import Chart from "chart.js";
export default {

  name: "facture-chart",

  data() {
    return {
      annee : null,
    };
  },

  props: {
    selectYear : Number,
    selectMois : Number
  },

  methods: {},

  created() {
  },

  mounted() {

      Service.IndicteurAvancementFacture(this.selectYear, this.selectMois)
        .then((result) => {

            const { affaires, emise,  paiement } = result.data;

            const sommes = affaires + emise + paiement;

            const pourcentageEncaisse = parseFloat((affaires * 100) / sommes).toFixed(2);
            const pourcentagEmise = parseFloat((emise * 100) / sommes).toFixed(2);
            const pourcentagPaiement = parseFloat((paiement * 100) / sommes).toFixed(2);

            const data = {
                labels: [
                  `En Attente de Facturation :${parseInt(affaires)}`,
                  `Facture Émise :${parseInt(emise)}`,
                  `Facture Encaissée :${parseInt(paiement)}`
                ],
                datasets: [{
                    label: 'My First Dataset',
                    data: [pourcentageEncaisse, pourcentagEmise, pourcentagPaiement],
                    backgroundColor: [
                    'rgb(255, 0, 0)',
                    'rgb(19, 209, 0)',
                    'rgb(78, 0, 0)'
                    ],
                    hoverOffset: 4
                }]
            };

            const config = {
                type: 'doughnut',
                data: data,
            };

            // instance Chart CTX
            const ctx = document.getElementById("myEtatAvancementFacture");
            const myEtatAvancementFacture = new Chart(ctx, config);

            return myEtatAvancementFacture;

        })
        .catch((error) => {
          console.log(error.message)
        });

  }


};
</script>

<style scoped>

  .facture-chart {
    max-height: fit-content;
  }
  .facture-chart > canvas{
      height: 100%;
  }

  .facture-chart > h4 {
       width: 100%;
      justify-content: center;
      display: flex;
      color: #1e1ed3;
      font-size: x-large;
      opacity: 0.6;
      font-weight: 500;
      margin-bottom: 10px;
      margin-top: 20px;
  }

</style>