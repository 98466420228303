<template>
    <div class="creationClient">
  
      <h3>GESTION DES JOURS FÉRIÉS</h3>
  
      <Traitement :msg="msgCompetence" v-if="flagTraitement == true"/>
  
      <p v-if="echec" :class="{ echec: echec }">
          {{ error }}
      </p>
  
      <div class="formCreation">
            <label for="Veuillez sélectionner l'année">Veuillez sélectionner l'année</label>
            <select v-model="year" @change="selectAnnee">
                <option v-for="index in maxAnnes" :key="index" :value="index" >{{ index }}</option>
            </select>
        <div>

        </div>
  
        <div>
          <label for="adresse">Dates des jours fériés</label>
          <div v-for="(item, index) in feries" :key="index">
            <label>{{ index + 1 }}</label>
            <input type="text" v-model="item.nom">
            <input type="date" v-model="item.dateDebut">
            <input type="date" v-model="item.dateFin">
          </div>

        </div>

        <div class="buttons">
          <input type="submit" value="Ajouter" @click="ajouter()">
          <input type="submit" value="Supprimer" v-show="feries.length > 1" @click="supprimer()">
          <input type="submit" value="Créer" @click="create()">
        </div>

  
      </div>
  
    </div>
  </template>
  
  <script>
  import Service from "../../../../../Service";
  import Traitement from "../../Affaire/Traitement.vue"

  export default {
    data() {
      return {
        feries : [],
        year : null,
        maxAnnes : [],
        flagTraitement : false,
        msgCompetence : null,
        succes: false,
        echec: false,
        error : null
      };
    },
  
    components : {
      Traitement
    },
  
    methods: {

      create() {

          Service.createJourFeries(this.feries, this.year)
          .then((result) => {
            console.log(result)
          })
          .catch((error) => {
            console.log(error);
          });      
      },

      ajouter() {
            this.feries.push({
                dateDebut : null,
                dateFin : null,
                nom : ""
            });
      },

      
      supprimer() {
            this.feries.pop();
      },

      selectAnnee() {
        Service.selectJourFeries(this.year)
          .then((result) => {

                if(result.data.feries == null) {
                    this.feries = [];
                    this.feries.push({
                        dateDebut : null,
                        dateFin : null,
                        nom : ""
                    });
                } else {
                  this.feries = result.data.feries.feries;
                }

          })
          .catch((error) => {
            console.log(error);
          });
      }


    },
  
    created(){

          const date = new Date();
          this.year = date.getFullYear()

          for(let i = date.getFullYear() + 1; i >= 2019; i--) {
            this.maxAnnes.push(i);
          }
  
          // get all jours feries
          Service.selectJourFeries(date.getFullYear())
          .then((result) => {

                if(result.data.feries == null) {
                    this.feries.push({
                        dateDebut : null,
                        dateFin : null,
                        nom : ""
                    });
                } else {
                  this.feries = result.data.feries.feries;
                }

          })
          .catch((error) => {
            console.log(error);
          });
    }
  
  
  };
  </script>
  
  <style scoped>
  .creationClient {
    width: 100%;
    padding:5px;
  
  }
  
  .creationClient h3 {
    width: 100%;
    margin:auto;
    color: white;
    background: linear-gradient(346deg, rgba(207,31,33,1) 0%, rgba(24,86,161,1) 100%);    text-align: center;
    text-align: center;
    padding:15px;
  
  
  }
  .succes {
    background-color: #69cd5b;
    color: white;
    padding: 10px;
    width: 100%;
    height: fit-content;
  }
  
  .echec {
    background-color: RED;
    color: white;
    padding: 10px;
    width: 100%;
    height: fit-content;
  }
  
  .formCreation {
    padding:20px 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1,1fr);
    justify-items: center;
  
  
  }
  
  .formCreation div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: center;
  }

  .formCreation div div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .formCreation div div input {
    margin: 10px;
    height: 60px;
  }

  .formCreation select {
    margin: 5px;
    width: 100px;
    height: 60px;
    font-size: 18px;
  }


  #app > div > div > div.menu-content > div.content > div > div > div > div > input[type=text] {
    width: 400px;
  }
  #app > div > div > div.menu-content > div.content > div > div > div > div > input[type=date] {
    width: 100px;
  }

  .buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .buttons > input[type=submit]{
    width: 150px;
    height: 40px;
    margin: 5px;
    color: white;
    border: 0px;
    cursor: pointer;
  }
  .buttons > input[type=submit]:nth-child(1){
    background-color: blue;
  }
  .buttons > input[type=submit]:nth-child(2){
    background-color: red;
  }
  .buttons > input[type=submit]:nth-child(3){
    background-color: green;
  }




  

  
  </style>