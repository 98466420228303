<template>
    <div class="page">

        <header style=" display: flex; justify-content:space-between; align-items:center; padding:0 10px; ">
            <div id="stat">
                <p @click="noteCalendrier" title="Calendrier"><i class="fa-solid fa-calendar-days fa-lg"></i> <span>{{
                    countCal }}</span> </p>
                <p @click="noteFrais" title="Les frais"><i class="fa-solid fa-comment-dollar fa-lg"></i> <span>{{
                    countFrais }}</span></p>
                <p @click="chatGthconsult" title="Contacts"><i class="fa-sharp fa-solid fa-headphones fa-lg">
                        <span></span></i></p>
                <p @click="posteGthconsult" title="Demande de poste"><i class="fa-solid fa-briefcase fa-lg">
                        <span></span></i></p>
                <p @click="demandeTicket" title="Demande de Ticket"><i
                        class="fa-sharp fa-solid fa-ticket"><span></span></i></p>
                <p v-if="falgPermetConge" @click="congeGthconsult" title="Demande Conge ou absence"><i
                        class="fa-solid fa-pen"> <span></span></i></p>
                <p v-if="falgPermetConge" @click="recuperationGthconsult" title="Demande de récupération"><i
                        class="fa-solid fa-r"> <span></span></i></p>
                <p @click="demandeAchat" title="Demande d'achat interne"><i
                            class="fa-solid fa-cart-shopping"><span></span></i></p>
            </div>

            <div class="logo" @click="handelPageinterface">
                <img src="../assets/logo.png" alt="" style="width: 150px;">
            </div>

            <div class="button " style="display:flex; align-items:center;">
                <ul
                    style="display: flex ;gap: 10px; align-items: center ;border-radius: 5px ;margin-right: 10px ; ;list-style: none; padding:10px">
                    <div class="left"
                        style="display: flex ;align-items:center; gap: 5px; padding:0px 10px;border-right: 2px solid rgb(202, 202, 202);">
                        <div class="image">
                            <img src="../assets/person.png" alt="" width="40px">
                        </div>
                        <div class="info">
                            <li style="font-weight: 700;">Prénom & Nom: <span style="font-weight: 500; color:gray;">{{
                    prenom }} {{ nom }} </span></li>
                            <li style="font-weight: 700;">Matricule : <span style="font-weight: 500;color:gray;">{{
                    matricule }}</span></li>


                        </div>
                    </div>


                    <li style="display: flex; align-items:center"><span style="font-weight: bold;"> <img
                                src="../assets/time.png" width="50px" alt=""> </span> {{ new Date().toLocaleDateString()
                        }} | {{ new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds() }}
                    </li>

                </ul>


                <button class="btnLogout" @click="deconnexion"> <i class="fa-solid fa-right-from-bracket"></i>
                    Deconexion</button>
            </div>


        </header>


        <div class="Intranet">

            <div class="formCreation">

                <div>
                    <label for="Nom">Nom</label>
                    <input type="text" v-model="ticket.nom" disabled>
                </div>

                <div>
                    <label for="Nom">Prénom</label>
                    <input type="text" v-model="ticket.prenom" disabled>
                </div>

                <div>
                    <label for="Nom">E-mail de Demandeur</label>
                    <input type="text" v-model="ticket.email" disabled>
                </div>

                <div>
                    <label for="Récepteur">Récepteur</label>
                    <select v-model="ticket.recepteur">
                        <option v-for="item in recepteurs" :value="item.email" :key="item.email">{{ item.nom }} {{
                    item.prenom }}</option>
                    </select>
                </div>


                <div>
                    <label for="Nom">Objet</label>
                    <input type="text" v-model="ticket.titre">
                </div>

                <div>
                    <label for="Message">Message</label>
                    <textarea v-model="ticket.message"></textarea>
                </div>

                <div class="create">
                    <input type="submit" value="Envoyer ticket" @click.once="create">
                </div>

                <div class="quitter">
                    <input type="submit" value="Quitter" @click="quitter">
                </div>

            </div>


            <div class="copyright" v-if="this.status == 'admin'">
                <p>© {{ new Date().getFullYear() }} GTHCONSULT BUREAU DE CONTRÔLE AGRÉÉ PAR L'ETAT</p>
            </div>

        </div>

        <ModalLanding v-if="flagModelLanding" />
    </div>

</template>

<script>
import Service from '../Service';
import ModalLanding from "./components/ModalLanding.vue"
export default {

    name: "Intranet",
    data() {
        return {
            flagModelLanding : false,
            status: null,
            matricule: null,
            countCal: 0,
            countFrais: 0,
            falgPermetConge: false,
            msgTicket: null,
            media: null,
            traitement: null,
            ticket: {
                nom: null,
                prenom: null,
                email: null,
                recepteur: null,
                titre: null,
                message: null
            },
            succes: false,
            echec: false,
            error: null,
            recepteurs: []
        }
    },
    components: {
        ModalLanding
    },

    methods: {

        quitter() {
            this.$router.push("/interface");
        },


        create() {


            const flag = this.ticket.email != null && this.ticket.recepteur != null && this.ticket.titre != null && this.ticket.message != null;

            if (flag == false) {
                alert("Veuillez remplir le champ");
            } else {

                Service.createTicket(this.ticket.email, this.ticket.recepteur, this.ticket.titre, this.ticket.message, this.ticket.file)
                    .then((response) => {

                        var finish = false;
                        this.flagModelLanding = true;
                        this.ticket.titre = null;
                        this.ticket.message = null;
                        this.ticket.recepteur = null;

                        if (response) {
                            setTimeout(() => { console.log("wait"); }, 5000);
                            finish = true
                        }

                        if (finish) {
                            setTimeout(() => {
                                this.flagModelLanding = false;
                            }, 5000);
                        }
                    })
                    .catch((error) => {
                        this.error = error.message;
                        console.error(`HTTP error: ${error.name} => ${error.message}`);
                        throw "fail request at: GET /refreshtime";
                    });
            }

        },

        handelPageinterface() {
            this.$router.push("/interface");
        },

        da() {
            this.$router.push("/da");
        },

        dp() {
            this.$router.push("/dp");
        },

        dt() {
            this.$router.push("/dt");
        },

        dq() {
            this.$router.push("/dq");
        },

        noteCalendrier() {

            Service.deleteCheckNotificationCalendrier(this.matricule)
                .then((result) => {
                    console.log(result);
                }).catch((error) => {
                    console.log(error)
                });

            return this.$router.push("/notecalendrier");

        },

        chatGthconsult() {
            return this.$router.push("/chatgthconsult");
        },




        posteGthconsult() {

            Service.DemandePoste()
                .then((result) => {
                    this.downloadFile(result, "lettre-poste.docx");
                })
                .catch((error) => {
                    console.log(error.message);
                });

        },

        demandeTicket() {
            return this.$router.push("/ticket");
        },



        recuperationGthconsult() {

            Service.recuperationGthconsult()
                .then((result) => {
                    this.downloadFile(result, "lettre-recuperation.docx");
                })
                .catch((error) => {
                    console.log(error.message);
                });

        },

        demandeAchat() {

                Service.demandeAchat()
                .then((result) => {
                    this.downloadFile(result, "demande-achat.docx");
                })
                .catch((error) => {
                    console.log(error.message);
                });

        },

        downloadFile(response, title) {

            var newBlob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            });

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }

            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement("a");
            link.href = data;
            link.download = title;
            link.click();
            setTimeout(function () {
                window.URL.revokeObjectURL(data);
            }, 100);

        },

        congeGthconsult() {
            return this.$router.push("/demandeConge");
        },

        noteFrais() {
            return this.$router.push("/notefrais");
        },

        deconnexion() {

            // reject online
            Service.rejectOnline(sessionStorage.getItem("id"))
                .then((result) => {
                    console.log(result.data.response);
                })
                .catch((error) => {
                    console.log(error);
                });


            sessionStorage.removeItem("token");
            sessionStorage.removeItem("nom");
            sessionStorage.removeItem("prenom");
            sessionStorage.removeItem("status");
            sessionStorage.removeItem("email");
            sessionStorage.removeItem("id");
            sessionStorage.removeItem("statusAdmin");
            sessionStorage.removeItem("refreshTokenCalendrier");

            return this.$router.push("/");
        }
    },

    computed: {

    },

    created() {

        if (!sessionStorage.getItem("token")) {
            this.$router.push("/");
        } else {

            this.nom = sessionStorage.getItem("nom");
            this.prenom = sessionStorage.getItem("prenom");
            this.matricule = sessionStorage.getItem("id");
            this.status = sessionStorage.getItem("status");
            this.ticket.nom = sessionStorage.getItem("nom");
            this.ticket.prenom = sessionStorage.getItem("prenom");
            this.ticket.email = sessionStorage.getItem("email");

            Service.PermetConge(this.matricule)
                .then((response) => {
                    console.log(response)
                    this.falgPermetConge = response.data.result;
                })
                .catch((error) => {
                    console.log(error.message);
                });


            // check is exist notification Frais
            Service.checkNotificationFrais(this.matricule)
                .then((result) => {
                    if (result) {
                        // counter Notfication
                        this.countFrais = result.data.response;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            // check is exist notification Frais
            Service.checkNotificationCalendrier(this.matricule)
                .then((result) => {
                    if (result) {
                        // counter Notfication
                        this.countCal = result.data.response
                    }
                })
                .catch((error) => {
                    console.log(error);
                });


            Service.readAdminNotSousTritance()
                .then((result) => {
                    result.data.admins.forEach((el) => {
                        if(el._id != sessionStorage.getItem("id")) {
                            this.recepteurs.push({
                                nom: el.nom,
                                prenom: el.prenom,
                                email: el.email
                            });
                        }
                    })
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    },

    destory() {

        // reject online
        Service.rejectOnline(sessionStorage.getItem("id"))
            .then((result) => {
                console.log(result.data.response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
};
</script>

<style scoped>
header {
    box-shadow: 0px 0px 5px rgb(173, 173, 173);
    background-color: white;
    width: 100%;
}

.btnHome {
    padding: 10px 20px;
    background-color: white;
    border: 0;
    border-radius: 20px;
    color: rgb(228, 44, 44);
    font-weight: bold;
    outline: 2px solid #243064;
    cursor: pointer;
    margin: 5px;
}

.btnLogout {
    padding: 10px 20px;
    background-color: rgb(228, 44, 44);
    border: 0;
    color: white;
    font-weight: bold;
    border-radius: 20px;
    cursor: pointer;
    margin: 5px;
}

.btnLogout:hover {
    background-color: #243064;
}

header #stat {
    color: #243064;
    display: flex;
}

header #stat p:hover {
    color: #cf1f21;
}

header #stat p {

    position: relative;
    margin-right: 10px;
    cursor: pointer;
    color: #243064;
    position: relative;
}

header #stat p span {

    font-size: 15px;
    position: absolute;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    top: -10px;
    right: -10px;
    background-color: #ee272a;
    padding: 2px;
    border-radius: 100%;
}


#stat .fa-solid:hover {
    color: #cf1f21;
}



.fa-lg {
    font-size: 29px;
}


.spn {
    color: #243064;
    animation: typing 2s steps(10) infinite;
    overflow: hidden;
}

.fa-globe {
    font-size: 39px;
}

@keyframes typing {

    0%,
    90%,
    100% {
        opacity: 0;
    }

    30%,
    60% {
        opacity: 1;
    }
}

.copyright {
    text-align: center;
    font-weight: 700;
    color: rgb(54, 54, 54);

}

#stat svg {
    margin-left: 10px;
}


.formCreation {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
}

.formCreation div {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: 10px;
}

.formCreation div label {
    font-size: 20px;
    margin-bottom: 5px;
}

.formCreation div input, .formCreation div select {
    height: 50px;
    padding: 5px;
}

.formCreation div textarea {
    height: 200px;
    padding: 5px;
}

.create, .quitter {
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}


.create input {
    background-color: rgb(61, 224, 61);
    color : white; 
    border-radius: 10px;
    cursor: pointer;
    border: 0px;
    width: 300px;
}

.create input:hover {
    background-color: green;
}

.quitter input {
    background-color: rgb(243, 52, 52);
    color : white; 
    border-radius: 10px;
    cursor: pointer;
    border: 0px;
    width: 300px;
}

.quitter input:hover {
    background-color: red;
}


</style>