<template>
  <div class="etat-production-chart">
    <h4>État d'avancement de la production</h4>
    <canvas id="myEtatProduction"></canvas>
  </div>
</template>

<script>
import Service from "../../../../../Service";
import Chart from "chart.js";
export default {

  name: "affaires-missions-chart",

  data() {
    return {
      annee : null,
    };
  },

  props: {
    selectYear : Number,
    selectMois : Number
  },

  methods: {},

  created() {
  },

  mounted() {



      // get info Indicateur by current
      Service.IndicteurProduction(this.selectYear, this.selectMois)
      .then((result) => {

        const { attenteProduction, coursProduction,  realiseProduction} = result.data
        const sommes = attenteProduction + coursProduction + realiseProduction;

        const pourcentageAttenteProduction = (attenteProduction * 100) / sommes;
        const pourcentageCoursProduction = (coursProduction * 100) / sommes;
        const pourcentageRealiseProduction = (realiseProduction * 100) / sommes;


        const data = {
            labels: [
                `En attente production :${ parseInt(pourcentageAttenteProduction) } %`,
                `En cours production :${ parseInt(pourcentageCoursProduction ) } %`,
                `Réalisé :${ parseInt(pourcentageRealiseProduction) } %`
            ],
            datasets: [{
                label: 'My First Dataset',
                data: [attenteProduction, coursProduction, realiseProduction],
                backgroundColor: [
                'rgb(255, 0, 0)',
                'rgb(255, 108, 0)',
                'rgb(19, 209, 0)'
                ],
                hoverOffset: 4
            }]
        };

        const config = {
            type: 'doughnut',
            data: data,
        };

        // instance Chart CTX
        const ctx = document.getElementById("myEtatProduction");
        const myEtatProduction = new Chart(ctx, config);

          return myEtatProduction;
      })
      .catch((error) => {
          console.log(error.message)
      });



  }


};
</script>

<style scoped>
.etat-production-chart > h4 {
    width: 100%;
    justify-content: center;
    display: flex;
    color: #1e1ed3;
    font-size: x-large;
    opacity: 0.6;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
}
</style>