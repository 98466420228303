<template>
  <div class="etat-absence-inspecteur-chart">
    <h4>ÉTAT D'ABSENCE DE PERSONNEL</h4>
    <canvas id="myEtatAbsence"></canvas>
  </div>
</template>

<script>
import Service from "../../../../../../Service";
import Chart from "chart.js";

export default {

  name: "etat-absence-inspecteur-chart",

  data() {
    return {
      annee : null,
    };
  },

  props: {
    selectYear : Number,
    selectMois : Number,
  },

  methods: {},

  created() {
  },

  mounted() {

      // get info Indicateur by current
      Service.CalculEtatAbscence(this.selectYear)
      .then((result) => {

                const inspecteurs = [];
                const datasets = [];
                const soldes = [];
                const restes = [];
                const labels = ['Solde', 'Reste'] 

                result.data.forEach(element => {
                    // set name admin or inspecteurs
                    inspecteurs.push(element.name);
                    // set soldes chaque inspecteur
                    soldes.push(element.solde);
                    restes.push(element.reste);
                });

                const mergeSoldesRestes = [...soldes, ...restes];

                // set colors background color
                for(let i = 0; i < 2; i++) {
                  const randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1));
                  const a = randomBetween(0, 255);
                  const b = randomBetween(0, 255);
                  const c = randomBetween(0, 255);

                  const values = mergeSoldesRestes.slice(0, result.data.length);
                  mergeSoldesRestes.splice(0, result.data.length);

                  datasets.push({
                    data: values,
                    label: labels[i],
                    backgroundColor: `rgb(${a},${b},${c})`,
                  });
                }




      const ctx = document.getElementById("myEtatAbsence");
      var myEtatAbsence = new Chart(ctx, {
          type: 'horizontalBar',
          data: {
            labels: inspecteurs,
            datasets: datasets
              },
              options: {
                scales: {
                  xAxes: [{ 
                    stacked: true    
                  }],
                  yAxes: [{
                    stacked:true
                  }],
                }
              },
        });

          return myEtatAbsence;
      })
      .catch((error) => {
          console.log(error.message)
      });

  }


};
</script>

<style scoped>

.etat-absence-inspecteur-chart > h4 {
  width: 100%;
    justify-content: center;
    display: flex;
    color: #1e1ed3;
    font-size: x-large;
    opacity: 0.6;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
}

</style>