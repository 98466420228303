<template>
  <div class="admin">
    <div class="container">

      <div class="header">
        <Nav />
      </div>

      <div class="menu-content">
        <div class="menu">
          <Menu
            @indicateurAffaires="handelIndicateurAffaire()"
            @indicateurFacturation="handelIndicateurFacture()"
            @indicateurRh="handelIndicateurRh()"
            @indicateurFournisseur="handelIndicateurFournisseur()"
          />
        </div>
        <div class="content">

          <IndicateurAffaire v-if="flagIndicateurAffaire" />
          <IndicateurFacture v-if="flagIndicateurFacture" />
          <IndicateurRh v-if="flagIndicateurRh" />
          <IndicateurFournisseur v-if="flagIndicateurFournisseur" />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from "@/components/Admin/Menu.vue";
import Nav from "@/components/Admin/Nav.vue";

import IndicateurAffaire from "@/components/Admin/content/Affaire/IndicateurAffaire.vue";
import IndicateurFacture from "@/components/Admin/content/Facturation/IndicateurFacture.vue";
import IndicateurRh from "@/components/Admin/content/GestionRH/Indicateur/Indicateur.vue";
import IndicateurFournisseur from "@/components/Admin/content/Fournisseur/IndicateurFournisseur.vue";



export default {
  name: "admin",
  data() {
    return {
      flagIndicateurAffaire : true,
      flagIndicateurFacture : false,
      flagIndicateurRh : false,
      flagIndicateurFournisseur : false
    };
  },
  components: {
    Menu,
    Nav,
    IndicateurAffaire,
    IndicateurFacture,
    IndicateurRh,
    IndicateurFournisseur
  },

  methods: {

    handelIndicateurAffaire() {
      this.flagIndicateurAffaire = true;
      this.flagIndicateurFacture = false;
      this.flagIndicateurRh = false;
      this.flagIndicateurFournisseur = false;
    },

    handelIndicateurFacture() {
      this.flagIndicateurAffaire = false;
      this.flagIndicateurFacture = true;
      this.flagIndicateurRh = false; 
      this.flagIndicateurFournisseur = false;
    },

    handelIndicateurRh() {
      this.flagIndicateurAffaire = false;
      this.flagIndicateurFacture = false;
      this.flagIndicateurRh = true; 
      this.flagIndicateurFournisseur = false;
    },

    handelIndicateurFournisseur() {
      this.flagIndicateurAffaire = false;
      this.flagIndicateurFacture = false;
      this.flagIndicateurRh = false; 
      this.flagIndicateurFournisseur = true;
    }


  },


created() {

  if(!sessionStorage.getItem("token")) {
        this.$router.push("/")
  }

}



};
</script>

<style scoped>


.admin .container .header {
  width: 100%;
}

.admin .container .menu-content{
display: grid;
grid-template-columns: 20% 80%;
}



.admin .container .menu-content .content {
  width: 100%;


}


</style>