<template>
  <div class="inspecteurs-chart">
    <canvas id="myInspecteursMois"></canvas>
  </div>
</template>

<script>
import Service from "../../../../../../Service";
import Chart from "chart.js";
export default {

  name: "inspecteurs-chart",

  data() {
    return {
      annee : null,
    };
  },

  props: {
    selectYear : Number

  },

  methods: {},

  created() {
  },

  mounted() {

      // get info Indicateur by current
      Service.IndicteurInspecteursMissionsMois(this.selectYear)
      .then((result) => {

          const data = {
            labels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
            datasets : [],
            options: {
                indexAxis: 'y',
                scales: {
                  x: {
                    beginAtZero: true
                  }
                }
            }
          };

          result.data.statistique.forEach((element) => {

                // Start Generate RGB
                const randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1));
                const r = randomBetween(0, 255);
                const g = randomBetween(0, 255);
                const b = randomBetween(0, 255);
                const rgbBorder = `rgb(${r},${g},${b})`; // Collect all to a css color string
                const rgbBackgroundColor = `rgb(${r},${g},${b}, 0.1)`; // Collect all to a css color string
                // End Generate RGB

                data.datasets.push({
                    label : `${element.prenom} ${element.nom}`,
                    data : element.mois,
                    borderColor : rgbBorder,
                    backgroundColor : rgbBackgroundColor,
                    tension : 0.1
                });

          });

          // instance Chart CTX
          const ctx = document.getElementById("myInspecteursMois");
          const myInspecteursMois = new Chart(ctx, {
             type: "line",
             data: data,
          });

          return myInspecteursMois;
      })
      .catch((error) => {
          console.log(error.message)
      });



  }


};
</script>

<style>
</style>