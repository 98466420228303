<template>
  <div class="etat-affaires-inspecteurs-chart">
    <h4>État Production Inspecteurs - Missions - Affaires</h4>
    <canvas id="myEtatAffairesInspecteurs"></canvas>
  </div>
</template>

<script>
import Service from "../../../../../Service";
import Chart from "chart.js";
export default {

  name: "affaires-missions-chart",

  data() {
    return {
      annee : null,
    };
  },

  props: {
    selectYear : Number,
    selectMois : Number
  },

  methods: {},

  created() {
  },

  mounted() {



      // get info Indicateur by current
      Service.IndicteurAffairesInspecteurs(this.selectYear, this.selectMois)
      .then((result) => {

        // Generate backround Color
        let sum = 0;
        for (let i = 0; i < result.data.values.length; i++) {
            sum += result.data.values[i];
        }

        const values = result.data.values.map((element) => {
            return parseFloat((element * 100)/sum).toFixed(2);
        });

        const backgroundColor = [];
        for(let i = 0; i< result.data.namesInspecteurs.length; i++) {
          const randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1));
          const a = randomBetween(0, 255);
          const b = randomBetween(0, 255);
          const c = randomBetween(0, 255);
          backgroundColor.push(`rgb(${a},${b},${c})`)
        }
        

        const data = {
            labels: result.data.namesInspecteurs,
            datasets: [{
                label: 'dddd',
                data: values,
                backgroundColor: backgroundColor,
                hoverOffset: 4
            }]
        };

        const config = {
            type: 'pie',
            data: data,
        };

        // instance Chart CTX
        const ctx = document.getElementById("myEtatAffairesInspecteurs");
        const myEtatAffairesInspecteurs = new Chart(ctx, config);

          return myEtatAffairesInspecteurs;
      })
      .catch((error) => {
          console.log(error.message)
      });



  }


};
</script>

<style scoped>
.etat-affaires-inspecteurs-chart > h4 {
  width: 100%;
    justify-content: center;
    display: flex;
    color: #1e1ed3;
    font-size: x-large;
    opacity: 0.6;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
}
</style>