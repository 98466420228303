<template>
    <div class="creationClient">
  
      <h3>CRÉER UNE PRESTATION</h3>
  
      <Traitement :msg="msgAdmin" v-if="traitement == true"/>
  
      <p v-if="echec" :class="{ echec: echec }">
          {{ error }}
      </p>

      <div class="formCreation">
  
                <div>
                    <label for="Titre de L'actualité">Photo de L'actualité</label>
                    <input type="file" multiple="multiple" class="form-control" placeholder="Fichier PDF" ref="file" @change="previewFile">
                </div>

                <div>
                    <label for="Titre de L'actualité">Référence de Photo (Privacy)</label>
                    <input type="text" v-model="refPicture">
                </div>

                <div>
                    <label for="Titre de L'actualité">Titre de prestation</label>
                    <input type="text" v-model="titre">
                </div>


                <div>
                    <label for="Introduction">Description de prestation</label>
                    <textarea class="missions" type="text" v-model="description"></textarea>
                </div>


                <div>
                    <input type="submit" value="Créer la prestation" @click="create()">
                </div>

                <div>
                    <input type="submit" value="Quitter" @click="quitter()">
                </div>

        </div>
  
       </div>
  </template>
  
  <script>
  import Service from "../../../../Service";
  import Traitement from "../Affaire/Traitement.vue"
  
  export default {
    name : "",
    data() {
      return {
        file: null,
        echec : false,
        traitement : false,
        refPicture : null,
        titre: null,
        description : null
      };
  
    },
  
    components : {
      Traitement
    },
  
    methods: {

      // preciew file
      previewFile() {
        this.file = this.$refs.file.files[0];
      },

      // create Prestation
      create() {


        Service.createPrestation(this.file, this.refPicture, this.titre, this.description)
        .then((response) => {
                
                this.traitement = true;
                this.msgAdmin = "Veuillez patienter quelques secondes pour enregistre le prestation";
                var finish = false;
                this.file = null;
                this.refPicture = null;
                this.titre = null;
                this.description = null;

                if(response) {
                  setTimeout(() => { console.log("wait"); }, 5000);
                  finish = true
                }
  
                if(finish) {
                  setTimeout(() => {
                    this.msgAdmin = null;
                    this.traitement = false;
                  }, 5000);
                }
  
        })
        .catch((error) => {
            this.echec = true;
            this.error = error.message;
            console.error(`HTTP error: ${error.name} => ${error.message}`);
            throw "fail request at: GET /refreshtime";
        })
      }
    },
  
    created(){

    }
  
  
  };
  </script>
  
  <style scoped>
  .creationClient {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .creationClient h3 {
    width: 100%;
    margin:auto;
    color: white;
    background: linear-gradient(346deg, rgba(207,31,33,1) 0%, rgba(24,86,161,1) 100%);    text-align: center;
    text-align: center;
  }
  .succes {
    background-color: #69cd5b;
    color: white;
    padding: 10px;
    width: 100%;
    height: fit-content;
  }
  .echec {
    background-color: RED;
    color: white;
    padding: 10px;
    width: 100%;
    height: fit-content;
  }
  .formCreation {
    padding:20px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .formCreation div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 48%;
  }
  .formCreation div label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 700;
    color :#494949;
  }
  .formCreation div input {
    height: 40px;
    margin-bottom: 5px;
    border: 1px solid #243064;
    padding:5px;
  }.formCreation div input:focus-within {
    outline: 1px solid #cf1f21 ;
    border:0;
  }
  .formCreation div input:focus-within {
    outline: 1px solid #cf1f21 ;
    border:0;
  }
  .formCreation div select {
    height: 40px;
  }
  #app > div > div > div.menu-content > div.content > div > div > div.formInspecteur {
    width: 100%;
  }
  #app > div > div > div.menu-content > div.content > div > div > div.formInspecteur div:last-child {
    border-bottom: 2px dashed rgb(255, 0, 0);
    margin-bottom: 10px;
  }
  #app > div > div > div.menu-content > div.content > div > div > div > .addinspec {
      background-color: rgb(161, 0, 80);
      color: white;
      border: 0;
      margin-top: 20px;
      padding:15px;
      cursor: pointer;
      margin-bottom: 10px;
      transition: 0.4s;
  }
  #app > div > div > div.menu-content > div.content > div > div > div > .addinspec:hover {
      background-color: rgb(143, 93, 118);
  }
  #app > div > div > div.menu-content > div.content > div > div > div > .addinspec svg {
   margin-right: 10px;
   font-size: 16px;
  }
  .formCreation div select {
    height: 40px;
  }
  #app > div > div > div.menu-content > div.content > div > div > div:last-child > input[type=submit] {
      background-color: red;
      color: white;
      border: 0;
      margin-top: 10px;
      cursor: pointer;
  }
  #app > div > div > div.menu-content > div.content > div > div > div > input[type=submit] {
      background-color: green;
      color: white;
      border: 0;
      margin-top: 50px;
      cursor: pointer;
  }
  
  #app > div > div > div.menu-content > div.content > div {
    background-color: white;
  }
  .inspeclabel label{
    margin: 10px 0;
    color:rgb(63, 63, 63) !important;
    background-color: #e4e4e4;
    padding:5px;
  }
  #app > div > div > div.menu-content > div.content > div > h3 {
      padding: 15px;
  }

  .description , .missions {
    height: 300px;
  }

  .qualifications, .profils {
    height: 100px;
  }
  
  </style>