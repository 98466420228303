<template>

    <div class="gestionInspecteur">

      <h3>LISTE DEMANDE ABSENCE</h3>

      <p v-if="succes" :class="{ succes: succes }">
        {{ msg }}
      </p>

      <p v-if="echec" :class="{ echec: echec }">
        {{ msg }}
      </p>

      <div class="rechercher-table">

            <div class="rechercher">
                <input type="text" v-model="rechercher" placeholder="Recherche un Absence">
            </div>

            <div class="searchBox">
              <select v-model="year" name="" id="">
                <option v-for="item in years" :key="item" :value="item">{{ item }}</option>
              </select>
              <select v-model="mounth" name="" id="" >
                <option v-for="item in mounths" :key="item" :value="item">{{ item }}</option>
              </select>
              <button @click="generateListAbsence()">Telecharger</button>
            </div>

            <table id="inspecteurs">
              <thead>
              <tr>
                <th>Date demande congé</th>
                <th>Nom</th>
                <th>Prénom</th>
                <th>Fonction</th>
                <th>Type d'absence</th>
                <th>Pj</th>
                <th>Sortir</th>
                <th>Reprise</th>
                <th>Nbr Jrs</th>
                <th>Solde</th>
                <th>Reste</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in filterAbsence" :key="item._id">

                <!-- <td><input type="checkbox" :value="item.filename" v-model="checkedAbsences" style="width: 20px;"></td> -->

<!-- ---------------------------------------------------------------------------------------------- -->
                <td v-show ="item.action == true">
                  {{ new Date(item.date).toLocaleDateString() }} 
                </td>

                <td v-show ="item.action == false">
                  <input type="date" v-model="absence[i].date">
                </td>
<!-- ---------------------------------------------------------------------------------------------- -->
                <td>{{ item.nom }}</td>
                <td>{{ item.prenom }}</td>
<!-- ---------------------------------------------------------------------------------------------- -->
                <td v-show ="item.action == true">
                  {{ item.fonction }}
                </td>

                <td v-show ="item.action == false">
                  <input type="text" v-model="absence[i].fonction">
                </td>
<!-- ---------------------------------------------------------------------------------------------- -->
                <!-- <td v-show ="item.action == true">
                  {{ item }}
                </td>

                <td v-show ="item.action == false">
                <select v-model="item.departement">
                      <option v-for="(abs, index) in listDepartement" :key="index" :value="abs"> {{ abs }}</option>
                  </select>                
                </td> -->
<!-- ------------------------------------------------------------------------------------------ -->
                <td v-show ="item.action == true">
                  {{ item.typeAbsence }}
                </td>

                <td v-show ="item.action == false">
                  <select v-model="absence[i].typeAbsence">
                      <option v-for="(abs, index) in listTypeAbsence" :key="index" :value="abs.name"> {{ abs.name }}</option>
                  </select>                
                </td>
<!-- ---------------------------------------------------------------------------------------------- -->
                <td>
                    <a v-if="item.key != null" @click="certficatAbsence(item.key)" title="Télécharger Preuve"><i class="fa-regular fa-file-pdf"></i></a>
                    <a v-if="item.key == null">-</a>
                </td>
<!-- ---------------------------------------------------------------------------------------------- -->

<!-- ---------------------------------------------------------------------------------------------- -->
                <td v-show ="item.action == true">
                  {{ item.dateDebutConge }}
                </td>

                <td v-show="item.action == false">
                  <input type="date" v-model="absence[i].dateDebutConge">
                </td>
<!-- ---------------------------------------------------------------------------------------------- -->
                <td v-show = "item.action == true">
                  {{ item.dateFinConge }}
                </td>

                <td v-show ="item.action == false">
                  <input type="date" v-model="absence[i].dateFinConge">
                </td>
<!-- ---------------------------------------------------------------------------------------------- -->
                <td v-show="item.action == true">{{ parseFloat(item.nomberJours) }}</td>
                <td v-show = "item.action == false">
                  <input type="text" v-model="absence[i].nomberJours">
                </td>
<!-- ---------------------------------------------------------------------------------------------- -->
                <td v-show="item.action == true">{{ parseFloat(item.nomberJoursOuvrables).toFixed(1) }}</td>
                <td v-show="item.action == false">
                  <input type="text" v-model="absence[i].nomberJoursOuvrables">
                </td>
<!-- ---------------------------------------------------------------------------------------------- -->
                <td v-show="item.action == true">{{ parseFloat(item.reset).toFixed(1) }}</td>
                <td v-show="item.action == false">
                  <input type="text" v-model="absence[i].reset">
                </td>
<!-- ---------------------------------------------------------------------------------------------- -->
                <td v-if="item.status == true">accordé</td>
                <td v-if="item.status == false">non accordé</td>
<!-- ---------------------------------------------------------------------------------------------- -->
                <td>
                  <a @click="generateAbsence(item._id)" title="Télécharger"><i class="fa-regular fa-file-pdf"></i></a>
                  <a v-show="item.status == false" title="Validé" @click="valideAbsence(item._id, item.adminId, i, item)"><i class="fa-solid fa-check"></i></a>
                  <a v-show="item.status == true" title="Invalidé" @click="invalideAbsence(item._id, item.adminId, i)"><i class="fa-solid fa-xmark"></i></a>
                  <a v-show="item.status == false" title="Supprimé" @click="deleteAbsence(i,item._id, item.adminId, item.key)"><i class="fa-solid fa-trash"></i></a>
                  <a v-show="item.action == true" title="Modifié" @click="editAbsence(item._id)"><i class="fa-solid fa-pen"></i></a>
                  <a v-show="item.action == false" title="Appliqué" @click="appliquerAbsence(item)"><i class="fa-solid fa-circle-check"></i></a>
                </td>
<!-- ---------------------------------------------------------------------------------------------- -->

              </tr>
            </tbody>
            </table>

            <div class="deleteAll" v-show="checkedAbsences.length > 1">
              <input type="submit" value="Supprimer tout" @click="deleteAbsences()">
            </div>

      </div>

    
    <ul class="pagination">
        <button @click="selectPageLeft()"><i class="fa-solid fa-backward"></i></button>
          <li v-for="n in numberPage" :key="n" @click="selectPage(n)">
            <p v-show="numberPageSelect[n-1] == false">{{ n }}</p>
            <p  v-show="numberPageSelect[n-1] == true" style="background-color: #0300db; color: white;">{{ n }}</p>
           </li>
        <button @click="selectPageRight()"><i class="fa-solid fa-forward"></i></button>
    </ul>

    
    <AlertModel v-if="flagAlertModel" :message="messageAlert" @closeAlertModel="closeAlertModelAbsence" />

  </div>

</template>

<script>
import Service from "../../../../../Service";
import AlertModel from "@/views/components/AlertModel.vue"

export default {
  name: "gestionEtalonage",
  components: {
    AlertModel
  },

  data() {
    return {
      flagAlertModel : false,
      messageAlert : null,
      numberPageSelect : [],
      pageIndex : 0,
      itemsPerPage : 10,
      numberPage: null,
      preAbsence  : [],
      succes: false,
      echec : false,
      rechercher : null,
      absence  : [],
      checkedAbsences : [],
      years : [],
      year : null,
      mounths : [],
      mounth : null,
      listTypeAbsence : [
            { name : "Congé annuel", duree : null },
            { name : "Congé mariage", duree : 4 },
            { name : "Congé mariage d'un enfant", duree : 2 },
            { name : "Décès d'un conjoint, d'un enfant, d'un petit enfant", duree : 3 },
            { name : "Décès d'un des parents, grand-père ou grand-mère", duree : 3 },
            { name : "Décèsd d'un frère, d'une sœur", duree : 2 },
            { name : "Décès d'un proche", duree : 1 },
            { name : "Circoncision", duree : 2 },
            { name : "Convocation Tribunal", duree : 1 },
            { name : "Opération chirurgicale du conjoint ou d'un enfant à charge", duree : 2 },
            { name : "Accompagner un patient au cabinet du médecin", duree : 1 },
            { name : "Congé maternité", duree : 98 },
            { name : "Maladie", duree: null },
            { name : "Examen ou Formation", duree : null },
            { name : "Autres", duree : null }
        ],
        
        listDepartement : [
            "PRODUCTION",
            "ADMINISTRATIVE",
            "TECHNIQUE",
            "QUALITÉ"
        ],
      }
  },

  computed : {
          filterAbsence() {
            return this.absence.filter((item) => {
              if(!this.rechercher)
              {
                return item
              }
                return !item.nom.toLowerCase().toString().indexOf(this.rechercher.toLowerCase().toString()) ||
                !item.prenom.toLowerCase().toString().indexOf(this.rechercher.toLowerCase().toString()) ||
                !item.cin.toLowerCase().toString().indexOf(this.rechercher.toString()) ||
                !item.telephone.toString().indexOf(this.rechercher.toString());
          })
      }
  },
  methods: {

    closeAlertModelAbsence() {
        this.flagAlertModel = false;
    },

    downloadFileWord(response) {
        var newBlob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        link.download = "resume.docx";
        link.click();
        setTimeout(function() {
            window.URL.revokeObjectURL(data);
        }, 100);
    },

    generateAbsence(absenceId) {
          Service.generateAbsence(absenceId)
          .then((result) => {
            if(result.data.msg == true) {
              Service.telechargerGenerateAbsence()
              .then((response) => {
                this.downloadFileWord(response);
              })
              .catch((error) => {
                console.log(error.message);
              });
            }
          })
          .catch((error) => {
              console.log(error)
          });
    },

    selectPageLeft() {

        for(let i = 0; i < this.numberPage; i++) {
              this.numberPageSelect[i] = false;
        }

        this.numberPageSelect[0] = true;
        this.absence = [];

        for(let i = 0 * this.itemsPerPage; i < (0 * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.absence.push(this.preAbsence[i]);
        }
        
    },

    selectPageRight() {

        for(let i = 0; i < this.numberPage; i++) {
              this.numberPageSelect[i] = false;
        }

        const indice = this.numberPage - 1;
        this.numberPageSelect[indice] = true;
        this.absence = [];

        for(let i = indice * this.itemsPerPage; i < (indice * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.absence.push(this.preAbsence[i]);
        }

    },

    selectPage(value) {
        const select = value - 1;
        // change current select for change color
        for(let i = 0; i < this.numberPage; i++) {
              this.numberPageSelect[i] = false;
        }

        this.numberPageSelect[select] = true;
        this.absence = [];

        for(let i = select * this.itemsPerPage; i < (select * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.absence.push(this.preAbsence[i]);
        }
    },


    // display Certificate Absence
    certficatAbsence(filename) {
          Service.displayAbsence(filename)
          .then((result) => {
              console.log(result);
          })
          .catch((error) => {
              console.log(error)
          });

    },

    // delete more one Salaries (Salaries)
    deleteAbsences() {

        //   // // Delete in Fron-end
        //   for(let i = 0; i < this.checkedSalaries.length; i++ ) {
        //     let indexSalaries = this.salaries.indexOf(this.checkedSalaries[i]);
        //     //delete in front end
        //     this.salaries.splice(indexSalaries, 1);
        //   }

        //   //    //  delete in db backend
        //   Service.deleteSalarie(this.checkedSalaries)
        //     .then((response) => {
        //           console.log(response);
        //     })
        //     .catch((error) => {
        //           this.msg = error.message;
        //           console.error(`HTTP error: ${error.name} => ${error.message}`);
        //           throw "fail request at: GET /refreshtime";
        //     });

    },

    // delete one Salarie
    deleteAbsence(i,id, adminId, filename) {

      this.absence.splice(i, 1);

      Service.deleteAbsence(id, adminId, filename)
      .then((result) => {
            console.log(result)
      })
      .catch((error) => {
            this.msg = error.message;
            console.error(`HTTP error: ${error.name} => ${error.message}`);
            throw "fail request at: GET /refreshtime";
      });

    },


    // edit absence
    editAbsence(id) {
      const index = this.absence.findIndex((el) => el._id == id);
      this.absence[index].action = !this.absence[index].action;
    },

    // Applique Absence
    appliquerAbsence(item) {

      const index = this.absence.findIndex((el) => el._id == item._id);
      this.absence[index].action = !this.absence[index].action;

      Service.AppliquerAbsence(item)
      .then((result) => {
        console.log(result)
      })
      .catch((error) => {
        console.log(error);
      });

    },

    // valide Absence
    valideAbsence(id, adminId, i, item) {
      console.log(this.absence[i].nomberJoursOuvrables, this.absence[i].reset)
      if(this.absence[i].nomberJoursOuvrables == null || this.absence[i].reset == null) {
          this.flagAlertModel = true;
          this.messageAlert = "Vous devez saisir deux champs SOLDE et RESTE pour envoyer la lettre acceptation vers le demendeur"
      } else {

        Service.valideAbsence(id, adminId, item)
        .then(() => {
            this.absence[i].status = true;
        })
        .catch((error) => {
              this.msg = error.message;
              console.error(`HTTP error: ${error.name} => ${error.message}`);
              throw "fail request at: GET /refreshtime";
        });

      }

    },

    // invalide Absence
    invalideAbsence(id, adminId, i) {
      Service.invalideAbsence(id, adminId)
      .then(() => {
            this.absence[i].status = false;
      })
      .catch((error) => {
            this.msg = error.message;
            console.error(`HTTP error: ${error.name} => ${error.message}`);
            throw "fail request at: GET /refreshtime";
      });
    },

    downloadFileExcel(response) {

        var newBlob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }

        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        link.download = "resume.xlsx";
        link.click();
        setTimeout(function() {
            window.URL.revokeObjectURL(data);
        }, 100);

    },

    generateListAbsence() {
      Service.generateListAbsence(this.mounth, this.year)
      .then((result) => {
          if(result.data.msg == true) {
            Service.telechargerListeAbsence()
            .then((response) => {
              if(response) {
                this.downloadFileExcel(response);
              }
            })
            .catch((error) => {
                console.log(error.message);
            });
          }
      })
      .catch((error) => {
        console.log(error);
      });
    }



  },

  created() {

    const date = new Date();

      Service.readConges()
      .then((result) => {
        if(result.data.data != null) {

            result.data.data.forEach((element) => {
                element.action = true;
                this.preAbsence.push(element);
                 // Add key and value for sole probleme Update
                // this.preAbsence[index]["action"] = false;
            });

            this.numberPage = Math.ceil(this.preAbsence.length / this.itemsPerPage);

            for(let i = 0; i < this.numberPage; i++) {
                this.numberPageSelect.push(false);
            }

            this.numberPageSelect[0] = true;

            for(let i = this.pageIndex * this.itemsPerPage; i < (this.pageIndex * this.itemsPerPage) + this.itemsPerPage; i++) {
                this.absence.push(this.preAbsence[i]);
            }

        }
      })
      .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
      });

      this.year = date.getFullYear();
      for(let i = this.year; i > 2019; i--) {
        this.years.push(i);
      }
      this.mounth = date.getUTCMonth() + 1;
      for(let i = 12; i > 0; i--) {
        this.mounths.push(i);
      }
      

      // for(let j = 0; j < 12; j++) {
      //     console.log(j)
      // }

  }
};
</script>

<style scoped>

.succes {
  background-color: green;
  color: white;
  padding: 10px;
  height: fit-content;
  width: 100%;
}

.echec {
  background-color: red;
  color: white;
  padding: 10px;
  height: fit-content;
  width: 100%;
}

.gestionInspecteur {
  width: 100%;
  height: 100%;
  padding-top: 0px;
  padding-left: 0px;
  margin: 0px;
  position: relative;
}
.gestionInspecteur h3 {
  width: 100%;
  height: fit-content;
  padding: 5px;
  margin: 0;
  color: white;
  background-color: #243064;
  text-align: center;
  margin-bottom: 10px;
}

.rechercher-table {
  width: 100%;
}

.gestionInspecteur .rechercher-table .rechercher {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;

}

table{
  border-collapse: collapse;
}

.gestionInspecteur .rechercher-table .rechercher input {
  width: 50%;
  height: 40px;
  outline: 0;
  border: 1px solid #243064;
  font-size: 16px;
  background-color: white;
  color :#243064;
}

.gestionInspecteur .rechercher-table table  {
  width: 100%;
}

thead > tr > th {
  text-transform: uppercase;
}

.gestionInspecteur .rechercher-table table tr  {
  width: 100%;
}
.gestionInspecteur .rechercher-table table tr th {
    background-color: #243064;
    color: white;
    padding: 10px 2px;
    border-right:1px solid white;
}

.gestionInspecteur .rechercher-table table tr td {
    color: black;
    padding: 5px 2px;
    border-right: 1px solid rgb(206, 206, 206);
}
.gestionInspecteur .rechercher-table table tr td a {
  cursor: pointer;
  margin-right: 10px;
}



.gestionInspecteur .rechercher-table ul {
  width: 100%;
  height: fit-content;
  text-align: center;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gestionInspecteur .rechercher-table ul li {
  color:black;
 margin-left: 5px;
 cursor: pointer;
 font-size: 18px;
}
.gestionInspecteur .rechercher-table ul li:hover {
  color:red;
 margin-left: 5px;
 transition: 0.3s;
}


.gestionInspecteur .rechercher-table .deleteAll {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.gestionInspecteur .rechercher-table .deleteAll input {
  width: fit-content;
  padding: 10px;
  height: 40px;
  background-color: red;
  color: white;
  border: 0;
  cursor: pointer;
}

.fa-xmark {
  color: rgb(255, 187, 0);

}

.fa-trash {
  color: rgb(248, 29, 29);
}

.fa-check {
  color: green;
}

.fa-file-pdf {
  color: rgb(216, 25, 25);
  font-size: 24px !important;
}


#inspecteurs > tbody > tr > td > input[type=date] {
  height : 40px;
  width: fit-content;
  margin: 5px;
}

#inspecteurs > tbody > tr > td > input[type=text] {
  height : 40px;
  width: fit-content;
  margin: 5px;
}

#inspecteurs > tbody > tr > td > input[type=date]{
  height : 40px;
  width: fit-content;
  margin: 5px;
}


#inspecteurs > tbody > tr:nth-child(1) > td:nth-child(6) > select {
  height : 40px;
  margin: 5px;
}

#inspecteurs > tbody > tr > td:nth-child(8) > select {
  height : 40px;
  width: fit-content;
  margin: 5px;
}



#inspecteurs > tr > td:nth-child(10) > a:nth-child(1) > svg  {
  color: red;
  font-size: 20px;
}

#inspecteurs > tr > td:nth-child(10) > a:nth-child(2) > svg  {
  color: blue;
  font-size: 20px;
}

#inspecteurs > tr > td:nth-child(10) > a:nth-child(3) > svg  {
  color: green;
  font-size: 20px;
}

#app > div > div > div.menu-content > div.content > div > h3 {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin:0;

    color: white;
    background: linear-gradient(346deg, rgba(207,31,33,1) 0%, rgba(24,86,161,1) 100%);    text-align: center;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 25px;
}

#app > div > div > div.menu-content > div.content {
  background-color: white;
}

#inspecteurs > tr > td {
    color: #000000;
    border-bottom: 1px solid #9c9c9c;
    text-transform: capitalize;
}

table tbody tr:nth-child(even) {
  background-color: #f1f1f1;
}

#app > div > div > div.menu-content > div.content > div {
  background-color: white;
}

.pagination{
  display: flex;
  align-items: center;
  justify-content: center;
  margin:10px auto;
  width: fit-content;
  flex-wrap: wrap;
  padding:5px 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.pagination p{
  border:1px solid rgb(151, 151, 151);
  margin: 0 4px;
  padding:5px 10px;
  border-radius: 20px;
  cursor: pointer;
}

.pagination p:hover , button:hover{
background-color: #243064;
color:white;
}


.pagination button {
  border:0;
  padding:5px;
  border:1px solid rgb(170, 170, 170);
  margin-left: 15px;
  color:gray;
  cursor: pointer;
}

.pagination button:nth-child(1) {
  margin-right: 15px;
  margin-left: 0;
}

.searchBox {
  background-color: rgb(238, 238, 238);
  display: flex;
  margin: 0 10px;
  padding: 10px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.searchBox > select {
  padding: 10px;
  margin-left: 10px;
  border: 1px solid #bebebe;
  border-radius: 20px;
}
.searchBox >label {
  margin-left: 10px;
}

.searchBox > button {
    margin-left: 10px;
    padding: 10px;
    background-color: #00cf00a3;
    color: white;
    border: 0px;
    border-radius: 10px;
    cursor: pointer;
}



</style>