<template>
  <div class="etat-competence-inspecteur-chart">
    <h4>ÉTAT COMPETENCES & QUALIFICATIONS</h4>
    <table>
      <thead>
        <tr>
          <th>Collaborateurs</th>
          <th v-for="item in listeQualification" :key="item">{{ item }}</th>
        </tr>
      </thead>
      <tfoot v-for="(item) in inspecteurs" :key="item.name">
        <tr>
          <td>{{ item.name }}</td>
          <td v-for="index in countInspecteur" :key="index">{{ item[index] }}</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import Service from "../../../../../../Service";

export default {

  name: "etat-competence-chart",

  data() {
    return {
      countInspecteur : 0,
      listeQualification : [],
      inspecteurs : [],
      annee : null,
    };
  },

  props: {
    selectYear : Number,
    selectMois : Number,
  },

  methods: {},

  created() {
  },

  mounted() {

    // get info Indicateur by current
      Service.CalculEtatCompetence()
      .then((result) => {
        this.listeQualification = result.data.finalListeQualification
        this.inspecteurs = result.data.completeInfo
        this.inspecteurs.forEach((element) => {
           this.countInspecteur = Object.keys(element).length;
        });

        this.countInspecteur =  this.countInspecteur -3;

      })
      .catch((error) => {
          console.log(error.message)
      });
  }


};
</script>

<style scoped>

.etat-competence-inspecteur-chart > h4 {
  width: 100%;
    justify-content: center;
    display: flex;
    color: #1e1ed3;
    font-size: x-large;
    opacity: 0.6;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
}

table {
  width: min-content;
    border: 1px solid black;
}

table > thead > tr > th {
    border: 1px solid black;
}
table > tfoot > tr > td {
    border: 1px solid black;
}

</style>