<template>
    <div class="creationClient">
  
      <h3>MODIFIER UNE ACTUALITÉ</h3>
  
      <Traitement :msg="msgAdmin" v-if="traitement == true"/>
  
      <p v-if="echec" :class="{ echec: echec }">
          {{ error }}
      </p>

      <div class="formCreation">
                
                <div v-if="location != null">
                    <img :src="location" height="100px" width="100px" :alt="title">
                </div>
  
                <div>
                    <label for="Titre de L'actualité">Photo de L'actualité</label>
                    <input type="file" multiple="multiple" class="form-control" placeholder="Fichier PDF" ref="file" @change="previewFile" id="myFile" name="myFile" >
                </div>

                <div>
                    <label for="Titre de L'actualité">Référence de Photo (Privacy)</label>
                    <input type="text" v-model="refPicture">
                </div>

                <div>
                    <label for="Titre de L'actualité">Titre de L'actualité</label>
                    <input type="text" v-model="titre">
                </div>

                <div>
                    <label for="Auteur">Auteur</label>
                    <input type="text" v-model="auteur">
                </div>

                <div>
                    <label for="Introduction">Introduction</label>
                    <textarea class="missions" type="text" v-model="introduction"></textarea>
                </div>

                <div v-for="(item, index) in articles" :key="item.id">
                    <label for="Content">Paragraph {{ item.id }}</label>
                    <textarea class="missions" type="text" v-model="articles[index].content"></textarea>
                </div>

                <div>
                    <input type="button" value="Ajouter une paragraph" @click="ajouter()">
                </div>

                <div>
                    <label for="Conclusion">Conclusion</label>
                    <textarea class="missions" type="text" v-model="conclusion"></textarea>
                </div>

                <div>
                    <input type="submit" class="update" value="modifier l'actualité" @click="update()">
                </div>

                <div>
                    <input type="submit" value="Quitter" @click="quitter()">
                </div>

        </div>
  
       </div>
  </template>
  
  <script>
  import Service from "../../../../../Service";
  import Traitement from "../../Affaire/Traitement.vue"
  
  export default {
    data() {
      return {
        location : null,
        title : null,
        file: null,
        echec : false,
        traitement : false,
        titre: null,
        auteur: null,
        introduction : null,
        developments  : [],
        conclusion : null,
        refPicture : null,
        articles : []
      };
  
    },
  
    components : {
      Traitement
    },

    props :{
        actualiteId : String
    },
  
    methods: {

      // preciew file
      previewFile() {

        this.file = this.$refs.file.files[0];
        this.location = null;

      },

      ajouter() {
        const count = this.articles.length + 1;
        this.articles.push({ id : count , content : ""})
      },
  
      // update
      update() {

        for(let i = 0; i < this.articles.length; i++) {
            this.developments.push(this.articles[i].content);
        }

        Service.updateActualite(this.file, this.titre, this.auteur, this.introduction, this.developments, this.conclusion, this.refPicture, this.actualiteId)
        .then((response) => {
                
                this.traitement = true;
                this.msgAdmin = "Veuillez patienter quelques secondes pour enregistre l'actualité";
                var finish = false;
                this.file = null;
                this.titre = null;
                this.auteur = null;
                this.introduction = null;
                this.developments = [];
                this.conclusion = null;
                this.refPicture = null;
                this.location = null;
                this.title = null;
                this.articles = [
                  { id : 1, content : ""}
                ]

                if(response) {
                  setTimeout(() => { console.log("wait"); }, 5000);
                  finish = true
                }
  
                if(finish) {
                  setTimeout(() => {
                    this.msgAdmin = null;
                    this.traitement = false;
                  }, 5000);
                }
  
        })
        .catch((error) => {
            this.echec = true;
            this.error = error.message;
            console.error(`HTTP error: ${error.name} => ${error.message}`);
            throw "fail request at: GET /refreshtime";
        });

      }

    },
  
    created(){

        Service.selectActualite(this.actualiteId)
        .then((result) => {
                this.location = result.data.actualite.location;
                this.titre = result.data.actualite.titre;
                this.auteur = result.data.actualite.auteur;
                this.introduction = result.data.actualite.introduction;
                this.conclusion = result.data.actualite.conclusion;
                this.refPicture = result.data.actualite.refPicture;

                for(let i = 0; i < result.data.actualite.developments.length; i++) {
                    this.articles.push({ id : i , content : result.data.actualite.developments[i] });
                }
        })
        .catch((error) => {
            console.log(error);
        });

    }
  
  
  };
  </script>
  
  <style scoped>
  .creationClient {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .creationClient h3 {
    width: 100%;
    margin:auto;
    color: white;
    background: linear-gradient(346deg, rgba(207,31,33,1) 0%, rgba(24,86,161,1) 100%);    text-align: center;
    text-align: center;
  }
  .succes {
    background-color: #69cd5b;
    color: white;
    padding: 10px;
    width: 100%;
    height: fit-content;
  }
  .echec {
    background-color: RED;
    color: white;
    padding: 10px;
    width: 100%;
    height: fit-content;
  }
  .formCreation {
    padding:20px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .formCreation div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 48%;
  }
  .formCreation div label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 700;
    color :#494949;
  }
  .formCreation div input {
    height: 40px;
    margin-bottom: 5px;
    border: 1px solid #243064;
    padding:5px;
  }.formCreation div input:focus-within {
    outline: 1px solid #cf1f21 ;
    border:0;
  }
  .formCreation div input:focus-within {
    outline: 1px solid #cf1f21 ;
    border:0;
  }
  .formCreation div select {
    height: 40px;
  }
  #app > div > div > div.menu-content > div.content > div > div > div.formInspecteur {
    width: 100%;
  }
  #app > div > div > div.menu-content > div.content > div > div > div.formInspecteur div:last-child {
    border-bottom: 2px dashed rgb(255, 0, 0);
    margin-bottom: 10px;
  }
  #app > div > div > div.menu-content > div.content > div > div > div > .addinspec {
      background-color: rgb(161, 0, 80);
      color: white;
      border: 0;
      margin-top: 20px;
      padding:15px;
      cursor: pointer;
      margin-bottom: 10px;
      transition: 0.4s;
  }
  #app > div > div > div.menu-content > div.content > div > div > div > .addinspec:hover {
      background-color: rgb(143, 93, 118);
  }
  #app > div > div > div.menu-content > div.content > div > div > div > .addinspec svg {
   margin-right: 10px;
   font-size: 16px;
  }
  .formCreation div select {
    height: 40px;
  }
  #app > div > div > div.menu-content > div.content > div > div > div > div:nth-child(6) > input[type=submit] {
      background-color: green;
      color: white;
      border: 0;
      margin-top: 20px;
      cursor: pointer;
  }
  #app > div > div > div.menu-content > div.content > div > div > div > div:nth-child(14) > input[type=submit] {
      background-color: red;
      color: white;
      border: 0;
      margin-top: 10px;
      cursor: pointer;
  }
  
  #app > div > div > div.menu-content > div.content > div {
    background-color: white;
  }
  .inspeclabel label{
    margin: 10px 0;
    color:rgb(63, 63, 63) !important;
    background-color: #e4e4e4;
    padding:5px;
  }
  #app > div > div > div.menu-content > div.content > div > h3 {
      padding: 15px;
  }

  .description , .missions {
    height: 300px;
  }

  .qualifications, .profils {
    height: 100px;
  }
  
  </style>