<template>
  <div class="facture-financier-chart">
    <h4>ÉTAT FINANCIER DES FACTURES</h4>
    <canvas id="myEtatFinancierFactures"></canvas>
  </div>
</template>

<script>
import Service from "../../../../../Service";
import Chart from "chart.js";
export default {

  name: "facture-financier-chart",

  data() {
    return {
      annee : null,
    };
  },

  props: {
    selectYear : Number,
    selectMois : Number
  },

  methods: {},

  created() {
  },

  mounted() {

      Service.IndicteurSituationFinancierFacture(this.selectYear, this.selectMois)
        .then((result) => {

            const labels = [];
            const prixTTC = [];
            const colors = [];

            result.data.factureList.forEach(element => {
              labels.push(element.referenceFacture);
              prixTTC.push(element.prixTotalTTC);
              const randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1));
              const a = randomBetween(0, 255);
              const b = randomBetween(0, 255);
              const c = randomBetween(0, 255);
              colors.push(`rgb(${a},${b},${c})`)
            });


            const data = {
              labels: labels,
              datasets: [{
                label: "Prix TTC",
                data: prixTTC,
                backgroundColor: colors,
                borderWidth: 1
              }]
            };

            const config = {
                type: 'bar',
                data: data,
                options: {
                  scales: {
                    y: {
                      beginAtZero: true
                    }
                  }
                }
            };

            // instance Chart CTX
            const ctx = document.getElementById("myEtatFinancierFactures");
            const myEtatFinancierFactures = new Chart(ctx, config);

            return myEtatFinancierFactures;

        })
        .catch((error) => {
          console.log(error.message)
        });

  }


};
</script>

<style scoped>

  .facture-financier-chart {
    max-height: fit-content;
  }

  .facture-financier-chart > h4 {
      width: 100%;
      justify-content: center;
      display: flex;
      color: #1e1ed3;
      font-size: x-large;
      opacity: 0.6;
      font-weight: 500;
      margin-bottom: 10px;
      margin-top: 20px;
  }
  
</style>