<template>
    <div class="admin">
      <div class="container">
        <div class="header">
          <Nav />
        </div>
        <div class="menu-content">
          <div class="menu">
            <Menu
              @demandeAbsence="handelDemandeAbsence()"
            />
          </div>
          <div class="content">
  
            <!-- Start content Absence -->
            <DemandeAbsence v-if="flagDemandeAbsence" />
            <!-- Fin content Absence -->

          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Menu from "@/components/Admin/Menu.vue";
  import Nav from "@/components/Admin/Nav.vue";
  import DemandeAbsence from "../components/Admin/content/GestionRH/Conge/absence.vue";

  
  
  
  export default {
    name: "admin",
    data() {
  
      return {
        flagDemandeAbsence : true,
      };
  
    },
    components: {
      Menu,
      Nav,
      DemandeAbsence,
    },
  
    methods: {
  

      handelDemandeAbsence() {
        this.flagDemandeAbsence  = true;
      }
  
    },
  
  
  created() {
  
        if(!sessionStorage.getItem("token"))
        {
          this.$router.push("/")
        }
  }
  
  
  
  };
  </script>
  
  <style scoped>
  .admin .container .header {
    width: 100%;
    height: 80px;
    padding: 0px;
    margin: 0px;
  }
  
  .admin .container .menu-content{
  display: grid;
  grid-template-columns: 20% 80%;
  
  }
  
  
  .admin .container .menu-content menu {
  width: 20%;
  
  }
  .admin .container .menu-content .content {
    width: 100%;
  
  }
  
  
  
  </style>