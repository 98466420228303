<template>
  <div id="menuBanner">

  <ul class="list-menu">
    <li @click="accueille()" style="background:white;  box-shadow: 0px 0px 5px rgb(173, 173, 173);"><img src="./../../assets/logo.png" style="width: 157px; margin:0 50px;cursor: pointer;" alt=""></li>

    <li v-if="flagRouterAdmin == true">
      <ul @click="$emit('administrative')">
        <li><i class="fa-solid fa-user"></i></li>
        <li>CLIENT</li>
      </ul>

      <ul class="hoverlist">
        <li @click="$emit('creationClient')"><i class="fa-solid fa-square-plus"></i>Nouveau client</li>
        <li @click="$emit('gestionClient')"><i class="fa-solid fa-list"></i>Liste des Clients</li>
      </ul>

    </li>

    <li v-if="flagRouterAdmin == true">
      <ul @click="$emit('administrative')">
        <li><i class="fa-solid fa-user-group"></i></li>
        <li>INTERLOCUTEUR</li>
      </ul>

      <ul class="hoverlist">
        <li @click="$emit('creationInterlocuteur')"><i class="fa-solid fa-square-plus"></i>Nouveau Interlocuteur</li>
        <li @click="$emit('gestionInterlocuteur')"><i class="fa-solid fa-list"></i>Liste des Interlocuteurs</li>
      </ul>
    </li>

    
    <li v-if="flagRouterAdmin == true">

      <ul @click="$emit('administrative')">
        <li><i class="fa-solid fa-business-time"></i></li>
        <li>LES OFFRES COMMERCIALES</li>
      </ul>

      <ul class="hoverlist">
        <li @click="$emit('creationAffaire')"><i class="fa-solid fa-square-plus"></i>Nouvelle offre</li>
        <li @click="$emit('gestionAffaire')"><i class="fa-solid fa-list"></i>Liste des Offres Commeciales</li>
      </ul>

    </li>

    <li v-if="flagRouterFacture == true">
      <ul @click="$emit('facture')">
        <li><i class="fa-solid fa-file-lines"></i></li>
        <li>FACTURATION</li>
      </ul>

      <ul class="hoverlist">
        <li @click="$emit('attenteFacturation')"><i class="fa-solid fa-file-invoice"></i>En Attente de Facturation</li>
        <li @click="$emit('emiseFacture')"><i class="fa-solid fa-file-invoice-dollar"></i>Facture Émise</li>
        <li @click="$emit('caisseFacture')"><i class="fa-solid fa-sack-dollar"></i>Facture Encaissée</li>
      </ul>
    </li>

    <li v-if="flagSalarie == true">
      <ul @click="$emit('gestionrh')">
        <li><i class="fa-solid fa-person-circle-xmark"></i></li>
        <li>LES CONGÉS & LES ABSENCES</li>
      </ul>
      <ul class="hoverlist">
        <li @click="$emit('demandeAbsence')"><i class="fa-solid fa-square-plus"></i>Demande Absence</li>
        <li @click="$emit('listeDemandeAbsence')"><i class="fa-solid fa-list"></i>Liste Demande Absence</li>
      </ul>
    </li>

    <li v-if="flagSalarie == true">
      <ul @click="$emit('gestionrh')">
        <li><i class="fa-solid fa-user-group"></i></li>
        <li>FICHES COLLABORATEURS</li>
      </ul>
      <ul class="hoverlist">
        <li @click="$emit('creationSalarie')"><i class="fa-solid fa-square-plus"></i>Nouveau Collaborateur</li>
        <li @click="$emit('gestionSalarie')"><i class="fa-solid fa-list"></i>Liste Des Collaborateurs</li>
      </ul>
    </li>

    <li v-if="flagSalarie == true">
      <ul @click="$emit('gestionrh')">
        <li><i class="fa-solid fa-person-circle-check"></i></li>
        <li>COMPETENCES & QUALIFICATIONS</li>
      </ul>
      <ul class="hoverlist">
        <li @click="$emit('nouveauCompetences')"><i class="fa-solid fa-square-plus"></i>Nouveau compétences & qualifications</li>
        <li @click="$emit('listeCompetences')"><i class="fa-solid fa-list"></i>Liste des Qualifications</li>
      </ul>
    </li>

    <li v-if="flagSalarie == true">
      <ul @click="$emit('gestionrh')">
        <li><i class="fa-solid fa-user-group"></i></li>
        <li>LES FRAIS DES SALARIÉS</li>
      </ul>
      <ul>
        <li @click="$emit('gestionFraisSalaries')"><i class="fa-solid fa-square-plus"></i>Gestion les frais des salariés</li>
      </ul>
    </li>

    <li v-if="flagSalarie == true">
      <ul @click="$emit('gestionrh')">
        <li><i class="fa-solid fa-user-group"></i></li>
        <li>LES JOURS FERIES</li>
      </ul>
      <ul>
        <li @click="$emit('gestionJoursFeries')"><i class="fa-solid fa-square-plus"></i>Gestion les jours feries</li>
      </ul>
    </li>

    <li v-if="flagSalarie == true">
      <ul @click="$emit('gestionrh')">
        <li><i class="fa-solid fa-user-group"></i></li>
        <li>RECRUTEMENT</li>
      </ul>
      <ul>
        <li @click="ficheFonction"><i class="fa-solid fa-square-plus"></i>La fiche de fonction</li>
        <li @click="ficheCandidature"><i class="fa-solid fa-square-plus"></i>La fiche de candidtaure</li>
        <li @click="ficheIntegration"><i class="fa-solid fa-square-plus"></i>La fiche d'intégration</li>
      </ul>
    </li>

    <li v-if="flagSalarie == true">
      <ul @click="$emit('gestionrh')">
        <li><i class="fa-solid fa-user-group"></i></li>
        <li>Ticket</li>
      </ul>
      <ul>
        <li @click="$emit('createTicket')"><i class="fa-solid fa-square-plus"></i>Demande Ticket</li>
        <li @click="$emit('gestionTicket')"><i class="fa-solid fa-square-plus"></i>Liste de Tickets</li>
      </ul>
    </li>

    <li v-if="flagRouterSousTritance == true">
        <ul @click="$emit('fournisseurs')">
          <li><i class="fa-solid fa-boxes-packing"></i></li>
          <li>FOURNISSEURS</li>
        </ul>

        <ul class="hoverlist">
          <li @click="$emit('creationFournisseur')"><i class="fa-solid fa-square-plus"></i>Nouveau Fournisseur</li>
          <li @click="$emit('gestionFournisseur')"><i class="fa-solid fa-list"></i>Liste Des Fournisseurs</li>
        </ul>

    </li>

    <li v-if="flagRouterSousTritance == true">
        <ul @click="$emit('fournisseurs')">
          <li><i class="fa-solid fa-briefcase"></i></li>
          <li>SOUS-TRAITANCE</li>
        </ul>

        <ul class="hoverlist">
          <li @click="$emit('creationSousTraitance')"><i class="fa-solid fa-square-plus"></i>Nouveau sous-traitance</li>
          <li @click="$emit('gestionSousTraitance')"><i class="fa-solid fa-list"></i>Liste Des sous-traitances</li>
        </ul>
    </li>

    <li v-if="flagRouterSousTritance == true">
        <ul @click="$emit('fournisseurs')">
          <li><i class="fa-solid fa-users"></i></li>
          <li>INTERLOCUTEURS</li>
        </ul>

        <ul class="hoverlist">
          <li @click="$emit('creationInterlocuteurSousTraitance')"><i class="fa-solid fa-square-plus"></i>Nouveau Interlocuteur</li>
          <li @click="$emit('gestionInterlocuteurSousTraitance')"><i class="fa-solid fa-list"></i>Liste Des Interlocuteurs</li>
        </ul>
    </li>

    <li v-if="flagRouterSousTritance == true">
      <ul @click="$emit('fournisseurs')">
        <li><i class="fa-solid fa-person-walking"></i></li>
        <li>CHARGES EN EXPLOITATION</li>
      </ul>
      <ul>
        <li @click="$emit('creationChargeExploitation')"><i class="fa-solid fa-square-plus"></i>Création Charge Exploitation</li>
        <li @click="$emit('gestionChargeExploitation')"><i class="fa-solid fa-clipboard-list"></i>Liste Charges Exploitation</li>
      </ul>
    </li>

    <li v-if="flagRouterSousTritance == true">
      <ul @click="$emit('fournisseurs')">
        <li><i class="fa-solid fa-landmark"></i></li>
        <li>ACHAT FOURNISSEUR</li>
      </ul>
      <ul>
        <li @click="$emit('demandeAchatFournisseur')"><i class="fa-solid fa-square-plus"></i>Demande</li>
        <li @click="$emit('encoursAchatFournisseur')"><i class="fa-solid fa-spinner"></i>En cours</li>
        <li @click="$emit('termineAchatFournisseur')"><i class="fa-solid fa-clipboard-check"></i>Terminé</li>
      </ul>
    </li>

    <li v-if="flagRouterSousTritance == true">
      <ul @click="$emit('fournisseurs')">
        <li><i class="fa-solid fa-sack-dollar"></i></li>
        <li>ACHAT SOUS-TRAITANCE</li>
      </ul>
      <ul>
        <li @click="$emit('demandeAchatSousTraitance')"><i class="fa-solid fa-file-circle-plus"></i>Demande</li>
        <li @click="$emit('encoursAchatSousTraitance')"><i class="fa-solid fa-spinner"></i>En cours</li>
        <li @click="$emit('termineAchatSousTraitance')"><i class="fa-solid fa-clipboard-check"></i>Terminé</li>
      </ul>
    </li>


    <li v-if="flagRouterSousTritance == true">
      <ul @click="$emit('fournisseurs')">
        <li><i class="fa-solid fa-money-bill-wheat"></i></li>
        <li>AUTRE DÉPENSE</li>
      </ul>
      <ul>
        <li @click="$emit('creationDepense')"><i class="fa-solid fa-square-plus"></i>Création  Dépense</li>
        <li @click="$emit('gestionDepense')"><i class="fa-solid fa-table-list"></i>Liste Dépenses</li>
      </ul>
    </li>


    <li v-if="flagRouterProduction == true">
      <ul @click="$emit('production')">
        <li><i class="fa-brands fa-product-hunt"></i></li>
        <li>PRODUCTION</li>
      </ul>
      <ul class="hoverlist">
        <li @click="$emit('attenteProduction')"><i class="fa-solid fa-circle-pause"></i>En attente production</li>
        <li @click="$emit('coursProduction')"><i class="fa-solid fa-play"></i>En cours production</li>
        <li @click="$emit('realiseProduction')"><i class="fa-solid fa-check"></i>Réalisé</li>
      </ul>
    </li>

    <li v-if="flagRouterProduction == true">

      <ul @click="$emit('production')">
        <li><i class="fa-solid fa-clipboard"></i></li>
        <li>BORDEREAU D'ENVOI</li>
      </ul>

      <ul class="hoverlist">
        <li @click="$emit('creationBD')"><i class="fa-solid fa-square-plus"></i>Création Bordereau d'envoi</li>
        <li @click="$emit('listBD')"><i class="fa-solid fa-list"></i>Liste Bordereau d'envoi</li>
      </ul>
    </li>

    <li v-if="flagStatstiqueProduction == true">
      <ul @click="$emit('production')">
        <li><i class="fa-solid fa-handshake"></i></li>
        <li>STATSTIQUE PRODUCTION</li>
      </ul>
      <ul class="hoverlist">
        <li @click="$emit('affairesMois')"><i class="fa-solid fa-list"></i>Les affaires</li>
        <li @click="$emit('numberInspecteurs')"><i class="fa-solid fa-list"></i>Les inspecteurs</li>
      </ul>
    </li>



    <li v-if="flagRouterEtalonnage == true">

      <ul @click="qualite()">
        <li><i class="fa-solid fa-user-group"></i></li>
        <li>ÉTALONNAGE</li>
      </ul>

      <ul class="hoverlist">
        <li @click="$emit('creationEtalonnage')"><i class="fa-solid fa-square-plus"></i>Crée Étalonnage</li>
        <li @click="$emit('gestionEtalonnage')"><i class="fa-solid fa-list"></i>Liste Des Étalonnage</li>
      </ul>

    </li>

    <li v-if="flagTechniqueMateriel == true">
      <ul @click="qualite()">
        <li><i class="fa-solid fa-file-lines"></i></li>
        <li>FICHE TECHNIQUE MATÉRIEL</li>
      </ul>

      <ul class="hoverlist">
        <li @click="$emit('creationFicheTechnique')"><i class="fa-solid fa-square-plus"></i>Crée fiche technique matériel</li>
        <li @click="$emit('gestionFicheTechnique')"><i class="fa-solid fa-list"></i>Liste Des fiches techniques matériels</li>
      </ul>
    </li>



    <li v-if="flagAgrement == true">
      <ul @click="qualite()">
        <li><i class="fa-solid fa-handshake"></i></li>
        <li>LES AGRÉMENTS</li>
      </ul>
      <ul class="hoverlist">
        <li @click="$emit('nouveauAgrement')"><i class="fa-solid fa-square-plus"></i>Ajouter un nouvelle agrément</li>
        <li @click="$emit('listeAgrements')"><i class="fa-solid fa-list"></i>Liste des agréments ministériel</li>
      </ul>
    </li>

    <li v-if="flagPrivate == true">
      
      <ul @click="accueille()">
        <li><i class="fa-solid fa-handshake"></i></li>
        <li>Rapports</li>
      </ul>

      <ul class="hoverlist">
        <li @click="$emit('gestionRapports')"><i class="fa-solid fa-list"></i>Liste des rapports</li>
        <li @click="$emit('gestionRapportsNotConfirm')"><i class="fa-solid fa-list"></i>Liste des rapports non confirme</li>
        <li @click="$emit('gestionRapportsConfirm')"><i class="fa-solid fa-list"></i>Liste des rapports confirme</li>
      </ul>

    </li>

    <li v-if="flagPrivate == true">
      
      <ul @click="accueille()">
        <li><i class="fa-solid fa-handshake"></i></li>
        <li>Recherche and Fix Errors</li>
      </ul>

      <ul class="hoverlist">
        <li @click="$emit('rechercheFilename')"><i class="fa-solid fa-list"></i>Recherche par nom de fichier</li>
        <li @click="$emit('rechercheNumeroAffaire')"><i class="fa-solid fa-list"></i>Recherche par Numéro d'affaires </li>
      </ul>

    </li>

    <li v-if="flagPrivate == true">
      <ul @click="accueille()">
        <li><i class="fa-solid fa-handshake"></i></li>
        <li>Admins</li>
      </ul>
      <ul class="hoverlist">
        <li @click="$emit('gestionAdmins')"><i class="fa-solid fa-list"></i>Liste des admins</li>
        <li @click="$emit('creationAdmin')"><i class="fa-solid fa-list"></i>Création admin</li>
      </ul>
    </li>

    <li v-if="flagPrivate == true">
      <ul @click="accueille()">
        <li><i class="fa-solid fa-handshake"></i></li>
        <li>Agendas</li>
      </ul>
      <ul class="hoverlist">
        <li @click="$emit('readAgenda')"><i class="fa-solid fa-list"></i>Liste des agendas</li>
      </ul>
    </li>

    <li v-if="flagPrivate == true">
      <ul @click="accueille()">
        <li><i class="fa-solid fa-handshake"></i></li>
        <li>Fix Affaire & Rapport</li>
      </ul>
      <ul class="hoverlist">
        <li @click="$emit('fixAffaireRapport')"><i class="fa-solid fa-list"></i>Réparation N°Affaire & N° Rapport</li>
        <li @click="$emit('initAffaireRapport')"><i class="fa-solid fa-list"></i>Initialiser N°Affaire & N° Rapport</li>
      </ul>

    </li>

    <li v-if="flagPrivate == true">
      <ul @click="accueille()">
        <li><i class="fa-solid fa-handshake"></i></li>
        <li>Fix Interlocuteur & Client</li>
      </ul>
      <ul class="hoverlist">
        <li @click="$emit('fixInterlocuteurClient')"><i class="fa-solid fa-list"></i>Réparation Interlocuteurs & Clients</li>
      </ul>
    </li>

    <li v-if="flagPrivate == true">
      <ul @click="accueille()">
        <li><i class="fa-solid fa-handshake"></i></li>
        <li>Fix Calendrier</li>
      </ul>
      <ul class="hoverlist">
        <li @click="$emit('fixMissionInspecteur')"><i class="fa-solid fa-list"></i>Supprimer Mission Inspecteur</li>
      </ul>
    </li>

    <li v-if="flagPrivate == true">
      <ul @click="accueille()">
        <li><i class="fa-solid fa-handshake"></i></li>
        <li>Offre D'emploi</li>
      </ul>
      <ul class="hoverlist">
        <li @click="$emit('OffreEmploi')"><i class="fa-solid fa-list"></i>Nouveau Offre Emploi</li>
        <li @click="$emit('listeOffreEmploi')"><i class="fa-solid fa-list"></i>Liste des Emploi</li>
      </ul>
    </li>

    <li v-if="flagPrivate == true">
      <ul @click="accueille()">
        <li><i class="fa-solid fa-handshake"></i></li>
        <li>GTHCONSULT Actualités</li>
      </ul>
      <ul class="hoverlist">
        <li @click="$emit('actualite')"><i class="fa-solid fa-list"></i>Nouveau Actualité</li>
        <li @click="$emit('listeActualite')"><i class="fa-solid fa-list"></i>Liste des Actualité</li>
      </ul>
    </li>

    <li v-if="flagPrivate == true">
      <ul @click="accueille()">
        <li><i class="fa-solid fa-handshake"></i></li>
        <li>GTHCONSULT Prestations</li>
      </ul>
      <ul class="hoverlist">
        <li @click="$emit('prestation')"><i class="fa-solid fa-list"></i>Nouveau Prestation</li>
        <li @click="$emit('listePrestation')"><i class="fa-solid fa-list"></i>Liste des Prestations</li>
      </ul>
    </li>



    <li v-if="flagAnalyseStatistiqueAdminstrative == true">

      <ul @click="accueille()">
        <li><i class="fa-solid fa-handshake"></i></li>
        <li>Analyse Statistique Adminstrative</li>
      </ul>

      <ul class="hoverlist">
        <li @click="$emit('indicateurAffaires')"><i class="fa-solid fa-list"></i>Les indicateur Affaires</li>
        <li @click="$emit('indicateurFacturation')"><i class="fa-solid fa-list"></i>Les indicateur Facturation</li>
        <li @click="$emit('indicateurRh')"><i class="fa-solid fa-list"></i>Les indicateur RH</li>
        <li @click="$emit('indicateurFournisseur')"><i class="fa-solid fa-list"></i>Les indicateur Des Fournisseur</li>
      </ul>

    </li>

    <li v-if="flagAnalyseStatistiqueProduction == true">

      <ul @click="accueille()">
        <li><i class="fa-solid fa-handshake"></i></li>
        <li>Analyse Statistique Production</li>
      </ul>

      <ul class="hoverlist">
        <li @click="$emit('indicateurProduction')"><i class="fa-solid fa-list"></i>Les indicateur Production</li>
      </ul>

    </li>


  </ul>

</div>
</template>

<script>
import Service from '../../Service';
Service
export default {
  data() {
    return {
      flagClients: true,
      flagIntelocuteur: true,
      flagAffaire: true,
      flagProduction : true,
      flagBD : true,
      flagFacturation : false,
      flagRouterFacture : false,
      flagRouterProduction : false,
      flagRouterAdmin : false,
      flagRouterEtalonnage : false,
      flagTechniqueMateriel : false,
      flagSalarie : false,
      flagConge : false,
      flagRouterSousTritance : false,
      flagAgrement : false,
      flagAchat : false,
      flagDepense : false,
      flagCharge : false,
      flagPrivate : false,
      flagStatstiqueProduction : false,
      flagAnalyseStatistiqueAdminstrative : false,
      flagAnalyseStatistiqueProduction : false

    };
  },

  methods: {


    downloadFile(response, title) {

      var newBlob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
      }

      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = title;
      link.click();
      setTimeout(function() {
          window.URL.revokeObjectURL(data);
      }, 100);

    },

    ficheFonction() {
      Service.ficheFonction()
      .then((result) => {
        this.downloadFile(result, "fiche-fonction.docx");
      })
      .catch((error) => {
        console.log(error);
      })
    },

    ficheCandidature() {
      Service.ficheCandidature()
      .then((result) => {
        this.downloadFile(result, "fiche-candidature.docx");
      })
      .catch((error) => {
        console.log(error);
      })
    },

    ficheIntegration() {
      Service.ficheIntegration()
      .then((result) => {
        this.downloadFile(result, "fiche-integration.docx");
      })
      .catch((error) => {
        console.log(error);
      })
    },


    accueille() {
      return this.$router.push("/interface");
    },


    qualite() {
      return this.$router.push("/dq");
    }

  },

  created() {


    if(this.$route.name == "Facture") {

      this.flagRouterProduction = false;
      this.flagRouterFacture = true;
      this.flagRouterAdmin = false;
      this.flagRouterEtalonnage = false;
      this.flagTechniqueMateriel = false;
      this.flagSalarie = false;
      this.flagConge = false;
      this.flagRouterSousTritance = false;
      this.flagAgrement = false;
      this.flagAchat = false;
      this.flagDepense = false;
      this.flagCharge = false;
      this.flagPrivate = false;
      this.flagStatstiqueProduction = false;
      this.flagAnalyseStatistiqueAdminstrative = false;
      this.flagAnalyseStatistiqueProduction = false;
    }

    if(this.$route.name == "Production") {

      this.flagRouterProduction = true;
      this.flagRouterFacture = false;
      this.flagRouterAdmin = false;
      this.flagRouterEtalonnage = false;
      this.flagTechniqueMateriel = false;
      this.flagSalarie = false;
      this.flagConge = false;
      this.flagRouterSousTritance = false;
      this.flagAgrement = false;
      this.flagAchat = false;
      this.flagDepense = false;
      this.flagCharge = false;
      this.flagPrivate = false;
      this.flagStatstiqueProduction = false;
      this.flagAnalyseStatistiqueAdminstrative = false;
      this.flagAnalyseStatistiqueProduction = false;
    }


    if(this.$route.name == "Admin") {
      this.flagRouterProduction = false;
      this.flagRouterFacture = false;
      this.flagRouterAdmin = true;
      this.flagRouterEtalonnage = false;
      this.flagTechniqueMateriel = false;
      this.flagSalarie = false;
      this.flagConge = false;
      this.flagRouterSousTritance = false;
      this.flagAgrement = false;
      this.flagAchat = false;
      this.flagDepense = false;
      this.flagCharge = false;
      this.flagPrivate = false;
      this.flagStatstiqueProduction = false;
      this.flagAnalyseStatistiqueAdminstrative = false;
      this.flagAnalyseStatistiqueProduction = false;
    }

    if(this.$route.name == "Etalonnage") {

      this.flagRouterProduction = false;
      this.flagRouterFacture = false;
      this.flagRouterAdmin = false;
      this.flagRouterEtalonnage = true;
      this.flagTechniqueMateriel = false;
      this.flagSalarie = false;
      this.flagConge = false;
      this.flagRouterSousTritance = false;
      this.flagAgrement = false;
      this.flagAchat = false;
      this.flagDepense = false;
      this.flagCharge = false;
      this.flagPrivate = false;
      this.flagStatstiqueProduction = false;
      this.flagAnalyseStatistiqueAdminstrative = false;
      this.flagAnalyseStatistiqueProduction = false;
      }

    if(this.$route.name == "FicheTechnique") {

      this.flagRouterProduction = false;
      this.flagRouterFacture = false;
      this.flagRouterAdmin = false;
      this.flagRouterEtalonnage = false;
      this.flagTechniqueMateriel = true;
      this.flagSalarie = false;
      this.flagConge = false;
      this.flagRouterSousTritance = false;
      this.flagAgrement = false;
      this.flagAchat = false;
      this.flagDepense = false;
      this.flagCharge = false;
      this.flagPrivate = false;
      this.flagStatstiqueProduction = false;
      this.flagAnalyseStatistiqueAdminstrative = false;
      this.flagAnalyseStatistiqueProduction = false;
      }

    if(this.$route.name == "GestionRH") {

      this.flagRouterProduction = false;
      this.flagRouterFacture = false;
      this.flagRouterAdmin = false;
      this.flagRouterEtalonnage = false;
      this.flagTechniqueMateriel = false;
      this.flagSalarie = true;
      this.flagConge = false
      this.flagRouterSousTritance = false;
      this.flagAgrement = false;
      this.flagAchat = false;
      this.flagDepense = false;
      this.flagCharge = false;
      this.flagPrivate = false;
      this.flagStatstiqueProduction = false;
      this.flagAnalyseStatistiqueAdminstrative = false;
      this.flagAnalyseStatistiqueProduction = false;
    }

    if(this.$route.name == "Conge") {

      this.flagRouterProduction = false;
      this.flagRouterFacture = false;
      this.flagRouterAdmin = false;
      this.flagRouterEtalonnage = false;
      this.flagTechniqueMateriel = false;
      this.flagSalarie = false;
      this.flagConge = true;
      this.flagRouterSousTritance = false;
      this.flagAgrement = false;
      this.flagAchat = false;
      this.flagDepense = false;
      this.flagCharge = false;
      this.flagPrivate = false;
      this.flagStatstiqueProduction = false;
      this.flagAnalyseStatistiqueAdminstrative = false;
      this.flagAnalyseStatistiqueProduction = false;
    }

    if(this.$route.name == "Fournisseur") {

      this.flagRouterProduction = false;
      this.flagRouterFacture = false;
      this.flagRouterAdmin = false;
      this.flagRouterEtalonnage = false;
      this.flagTechniqueMateriel = false;
      this.flagSalarie = false;
      this.flagConge = false;
      this.flagRouterSousTritance = true;
      this.flagAgrement = false;
      this.flagAchat = false;
      this.flagDepense = false;
      this.flagCharge = false;
      this.flagPrivate = false;
      this.flagStatstiqueProduction = false;
      this.flagAnalyseStatistiqueAdminstrative = false;
      this.flagAnalyseStatistiqueProduction = false;
    }

    if(this.$route.name == "Agrement") {

      this.flagRouterProduction = false;
      this.flagRouterFacture = false;
      this.flagRouterAdmin = false;
      this.flagRouterEtalonnage = false;
      this.flagTechniqueMateriel = false;
      this.flagSalarie = false;
      this.flagConge = false;
      this.flagRouterSousTritance = false;
      this.flagAgrement = true;
      this.flagAchat = false;
      this.flagDepense = false;
      this.flagCharge = false;
      this.flagPrivate = false;
      this.flagStatstiqueProduction = false;
      this.flagAnalyseStatistiqueAdminstrative = false;
      this.flagAnalyseStatistiqueProduction = false;
    }

    if(this.$route.name == "Achat") {

      this.flagRouterProduction = false;
      this.flagRouterFacture = false;
      this.flagRouterAdmin = false;
      this.flagRouterEtalonnage = false;
      this.flagTechniqueMateriel = false;
      this.flagSalarie = false;
      this.flagConge = false;
      this.flagRouterSousTritance = false;
      this.flagAgrement = false;
      this.flagAchat = true;
      this.flagDepense = false;
      this.flagCharge = false;
      this.flagPrivate = false;
      this.flagStatstiqueProduction = false;
      this.flagAnalyseStatistiqueAdminstrative = false;
      this.flagAnalyseStatistiqueProduction = false;
    }

    if(this.$route.name == "Depense") {

      this.flagRouterProduction = false;
      this.flagRouterFacture = false;
      this.flagRouterAdmin = false;
      this.flagRouterEtalonnage = false;
      this.flagTechniqueMateriel = false;
      this.flagSalarie = false;
      this.flagConge = false;
      this.flagRouterSousTritance = false;
      this.flagAgrement = false;
      this.flagAchat = false;
      this.flagDepense = true;
      this.flagCharge = false;
      this.flagPrivate = false;
      this.flagStatstiqueProduction = false;
      this.flagAnalyseStatistiqueAdminstrative = false;
      this.flagAnalyseStatistiqueProduction = false;
    }

    if(this.$route.name == "ChargeExploitaton") {

      this.flagRouterProduction = false;
      this.flagRouterFacture = false;
      this.flagRouterAdmin = false;
      this.flagRouterEtalonnage = false;
      this.flagTechniqueMateriel = false;
      this.flagSalarie = false;
      this.flagConge = false;
      this.flagRouterSousTritance = false;
      this.flagAgrement = false;
      this.flagAchat = false;
      this.flagDepense = false;
      this.flagCharge = true;
      this.flagPrivate = true;
      this.flagStatstiqueProduction = false;
      this.flagAnalyseStatistiqueAdminstrative = false;
      this.flagAnalyseStatistiqueProduction = false;
    }

    if(this.$route.name == "Private") {

      this.flagRouterProduction = false;
      this.flagRouterFacture = false;
      this.flagRouterAdmin = false;
      this.flagRouterEtalonnage = false;
      this.flagTechniqueMateriel = false;
      this.flagSalarie = false;
      this.flagConge = false;
      this.flagRouterSousTritance = false;
      this.flagAgrement = false;
      this.flagAchat = false;
      this.flagDepense = false;
      this.flagCharge = true;
      this.flagPrivate = true;
      this.flagStatstiqueProduction = false;
      this.flagAnalyseStatistiqueAdminstrative = false;
      this.flagAnalyseStatistiqueProduction = false;
    }

    if(this.$route.name == "StatistiqueProduction") {

      this.flagRouterProduction = false;
      this.flagRouterFacture = false;
      this.flagRouterAdmin = false;
      this.flagRouterEtalonnage = false;
      this.flagTechniqueMateriel = false;
      this.flagSalarie = false;
      this.flagConge = false;
      this.flagRouterSousTritance = false;
      this.flagAgrement = false;
      this.flagAchat = false;
      this.flagDepense = false;
      this.flagCharge = false;
      this.flagPrivate = false;
      this.flagStatstiqueProduction = true;
      this.flagAnalyseStatistiqueAdminstrative = false;
      this.flagAnalyseStatistiqueProduction = false;
    }

    if(this.$route.name == "AnalyseAdminstrative") {

      this.flagRouterProduction = false;
      this.flagRouterFacture = false;
      this.flagRouterAdmin = false;
      this.flagRouterEtalonnage = false;
      this.flagTechniqueMateriel = false;
      this.flagSalarie = false;
      this.flagConge = false;
      this.flagRouterSousTritance = false;
      this.flagAgrement = false;
      this.flagAchat = false;
      this.flagDepense = false;
      this.flagCharge = false;
      this.flagPrivate = false;
      this.flagStatstiqueProduction = false;
      this.flagAnalyseStatistiqueAdminstrative = true;
      this.flagAnalyseStatistiqueProduction = false;
    }

    if(this.$route.name == "AnalyseProduction") {

      this.flagRouterProduction = false;
      this.flagRouterFacture = false;
      this.flagRouterAdmin = false;
      this.flagRouterEtalonnage = false;
      this.flagTechniqueMateriel = false;
      this.flagSalarie = false;
      this.flagConge = false;
      this.flagRouterSousTritance = false;
      this.flagAgrement = false;
      this.flagAchat = false;
      this.flagDepense = false;
      this.flagCharge = false;
      this.flagPrivate = false;
      this.flagStatstiqueProduction = false;
      this.flagAnalyseStatistiqueAdminstrative = false;
      this.flagAnalyseStatistiqueProduction = true;
    }

  },

  computed: { }

};
</script>

<style scoped>

#menuBanner{
  background:rgb(245, 245, 245);
  width: 20%;
  height: 100vh;
  position: fixed;
  top:0;
  border-right: 1px solid rgb(223, 223, 223);
  left:0;
  overflow-y: auto;
}

.list-menu li ul:nth-child(1) {
    display: flex;
    flex-direction: row;
    padding: 10px;
    background: #1943ff;
    cursor: pointer;
}

.list-menu li ul:nth-child(1) li:nth-child(1) svg {
  font-size: 20px;
  color: white;
}

.list-menu li ul:nth-child(1) li:nth-child(2) {
  font-size: 19px;
  font-weight: 700;

  color: white;
}

/* /////////////////////////////////////// */

.list-menu li ul:not(:nth-child(1)) {

    padding: 5px;
    font-weight: 500;
}



.list-menu li ul:not(:nth-child(1)) li:nth-child(1) svg {
  font-size: 15px;
  color: white;
}

.list-menu li ul:not(:nth-child(1)) li:nth-child(1) {
  font-size: 15px;
  margin-left: 5px;
  color: white;
  background-color: #ff4043 ;
  padding: 5px;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
}



.list-menu li ul:not(:nth-child(1)) li:nth-child(2) svg {
  font-size: 15px;
  color: white;
}

.list-menu li ul:not(:nth-child(1)) li:nth-child(2) {
  font-size: 15px;
  margin-left: 5px;
  color: white;
  background-color: #ff4043 ;
  padding: 5px;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
}

.list-menu li ul:not(:nth-child(1)) li:nth-child(3) svg {
  font-size: 15px;
  color: white;

}

.list-menu li ul:not(:nth-child(1)) li:nth-child(3) {
  font-size: 15px;
  margin-left: 5px;
  color: white;
  background-color :#ff4043;
  padding: 5px;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
}

.list-menu li ul:not(:nth-child(1)) li:nth-child(4) svg {
  font-size: 15px;
  color: white;

}

.list-menu li ul:not(:nth-child(1)) li:nth-child(4) {
  font-size: 15px;
  margin-left: 5px;
  color: white;
  background-color :#ff4043;
  padding: 5px;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
}

.list-menu li ul:not(:nth-child(1)) li:nth-child(5) svg {
  font-size: 15px;
  color: white;

}

.list-menu li ul:not(:nth-child(1)) li:nth-child(5) {
  font-size: 15px;
  margin-left: 5px;
  color: white;
  background-color :#ff4043;
  padding: 5px;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
}

.hoverlist li:hover{
  background-color:rgb(202, 29, 29) !important ;
}


svg{
  margin-right: 5px;
}



</style>