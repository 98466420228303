<template>
  <div class="admin">
    <div class="container">

      <div class="header">
        <Nav />
      </div>

      <div class="menu-content">
        <div class="menu">
          <Menu
            @gestionRapports="handelGestionRapports()"
            @gestionRapportsNotConfirm="handelGestionRapportsNotConfirm()"
            @gestionRapportsConfirm="handelGestionRapportsConfirm()"
            @gestionAdmins="handelGestionAdmins()"
            @creationAdmin="handelCreationAdmin()"
            @rechercheFilename="handelRechercheFilename()"
            @rechercheNumeroAffaire="handelRechercheNumeroAffaire()"
            @readAgenda="handelReadAgenda()"
            @fixAffaireRapport="handelFixAffaireRapport()"
            @initAffaireRapport="handelInitAffaireRapport()"
            @fixInterlocuteurClient="handelFixInterlocuteurClient()"
            @OffreEmploi="handelOffreEmploi()"
            @fixMissionInspecteur="handelFixMissionInspecteur()"
            @listeOffreEmploi="handelListeOffreEmploi()"
            @actualite="handelActualite()"
            @listeActualite="handelListeActualite()"
            @prestation="handelPrestation()"
            @listePrestation="handelListePrestation()"
          />
        </div>
        <div class="content">

          <!-- start content Rapports -->
          <GestionRapport v-if="flagGestionRapport" />
          <GestionAdmin v-if="flagGestionAdmin" />
          <CreationAdmin v-if="flagCreationAdmin" />
          <GestionRapportConfirm v-if="flagGestionRapportConfirm" />
          <GestionRapportNotConfirm v-if="flagGestionRapportNotConfirm" />
          <RechercheFilename v-if="flagRechercheFilename" />
          <RechercheNumeroAffaire v-if="flagRechercheNumeroAffaire" />
          <ReadAgenda v-if="flagReadAgenda" />
          <FixAffaireRapport v-if="flagFixAffaireRapport" />
          <InitAffaireRapport v-if="flagInitAffaireRapport" />
          <FixInterlocuteurClient v-if="flagFixInterlocuteurClient" />
          <FixMissionInspecteur v-if="flagFixMissionInspecteur" />
          <OffreEmploi v-if="flagOffreEmploi" />
          <Actualites v-if="flagActualites" />
          <ListeOffreEmploi v-if="flagListeOffreEmploi" />
          <ListeActualites v-if="flagListeActualites" />
          <NouveauPrestation v-if="flagPrestation" />
          <ListePrestation v-if="flagListePrestation" />
          <!-- end content Rapports -->


        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from "@/components/Admin/Menu.vue";
import Nav from "@/components/Admin/Nav.vue";

// import IndicateurFournisseur from "@/components/Admin/content/Fournisseur/IndicateurFournisseur";

import FixAffaireRapport from "@/components/Admin/content/FixAffaireRapport/FixAffaireRapport.vue";
import OffreEmploi from "@/components/Admin/content/FixAffaireRapport/OffreEmploi.vue";
import ListeOffreEmploi from "@/components/Admin/content/FixAffaireRapport/ListeOffreEmploi.vue";
import Actualites from "@/components/Admin/content/FixAffaireRapport/Actualites.vue";
import ListeActualites from "@/components/Admin/content/FixAffaireRapport/ListeActualites.vue";
import InitAffaireRapport from "@/components/Admin/content/FixAffaireRapport/InitAffaireRapport.vue";
import ReadAgenda from "@/components/Admin/content/FixAgenda/ReadAgenda";
import GestionRapport from "@/components/Admin/content/Rapport/GestionRapport";
import GestionRapportConfirm from "@/components/Admin/content/Rapport/GestionRapportConfirm";
import GestionRapportNotConfirm from "@/components/Admin/content/Rapport/GestionRapportNotConfirm";
import RechercheFilename from "@/components/Admin/content/Rapport/RechercheFilename";
import RechercheNumeroAffaire from "@/components/Admin/content/Rapport/RechercheNumeroAffaire";
import GestionAdmin from "@/components/Admin/content/Admin/GestionAdmin";
import CreationAdmin from "@/components/Admin/content/Admin/CreationAdmin";
import FixInterlocuteurClient from "@/components/Admin/content/FixInterlocuteurClient/FixInterlocuteurClient";
import FixMissionInspecteur from "@/components/Admin/content/Calendrier/FixMissionInspecteur";
import NouveauPrestation from "@/components/Admin/content/Prestation/NouveauPrestation";
import ListePrestation from "@/components/Admin/content/Prestation/ListePrestation";


export default {
  name: "admin",
  data() {
    return {

      flagGestionRapport : false,
      flagGestionAdmin : false,
      flagCreationAdmin : false,
      flagGestionRapportConfirm : false,
      flagGestionRapportNotConfirm : false,
      flagRechercheFilename : false,
      flagRechercheNumeroAffaire : false,
      flagReadAgenda : false,
      flagFixAffaireRapport : false,
      flagInitAffaireRapport : false,
      flagFixInterlocuteurClient : false,
      flagFixMissionInspecteur : false,
      flagOffreEmploi : false,
      flagActualites: false,
      flagListeOffreEmploi : false,
      flagListeActualites : false,
      flagPrestation : false,
      flagListePrestation : false,

    };
  },
  components: {
    Menu,
    Nav,
    GestionRapport,
    GestionAdmin,
    CreationAdmin,
    GestionRapportConfirm,
    GestionRapportNotConfirm,
    RechercheFilename,
    RechercheNumeroAffaire,
    ReadAgenda,
    FixAffaireRapport,
    InitAffaireRapport,
    FixInterlocuteurClient,
    FixMissionInspecteur,
    OffreEmploi,
    Actualites,
    ListeOffreEmploi,
    ListeActualites,
    NouveauPrestation,
    ListePrestation
  },

  methods: {

    handelPrestation() {
      this.flagPrestation = true;
      this.flagListePrestation = false;
      this.flagListeActualites = false;
      this.flagActualites = false;
      this.flagFixInterlocuteurClient = false;
      this.flagGestionRapport = false;
      this.flagGestionAdmin = false;
      this.flagCreationAdmin = false;
      this.flagGestionRapportConfirm = false;
      this.flagGestionRapportNotConfirm = false;
      this.flagRechercheFilename = false;
      this.flagRechercheNumeroAffaire = false;
      this.flagReadAgenda = false;
      this.flagFixAffaireRapport = false;
      this.flagInitAffaireRapport = false;
      this.flagFixMissionInspecteur = false;
      this.flagOffreEmploi = false;
      this.flagListeOffreEmploi = false;
    },

    handelListePrestation() {
      this.flagPrestation = false;
      this.flagListePrestation = true;
      this.flagListeActualites = false;
      this.flagActualites = false;
      this.flagFixInterlocuteurClient = false;
      this.flagGestionRapport = false;
      this.flagGestionAdmin = false;
      this.flagCreationAdmin = false;
      this.flagGestionRapportConfirm = false;
      this.flagGestionRapportNotConfirm = false;
      this.flagRechercheFilename = false;
      this.flagRechercheNumeroAffaire = false;
      this.flagReadAgenda = false;
      this.flagFixAffaireRapport = false;
      this.flagInitAffaireRapport = false;
      this.flagFixMissionInspecteur = false;
      this.flagOffreEmploi = false;
      this.flagListeOffreEmploi = false;
    },

    handelListeActualite() {
      this.flagListeActualites = true;
      this.flagActualites = false;
      this.flagFixInterlocuteurClient = false;
      this.flagGestionRapport = false;
      this.flagGestionAdmin = false;
      this.flagCreationAdmin = false;
      this.flagGestionRapportConfirm = false;
      this.flagGestionRapportNotConfirm = false;
      this.flagRechercheFilename = false;
      this.flagRechercheNumeroAffaire = false;
      this.flagReadAgenda = false;
      this.flagFixAffaireRapport = false;
      this.flagInitAffaireRapport = false;
      this.flagFixMissionInspecteur = false;
      this.flagOffreEmploi = false;
      this.flagListeOffreEmploi = false;
    },

    handelListeOffreEmploi() {
      this.flagListeActualites = false;
      this.flagActualites = false;
      this.flagFixInterlocuteurClient = false;
      this.flagGestionRapport = false;
      this.flagGestionAdmin = false;
      this.flagCreationAdmin = false;
      this.flagGestionRapportConfirm = false;
      this.flagGestionRapportNotConfirm = false;
      this.flagRechercheFilename = false;
      this.flagRechercheNumeroAffaire = false;
      this.flagReadAgenda = false;
      this.flagFixAffaireRapport = false;
      this.flagInitAffaireRapport = false;
      this.flagFixMissionInspecteur = false;
      this.flagOffreEmploi = false;
      this.flagListeOffreEmploi = true;
    },

    handelActualite() {
      this.flagListeActualites = false;
      this.flagActualites = true;
      this.flagFixInterlocuteurClient = false;
      this.flagGestionRapport = false;
      this.flagGestionAdmin = false;
      this.flagCreationAdmin = false;
      this.flagGestionRapportConfirm = false;
      this.flagGestionRapportNotConfirm = false;
      this.flagRechercheFilename = false;
      this.flagRechercheNumeroAffaire = false;
      this.flagReadAgenda = false;
      this.flagFixAffaireRapport = false;
      this.flagInitAffaireRapport = false;
      this.flagFixMissionInspecteur = false;
      this.flagOffreEmploi = false;
      this.flagListeOffreEmploi = false;

    },

    handelFixInterlocuteurClient() {
      this.flagListeActualites = false;
      this.flagFixInterlocuteurClient = true;
      this.flagGestionRapport = false;
      this.flagGestionAdmin = false;
      this.flagCreationAdmin = false;
      this.flagGestionRapportConfirm = false;
      this.flagGestionRapportNotConfirm = false;
      this.flagRechercheFilename = false;
      this.flagRechercheNumeroAffaire = false;
      this.flagReadAgenda = false;
      this.flagFixAffaireRapport = false;
      this.flagInitAffaireRapport = false;
      this.flagFixMissionInspecteur = false;
      this.flagOffreEmploi = false;
      this.flagActualites = false;
      this.flagListeOffreEmploi = false;

    },

    handelOffreEmploi() {
      this.flagListeActualites = false;
      this.flagFixInterlocuteurClient = false;
      this.flagGestionRapport = false;
      this.flagGestionAdmin = false;
      this.flagCreationAdmin = false;
      this.flagGestionRapportConfirm = false;
      this.flagGestionRapportNotConfirm = false;
      this.flagRechercheFilename = false;
      this.flagRechercheNumeroAffaire = false;
      this.flagReadAgenda = false;
      this.flagFixAffaireRapport = false;
      this.flagInitAffaireRapport = false;
      this.flagFixMissionInspecteur = false;
      this.flagOffreEmploi = true;
      this.flagActualites = false;
      this.flagListeOffreEmploi = false;

    },

    handelFixMissionInspecteur() {
      this.flagListeActualites = false;
      this.flagFixInterlocuteurClient = false;
      this.flagGestionRapport = false;
      this.flagGestionAdmin = false;
      this.flagCreationAdmin = false;
      this.flagGestionRapportConfirm = false;
      this.flagGestionRapportNotConfirm = false;
      this.flagRechercheFilename = false;
      this.flagRechercheNumeroAffaire = false;
      this.flagReadAgenda = false;
      this.flagFixAffaireRapport = false;
      this.flagInitAffaireRapport = false;
      this.flagFixMissionInspecteur = true;
      this.flagOffreEmploi = false;
      this.flagActualites = false;
      this.flagListeOffreEmploi = false;
    },

    handelFixAffaireRapport() {
      this.flagListeActualites = false;
      this.flagFixInterlocuteurClient = false;
      this.flagGestionRapport = false;
      this.flagGestionAdmin = false;
      this.flagCreationAdmin = false;
      this.flagGestionRapportConfirm = false;
      this.flagGestionRapportNotConfirm = false;
      this.flagRechercheFilename = false;
      this.flagRechercheNumeroAffaire = false;
      this.flagReadAgenda = false;
      this.flagFixAffaireRapport = true;
      this.flagInitAffaireRapport = false;
      this.flagFixMissionInspecteur = false;
      this.flagOffreEmploi = false;
      this.flagActualites = false;
      this.flagListeOffreEmploi = false;
    },

    handelInitAffaireRapport() {
      this.flagListeActualites = false;
      this.flagFixInterlocuteurClient = false;      
      this.flagGestionRapport = false;
      this.flagGestionAdmin = false;
      this.flagCreationAdmin = false;
      this.flagGestionRapportConfirm = false;
      this.flagGestionRapportNotConfirm = false;
      this.flagRechercheFilename = false;
      this.flagRechercheNumeroAffaire = false;
      this.flagReadAgenda = false;
      this.flagFixAffaireRapport = false;
      this.flagInitAffaireRapport = true;
      this.flagFixMissionInspecteur = false;
      this.flagOffreEmploi = false;
      this.flagActualites = false;
      this.flagListeOffreEmploi = false;

    },

    handelGestionRapports() {
      this.flagListeActualites = false;
      this.flagFixInterlocuteurClient = false;    
      this.flagGestionRapport = true;
      this.flagGestionAdmin = false;
      this.flagCreationAdmin = false;
      this.flagGestionRapportConfirm = false;
      this.flagGestionRapportNotConfirm = false;
      this.flagRechercheFilename = false;
      this.flagRechercheNumeroAffaire = false;
      this.flagReadAgenda = false;
      this.flagFixAffaireRapport = false;
      this.flagInitAffaireRapport = false;
      this.flagFixMissionInspecteur = false;
      this.flagOffreEmploi = false;
      this.flagActualites = false;
      this.flagListeOffreEmploi = false;

    },

    handelGestionAdmins() {
      this.flagListeActualites = false;
      this.flagFixInterlocuteurClient = false;   
      this.flagGestionRapport = false;
      this.flagGestionAdmin = true;
      this.flagCreationAdmin = false;
      this.flagGestionRapportConfirm = false;
      this.flagGestionRapportNotConfirm = false;
      this.flagRechercheFilename = false;   
      this.flagRechercheNumeroAffaire = false;   
      this.flagReadAgenda = false;   
      this.flagFixAffaireRapport = false;   
      this.flagInitAffaireRapport = false;
      this.flagFixMissionInspecteur = false;
      this.flagOffreEmploi = false;
      this.flagActualites = false;
      this.flagListeOffreEmploi = false;

    },

    handelCreationAdmin() {
      this.flagListeActualites = false;
      this.flagFixInterlocuteurClient = false;        
      this.flagGestionRapport = false;
      this.flagGestionAdmin = false;
      this.flagCreationAdmin = true; 
      this.flagGestionRapportConfirm = false;
      this.flagGestionRapportNotConfirm = false; 
      this.flagRechercheFilename = false;  
      this.flagRechercheNumeroAffaire = false;    
      this.flagReadAgenda = false;    
      this.flagFixAffaireRapport = false; 
      this.flagInitAffaireRapport = false;
      this.flagFixMissionInspecteur = false;
      this.flagOffreEmploi = false;
      this.flagActualites = false;
      this.flagListeOffreEmploi = false;

    },

    handelGestionRapportsNotConfirm() {
      this.flagListeActualites = false;
      this.flagFixInterlocuteurClient = false;        
      this.flagGestionRapport = false;
      this.flagGestionAdmin = false;
      this.flagCreationAdmin = false; 
      this.flagGestionRapportConfirm = false;
      this.flagGestionRapportNotConfirm = true; 
      this.flagRechercheFilename = false;  
      this.flagRechercheNumeroAffaire = false;  
      this.flagReadAgenda = false;  
      this.flagFixAffaireRapport = false; 
      this.flagInitAffaireRapport = false;
      this.flagFixMissionInspecteur = false;
      this.flagOffreEmploi = false;
      this.flagActualites = false;
      this.flagListeOffreEmploi = false;

    },

    handelGestionRapportsConfirm() {
      this.flagListeActualites = false;
      this.flagFixInterlocuteurClient = false;         
      this.flagGestionRapport = false;
      this.flagGestionAdmin = false;
      this.flagCreationAdmin = false; 
      this.flagGestionRapportConfirm = true;
      this.flagGestionRapportNotConfirm = false; 
      this.flagRechercheFilename = false;    
      this.flagRechercheNumeroAffaire = false;  
      this.flagReadAgenda = false;  
      this.flagFixAffaireRapport = false;  
      this.flagInitAffaireRapport = false;
      this.flagFixMissionInspecteur = false;
      this.flagOffreEmploi = false;
      this.flagActualites = false;
      this.flagListeOffreEmploi = false;


    },

    handelRechercheFilename() {
      this.flagListeActualites = false;
      this.flagFixInterlocuteurClient = false;         
      this.flagGestionRapport = false;
      this.flagGestionAdmin = false;
      this.flagCreationAdmin = false; 
      this.flagGestionRapportConfirm = false;
      this.flagGestionRapportNotConfirm = false; 
      this.flagRechercheFilename = true;  
      this.flagRechercheNumeroAffaire = false;    
      this.flagReadAgenda = false;    
      this.flagFixAffaireRapport = false;   
      this.flagInitAffaireRapport = false;
      this.flagFixMissionInspecteur = false;
      this.flagOffreEmploi = false;
      this.flagActualites = false;
      this.flagListeOffreEmploi = false;

    },

    handelRechercheNumeroAffaire() {
      this.flagListeActualites = false;
      this.flagFixInterlocuteurClient = false;         
      this.flagGestionRapport = false;
      this.flagGestionAdmin = false;
      this.flagCreationAdmin = false; 
      this.flagGestionRapportConfirm = false;
      this.flagGestionRapportNotConfirm = false; 
      this.flagRechercheFilename = false;     
      this.flagRechercheNumeroAffaire = true; 
      this.flagReadAgenda = false; 
      this.flagFixAffaireRapport = false; 
      this.flagInitAffaireRapport = false;
      this.flagFixMissionInspecteur = false;
      this.flagOffreEmploi = false;
      this.flagActualites = false;
      this.flagListeOffreEmploi = false;

    },

    handelReadAgenda() {
      this.flagListeActualites = false;
      this.flagFixInterlocuteurClient = false;         
      this.flagGestionRapport = false;
      this.flagGestionAdmin = false;
      this.flagCreationAdmin = false; 
      this.flagGestionRapportConfirm = false;
      this.flagGestionRapportNotConfirm = false; 
      this.flagRechercheFilename = false;     
      this.flagRechercheNumeroAffaire = false; 
      this.flagReadAgenda = true; 
      this.flagFixAffaireRapport = false; 
      this.flagInitAffaireRapport = false;
      this.flagFixMissionInspecteur = false;
      this.flagOffreEmploi = false;
      this.flagActualites = false;
      this.flagListeOffreEmploi = false;
    }


  },


created() {

  if(!sessionStorage.getItem("token")) {
        this.$router.push("/")
  }

}



};
</script>

<style scoped>
.admin .container .header {
    width: 100%;
    height: 80px;
    padding: 0px;
    margin: 0px;
}

.admin .container .menu-content{
    display: grid;
    grid-template-columns: 20% 80%;
}


.admin .container .menu-content menu {
    width: 20%;
}

.admin .container .menu-content .content {
    width: 100%;
}
</style>