<template>

    <div class="gestionInspecteur">

      <h3  v-if="flagEditInterlocuteur == false">FICHES DES INTERLOCUTEURS</h3>

      <p v-if="succes" :class="{ succes: succes }">
        {{ msg }}
      </p>

      <p v-if="echec" :class="{ echec: echec }">
        {{ msg }}
      </p>

      <div class="rechercher-table" v-if="flagEditInterlocuteur == false">

            <div class="rechercher">
                <input type="text" v-model="rechercher" placeholder="Recherche un Interlocuteur">
                <button class="export" @click="exportFicheListeInterlocuteur">Export</button>
            </div>

            <table id="inspecteurs">
              <tr>
                <th> </th>
                <th>Prénom</th>
                <th>Nom</th>
                <th>E-mail</th>
                <th>Fonction</th>
                <th>Téléphone</th>
                <th>Raison Sociale</th>
                <th>Actions</th>
              </tr>
              <tr v-for="(item, i) in filterInterlocuteurs" :key="item._id">
                <td><input type="checkbox" :value="item._id" v-model="checkedInterlocuteurs" style="width: 20px;"></td>
                <td>{{ item.prenom }}</td>
                <td>{{ item.nom }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.fonction }}</td>
                <td>{{ item.telephone }}</td>
                <td>
                     <p v-for="(client, index) in clients" :key="index">{{ item.clientId == client._id  ? client.raisonSocial : '' }}</p>
                </td>
                <td>
                  <a @click="deleteInterlocuteur(item._id)" title="Supprimé"><i class="fa-solid fa-trash"></i></a>
                  <a @click="editInterlocuteur(item)" title="Modifié"><i class="fa-solid fa-pen-to-square"></i></a>
                  <a v-show="item.status == false" title="Accepter-Access" @click="valideInterlocuteur(item._id, i)"><i class="fa-solid fa-unlock-keyhole"></i></a>
                  <a v-show="item.status == true" title="Refuser-Access" @click="invalideInterlocuteur(item._id, i)"><i class="fa-solid fa-unlock-keyhole"></i></a>
                  <a v-show="item.send == false" title="Envoyé-Access" @click="sendEmailInterlocuteur(item._id , i)"><i class="fa-solid fa-envelope"></i></a>
                  <a v-show="item.send == true" title="Renvoyé-Access" @click="sendEmailInterlocuteur(item._id , i)"><i class="fa-solid fa-rotate-left"></i></a>
                  <!-- <a v-if="item.statisfaction == true" :title="new Date(item.dateSend).toLocaleDateString()" @click="envoyerStatisfactionClient(item._id, i)"><i class="fa-solid fa-magnifying-glass"></i></a> -->
                  <a title="Envoyé l'enquete" @click="envoyerStatisfactionClient(item._id)"><i class="fa-solid fa-magnifying-glass"></i></a>
                  <a v-if ="item.satisfaction == true" title="Apércu l'enquete" @click="generateStatisfactionClient(item._id)"><i class="fa-solid fa-book"></i></a>
                </td>
              </tr>
            </table>

            <div class="deleteAll" v-show="checkedInterlocuteurs.length > 1">
              <input type="submit" value="Supprimer tout" @click="deleteInterlocuteurs">
            </div>

      </div>

      <ul class="pagination" v-if="flagEditInterlocuteur == false">
            <button @click="selectPageLeft()"><i class="fa-solid fa-backward"></i></button>
              <li v-for="n in numberPage" :key="n" @click="selectPage(n)">
                <p v-show="numberPageSelect[n-1] == false">{{ n }}</p>
                <p  v-show="numberPageSelect[n-1] == true" style="background-color: #0300db; color: white;">{{ n }}</p>
              </li>
            <button @click="selectPageRight()"><i class="fa-solid fa-forward"></i></button>
      </ul>
      


    <!--  Start Edit Inspecteur   -->
    <EditInterlocuteur :infoInterlocuteur="infoInterlocuteur" :clientId_raisonSocial="clientId_raisonSocial" v-if="flagEditInterlocuteur == true" />
    <!--  End Edit Inspecteur   -->

    <!--  Start Info Delete Model   -->
      <DeleteModel v-if="flagModelDelete == true" :text="textDeleteModel" @closeDeleteModel="closeDeleteModel()" @validerDeleteModel="validerDeleteModel()" />
    <!--  End Info Delete Model   -->

    <!--  Start Info Delete Model Email   -->
      <SendEmail v-if="flagModelSendEmail == true" :text="textSendEmail" @closeDeleteModelEmail="closeDeleteModelEmail()" @validerDeleteModelEmail="validerDeleteModelEmail()" />
    <!--  End Info Delete Model Email   -->

    <!--  Start Info Delete Model Email   -->
      <ModalLanding v-if="flagModalLanding" />
    <!--  End Info Delete Model Email   -->

  </div>

</template>

<script>
import Service from "../../../../Service";
import EditInterlocuteur from "./EditInterlocuteur.vue";
import DeleteModel from "../../../../views/components/DeleteModel.vue";
import SendEmail from "../../../../views/components/SendEmail.vue";
import ModalLanding from "../../../../views/components/ModalLanding.vue";

export default {
  name: "gestionInterlocuteur",
  components: {
    EditInterlocuteur,
    DeleteModel,
    SendEmail,
    ModalLanding
  },
  data() {
    return {
      flagModalLanding : false,  
      numberPageSelect: [],
      flagModelSendEmail : false,
      textSendEmail : null,
      interlocuteurIdEmail: null,
      indexEmail: null,
      flagModelDelete : false,
      textDeleteModel : null,
      savedIndexDeleteModel : null,
      pageIndex : 0,
      itemsPerPage : 16,
      numberPage: null,
      succes: false,
      echec: false,
      msg: null,
      rechercher: null,
      infoInterlocuteur: [],
      preInterlocuteurs : [],
      interlocuteurs: [],
      flagEditInterlocuteur: false,
      checkedInterlocuteurs : [],
      clientId_raisonSocial : [],
      clients : [],
      clientId : null
    };
  },

  computed : {
            filterInterlocuteurs() {

            if(!this.rechercher){
                        return this.interlocuteurs.filter((item) => {
                          return item;
                        })

            } else {

                      return this.preInterlocuteurs.filter((item) => {
                          if(item != undefined) {

                            const nom = String(this.rechercher).toLocaleLowerCase();
                            const prenom = String(this.rechercher).toLocaleLowerCase();
                            const telephone = String(this.rechercher).toLocaleLowerCase();
                            const email = String(this.email).toLocaleLowerCase();

                            return !item.nom.toLocaleLowerCase().toString().indexOf(nom) ||
                                  !item.prenom.toLocaleLowerCase().toString().indexOf(prenom) ||
                                  !item.email.toLocaleLowerCase().toString().indexOf(email) ||
                                  !item.telephone.toLocaleLowerCase().toString().indexOf(telephone);
                            }
                      });
            }
            
      }
  },
  methods: {

    exportFicheListeInterlocuteur() {
        Service.exportFicheListeInterlocuteur()
        .then((result) => {
              console.log(result);
        })
        .catch((error) => {
              console.log(error);
        });
    },

    selectPageLeft() {

        for(let i = 0; i < this.numberPage; i++) {
              this.numberPageSelect[i] = false;
        }

        this.numberPageSelect[0] = true;

        this.interlocuteurs = [];
        for(let i = 0 * this.itemsPerPage; i < (0 * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.interlocuteurs.push(this.preInterlocuteurs[i]);
        }
        
    },

    selectPageRight() {

        for(let i = 0; i < this.numberPage; i++) {
              this.numberPageSelect[i] = false;
        }

        const indice = this.numberPage - 1;
        this.numberPageSelect[indice] = true;
        this.interlocuteurs = [];
        for(let i = indice * this.itemsPerPage; i < (indice * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.interlocuteurs.push(this.preInterlocuteurs[i]);
        }
    },

    selectPage(value) {
        const select = value - 1;
        // change current select for change color
        for(let i = 0; i < this.numberPage; i++) {
              this.numberPageSelect[i] = false;
        }

        this.numberPageSelect[select] = true;
        this.interlocuteurs = [];
        for(let i = select * this.itemsPerPage; i < (select * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.interlocuteurs.push(this.preInterlocuteurs[i]);
        }
    },

    sendEmailInterlocuteur(interlocuteurId, index) {
      this.flagModelSendEmail = true;
      this.textSendEmail = "Voulez-vous vraiment Envoyer mot de passe par Mail à l'interlocuteur ?";
      this.interlocuteurIdEmail = interlocuteurId,
      this.indexEmail = index
    },


    envoyerStatisfactionClient(interlocuteurId) {
      this.flagModalLanding = true;
      Service.envoyerStatisfactionClient(interlocuteurId)
        .then((response) => {
          console.log(response)
          this.flagModalLanding = false;
        })
        .catch((error) => {
            console.log(error.message);
            this.flagModalLanding = false;
        });
    },

      // download file extention DOCX
    downloadFile(response) {
            var newBlob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement("a");
            link.href = data;
            link.download = "resume.docx";
            link.click();
            setTimeout(function() {
                window.URL.revokeObjectURL(data);
            }, 100);
    },

    generateStatisfactionClient(InterlocuteurId) {
      Service.generateStatisfactionClient(InterlocuteurId)
        .then((response) => {
          this.downloadFile(response);
        })
        .catch((error) => {
            console.log(error.message);
        });
    },

    checkStatisfactionClient(email) {
      Service.checkStatisfactionClient(email)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
            console.log(error.message);
        });
    },




    closeDeleteModelEmail() {
      this.flagModelSendEmail = false;
    },

    validerDeleteModelEmail() {

          this.flagModelSendEmail = false;

          Service.SendEmailInterlocuteur(this.interlocuteurIdEmail)
          .then(() => {
              this.interlocuteurs[this.indexEmail].send = true;
          })
          .catch((error) => {
                console.log(error);
          });

    },


    valideInterlocuteur(interlocuteurId, index) {
        Service.ValideInterlocuteur(interlocuteurId)
        .then((result) => {
            if(result) {
              this.interlocuteurs[index].status = true;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    },


    closeDeleteModel() {
      this.flagModelDelete = false;
    },


    validerDeleteModel() {

        this.flagModelDelete = false;

        if(this.checkedInterlocuteurs.length > 0) {

      
          // Delete in Fron-end
          for(let i = 0; i < this.checkedInterlocuteurs.length; i++ ) {
              const searchIndex = this.interlocuteurs.findIndex((interlocuteur) => interlocuteur._id == this.checkedInterlocuteurs[i]);
              this.interlocuteurs.splice(searchIndex, 1);
              this.filterInterlocuteurs.splice(searchIndex, 1);
          }


          //  delete in db backend
          Service.deleteInterlocuteur(this.checkedInterlocuteurs)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            this.msg = error.message;
            console.error(`HTTP error: ${error.name} => ${error.message}`);
            throw "fail request at: GET /refreshtime";
          });


        }

        if(this.savedInterlocuteurIdDeleteModel != null && this.checkedInterlocuteurs.length == 0) {

            // search index principale
            const searchIndexInterlocuteur = this.interlocuteurs.findIndex((interlocuteur) => interlocuteur._id == this.savedInterlocuteurIdDeleteModel);
            // pointer clientId
            const idInterlocuteur = this.savedInterlocuteurIdDeleteModel;
            // delete value and index cote Client
            this.interlocuteurs.splice(searchIndexInterlocuteur, 1);

            // search index Temporaire
            const searchIndexPreInterlocuteur = this.preInterlocuteurs.findIndex((interlocuteur) => interlocuteur._id == this.savedInterlocuteurIdDeleteModel);
            // delete value and index cote Client
            this.preInterlocuteurs.splice(searchIndexPreInterlocuteur, 1);

            Service.deleteInterlocuteur(idInterlocuteur)
            .then((result) => {
              this.msg = result.data.msg;
            })
            .catch((error) => {
                this.msg = error.message;
                console.error(`HTTP error: ${error.name} => ${error.message}`);
                throw "fail request at: GET /refreshtime";
            });
        }


        this.savedInterlocuteurIdDeleteModel = null;
        this.checkedInterlocuteurs = [];
    },

    // delete more one Interlocuteur (interlocuteurs)
    deleteInterlocuteurs() {
      this.flagModelDelete = true;
      this.textDeleteModel = "Êtes-vous sûr de supprimer tout ?";
    },

    // delete one client
    deleteInterlocuteur(interlocuteurId) {
      this.flagModelDelete = true;
      this.textDeleteModel = "Êtes-vous sûr de supprimer ?"
      this.savedInterlocuteurIdDeleteModel = interlocuteurId;
    },

    // edit one client
    editInterlocuteur(interlocuteur) {
      // get raison sosial by interlocuteur
      Service.selectClient(interlocuteur.clientId)
        .then(async (result) => {
          console.log(result)
          this.clientId_raisonSocial = [result.data.client._id, result.data.client.raisonSocial];
          this.infoInterlocuteur.push(interlocuteur);
          this.flagEditInterlocuteur = true;
        })
        .catch((error) => {
            this.msg = error.message;
            console.error(`HTTP error: ${error.name} => ${error.message}`);
            throw "fail request at: GET /refreshtime";
        });

    },

      // edit one client
      invalideInterlocuteur(interlocuteurId, index) {

        Service.InvalideInterlocuteur(interlocuteurId)
          .then((result) => {
              if(result) {
                this.interlocuteurs[index].status = false;
              }
          })
          .catch((error) => {
              console.log(error);
          });
      }

  },

  created() {
      
      Service.readInterlocuteur()
      .then((result) => {

        const listeInterlocuteurs = result.data.interlocuteurs.sort((a, b) => a.prenom.localeCompare(b.prenom))

        listeInterlocuteurs.forEach((element) => {
          this.preInterlocuteurs.push(element);
        });

        this.numberPage = Math.ceil(result.data.interlocuteurs.length / this.itemsPerPage);

        for(let i = 0; i < this.numberPage; i++) {
            this.numberPageSelect.push(false);
        }

        this.numberPageSelect[0] = true;

        for(let i = this.pageIndex * this.itemsPerPage; i < (this.pageIndex * this.itemsPerPage) + this.itemsPerPage; i++) {
             this.interlocuteurs.push(this.preInterlocuteurs[i]);
        }

      })
      .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
      });

      // Read all clients
      Service.readClient()
      .then((result) => {
        this.clients = result.data.clients;
      })
      .catch((error) => {
              this.msg = error.message;
              console.error(`HTTP error: ${error.name} => ${error.message}`);
              throw "fail request at: GET /refreshtime";
      });

  }
};
</script>

<style scoped>
.succes {
  background-color: green;
  color: white;
  padding: 10px;
  height: fit-content;
  width: 100%;
}

.echec {
  background-color: red;
  color: white;
  padding: 10px;
  height: fit-content;
  width: 100%;
}

.gestionInspecteur {
  width: 100%;
  height: 100%;
  padding:2px;
  margin: 0px;
  position: relative;
}
.gestionInspecteur h3 {
  width: 100%;
  height: fit-content;
  padding: 5px;
  margin: 0;
  color: white;
  background-color: #243064;
  text-align: center;
  margin-bottom: 10px;
}

pecteur .rechercher-table {
  width: 100%;
}
.gestionInspecteur .rechercher-table .rechercher {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;

}

.gestionInspecteur .rechercher-table .rechercher input {
  width: 50%;
  height: 40px;
  outline: 0;
  border: 1px solid #243064;
  font-size: 16px;
  background-color: white;
  color :#243064;
  padding-left : 5px; 
}

.gestionInspecteur .rechercher-table .rechercher .export {
  background-color: #116d09;
  color: white;
  margin-left: 10px;
  width: 100px;
  border-radius: 20px;
  cursor: pointer;
  border: 0px;
}


.gestionInspecteur .rechercher-table table  {
  width: 100%;
}
.gestionInspecteur .rechercher-table table tr  {
  width: 100%;
}
.gestionInspecteur .rechercher-table table tr th {
    background-color: #243064;
    color: white;
    padding: 10px 2px;
    border-right: 1px solid white;
}
table{
  border-collapse: collapse;
}
.gestionInspecteur .rechercher-table table tr td {
    color: black;
    padding: 5px;
}


.gestionInspecteur .rechercher-table table tr:nth-child(even) {
  background-color: #f5f5f5;
}
.gestionInspecteur .rechercher-table table tr td a {
  cursor: pointer;
  margin-right: 5px;
}
.fa-trash-can {
  color: red;
}

.fa-pen-to-square {
  color: blue;
}

.fa-circle-check {
  color: green;
}
.fa-download {
  color: black;
}


.gestionInspecteur .rechercher-table ul {
  width: 100%;
  height: fit-content;
  text-align: center;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gestionInspecteur .rechercher-table ul li {
  color:black;
 margin-left: 5px;
 cursor: pointer;
 font-size: 18px;
}
.gestionInspecteur .rechercher-table ul li:hover {
  color:red;
 margin-left: 5px;
 transition: 0.3s;
}


.gestionInspecteur .rechercher-table .deleteAll {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.gestionInspecteur .rechercher-table .deleteAll input {
  width: fit-content;
  padding: 10px;
  height: 40px;
  background-color: red;
  color: white;
  border: 0;
  cursor: pointer;
}


#inspecteurs > tr > td:nth-child(10) > a:nth-child(1) > svg  {
  color: red;
  font-size: 20px;
}

#inspecteurs > tr > td:nth-child(10) > a:nth-child(2) > svg  {
  color: blue;
  font-size: 20px;
}

#inspecteurs > tr > td:nth-child(10) > a:nth-child(3) > svg  {
  color: green;
  font-size: 20px;
}

#app > div > div > div.menu-content > div.content > div > h3 {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin:0;

    color: white;
    background: linear-gradient(346deg, rgba(207,31,33,1) 0%, rgba(24,86,161,1) 100%);    text-align: center;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 25px;
}

#app > div > div > div.menu-content > div.content {
  background-color: white;
}

#inspecteurs > tr > td {
    color: #000000;
    border-bottom: 1px solid #888888;
    text-transform: capitalize;
}

#app > div > div > div.menu-content > div.content > div {
  background-color: white;
}


#inspecteurs > tr > td:nth-child(8) > a:nth-child(1) > svg {
  color: red;
}

#inspecteurs > tr > td:nth-child(8) > a:nth-child(2) > svg {
  color: rgb(0, 0, 0);
}

#inspecteurs > tr > td:nth-child(8) > a:nth-child(3) > svg {
  color: green;
}

#inspecteurs > tr > td:nth-child(8) > a:nth-child(4) > svg {
  color: orange;
}

#inspecteurs > tr > td:nth-child(8) > a:nth-child(5) > svg {
  color: rgb(240, 4, 209);
}

#inspecteurs > tr > td:nth-child(8) > a:nth-child(6) > svg {
  color: rgb(81, 0, 255);
}


.pagination{
  display: flex;
  align-items: center;
  justify-content: center;
  margin:10px auto;
  width: fit-content;
  flex-wrap: wrap;
  padding:5px 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.pagination p{
  border:1px solid rgb(151, 151, 151);
  margin: 0 4px;
  padding:5px 10px;
  border-radius: 20px;
  cursor: pointer;
}

.pagination p:hover , button:hover{
background-color: #243064;
color:white;
}


.pagination button {
  border:0;
  padding:5px;
  border:1px solid rgb(170, 170, 170);
  margin-left: 15px;
  color:gray;
  cursor: pointer;
}

.pagination button:nth-child(1) {
    margin-right: 15px;
    margin-left: 0;
}





</style>