<template>
  <div class="etat-missions-affaires-chart">
    <h4>État Production Inspecteurs - Affaires</h4>
    <canvas id="myEtatMissionsAffaires"></canvas>
  </div>
</template>

<script>
import Service from "../../../../../Service";
import Chart from "chart.js";
export default {

  name: "affaires-missions-chart",

  data() {
    return {
      annee : null,
    };
  },

  props: {
    selectYear : Number,
    selectMois : Number,
  },

  methods: {},

  created() {
  },

  mounted() {

      // get info Indicateur by current
      Service.IndicteurProductionEtatMissionsAffaires(this.selectYear, this.selectMois)
      .then((result) => {

                // inctance arry
                const listAffaires = [];
                const perventDatasets = [];
                const fakeListeIstInspecteur = [];
                const interventionTable = [];
                const values = [];

                // instance count intervention for get big intervention
                result.data.forEach((element) => {
                    interventionTable.push(element.values.length);
                });

                var maxIntervention = Math.max(...interventionTable);

                result.data.forEach((element) => {
                    for(let i = 0; i < maxIntervention; i++) {
                      if(element.values[i] == undefined) {
                          element.values[i] = 0;
                      }
                    }
                });


                for(let i = 0; i < maxIntervention; i++) {
                    for(let j = 0; j < result.data.length; j++) {
                          const val = result.data[j].values[i];
                           values.push(val)
                    }
                }


                // sommet all inspecteurs
                result.data.forEach(element => {
                  listAffaires.push(element.numeroAffaire);
                });

                // sommet all inspecteurs
                result.data.forEach(element => {
                  element.inspecteurs.forEach((el) => {
                    fakeListeIstInspecteur.push(el);
                  });
                });

                // delete Duplcate all inspecteurs
                let uniqInspecteurs = [...new Set(fakeListeIstInspecteur)];

                    let count = 0;
                    for(let i = 0; i < uniqInspecteurs.length; i++) {
                      const res = values.slice(count, result.data.length);
                      values.splice(count, result.data.length);

                      const randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1));
                      const a = randomBetween(0, 255);
                      const b = randomBetween(0, 255);
                      const c = randomBetween(0, 255);
                      perventDatasets.push({
                          data: res,
                          label: uniqInspecteurs[i],
                          backgroundColor: `rgb(${a},${b},${c})`,
                      });
                    }

                const ctx = document.getElementById("myEtatMissionsAffaires");
                var myEtatMissionsAffaires = new Chart(ctx, {
                  type: 'bar',
                  data: {
                    labels: listAffaires,
                    datasets: perventDatasets
                  },
                  options: {
                    scales: {
                      xAxes: [{ 
                        stacked: true,   
                        barThickness: 50,  // number (pixels) or 'flex'
                        maxBarThickness: 50 // number (pixels)
                      }],
                      yAxes: [{
                        stacked: true
                      }],
                    }
                  },
                });

                return myEtatMissionsAffaires;
      })
      .catch((error) => {
          console.log(error.message)
      });

  }


};
</script>

<style scoped>

.etat-missions-affaires-chart > h4 {
  width: 100%;
    justify-content: center;
    display: flex;
    color: #1e1ed3;
    font-size: x-large;
    opacity: 0.6;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
}

</style>