<template>
  <div class="etat-chart">
    <canvas id="myEtat"></canvas>
  </div>
</template>

<script>
import Service from "../../../../../../Service";
import Chart from "chart.js";
export default {

  name: "etat-chart",

  data() {
    return {
      annee : null,
    };
  },

  props: {
    selectYear : Number
  },

  methods: {},

  created() {
  },

  mounted() {

      // get info Indicateur by current
      Service.IndicteurProduction(this.selectYear)
      .then((result) => {
          
          const ctx = document.getElementById("myEtat");
          const myEtat = new Chart(ctx, {
            type: "bar",
            data: {
              labels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
              datasets: [
                  {
                  label: 'En attente',
                  data: [
                      result.data.bcJanvier,
                      result.data.bcFevrier,
                      result.data.bcMars,
                      result.data.bcAvril,
                      result.data.bcMai,
                      result.data.bcJuin,
                      result.data.bcJuillet,
                      result.data.bcAout,
                      result.data.bcSeptembre,
                      result.data.bcOctober,
                      result.data.bcNovembre,
                      result.data.bcDecembre
                  ],
                  backgroundColor: [
                    'rgba(255, 0, 0, 1)',
                    'rgba(255, 0, 0, 1)',
                    'rgba(255, 0, 0, 1)',
                    'rgba(255, 0, 0, 1)',
                    'rgba(255, 0, 0, 1)',
                    'rgba(255, 0, 0, 1)',
                    'rgba(255, 0, 0, 1)',
                    'rgba(255, 0, 0, 1)',
                    'rgba(255, 0, 0, 1)',
                    'rgba(255, 0, 0, 1)',
                    'rgba(255, 0, 0, 1)',
                    'rgba(255, 0, 0, 1)'
                  ],
                  borderWidth: 1,
                  },
                  {
                  label: 'Production',
                  data: [
                      result.data.interventionJanvier,
                      result.data.interventionFevrier,
                      result.data.interventionMars,
                      result.data.interventionAvril,
                      result.data.interventionMai,
                      result.data.interventionJuin,
                      result.data.interventionJuillet,
                      result.data.interventionAout,
                      result.data.interventionSeptembre,
                      result.data.interventionOctober,
                      result.data.interventionNovembre,
                      result.data.interventionDecembre,
                  ],
                  backgroundColor: [
                    'rgba(255, 116, 0, 1)',
                    'rgba(255, 116, 0, 1)',
                    'rgba(255, 116, 0, 1)',
                    'rgba(255, 116, 0, 1)',
                    'rgba(255, 116, 0, 1)',
                    'rgba(255, 116, 0, 1)',
                    'rgba(255, 116, 0, 1)',
                    'rgba(255, 116, 0, 1)',
                    'rgba(255, 116, 0, 1)',
                    'rgba(255, 116, 0, 1)',
                    'rgba(255, 116, 0, 1)',
                    'rgba(255, 116, 0, 1)'
                  ],
                  borderWidth: 1,
                  },
                  {
                  label: 'Réalisé',
                  data: [
                      result.data.realiseJanvier,
                      result.data.realiseFevrier,
                      result.data.realiseMars,
                      result.data.realiseAvril,
                      result.data.realiseMai,
                      result.data.realiseJuin,
                      result.data.realiseJuillet,
                      result.data.realiseAout,
                      result.data.realiseSeptembre,
                      result.data.realiseOctober,
                      result.data.realiseNovembre,
                      result.data.realiseDecembre
                  ],
                  backgroundColor: [
                    'rgba(30, 180, 0, 1)',
                    'rgba(30, 180, 0, 1)',
                    'rgba(30, 180, 0, 1)',
                    'rgba(30, 180, 0, 1)',
                    'rgba(30, 180, 0, 1)',
                    'rgba(30, 180, 0, 1)',
                    'rgba(30, 180, 0, 1)',
                    'rgba(30, 180, 0, 1)',
                    'rgba(30, 180, 0, 1)',
                    'rgba(30, 180, 0, 1)',
                    'rgba(30, 180, 0, 1)',
                    'rgba(30, 180, 0, 1)'
                  ],
                  borderWidth: 1,
                  }
                ]
            }
          });

          return myEtat;
      })
      .catch((error) => {
          console.log(error.message)
      });



  }


};
</script>

<style>
</style>