<template>
  <div class="mois-chart">
    <canvas id="myMois"></canvas>
  </div>
</template>

<script>
import Service from "../../../../../../Service";
import Chart from "chart.js";
export default {
  name: "mois-chart",

  data() {
    return {
      annee: null,
    };
  },

  props: {
    selectYear : Number
  },

  methods: {},

  created() {},

  mounted() {
    // get info Indicateur by current
    Service.countAffairesMois(this.selectYear)
      .then((result) => {
        const ctx = document.getElementById("myMois");
        const myMois = new Chart(ctx, {
          type: 'line',
          data: {
            labels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
            datasets: [
                {
                label: "Nombre d'affaire",
                data: result.data.response,
                fill: false,
                borderColor: "rgb(75, 192, 192)",
                tension: 0.1,
                },
            ]
          }
        });

        return myMois;
      })
      .catch((error) => {
        console.log(error.message);
      });
  },
};
</script>

<style>
</style>