<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

export default {
  name:"app",
  data() {
    return {

    }
  },
  created() {
  }

}

</script>

<style lang="scss">

.app {
  width: 100%;
  height: 100%;
}

</style>
