require('dotenv').config();
import axios from 'axios'
const { VUE_APP_API_BASE_URL_DEV } = require('./config');
const FormData = require('form-data');
//TelechargerSituationFrais
// getAffaire
class Service {

    //---------------------------------------MAC ADDRESSE --------------------------------------------
    //Check Mac Address
    static CheckMacAddress(macAddresse) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/mac/read`,
                {
                    macAddresse: macAddresse
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }
    // -----------------------------------------------------------------------------------------------



    //--------------------------------------- ADMIN --------------------------------------------------
    
    // Auth 
    static auth() {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/admins/auth`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    // Login Admin
    static loginAdmin(email, password) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/admins/login`,

                {
                    email: email,
                    password: password
                },

                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // Read Admin 

    static readAdmin() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/admins/read`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }

    // read Admin Not SousTritance
    static readAdminNotSousTritance() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/admins/readAdminNotSousTritance`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }

    // SelectMoisCalendrierInspecteur
    static getAdmin(adminId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/admins/get`,
                {
                    adminId: adminId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }


    static createAdmin(admin) {

        const {
            email,
            password,
            nom,
            prenom,
            dateEmbauche,
            statusCompte,
            rendement,
            status,
            dateEmbauchePrecedent,
            matricule,
            apporteur
        } = admin;

        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/admins/create`,
                {
                    email: email,
                    password: password,
                    nom: nom,
                    prenom: prenom,
                    dateEmbauche: dateEmbauche,
                    statusCompte: statusCompte,
                    rendement: rendement,
                    status: status,
                    dateEmbauchePrecedent: dateEmbauchePrecedent,
                    matricule: matricule,
                    apporteur: apporteur
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    static updateAdmin(admin, adminId) {

        const {
            email,
            password,
            nom,
            prenom,
            dateEmbauche,
            statusCompte,
            rendement,
            status,
            dateEmbauchePrecedent,
            matricule,
            apporteur
        } = admin;

        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/admins/update`,
                {
                    adminId: adminId,
                    email: email,
                    password: password,
                    nom: nom,
                    prenom: prenom,
                    dateEmbauche: dateEmbauche,
                    statusCompte: statusCompte,
                    rendement: rendement,
                    status: status,
                    dateEmbauchePrecedent: dateEmbauchePrecedent,
                    matricule: matricule,
                    apporteur: apporteur
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // Delete Admin
    static deleteAdmin(adminId) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/admins/delete`,
                {
                    adminId: adminId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    //----------------------------------- End ADMIN ---------------------------------------------------

    //------------------------------------ CLIENT -----------------------------------------------------
    // export fiche liste client
    static exportFicheListeClient() {

        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/clients/export`, {
                headers: this.headers,
                responseType: 'blob',
            })
                .then(response => {
                    resolve(window.open(URL.createObjectURL(response.data)));
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });

    }


    static createClient(client) {

        const {
            adresse,
            codePostal,
            email,
            ice,
            pays,
            raisonSocial,
            refClient,
            telephone,
            ville,
            dateEntree
        } = client;


        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/clients/create`,

                {
                    raisonSocial: raisonSocial,
                    adresse: adresse,
                    ville: ville,
                    codePostal: codePostal,
                    pays: pays,
                    email: email,
                    ice: ice,
                    refClient: refClient,
                    telephone: telephone,
                    dateEntree: dateEntree
                },

                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    // update Client

    static updateClient(client) {

        const {
            raisonSocial,
            adresse,
            ville,
            codePostal,
            pays,
            email,
            ice,
            id,
            refClient,
            telephone,
            dateEntree
        } = client;

        return new Promise((resolve, reject) => {
            axios.put(`${VUE_APP_API_BASE_URL_DEV}/clients/update/${id}`,

                {
                    raisonSocial: raisonSocial,
                    adresse: adresse,
                    ville: ville,
                    codePostal: codePostal,
                    pays: pays,
                    email: email,
                    ice: ice,
                    refClient: refClient,
                    telephone: telephone,
                    dateEntree: dateEntree
                },

                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    //Get Reference Client 
    static getRefClient() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/clients/reference`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // read all clients 
    static readClient() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/clients/read`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // select client 
    static selectClient(clientId) {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/clients/select/${clientId}`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }


    // select client with Interlocuteur
    static selectClientInterlocuteur(clientId) {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/clients/select/interlocuteurs/${clientId}`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }


    // delete Client or Clients
    static deleteClient(idClients) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/clients/delete`,
                {
                    idClients: idClients
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => {
                    reject(response)
                })

        })
    }
    //----------------------------------------- END CLIENT -----------------------------------------------   


    // _________________________________________ INTERLOCUTEUR _________________________________________________       
    // create Interlocuteur

    static UpdateInterlocuteurAffaire(numeroAffaire, interlocuteurId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/interlocuteurs/updateInterlocuteurAffaire`,
                {
                    numeroAffaire: numeroAffaire,
                    interlocuteurId: interlocuteurId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }


    static createInterlocuteur(interlocuteur) {

        const {
            nom,
            prenom,
            email,
            codePostal,
            fonction,
            telephone,
            clientId,
            raisonSocial,
            password
        } = interlocuteur;

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/interlocuteurs/create`,

                {
                    nom: nom,
                    prenom: prenom,
                    email: email,
                    codePostal: codePostal,
                    fonction: fonction,
                    telephone: telephone,
                    clientId: clientId,
                    raisonSocial: raisonSocial,
                    password: password
                },

                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // read all interlocuteurs 
    static readInterlocuteur() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/interlocuteurs/read`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // select interlocuteur  by client ID
    static selectInterlocuteurs(clientId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/interlocuteurs/select`,
                {
                    clientId: clientId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // get Interlocuteur
    static getInterlocuteur(interlocuteurId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/interlocuteurs/get`,
                {
                    interlocuteurId: interlocuteurId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }


    // update Client
    static updateInterlocuteur(interlocuteur) {

        const {
            clientId,
            email,
            fonction,
            interlocuteurId,
            nom,
            password,
            prenom,
            raisonSocial,
            telephone
        } = interlocuteur;


        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/interlocuteurs/update`,

                {
                    clientId: clientId,
                    email: email,
                    fonction: fonction,
                    interlocuteurId: interlocuteurId,
                    nom: nom,
                    password: password,
                    prenom: prenom,
                    raisonSocial: raisonSocial,
                    telephone: telephone
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }



    // delete Interlocuteur   
    static deleteInterlocuteur(idInterlocuteur) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/interlocuteurs/delete`,
                {
                    idInterlocuteur: idInterlocuteur
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => {
                    reject(response)
                })

        })
    }


    // Send Email Interlocuteur Interlocuteur   
    static ValideInterlocuteur(idInterlocuteur) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/interlocuteurs/valideInterlocuteur`,
                {
                    idInterlocuteur: idInterlocuteur
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => {
                    reject(response)
                })

        })
    }

    // Send Email Interlocuteur    
    static InvalideInterlocuteur(idInterlocuteur) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/interlocuteurs/invalideInterlocuteur`,
                {
                    idInterlocuteur: idInterlocuteur
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => {
                    reject(response)
                })

        })
    }


    // Send Email Interlocuteur Interlocuteur   
    static SendEmailInterlocuteur(idInterlocuteur) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/interlocuteurs/sendEmailInterlocuteur`,
                {
                    idInterlocuteur: idInterlocuteur
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => {
                    reject(response)
                })

        })
    }


    static exportFicheListeInterlocuteur() {

        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/interlocuteurs/export`, {
                headers: this.headers,
                responseType: 'blob',
            })
                .then(response => {
                    resolve(window.open(URL.createObjectURL(response.data)));
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });

    }
    // ----------------------------------------- Fin Interlocuteur ----------------------------------------



    // ------------------------------------------ missions ------------------------------------------------       

    // create Mission 
    static AnnulerAffaireAfterCreateMission(numeroAffaire) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/missions/annulerAffaireAfterCreateMission`,
                {
                    numeroAffaire: numeroAffaire,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // Nettoyage Mission 
    static NettoyageMissions() {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/missions/nettoyageMissions`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    // create Mission 
    static createMission(mission) {

        const {
            typeMission,
            codeMission,
            equipement,
            qte,
            quantiteEquipement,
            uniteEquipement,
            prixEquipement,
            tauxRemiseEquipement,
            prixFinaleEquipement,
            prix,
            devis,
            interlocuteurId,
            clientId,
            numeroAffaire,
            status,
            statusWithAffaire,
            typeRapport,
            categorie,
            remise,
            unite,
            quantite,
            nameMissionSpecifique,
            statusEquipement
        } = mission[0];


        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/missions/create`,

                {
                    typeMission: typeMission,
                    codeMission: codeMission,
                    equipement: equipement,
                    qte: qte,
                    quantiteEquipement: quantiteEquipement,
                    uniteEquipement: uniteEquipement,
                    prixEquipement: prixEquipement,
                    tauxRemiseEquipement: tauxRemiseEquipement,
                    prixFinaleEquipement: prixFinaleEquipement,
                    prix: prix,
                    devis: devis,
                    interlocuteurId: interlocuteurId,
                    clientId: clientId,
                    numeroAffaire: numeroAffaire,
                    status: status,
                    statusWithAffaire: statusWithAffaire,
                    typeRapport: typeRapport,
                    categorie: categorie,
                    remise: remise,
                    unite: unite,
                    quantite: quantite,
                    nameMissionSpecifique: nameMissionSpecifique,
                    statusEquipement: statusEquipement
                },

                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // delete mission
    static deleteMission(missionId, countRapport) {
        return new Promise((resolve, reject) => {
            axios.delete(`${VUE_APP_API_BASE_URL_DEV}/missions/delete/${missionId}/${countRapport}`,

                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => {
                    reject(response)
                })

        })
    }


    // delete mission
    static deleteMissionsNotComplete(numeroAffaire) {
        return new Promise((resolve, reject) => {
            axios.delete(`${VUE_APP_API_BASE_URL_DEV}/missions/deleteaffairenotcomplete/${numeroAffaire}`,

                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => {
                    reject(response)
                })

        })
    }

    static getMission(numeroAffaire) {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/missions/getMission/${numeroAffaire}`,

                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => {
                    reject(response)
                })

        })
    }

    static readAllMissions() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/missions/read`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => {
                    reject(response)
                })

        })
    }

    // update Affaire
    static DeleteMission(missionId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/missions/deleteMission`,
                {
                    missionId: missionId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }
    //------------------------------   Mission ------------------------------------------------     



    //--------------------------------------------AFFAIRE--------------------------------------//

    // generate liste offre
    static deleteFacture(affaireId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/factures/deleteFacture`,
                {
                    affaireId: affaireId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // generate liste offre
    static generateListeOffre(filter) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/generateListeOffre`,
                {
                    filter: filter
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // download Liste Offre
    static downloadListeOffre() {
        return new Promise((resolve, reject) => {

            axios.get(`${VUE_APP_API_BASE_URL_DEV}/affaires/downloadListeOffre`,
                {
                    responseType: "arraybuffer"
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // generate offre de prix
    static changeStatusStatisfactionAffaires() {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/changeStatusStatisfactionAffaires`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // generate offre de prix
    static generateOffrePrix(numeroAffaire) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/generateOffrePrix`,
                {
                    numeroAffaire: numeroAffaire
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // download offre de commerciale
    static downloadOffrePrix(numeroAffaire) {
        return new Promise((resolve, reject) => {

            axios.get(`${VUE_APP_API_BASE_URL_DEV}/affaires/downloadOffrePrix/${numeroAffaire}`,
                {
                    responseType: "arraybuffer"
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // generate offre de commerciale
    static generateOffreCommerciale(numeroAffaire) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/generateOffreCommerciale`,
                {
                    numeroAffaire: numeroAffaire
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // download offre de commerciale
    static downloadOffreCommerciale(numeroAffaire) {
        return new Promise((resolve, reject) => {

            axios.get(`${VUE_APP_API_BASE_URL_DEV}/affaires/downloadOffreCommerciale/${numeroAffaire}`,
                {
                    responseType: "arraybuffer"
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    // update Affaire
    static UpdateClientAffaire(numeroAffaire, clientId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/updateClientAffaire`,
                {
                    numeroAffaire: numeroAffaire,
                    clientId: clientId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // get get Indicateur By annee
    static IndicteurAffaire(annee, mois) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/indicateur`,
                {
                    annee: annee,
                    mois: mois
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // check ref affaire
    static CheckRefAffaire(coumpteur) {
        console.log(coumpteur)
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/checkRefAffaire`,
                {
                    coumpteur: coumpteur
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // check ref rapport
    static CheckRefRapport(coumpteur) {
        console.log(coumpteur)
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/checkRefRapport`,
                {
                    coumpteur: coumpteur
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    // Check exist Numero D' affaire
    static CheckExisteNumeroAffaire(numeroAffaire) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/checkExisteNumeroAffaire`,
                {
                    numeroAffaire: numeroAffaire
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    // get get RefClient By NumeroAffaire
    static EnvoyerClientEmail(checkedInterlocuteur, checkedRapport, numeroAffaire) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/EnvoyerClientEmail`,

                {
                    checkedInterlocuteur: checkedInterlocuteur,
                    checkedRapport: checkedRapport,
                    numeroAffaire: numeroAffaire

                },

                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }
    // get get RefClient By NumeroAffaire

    static getRefClientByNumeroAffaire(numeroAffaire) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/getRefClientByNumeroAffaire`,

                {
                    numeroAffaire: numeroAffaire
                },

                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }
    // get affaire By NumeroAffaire

    static getAffaireByNumeroAffaire(numeroAffaire) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/getAffaireByNumeroAffaire`,

                {
                    numeroAffaire: numeroAffaire
                },

                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }
    // get affaire by ID

    static getAffaireById(affaireId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/getAffaireById`,

                {
                    affaireId: affaireId
                },

                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // Generate Suivi de Rapport
    static downloadSuiviRapport(annee) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/downloadSuiviRapport`,
                {
                    annee: annee
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // download Suivi derapport
    static generateSuiviRapport() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/affaires/generateSuiviRapport`,
                {
                    responseType: "arraybuffer"
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // get last numero last Rapport
    static lastRapport(lastrapportId) {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/Lastrapport/updateLastRapport`,
                {
                    lastrapportId: lastrapportId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // change date affaire
    static ChangeDateAffaire(dateAffaire, numeroAffaire, apporteurAffaire, oldNumeroAffaire) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/changeDateAffaire`,
                {
                    dateAffaire: dateAffaire,
                    numeroAffaire: numeroAffaire,
                    apporteurAffaire: apporteurAffaire,
                    oldNumeroAffaire: oldNumeroAffaire
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // update last numero Rapport in Affaie 
    static lastAffaire(lastaffaireId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/last/updateLastAffaire`,
                {
                    lastaffaireId: lastaffaireId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // get last numero Affaire 
    static getLastRefAffaireORLastRefRapport() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/last/read`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // create Affaire 
    static createAffaire(sourceProspection, numeroAffaire, dateAffaire, apporteurAffaire, apporteurAffaireId, targetClient, targetInterlocuteur, titreOffre, tva) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/create`,
                {
                    sourceProspection: sourceProspection,
                    numeroAffaire: numeroAffaire,
                    dateAffaire: dateAffaire,
                    apporteurAffaire: apporteurAffaire,
                    apporteurAffaireId: apporteurAffaireId,
                    clientId: targetClient,
                    interlocuteurId: targetInterlocuteur,
                    titreOffre: titreOffre,
                    tva: tva
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })

    }

    // read all affaires 
    static readAllAffaires() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/affaires/readAll`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // Delete affaire
    static deleteAffaire(affaireId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/delete`,
                {
                    affaireId: affaireId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => {
                    reject(response)
                })

        })
    }
    //--------------------------------------Fin AFFAIRE -------------------------------------------------


    ////_______________________________________________Bon Cammande _____________________________________//


    // create Bon Cammande  
    static enregitreBonCommande(file, affaireId, dateBonCammnade, numeroBonCammnade, libelles, prixBonCommande) {
        return new Promise((resolve, reject) => {

            var formData = new FormData();
            if (file) {
                formData.append('file', file);
            }
            formData.append('affaireId', affaireId);
            formData.append('dateBonCammnade', dateBonCammnade);
            formData.append('numeroBonCammnade', numeroBonCammnade);
            formData.append('libelles', libelles);
            formData.append('prixBonCommande', prixBonCommande);

            axios.put(`${VUE_APP_API_BASE_URL_DEV}/affaires/bonCommande`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }


    // Delete Bon Cammande
    static DeleteBonCammande(affaireId) {
        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/deletebc`,
                {
                    affaireId: affaireId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }


    // get Prestation Bon Commandes
    static getPrestationBonCommandes(affaireId) {
        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/getPrestationBonCommandes`,
                {
                    affaireId: affaireId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }



    // ---------------------------------------- Rapports & Affaires -----------------------------------------------
    // Delete One Rapport
    static displayRapport(filename) {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/affaires/displayRapport/${filename}`, {
                headers: this.headers,
                responseType: 'blob',
            })
                .then(response => {
                    resolve(window.open(URL.createObjectURL(response.data)));
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    // get Rapport With Filename
    static getRapportWithFilename(filename) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/getRapportWithFilename`,
                {
                    filename: filename
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }



    // Delete One Rapport
    static deleteRapport(rapportId, filename) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/deleteRapport`,
                {
                    rapportId: rapportId,
                    filename: filename
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    // Update reference Rapport
    static UpdateReferenceRapport(equipement, missionId, codeMission, prix, typeMission, devis, categorie, typeRapport) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/updateReferenceRapport`,
                {
                    equipement: equipement,
                    missionId: missionId,
                    codeMission: codeMission,
                    prix: prix,
                    typeMission: typeMission,
                    devis: devis,
                    categorie: categorie,
                    typeRapport: typeRapport
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // get Rapport
    static getRapport(rapportId) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/getRapport`,
                {
                    rapportId: rapportId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // Delete One Rapports
    static deleteRapports(checkedRapports) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/deleteRapports`,
                {
                    checkedRapports: checkedRapports
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // confirm rapport
    static NotConfirm(rapportId) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/notConfirm`,
                {
                    rapportId: rapportId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // confirm rapport
    static Confirm(rapportId) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/confirm`,
                {
                    rapportId: rapportId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // resend rapport to client
    static ResendEmail(rapportId) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/resend`,
                {
                    rapportId: rapportId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // read all affaires 
    static readRapports() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/affaires/readRapports`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // read all rapport not cofirm 
    static readRapportsNotConfirm() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/affaires/readRapportsNotConfirm`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }
    // read all rapports confirm 
    static readRapportsConfirm() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/affaires/readRapportsConfirm`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }


    // classement  Rapports with numeroAffaire
    static ClassementRapport(numeroAffaire, arber) {
        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/classementRapport`,
                {
                    numeroAffaire: numeroAffaire,
                    arber: arber
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }

    // select Rapports with numeroAffaire
    static selectRapports(numeroAffaire) {
        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/selectRapports`,
                {
                    numeroAffaire: numeroAffaire
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }


    // Modifier Rapport   
    static ModifierRapport(refRapport, missionId, numeroAffaire, i, j, index) {
        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/updateRapport`,
                {
                    refRapport: refRapport,
                    missionId: missionId,
                    numeroAffaire: numeroAffaire,
                    i: i,
                    j: j,
                    index: index

                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }


    // get Affaire   
    static getLastRapport() {
        return new Promise((resolve, reject) => {

            axios.get(`${VUE_APP_API_BASE_URL_DEV}/affaires/getLastRapport`, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }



    static checkRapports() {
        return new Promise((resolve, reject) => {

            axios.get(`${VUE_APP_API_BASE_URL_DEV}/affaires/checkRapports`, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }

    static accepterRapport(numeroAffaire) {

        return new Promise((resolve, reject) => {

            axios.get(`${VUE_APP_API_BASE_URL_DEV}/affaires/accepterRapport/${numeroAffaire}`,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }

    static DeleteImportRapport(affaireId) {

        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/deleteImportRapport`,
                {
                    affaireId: affaireId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }

    //-----------------------------Fin AFFAIRE & RAPPORTS ------------------------------------------




    //-------------------------------Interventions--------------------------------------------------

    // get Indicateur Production By annee
    static IndicteurProduction(annee, mois) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/interventions/indicateur`,
                {
                    annee: annee,
                    mois: mois
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    // generate la fiche Intervention de chaque inspecteur
    static generateFicheInterventionInspecteur(intervention) {

        const {
            aDate,
            adresseClient,
            chargeAffaire,
            commentaire,
            deDate,
            inspecteur,
            nameInterlocuteur,
            numeroAffaire,
            raisonSocial,
            refClient,
            telephoneInterlocuteur,
            inspecteurlibelles,
            dateInspecteurlibelles
        } = intervention;

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/interventions/generateFicheInterventionInspecteur`,
                {
                    inspecteur: inspecteur,
                    commentaire: commentaire,
                    refClient: refClient,
                    deDate: deDate,
                    aDate: aDate,
                    chargeAffaire: chargeAffaire,
                    numeroAffaire: numeroAffaire,
                    nameInterlocuteur: nameInterlocuteur,
                    telephoneInterlocuteur: telephoneInterlocuteur,
                    adresseClient: adresseClient,
                    raisonSocial: raisonSocial,
                    inspecteurlibelles: inspecteurlibelles,
                    dateInspecteurlibelles: dateInspecteurlibelles
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    // download Intervntion after GENERATE
    static downloadFicheInterventionInspecteur() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/interventions/downloadFicheInterventionInspecteur`,
                {
                    responseType: "arraybuffer"
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // display Intervention but deprecated
    static displayIntervention() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/affaires/displayintervention`,
                { responseType: "arraybuffer" }
            )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }


    // Enregistre La fiche Intervention Global
    static enregistreIntervention(intervention) {
        const {
            affaireId,
            commentaire,
            refClient,
            deDate,
            aDate,
            nameInspecteur,
            chargeAffaire,
            missions,
            libelles,
            interlocuteurId,
            clientId,
            numeroAffaire,
            nameInterlocuteur,
            telephoneInterlocuteur,
            adresseClient,
            raisonSocial,
            inspecteurs,
            inspecteursMissions,
            codeMissions,
            typeMissions
        } = intervention;

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/interventions/create`,

                {
                    affaireId: affaireId,
                    commentaire: commentaire,
                    refClient: refClient,
                    deDate: deDate,
                    aDate: aDate,
                    nameInspecteur: nameInspecteur,
                    chargeAffaire: chargeAffaire,
                    missions: missions,
                    libelles: libelles,
                    interlocuteurId: interlocuteurId,
                    clientId: clientId,
                    numeroAffaire: numeroAffaire,
                    nameInterlocuteur: nameInterlocuteur,
                    telephoneInterlocuteur: telephoneInterlocuteur,
                    adresseClient: adresseClient,
                    raisonSocial: raisonSocial,
                    inspecteurs: inspecteurs,
                    inspecteursMissions: inspecteursMissions,
                    codeMissions: codeMissions,
                    typeMissions: typeMissions,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }
    // delete Intervention
    static DeleteIntervention(affaireId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/interventions/deleteIntervention`,

                {
                    affaireId: affaireId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // ____________________ START display all file pdf ________________________________________________//

    // display Bon Cammande
    static displayBC(filename) {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/affaires/displaybc/${filename}`, {
                headers: this.headers,
                responseType: 'blob',
            })
                .then(response => {
                    resolve(window.open(URL.createObjectURL(response.data)));
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }

    // display Devis
    static displayDEVIS(filename) {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/affaires/displaydevis/${filename}`, {
                headers: this.headers,
                responseType: 'blob',
            })
                .then(response => {
                    resolve(window.open(URL.createObjectURL(response.data)));
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }

    // update Devis
    static UpdateDevis(file, affaireId) {

        return new Promise((resolve, reject) => {

            var formData = new FormData();
            if (file) {
                formData.append('file', file);
            }

            formData.append('affaireId', affaireId);
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/affaires/updateDevis`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }

    // generate Intervention
    static generateIntervention(affaireId) {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/affaires/generateIntervention/${affaireId}`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }


    //  old version because pdf now docx
    //     return new Promise((resolve, reject) => {

    //         axios.get(`${VUE_APP_API_BASE_URL_DEV}/affaires/displayintervention/${affaireId}`, {
    //             headers: this.headers,
    //             responseType: 'blob',
    //         })
    //         .then(response => {
    //                 resolve(window.open(URL.createObjectURL(response.data)));
    //         })
    //         .catch(error => {
    //                 reject(error.response.data);
    //         });

    // })



    static showBE(be) {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/bordereaus/displaybe/${be}`,
                {
                    responseType: "arraybuffer"
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }


    // display Intervention
    static ShowEmise(affaireId) {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/paiement/read/${affaireId}`, {
                headers: this.headers,
                responseType: 'blob',
            })
                .then(response => {
                    resolve(window.open(URL.createObjectURL(response.data)));
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }




    //___________________________BD_______________________________________________________________/

    // generate BD
    static generateBD(affaireId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/bordereaus/generateBD`,
                {
                    affaireId: affaireId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // download BD
    static downloadBD() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/bordereaus/downloadBD`,
                {
                    responseType: "arraybuffer"
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    // delete Bd 
    static DeleteImportRapportandBE(affaireId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/bordereaus/deleteImportRapportandBE`,
                {
                    affaireId: affaireId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }


    // Get last BD for get counter BD
    static getLastBD() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/bordereaus/getLastBD`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }
    // Delte BD 
    static deleteBD(affaireId, numeroAffaire, be) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/bordereaus/delete`,
                {
                    affaireId: affaireId,
                    numeroAffaire: numeroAffaire,
                    be: be
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }
    //GenerateXSLX

    // Delete BD without Filename BD 
    static DeleteBDWithoutFilenameBE(affaireId, numeroAffaire) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/bordereaus/DeleteBDWithoutFilenameBE`,
                {
                    affaireId: affaireId,
                    numeroAffaire: numeroAffaire,

                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // read all BD 
    static readAllBordereaus() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/bordereaus/readAllBordereaus`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }


    // enregitre bd
    static enregistreBD(bd) {
        const {
            numeroBD,
            refClient,
            numeroAffaire,
            missions,
            rapports,
            dateEnvoi,
            dateRecu,
            raisonSocial,
            adresse,
            telephone,
            adresseEnvoi,
            numeroBC,
            numeroICE,
            dateCreation,
            affaireId
        } = bd;
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/bordereaus/create`,
                {
                    numeroBD: numeroBD,
                    refClient: refClient,
                    numeroAffaire: numeroAffaire,
                    missions: missions,
                    rapports: rapports,
                    dateEnvoi: dateEnvoi,
                    dateRecu: dateRecu,
                    raisonSocial: raisonSocial,
                    adresse: adresse,
                    telephone: telephone,
                    adresseEnvoi: adresseEnvoi,
                    numeroBC: numeroBC,
                    numeroICE: numeroICE,
                    dateCreation: dateCreation,
                    affaireId: affaireId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // apercu bd
    static apercueBD(bd) {
        const {
            numeroBD,
            refClient,
            numeroAffaire,
            missions,
            rapports,
            dateEnvoi,
            dateRecu,
            raisonSocial,
            adresse,
            telephone,
            adresseEnvoi,
            numeroBC,
            numeroICE,
            dateCreation,
            affaireId
        } = bd;
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/bordereaus/apercueBD`,
                {
                    numeroBD: numeroBD,
                    refClient: refClient,
                    numeroAffaire: numeroAffaire,
                    missions: missions,
                    rapports: rapports,
                    dateEnvoi: dateEnvoi,
                    dateRecu: dateRecu,
                    raisonSocial: raisonSocial,
                    adresse: adresse,
                    telephone: telephone,
                    adresseEnvoi: adresseEnvoi,
                    numeroBC: numeroBC,
                    numeroICE: numeroICE,
                    dateCreation: dateCreation,
                    affaireId: affaireId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // -----------------------FIN BD ---------------------------------------------------------------


    //---------------------- FACTURE -------------------------------------------------------------


    // generate BD
    static generateFacture(affaireId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/factures/generateFacture`,
                {
                    affaireId: affaireId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // download BD
    static downloadFacture() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/factures/downloadFacture`,
                {
                    responseType: "arraybuffer"
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    // Get last BD for get counter BD
    static getLastFACTURE() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/factures/getLastFACTURE`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // read all BD 
    static readAllFactures() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/factures/readAllFactures`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    static apercuFACTURE(facture) {

        const {
            adresse,
            adresseEnvoi,
            affaireId,
            dateCreation,
            echeance,
            facturation,
            missions,
            numeroAffaire,
            numeroBC,
            numeroFACTURE,
            numeroICE,
            prixLettre,
            prixTotalHT,
            prixTotalTTC,
            raisonSocial,
            refClient,
            telephone,
            tva
        } = facture;


        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/factures/apercu`,
                {
                    adresse: adresse,
                    adresseEnvoi: adresseEnvoi,
                    affaireId: affaireId,
                    dateCreation: dateCreation,
                    echeance: echeance,
                    facturation: facturation,
                    missions: missions,
                    numeroAffaire: numeroAffaire,
                    numeroBC: numeroBC,
                    numeroFACTURE: numeroFACTURE,
                    numeroICE: numeroICE,
                    prixLettre: prixLettre,
                    prixTotalHT: prixTotalHT,
                    prixTotalTTC: prixTotalTTC,
                    raisonSocial: raisonSocial,
                    refClient: refClient,
                    telephone: telephone,
                    tva: tva
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    static enregistreFACTURE(facture) {
        const {
            adresse,
            adresseEnvoi,
            affaireId,
            dateCreation,
            echeance,
            facturation,
            missions,
            numeroAffaire,
            numeroBC,
            numeroFACTURE,
            numeroICE,
            prixLettre,
            prixTotalHT,
            prixTotalTTC,
            raisonSocial,
            refClient,
            telephone,
            tva
        } = facture;


        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/factures/create`,
                {
                    adresse: adresse,
                    adresseEnvoi: adresseEnvoi,
                    affaireId: affaireId,
                    dateCreation: dateCreation,
                    echeance: echeance,
                    facturation: facturation,
                    missions: missions,
                    numeroAffaire: numeroAffaire,
                    numeroBC: numeroBC,
                    numeroFACTURE: numeroFACTURE,
                    numeroICE: numeroICE,
                    prixLettre: prixLettre,
                    prixTotalHT: prixTotalHT,
                    prixTotalTTC: prixTotalTTC,
                    raisonSocial: raisonSocial,
                    refClient: refClient,
                    telephone: telephone,
                    tva: tva
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // ------------------------------ FIN FACTURE -----------------------------------------------


    //------------------------------- List Days -------------------------------------------------

    static Sauvgarder(listDays, index, matricule, mois, annee) {

        const {
            number,
            jour,
            client,
            lieu,
            objet,
            gasoil,
            autoroute,
            taxi,
            train,
            hotel,
            repas,
            autre,
            disabled,
            flagSauvgarder,
        } = listDays;


        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/listday/sauvgarder`,
                {
                    index: index,
                    matricule: matricule,
                    mois: mois,
                    number: number,
                    jour: jour,
                    annee: annee,
                    client: client,
                    lieu: lieu,
                    objet: objet,
                    gasoil: gasoil,
                    autoroute: autoroute,
                    taxi: taxi,
                    train: train,
                    hotel: hotel,
                    repas: repas,
                    autre: autre,
                    disabled: disabled,
                    flagSauvgarder: flagSauvgarder,
                },

                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }



    static Changer(index, matricule) {



        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/listday/changer`,
                {
                    index: index,
                    matricule: matricule,
                },

                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }


    static SelectMois(matricule, mois, annee) {


        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/listday/selectMois`,
                {
                    mois: mois,
                    matricule: matricule,
                    annee: annee
                },

                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    //____________________ Frais_ _________________________________________________________

    static Valider(nom, prenom, matricule, mois, annee, listDays, totalGeneral, valideListDays) {

        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/frai/valider`,
                {
                    nom: nom,
                    prenom: prenom,
                    matricule: matricule,
                    mois: mois,
                    annee: annee,
                    listDays: listDays,
                    totalGeneral: totalGeneral,
                    valideListDays: valideListDays
                },

                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }


    static CheckMoisValider(matricule) {

        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/frai/checkmoisvalider`,
                {
                    matricule: matricule
                },

                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }


    static Read() {

        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/frai/read`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }


    static GenerateXSLX(fraiId) {

        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/frai/generatexslx/${fraiId}`,
                {
                    responseType: "arraybuffer"
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }

    static generateWORD(fraiId, nom, prenom) {

        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/frai/generateword/${fraiId}/${nom}/${prenom}`,
                {
                    responseType: "arraybuffer"
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }

    static validRH(fraiId) {
        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/frai/validerh`,
                {
                    fraiId: fraiId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }



    static validDIR(fraiId) {
        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/frai/validedir`,
                {
                    fraiId: fraiId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    static Paiment(fraiId, typePaiment, refTransaction) {
        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/frai/paiment`,
                {
                    fraiId: fraiId,
                    typePaiment: typePaiment,
                    refTransaction: refTransaction,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    static SupprimerPaiment(fraiId) {
        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/frai/supprimerpaiment`,
                {
                    fraiId: fraiId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }


    static SupprimerFrais(fraiId, mois, annee, matricule) {
        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/frai/supprimerfrais`,
                {
                    fraiId: fraiId,
                    mois: mois,
                    annee: annee,
                    matricule: matricule
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }


    static PaimentDate(fraiId, datePaiment) {
        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/frai/paimentdate`,
                {
                    fraiId: fraiId,
                    datePaiment: datePaiment
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    //----------------------------Calendrier -------------------------------- 

    static SMS(listDays, mois, matricule, jour, number, annee, adminId) {

        const {
            client,
            countInput,
            disabled,
            flagSauvgarder,
            horaire,
            inspecteur,
            lieu,
            titre,
            valider
        } = listDays;

        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/calendriers/sms`,
                {
                    client: client,
                    countInput: countInput,
                    disabled: disabled,
                    flagSauvgarder: flagSauvgarder,
                    horaire: horaire,
                    inspecteur: inspecteur,
                    lieu: lieu,
                    titre: titre,
                    valider: valider,
                    mois: mois,
                    matricule: matricule,
                    jour: jour,
                    number: number,
                    annee: annee,
                    adminId: adminId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }


    static SauvegarderCalendrier(listDays, mois, matricule, jour, number, annee, adminId) {


        const {
            client,
            countInput,
            disabled,
            flagSauvgarder,
            horaire,
            inspecteur,
            lieu,
            titre,
            valider
        } = listDays;

        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/calendriers/sauvgardercalendrier`,
                {
                    client: client,
                    countInput: countInput,
                    disabled: disabled,
                    flagSauvgarder: flagSauvgarder,
                    horaire: horaire,
                    inspecteur: inspecteur,
                    lieu: lieu,
                    titre: titre,
                    valider: valider,
                    mois: mois,
                    matricule: matricule,
                    jour: jour,
                    number: number,
                    annee: annee,
                    adminId: adminId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }


    static getInspecteurByDate(debut, fin) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/calendriers/getInspecteurByDate`,
                {
                    debut: debut,
                    fin: fin
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // static gestionCalendrier(adminId) {

    //     return new Promise((resolve, reject) => {

    //         axios.post(`${VUE_APP_API_BASE_URL_DEV}/calendriers/gestionCalendrier`,
    //         {
    //             adminId : adminId
    //         },
    //         {
    //             headers: {
    //                  'Content-Type': 'application/json'
    //              }
    //          })
    //             .then(response => {
    //                 resolve(response);
    //             })
    //             .catch(error => {
    //                 reject(error);
    //             });

    //     })
    // }

    // static getTokenCalendrier(code, achraf, adminId) {

    //     return new Promise((resolve, reject) => {

    //         axios.post(`${VUE_APP_API_BASE_URL_DEV}/calendriers/getTokenCalendrier`,
    //         {
    //             code : code,
    //             adminId : adminId,
    //             achraf : achraf
    //         },
    //         {
    //             headers: {
    //                  'Content-Type': 'application/json'
    //              }
    //          })
    //             .then(response => {
    //                 resolve(response);
    //             })
    //             .catch(error => {
    //                 reject(error);
    //             });

    //     })
    // }


    static SelectMoisCalendrier(annee, mois) {

        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/calendriers/read`,
                {
                    mois: mois,
                    annee: annee
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    static selectAllCalendriers() {

        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/calendriers/selectAllCalendriers`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }


    static SelectMoisCalendrierInspecteur(annee, mois, matricule) {

        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/calendriers/readInspecteur`,
                {
                    mois: mois,
                    annee: annee,
                    matricule: matricule
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    static SelectMoisDaysAnneeCalendrierInspecteur(annee, mois, number, inspecteur) {

        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/calendriers/selectMoisDaysAnnee`,
                {
                    annee: annee,
                    mois: mois,
                    number: number,
                    matricule: inspecteur
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    static SelectJourMoisAnneeCalendrier(number, mois, annee, agendaId) {

        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/calendriers/selectJourMoisAnneeCalendrier`,
                {
                    annee: annee,
                    mois: mois,
                    number: number,
                    agendaId: agendaId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }


    static DeleteCalendrier(val, i, j, index, refreshToken, adminId) {

        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/calendriers/delete`,
                {
                    val: val,
                    i: i,
                    j: j,
                    index: index,
                    refreshToken: refreshToken,
                    adminId: adminId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    static ValiderCalendrier(id, annee, mois, i, j, inspecteurId) {

        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/calendriers/validercalendrier`,
                {
                    id: id,
                    annee: annee,
                    mois: mois,
                    i: i,
                    j: j,
                    inspecteurId: inspecteurId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    // telecharger word agenda
    static TelechargerAgenda(list, mois, annee, inspecteur) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/calendriers/telechargeragenda`,
                {
                    list: list,
                    mois: mois,
                    annee: annee,
                    inspecteur: inspecteur
                },
                {
                    responseType: "arraybuffer"
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }
    // telecharger word agenda
    static readAgendas() {

        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/calendriers/readAgendas`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }

    ///// ____________________________________________ Supports Techniques _________________________________///:
    // telecharger word agenda
    static handelPdfSupports(filename) {

        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/supports/display/${filename}`, {
                headers: this.headers,
                responseType: 'blob',
            })
                .then(response => {
                    resolve(response.request.responseURL);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }
    //////------------------------------Caisse----------------------------------------------
    static EnregitreCheque(typePaiement, refTransaction, datePaiement, targetAffaire) {
        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/paiement/create`, {
                typePaiement: typePaiement,
                refTransaction: refTransaction,
                datePaiement: datePaiement,
                targetAffaire: targetAffaire
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        })
    }


    static DeleteEmise(affaireId, nameFilePaiement) {
        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/paiement/delete`,
                {
                    affaireId: affaireId,
                    nameFilePaiement: nameFilePaiement
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        })
    }




    // Get users Online
    static Online(adminId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/admins/online`,
                {
                    adminId: adminId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // reject Online
    static rejectOnline(adminId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/admins/rejectonline`,
                {
                    adminId: adminId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    // check Notification Frais
    static checkNotificationFrais(adminId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/admins/checkNotificationFrais`,
                {
                    adminId: adminId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    static deleteCheckNotificationCalendrier(adminId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/admins/deleteCheckNotificationCalendrier`,
                {
                    adminId: adminId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // Delete check Notification Frais
    static deleteCheckNotificationFrais(fraiId, adminId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/admins/deleteCheckNotificationFrais`,
                {
                    adminId: adminId,
                    fraiId: fraiId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }
    // check Notification Calendrier
    static checkNotificationCalendrier(adminId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/admins/checkNotificationCalendrier`,
                {
                    adminId: adminId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    // ---------------------------CHAT CONTACTS---------------------------------------------------
    static ChatContact(currentContact, matricule) {
        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/chats/create`,
                {
                    currentContact: currentContact,
                    matricule: matricule
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        })
    }
    //_________________________________ CHAT CONTENT --------------------------------------------

    static EnvoyerMessage(currentRoom, message, matricule) {
        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/chatcontent/create`,
                {
                    chatId: currentRoom,
                    message: message,
                    clientId: matricule

                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        })
    }


    static EnregistrerClassAffaire(numeroAffaire, niveauUn, niveauDeux, niveauTrois, niveauQuatre, niveauCinq) {
        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/class/enregistrerRapportClass`,
                {
                    numeroAffaire: numeroAffaire,
                    niveauUn: niveauUn,
                    niveauDeux: niveauDeux,
                    niveauTrois: niveauTrois,
                    niveauQuatre: niveauQuatre,
                    niveauCinq: niveauCinq

                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        })
    }

    static ReadClassAffaire(numeroAffaire) {
        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/class/readClassAffaire`,
                {
                    numeroAffaire: numeroAffaire,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        })
    }


    static EffacerClassAffaire(numeroAffaire) {
        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/class/effacerClassAffaire`,
                {
                    numeroAffaire: numeroAffaire,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        })
    }

    static EnregistrerClassRapport(rapportId, niveauUn, niveauDeux, niveauTrois, niveauQuatre, niveauCinq) {
        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/class/enregistrerClassRapport`,
                {
                    rapportId: rapportId,
                    classOne: niveauUn,
                    classTow: niveauDeux,
                    classTree: niveauTrois,
                    classFour: niveauQuatre,
                    classFive: niveauCinq,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        })
    }

    static DeleteClassRapport(rapportId) {
        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/class/deleteClassRapport`,
                {
                    rapportId: rapportId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        })
    }

    static EnregistrerClassShema(numeroAffaire, niveauUn, niveauDeux, niveauTrois, niveauQuatre, niveauCinq, index) {
        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/class/enregistrerClassShema`,
                {
                    numeroAffaire: numeroAffaire,
                    niveauOne: niveauUn,
                    niveauTow: niveauDeux,
                    niveauTree: niveauTrois,
                    niveauFour: niveauQuatre,
                    niveauFive: niveauCinq,
                    index: index,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        })
    }

    static DeleteClassShema(numeroAffaire, index) {
        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/class/deleteClassShema`,
                {
                    numeroAffaire: numeroAffaire,
                    index: index
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        })
    }

    // Etalonnage -------------------------------------------------------------------------------------

    // create Etalonnage
    static createEtalonnage(etalonnage, file) {
        const {
            designation,
            marque,
            identification,
            etalonnieLe,
            prochaineEtalonnage,
            certificatsEtalonnage,
        } = etalonnage;

        return new Promise((resolve, reject) => {
            var formData = new FormData();

            if (file) {
                formData.append('file', file);
            }

            formData.append('designation', designation);
            formData.append('marque', marque);
            formData.append('identification', identification);
            formData.append('etalonnieLe', etalonnieLe);
            formData.append('prochaineEtalonnage', prochaineEtalonnage);
            formData.append('certificatsEtalonnage', certificatsEtalonnage);

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/etalonnages/create`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }


    // read Etalonnage
    static readEtalonnage() {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/etalonnages/read`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    // Delete Etalonnage
    static deleteEtalonnage(filename) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/etalonnages/delete`,
                {
                    filename: filename
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // Display Etalonnage
    static displayEtalonnage(filename) {

        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/etalonnages/display/${filename}`, {
                headers: this.headers,
                responseType: 'blob',
            })
                .then(response => {
                    resolve(window.open(URL.createObjectURL(response.data)));
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }

    // Select Etalonnage
    static selectEtalonnage(etalonnageId) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/etalonnages/select`,
                {
                    etalonnageId: etalonnageId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
    // deleteSalarie
    // Upadet Etalonnage
    static updateEtalonnage(etalonnageId, etalonnage, file) {

        const {
            designation,
            marque,
            identification,
            etalonnieLe,
            prochaineEtalonnage,
            certificatsEtalonnage,
        } = etalonnage;

        return new Promise((resolve, reject) => {
            var formData = new FormData();

            if (file) {
                formData.append('file', file);
            }

            formData.append('etalonnageId', etalonnageId);
            formData.append('designation', designation);
            formData.append('marque', marque);
            formData.append('identification', identification);
            formData.append('etalonnieLe', etalonnieLe);
            formData.append('prochaineEtalonnage', prochaineEtalonnage);
            formData.append('certificatsEtalonnage', certificatsEtalonnage);

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/etalonnages/update`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        });
    }
    // Fiche Technique -------------------------------------------------------------------------------------

    // create Fiche Technique
    static createFicheTechnique(ficheTechnique, file) {
        const { designation, marque, identification, numFiche } = ficheTechnique;

        return new Promise((resolve, reject) => {
            var formData = new FormData();

            if (file) {
                formData.append('file', file);
            }

            formData.append('designation', designation);
            formData.append('marque', marque);
            formData.append('identification', identification);
            formData.append('numFiche', numFiche);


            axios.post(`${VUE_APP_API_BASE_URL_DEV}/fichetechniques/create`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }


    // read Fiche Technique
    static readFicheTechnique() {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/fichetechniques/read`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    // Delete Fiche Technique
    static deleteFicheTechnique(filename) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/fichetechniques/delete`,
                {
                    filename: filename
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }




    // Select Fiche Technique
    static selectFicheTechnique(fichetechniqueId) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/fichetechniques/select`,
                {
                    fichetechniqueId: fichetechniqueId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    // UPDATE Fiche Technique
    static updateFicheTechnique(fichetechniqueId, ficheTechnique, file) {

        const { designation, marque, identification, numFiche } = ficheTechnique;


        return new Promise((resolve, reject) => {
            var formData = new FormData();

            if (file) {
                formData.append('file', file);
            }

            formData.append('fichetechniqueId', fichetechniqueId);
            formData.append('designation', designation);
            formData.append('marque', marque);
            formData.append('identification', identification);
            formData.append('numFiche', numFiche);

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/fichetechniques/update`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        });
    }

    // Display Fiche Technique
    static displayFicheTechnique(filename) {

        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/fichetechniques/display/${filename}`, {
                headers: this.headers,
                responseType: 'blob',
            })
                .then(response => {
                    resolve(window.open(URL.createObjectURL(response.data)));
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }
    // Fiche Salarie -------------------------------------------------------------------------------------

    // Display Salarie
    static displaySalarie(filename) {

        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/salaries/display/${filename}`, {
                headers: this.headers,
                responseType: 'blob',
            })
                .then(response => {
                    resolve(window.open(URL.createObjectURL(response.data)));
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }

    // create Fiche Salarie
    static createFicheSalarie(ficheSalarie, cin, diplome, photo, autres, medical) {

        const {
            adresse,
            banque,
            cnss,
            dateEmbauche,
            dateQuitter,
            email,
            identite,
            matricule,
            niveauEtude,
            nom,
            pays,
            poste,
            prenom,
            rip,
            telephone,
            typeContart,
            typeQuitter,
            ville
        } = ficheSalarie;


        const form = new FormData();

        form.append(`files[${0}]`, cin);
        form.append(`files[${1}]`, diplome);
        form.append(`files[${2}]`, photo);
        form.append(`files[${3}]`, autres);
        form.append(`files[${4}]`, medical);

        form.append('adresse', adresse);
        form.append('banque', banque);
        form.append('cnss', cnss);
        form.append('dateEmbauche', dateEmbauche);
        form.append('dateQuitter', dateQuitter);
        form.append('email', email);
        form.append('identite', identite);
        form.append('matricule', matricule);
        form.append('niveauEtude', niveauEtude);
        form.append('nom', nom);
        form.append('pays', pays);
        form.append('poste', poste);
        form.append('prenom', prenom);
        form.append('rip', rip);
        form.append('telephone', telephone);
        form.append('typeContart', typeContart);
        form.append('typeQuitter', typeQuitter);
        form.append('ville', ville);



        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/salaries/create`, form,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        });



    }


    // update Fiche Salarie
    static updateFicheSalarie(ficheSalarie, cin, diplome, photo, autres, medical, salarieId) {

        const {
            adresse,
            banque,
            cnss,
            dateEmbauche,
            dateQuitter,
            email,
            identite,
            matricule,
            niveauEtude,
            nom,
            pays,
            poste,
            prenom,
            rip,
            telephone,
            typeContart,
            typeQuitter,
            ville
        } = ficheSalarie;


        const form = new FormData();

        form.append(`files[${0}]`, cin);
        form.append(`files[${1}]`, diplome);
        form.append(`files[${2}]`, photo);
        form.append(`files[${3}]`, autres);
        form.append(`files[${4}]`, medical);
        form.append('salarieId', salarieId);
        form.append('adresse', adresse);
        form.append('banque', banque);
        form.append('cnss', cnss);
        form.append('dateEmbauche', dateEmbauche);
        form.append('dateQuitter', dateQuitter);
        form.append('email', email);
        form.append('identite', identite);
        form.append('matricule', matricule);
        form.append('niveauEtude', niveauEtude);
        form.append('nom', nom);
        form.append('pays', pays);
        form.append('poste', poste);
        form.append('prenom', prenom);
        form.append('rip', rip);
        form.append('telephone', telephone);
        form.append('typeContart', typeContart);
        form.append('typeQuitter', typeQuitter);
        form.append('ville', ville);



        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/salaries/update`, form,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        });
    }

    // update Fiche Salarie
    static EnregitreDateQuitter(collaborateurId, dateQuitter, typeQuitter) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/salaries/enregitreDateQuitter`,
                {
                    salarieId: collaborateurId,
                    dateQuitter: dateQuitter,
                    typeQuitter: typeQuitter,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // read Etalonnage
    static readSalaries() {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/salaries/read`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }
    // Delete Salarie
    static deleteSalarie(cin, diplome, photo, autres, medical, salarieId) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/salaries/delete`,
                {
                    cin: cin,
                    diplome: diplome,
                    photo: photo,
                    autres: autres,
                    medical: medical,
                    salarieId: salarieId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }
    // Delete Salaries
    static deleteSalaries(cins, diplomes, photos, autress, medicals, salariesId) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/salaries/deleteMany`,
                {
                    cins: cins,
                    diplomes: diplomes,
                    photos: photos,
                    autress: autress,
                    medicals: medicals,
                    salariesId: salariesId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    //select Fiche Salarie
    static selectFicheSalarie(salarieId) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/salaries/select`,
                {
                    salarieId: salarieId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
    // ----------------------- Conges --------------------------


    static telechargerGenerateAbsence() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/conges/telechargerGenerateAbsence`,
                { responseType: "arraybuffer" }
            )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        })
    }

    static generateAbsence(absenceId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/conges/generateAbsence`,
                {
                    absenceId: absenceId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    // check collaborateur conge 
    static PermetConge(adminId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/conges/permetConge`,
                {
                    adminId: adminId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    //select conge by Admin
    static selectCongeAdmin(adminId) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/conges/selectCongeAdmin`,
                {
                    adminId: adminId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    //conge recuperation
    static recuperation(matricule) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/conges/recuperation`,
                {
                    matricule: matricule
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    //les jour deja recuper
    static recuperer(matricule) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/conges/recuperer`,
                {
                    matricule: matricule
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });

    }

    //clacul etat abscence
    static CalculEtatAbscence(year) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/conges/calculEtatAbscence`,
                {
                    year: year
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    // Generate Liste Absence
    static generateListAbsence(mois, annee) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/conges/generateListAbsence`,
                {
                    mois: mois,
                    annee: annee
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    //select conge by Admin
    static telechargerListeAbsence() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/conges/telechargerListeAbsence`,
                {
                    responseType: "arraybuffer"
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }


    //create conge 
    static createConge(absence, file, matricule) {

        const {
            date,
            nom,
            prenom,
            fonction,
            departement,
            motif,
            duree,
            nomberJours,
            nomberJoursOuvrables,
        } = absence;

        const typeAbsence = absence.typeAbsence;
        const dateDebutConge = duree.start;
        const dateFinConge = duree.fin;

        const form = new FormData();

        form.append(`file`, file);
        form.append('date', date);
        form.append('adminId', matricule);
        form.append('nom', nom);
        form.append('prenom', prenom);
        form.append('fonction', fonction);
        form.append('departement', departement);
        form.append('typeAbsence', typeAbsence);
        form.append('motif', motif);
        form.append('dateDebutConge', dateDebutConge);
        form.append('dateFinConge', dateFinConge);
        form.append('nomberJours', parseFloat(nomberJours).toFixed(2));
        form.append('nomberJoursOuvrables', parseFloat(nomberJoursOuvrables).toFixed(2));

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/conges/create`, form,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        });
    }


    //select conge by Admin
    static readConges() {

        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/conges/read`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    // Display Absence
    static displayAbsence(filename) {

        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/conges/display/evidence/${filename}`, {
                headers: this.headers,
                responseType: 'blob',
            })
                .then(response => {
                    resolve(window.open(URL.createObjectURL(response.data)));
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }

    // Delete Absence
    static deleteAbsence(id, adminId, filename) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/conges/delete`,
                {
                    id: id,
                    adminId: adminId,
                    filename: filename
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }
    // valide Absence
    static valideAbsence(id, adminId, item) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/conges/valide`,
                {
                    id: id,
                    adminId: adminId,
                    item: item
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // invalide Absence
    static invalideAbsence(id, adminId) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/conges/invalide`,
                {
                    id: id,
                    adminId: adminId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    static AppliquerAbsence(item) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/conges/appliquer`,
                {
                    item: item
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    //----------------------- Competences -------------------------------------------
    //create competences 
    static createCompetence(competence, file) {

        var formData = new FormData();

        if (file) {
            formData.append('file', file);
        }

        const {
            collaborateurId,
            connaissance,
            dateFormation,
            domaineFamille,
            metier,
            moduleFormation,
            nom,
            prenom,
            qualification,
        } = competence;


        formData.append('collaborateurId', collaborateurId);
        formData.append('connaissance', connaissance);
        formData.append('dateFormation', dateFormation);
        formData.append('domaineFamille', domaineFamille);
        formData.append('moduleFormation', moduleFormation);
        formData.append('nom', nom);
        formData.append('prenom', prenom);
        formData.append('metier', metier);
        formData.append('qualification', qualification);

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/competences/create`, formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        });
    }

    // read Etalonnage
    static readCompetences() {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/competences/read`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    // Delete Competence
    static deleteCompetence(filename) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/competences/delete`,
                {
                    filename: filename
                }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // Display Etalonnage
    static displayCompetence(filename) {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/competences/display/${filename}`, {
                headers: this.headers,
                responseType: 'blob',
            })
                .then(response => {
                    resolve(window.open(URL.createObjectURL(response.data)));
                })
                .catch(error => {
                    reject(error.response.data);
                });
        })
    }

    // Select Etalonnage
    static selectCompetence(competenceId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/competences/select`,
                {
                    competenceId: competenceId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    // Upadet Etalonnage
    static updateCompetence(competenceId, competence, file, filename) {

        const {
            collaborateurId,
            connaissance,
            dateFormation,
            domaineFamille,
            metier,
            moduleFormation,
            nom,
            prenom,
            qualification,
        } = competence;


        return new Promise((resolve, reject) => {

            var formData = new FormData();

            if (file) {
                formData.append('file', file);
            }

            formData.append('competenceId', competenceId);
            formData.append('collaborateurId', collaborateurId);
            formData.append('connaissance', connaissance);
            formData.append('dateFormation', dateFormation);
            formData.append('domaineFamille', domaineFamille);
            formData.append('metier', metier);
            formData.append('moduleFormation', moduleFormation);
            formData.append('nom', nom);
            formData.append('prenom', prenom);
            formData.append('qualification', qualification);
            formData.append('filename', filename);

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/competences/update`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        });
    }

    //----------------------- Agrement -------------------------------------------
    static createAgrement(agrement, file) {

        var formData = new FormData();

        if (file) {
            formData.append('file', file);
        }

        const {
            dateArgument,
            dateExpiration,
            dateRenouvellement,
            duree,
            nomArgument,
            nomMinistre,
            preuve,
            status
        } = agrement;

        formData.append('dateArgument', dateArgument);
        formData.append('dateExpiration', dateExpiration);
        formData.append('dateRenouvellement', dateRenouvellement);
        formData.append('duree', duree);
        formData.append('nomArgument', nomArgument);
        formData.append('nomMinistre', nomMinistre);
        formData.append('preuve', preuve);
        formData.append('status', status);

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/agrements/create`, formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        });
    }

    // read Etalonnage
    static readAgrements() {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/agrements/read`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    // Delete Competence
    static deleteArgement(filename) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/agrements/delete`,
                {
                    filename: filename
                }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // Display Etalonnage
    static displayAgrement(filename) {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/agrements/display/${filename}`, {
                headers: this.headers,
                responseType: 'blob',
            })
                .then(response => {
                    resolve(window.open(URL.createObjectURL(response.data)));
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }

    // Select Etalonnage
    static selectAgrement(argementId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/agrements/select`,
                {
                    argementId: argementId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    // Upadet Argelent
    static UpdateArgement(argement, argumentId, file) {

        var formData = new FormData();
        if (file) {
            formData.append('file', file);
        }
        const {
            dateArgument,
            dateExpiration,
            dateRenouvellement,
            duree,
            nomArgument,
            nomMinistre,
            preuve,
            status
        } = argement;

        formData.append('argumentId', argumentId);
        formData.append('dateArgument', dateArgument);
        formData.append('dateExpiration', dateExpiration);
        formData.append('dateRenouvellement', dateRenouvellement);
        formData.append('duree', duree);
        formData.append('nomArgument', nomArgument);
        formData.append('nomMinistre', nomMinistre);
        formData.append('preuve', preuve);
        formData.append('status', status);


        return new Promise((resolve, reject) => {

            axios.post(`${VUE_APP_API_BASE_URL_DEV}/agrements/update`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        });


    }

    // -------------------------- Gestion Frais Salarie --------------------------------//
    static getFraisSalarieYear(inspecteur, annee) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/situations/read`,
                {
                    inspecteur: inspecteur,
                    annee: annee
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static createFraisSalarie(id, i, annee, inspecteur, current, after) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/situations/create`,
                {
                    id: id,
                    i: i,
                    annee: annee,
                    inspecteur: inspecteur,
                    current: current,
                    after: after
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }


    static deleteFraisSalarie(id, i, annee, inspecteur, current, after) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/situations/delete`,
                {
                    id: id,
                    i: i,
                    annee: annee,
                    inspecteur: inspecteur,
                    current: current,
                    after: after
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static TelechargerSituationFrais(inspecteur, annee) {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/situations/telecharger/${inspecteur}/${annee}`,
                {
                    responseType: "arraybuffer"
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }

    //------------------------- Sous- Traitance ------------------------------------
    static createSousTraitance(sousTraitance) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/sousTraitance/create`,
                {
                    sousTraitance: sousTraitance,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static updateSousTraitance(sousTraitance, sousTraitanceId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/sousTraitance/update`,
                {
                    sousTraitance: sousTraitance,
                    sousTraitanceId: sousTraitanceId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static selectSousTraitance(sousTraitanceId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/sousTraitance/select`,
                {
                    sousTraitanceId: sousTraitanceId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static deleteSousTraitance(sousTraitanceId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/sousTraitance/delete`,
                {
                    sousTraitanceId: sousTraitanceId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static readSousTraitance() {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/sousTraitance/read`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // -------------------------- Fournisseur --------------------------------------/
    static createFournisseur(fournisseur) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/fournisseurs/create`,
                {
                    fournisseur: fournisseur,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static updateFournisseur(fournisseur, fournisseurId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/fournisseurs/update`,
                {
                    fournisseur: fournisseur,
                    fournisseurId: fournisseurId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static selectFournisseur(fournisseurId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/fournisseurs/select`,
                {
                    fournisseurId: fournisseurId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static deleteFournisseur(fournisseurId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/fournisseurs/delete`,
                {
                    fournisseurId: fournisseurId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static readFournisseur() {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/fournisseurs/read`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    //-------------------------------- InterlocuteurSousTraitance -------------------------------

    static createInterlocuteurSousTraitance(interlocuteurSousTraitance) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/interlocuteurSousTraitance/create`,
                {
                    interlocuteurSousTraitance: interlocuteurSousTraitance,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    // update  InterlocuteurSousTraitance
    static updateInterlocuteurSousTraitance(interlocuteurSousTraitance, interlocuteurSousTraitanceId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/interlocuteurSousTraitance/update`,
                {
                    interlocuteurSousTraitance: interlocuteurSousTraitance,
                    interlocuteurSousTraitanceId: interlocuteurSousTraitanceId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static selectInterlocuteurSousTraitance(interlocuteurSousTraitanceId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/interlocuteurSousTraitance/select`,
                {
                    interlocuteurSousTraitanceId: interlocuteurSousTraitanceId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static deleteInterlocuteurSousTraitance(interlocuteurSousTraitanceId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/interlocuteurSousTraitance/delete`,
                {
                    interlocuteurSousTraitanceId: interlocuteurSousTraitanceId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static readInterlocuteurSousTraitance() {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/interlocuteurSousTraitance/read`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    //------------------------ Depense ------------------------------
    static createDepense(depense, file) {

        const form = new FormData();
        form.append(`file`, file);

        const {
            autreDepense,
            dateFacture,
            montantHT,
            montantHTT,
            nomSociete,
            numeroFacture,
            tauxTVA,
            type
        } = depense;

        form.append('autreDepense', autreDepense);
        form.append('dateFacture', dateFacture);
        form.append('montantHT', montantHT);
        form.append('montantHTT', montantHTT);
        form.append('nomSociete', nomSociete[1]);
        form.append('nomSocieteId', nomSociete[0]);
        form.append('numeroFacture', numeroFacture);
        form.append('tauxTVA', tauxTVA);
        form.append('type', type);

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/depences/create`, form,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        });
    }

    static readDepense() {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/depences/read`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // selectInterlocuteurSousTraitance
    static updateDepense(depense, file, depenseId) {
        const form = new FormData();
        form.append(`file`, file);
        form.append(`depenseId`, depenseId);

        const {
            autreDepense,
            dateFacture,
            montantHT,
            montantHTT,
            nomSociete,
            numeroFacture,
            tauxTVA,
            type,
            filename
        } = depense;

        form.append('autreDepense', autreDepense);
        form.append('dateFacture', dateFacture);
        form.append('montantHT', montantHT);
        form.append('montantHTT', montantHTT);
        form.append('nomSociete', nomSociete[1]);
        form.append('nomSocieteId', nomSociete[0]);
        form.append('numeroFacture', numeroFacture);
        form.append('tauxTVA', tauxTVA);
        form.append('type', type);
        form.append('filename', filename);

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/depences/update`, form,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        });
    }

    static selectDepense(depenseId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/depences/select`,
                {
                    depenseId: depenseId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static deleteDepense(filename) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/depences/delete`,
                {
                    filename: filename,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    // Display Absence
    static displayDepense(filename) {

        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/depences/display/${filename}`, {
                headers: this.headers,
                responseType: 'blob',
            })
                .then(response => {
                    resolve(window.open(URL.createObjectURL(response.data)));
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }


    //------------------------ Charge D' exploitation ------------------------------
    static createChargeExploitation(charge, file) {

        const form = new FormData();
        form.append(`file`, file);

        const {
            article,
            categorie,
            dateEcheance,
            dateFacture,
            modePaiement,
            montantHT,
            montantHTT,
            nomFournisseur,
            numeroFacture,
            paiement,
            tauxTVA,
            type
        } = charge;

        form.append('article', article);
        form.append('categorie', categorie);
        form.append('dateEcheance', dateEcheance);
        form.append('dateFacture', dateFacture);
        form.append('modePaiement', modePaiement);
        form.append('numeroFacture', numeroFacture);
        form.append('montantHT', montantHT);
        form.append('montantHTT', montantHTT);
        form.append('nomFournisseur', nomFournisseur[1]);
        form.append('nomFournisseurId', nomFournisseur[0]);
        form.append('paiement', paiement);
        form.append('tauxTVA', tauxTVA);
        form.append('type', type);

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/charges/create`, form,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        });
    }

    static readChargeExploitation() {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/charges/read`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // selectChargeExploitation
    static updateChargeExploitation(charge, file, chargeId, filename) {

        const form = new FormData();

        form.append(`file`, file);
        form.append(`chargeId`, chargeId);
        form.append(`filename`, filename);

        const {
            article,
            categorie,
            dateEcheance,
            dateFacture,
            modePaiement,
            montantHT,
            montantHTT,
            nomFournisseur,
            numeroFacture,
            paiement,
            tauxTVA,
            type
        } = charge;

        form.append('article', article);
        form.append('categorie', categorie);
        form.append('dateEcheance', dateEcheance);
        form.append('dateFacture', dateFacture);
        form.append('modePaiement', modePaiement);
        form.append('numeroFacture', numeroFacture);
        form.append('montantHT', montantHT);
        form.append('montantHTT', montantHTT);
        form.append('nomFournisseur', nomFournisseur[1]);
        form.append('nomFournisseurId', nomFournisseur[0]);
        form.append('paiement', paiement);
        form.append('tauxTVA', tauxTVA);
        form.append('type', type);

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/charges/update`, form,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        });
    }

    static selectChargeExploitation(chargeId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/charges/select`,
                {
                    chargeId: chargeId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static deleteChargeExploitation(filename) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/charges/delete`,
                {
                    filename: filename,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    // Display Absence
    static displayChargeExploitation(filename) {

        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/charges/display/${filename}`, {
                headers: this.headers,
                responseType: 'blob',
            })
                .then(response => {
                    resolve(window.open(URL.createObjectURL(response.data)));
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }

    //-------------------------- Statstique --------------------------------------/
    // get Indicateur Production By annee
    static countAffairesMois(annee) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/statistiques/countAffairesMois`,
                {
                    annee: annee
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // get Indicateur Inspecteurs chaque missions par mois by annee
    static IndicteurInspecteursMissionsMois(annee) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/statistiques/indicteurInspecteursMissionsMois`,
                {
                    annee: annee
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // get Indicateur Inspecteurs chaque affaires par mois by annee
    static indicteurAffairesMissions(annee) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/statistiques/indicteurAffairesMissions`,
                {
                    annee: annee
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // get Indicateur Inspecteurs chaque affaires par mois by annee
    static IndicteurProductionEtatMissionsAffaires(annee, mois) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/statistiques/indicteurProductionEtatMissionsAffaires`,
                {
                    annee: annee,
                    mois: mois
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // get Indicateur affaires chaque client par mois by annee
    static IndicteurAffairesClient(annee, mois) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/statistiques/indicteurAffairesClient`,
                {
                    annee: annee,
                    mois: mois
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // get Indicateur affaires chaque inspecteurs par mois by annee
    static IndicteurAffairesInspecteurs(annee, mois) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/statistiques/indicteurAffairesInspecteurs`,
                {
                    annee: annee,
                    mois: mois
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // get Indicateur Rendement Inspecteurs
    static indicteurEtatRendementInspecteurs(annee, mois) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/statistiques/indicteurEtatRendementInspecteurs`,
                {
                    annee: annee,
                    mois: mois
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // get Indicateur Rendement Inspecteurs
    static CalculRecrutement() {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/statistiques/calculRecrutement`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // get Indicateur claclul competence
    static CalculEtatCompetence() {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/statistiques/calculEtatCompetence`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // get Indicateur claclul competence
    static CalculEtatDepenser(selectYear, selectMois, selectInspecteur) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/statistiques/calculEtatDepenser`,
                {
                    year: selectYear,
                    mois: selectMois,
                    inspecteur: selectInspecteur
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // get Indicateur claclul competence
    static IndicteurApporteurAffaire(selectYear, selectMois) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/statistiques/indicteurApporteurAffaire`,
                {
                    year: selectYear,
                    mois: selectMois
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // get Indicateur Situation Financier Affaire
    static IndicteurSituationFinancierAffaire(selectYear, selectMois) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/statistiques/indicteurSituationFinancierAffaire`,
                {
                    year: selectYear,
                    mois: selectMois
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    // get Indicateur Avencment affaire
    static IndicteurAvancementFacture(selectYear, selectMois) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/statistiques/indicteurAvancementFacture`,
                {
                    year: selectYear,
                    mois: selectMois
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // get Indicateur financier factures
    static IndicteurSituationFinancierFacture(selectYear, selectMois) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/statistiques/indicteurSituationFinancierFacture`,
                {
                    year: selectYear,
                    mois: selectMois
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // get Indicateur Retard factures
    static IndicteurSituationRetardFacture(selectYear, selectMois) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/statistiques/indicteurSituationRetardFacture`,
                {
                    year: selectYear,
                    mois: selectMois
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // post reparer Interlocuteur Client
    static RepareInterlocuteurClient() {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/changes/repareInterlocuteurClient`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // ------------------------- class Offre emploi -------------------------
    static createOffreEmploi(titre, missions, description, qualifications, profils, dateLimiteCandidature, salaire, localisation, domaine, experience, typeTravail) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/offreEmplois/create`,
                {
                    titre: titre,
                    missions: missions,
                    description: description,
                    qualifications: qualifications,
                    profils: profils,
                    dateLimiteCandidature: dateLimiteCandidature,
                    salaire: salaire,
                    localisation: localisation,
                    domaine: domaine,
                    experience: experience,
                    typeTravail: typeTravail
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    static updateOffreEmploi(titre, missions, description, qualifications, profils, dateLimiteCandidature, salaire, localisation, domaine, experience, typeTravail, offreId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/offreEmplois/update`,
                {
                    titre: titre,
                    missions: missions,
                    description: description,
                    qualifications: qualifications,
                    profils: profils,
                    dateLimiteCandidature: dateLimiteCandidature,
                    salaire: salaire,
                    localisation: localisation,
                    domaine: domaine,
                    experience: experience,
                    typeTravail: typeTravail,
                    offreId: offreId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    static readOffres() {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/offreEmplois/read`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    static deleteOffre(offreId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/offreEmplois/delete`,
                {
                    offreId: offreId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    static getOffre(offreId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/offreEmplois/select`,
                {
                    offreId: offreId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    //------------------------- Actualites create -------------------------

    static createActualite(file, titre, auteur, introduction, developments, conclusion, refPicture) {

        const numberPr = ["one", "tow", "tree", "four", "five", "six", "seven", "eight", "nine", "ten"];
        const form = new FormData();

        form.append(`file`, file);
        form.append('titre', titre);
        form.append('auteur', auteur);
        form.append('introduction', introduction);
        form.append('conclusion', conclusion);
        form.append('refPicture', refPicture);

        developments.forEach((el, index) => {
            form.append(numberPr[index], el);
        });


        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/actualites/create`, form,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }

    static updateActualite(file, titre, auteur, introduction, developments, conclusion, refPicture, actualiteId) {

        const numberPr = ["one", "tow", "tree", "four", "five", "six", "seven", "eight", "nine", "ten"];

        const form = new FormData();

        form.append(`file`, file);
        form.append('titre', titre);
        form.append('auteur', auteur);
        form.append('introduction', introduction);
        form.append('conclusion', conclusion);
        form.append('refPicture', refPicture);
        form.append('actualiteId', actualiteId);

        developments.forEach((el, index) => {
            form.append(numberPr[index], el);
        });


        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/actualites/update`, form,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }



    static readActualites() {

        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/actualites/read`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }

    static deleteActualite(actualiteId, filename) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/actualites/delete`,
                {
                    actualiteId: actualiteId,
                    filename: filename
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }
    static selectActualite(actualiteId) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/actualites/select`,
                {
                    actualiteId: actualiteId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }

    //--------------------------------------------------------

    //----------------------------------------Prestation ---------------------------

    //------------------------- Actualites create -------------------------

    static createPrestation(file, refPicture, titre, description) {

        const form = new FormData();

        form.append(`file`, file);
        form.append('refPicture', refPicture);
        form.append('titre', titre);
        form.append('description', description);



        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/prestations/create`, form,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }


    static updatePrestation(file, refPicture, titre, description, prestationId) {


        const form = new FormData();

        form.append(`file`, file);
        form.append(`refPicture`, refPicture);
        form.append('titre', titre);
        form.append('description', description);
        form.append('prestationId', prestationId);


        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/prestations/update`, form,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }



    static readPrestations() {

        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/prestations/read`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }

    static deletePrestations(prestationId, filename) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/prestations/delete`,
                {
                    prestationId: prestationId,
                    filename: filename
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }
    static selectPrestation(prestationId) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/prestations/select`,
                {
                    prestationId: prestationId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }



    ///-------------------------------------------------------------------

    static envoyerStatisfactionClient(interlocuteurId) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/statisfactions/envoyer`,
                {
                    interlocuteurId: interlocuteurId
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    static generateStatisfactionClient(interlocuteurId) {
        return new Promise((resolve, reject) => {

            axios.get(`${VUE_APP_API_BASE_URL_DEV}/statisfactions/generate/${interlocuteurId}`,
                {
                    responseType: "arraybuffer"
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })

    }


    // static checkStatisfactionClient(email) {
    //     return new Promise((resolve, reject) => {

    //         axios.get(`${VUE_APP_API_BASE_URL_DEV}/statisfactions/check/${email}`, 
    //         { 
    //             responseType: "arraybuffer" 
    //         })
    //         .then(response => {
    //                 resolve(response);
    //         })
    //         .catch(error => {
    //                 reject(error);
    //         });
    //     })

    // }

    // get jours feries with year
    static selectJourFeries(year, yearPrecedent) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/feries/select`,
                {
                    year: year,
                    yearPrecedent: yearPrecedent
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }


    // get jours feries with year
    static createJourFeries(feries, year) {
        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/feries/create`,
                {
                    feries: feries,
                    year: year
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // -------------------------------------------- Start Documents ---------------------------------------------
    static DemandePoste() {

        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/documents/poste`,
                {
                    responseType: "arraybuffer"
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static ficheFonction() {

        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/documents/fonction`,
                {
                    responseType: "arraybuffer"
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static ficheCandidature() {

        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/documents/candidature`,
                {
                    responseType: "arraybuffer"
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static ficheIntegration() {

        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/documents/integration`,
                {
                    responseType: "arraybuffer"
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static recuperationGthconsult() {

        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/documents/recuperation`,
                {
                    responseType: "arraybuffer"
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }


    //-------------------------------------------- End Documents ------------------------------------------------


    // --------------------------------------------- Ticket -------------------------------------

    static createTicket(email, recepteur, titre, message) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/tickets/`, {
                email : email,
                recepteur : recepteur,
                titre : titre,
                message : message
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        })

    }

    static readtickets() {

        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/tickets`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });

    }

    // Delete Ticket
    static deleteTicket(filename) {

        return new Promise((resolve, reject) => {
            axios.delete(`${VUE_APP_API_BASE_URL_DEV}/tickets/${filename}`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    // checke ticket
    static chackeTicket(id, status) {

        return new Promise((resolve, reject) => {
            axios.put(`${VUE_APP_API_BASE_URL_DEV}/tickets/update`, 
                {
                    id : id,
                    status : status
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    static displayTicket(filename) {
        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/affaires/tickets/${filename}`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });

        })
    }


    //--------------------- Start Register -------------------------------
    static registerIp(ip) {

        return new Promise((resolve, reject) => {
            axios.post(`${VUE_APP_API_BASE_URL_DEV}/registers/create`, 
                {
                    ip : ip,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    //--------------------- Fin Register ---------------------------------



    //--------------------- Demande Achat -----------------------------
    static demandeAchat() {

        return new Promise((resolve, reject) => {
            axios.get(`${VUE_APP_API_BASE_URL_DEV}/documents/achat`,
                {
                    responseType: "arraybuffer"
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });

    }
    //----------------------Fin Achat----------------------------------




}


export default Service;

