import { render, staticRenderFns } from "./FixInterlocuteurClient.vue?vue&type=template&id=67761a8b&scoped=true&"
import script from "./FixInterlocuteurClient.vue?vue&type=script&lang=js&"
export * from "./FixInterlocuteurClient.vue?vue&type=script&lang=js&"
import style0 from "./FixInterlocuteurClient.vue?vue&type=style&index=0&id=67761a8b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67761a8b",
  null
  
)

export default component.exports