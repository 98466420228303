<template>
  <div class="affaires-missions-chart">
    <canvas id="myAffairesMissions"></canvas>
  </div>
</template>

<script>
import Service from "../../../../../../Service";
import Chart from "chart.js";
export default {

  name: "affaires-missions-chart",

  data() {
    return {
      annee : null,
    };
  },

  props: {
    selectYear : Number
  },

  methods: {},

  created() {
  },

  mounted() {

      // get info Indicateur by current
      Service.indicteurAffairesMissions(this.selectYear)
      .then((result) => {

        // instance Chart CTX
        const ctx = document.getElementById("myAffairesMissions");
        const myAffairesMissions = new Chart(ctx,  {
        type: 'bar',
        data: {
            datasets: [{
                label: 'Les affaires',
                data: result.data.bar,
                order: 2,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 68, 0, 0.3)'
            }, {
                label: 'Les missions',
                data: result.data.line,
                type: 'line',
                order: 1,
                fill: false,
                borderColor: 'rgb(54, 162, 235)'
            }],
            labels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]
          }
         });

          return myAffairesMissions;
      })
      .catch((error) => {
          console.log(error.message)
      });



  }


};
</script>

<style>
</style>