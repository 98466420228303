<template>
  <div class="etat-financier-chart">
    <h4>ÉTAT FINANCIER D'AFFAIRE</h4>
    <canvas id="myEtatFinancierAffaire"></canvas>
  </div>
</template>

<script>
import Service from "../../../../../Service";
import Chart from "chart.js";
export default {

  name: "affaire-financier-chart",

  data() {
    return {
      annee : null,
    };
  },

  props: {
    selectYear : Number,
    selectMois : Number
  },

  methods: {},

  created() {
  },

  mounted() {

      Service.IndicteurSituationFinancierAffaire(this.selectYear, this.selectMois)
        .then((result) => {


            const labels = [];
            const prix = [];
            const colors = [];

            result.data.forEach(element => {
              labels.push(element.name);
              prix.push(element.somme);
              const randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1));
              const a = randomBetween(0, 255);
              const b = randomBetween(0, 255);
              const c = randomBetween(0, 255);
              colors.push(`rgb(${a},${b},${c})`)
            });


            const data = {
              labels: labels,
              datasets: [{
                label: "Prix d'affaire",
                data: prix,
                backgroundColor: colors,
                borderWidth: 1
              }]
            };

            const config = {
                type: 'bar',
                data: data,
                options: {
                  scales: {
                    y: {
                      beginAtZero: true
                    }
                  }
                },
            };

            // instance Chart CTX
            const ctx = document.getElementById("myEtatFinancierAffaire");
            const myEtatFinancierAffaire = new Chart(ctx, config);

            return myEtatFinancierAffaire;

        })
        .catch((error) => {
          console.log(error.message)
        });

  }


};
</script>

<style scoped>

  .etat-financier-chart {
    max-height: fit-content;
  }

  .etat-financier-chart > h4 {
      width: 100%;
      justify-content: center;
      display: flex;
      color: #1e1ed3;
      font-size: x-large;
      opacity: 0.6;
      font-weight: 500;
      margin-bottom: 10px;
      margin-top: 20px;
  }
  
</style>