<template>
  <div class="creationClient">

    <h3><i class="fa-solid fa-person-circle-plus"></i> CRÉATION D'UN CLIENT</h3>

    <div class="formCreation">

      <div>
        <label for="nom">Raison sociale / Nom</label>
        <input type="text" v-model="client.raisonSocial">
      </div>

      <div>
        <label for="adresse">Adresse</label>
        <input type="text" v-model="client.adresse">
      </div>

      <div>
        <label for="ville">Ville</label>
        <input type="text" v-model="client.ville">
      </div>

      <div>
        <label for="codePostal">Code Postal</label>
        <input type="text" v-model="client.codePostal">
      </div>

      <div>
        <label for="pays">Pays</label>
        <input type="text" v-model="client.pays">
      </div>

      <div>
        <label for="nom">E-mail de Raison Sociale ou E-mail Interlocuteur</label>
        <input type="text" v-model="client.email">
      </div>



      <div>
        <label for="nom">ICE</label>
        <input type="text" v-model="client.ice">
      </div>

      <div>
        <label for="nom">Référence client</label>
        <input type="text" disabled v-model="client.refClient">
      </div>

      <div>
        <label for="téléphone">Téléphone de Raison sociale / Téléphone Interlocuteur</label>
        <input type="text" v-model="client.telephone">
      </div>


      <div>
        <label for="Date d'entrée">Date d'entrée</label>
        <input type="date" v-model="client.dateEntree">
      </div>

      <div>
        <input type="submit" value="Créer un client" @click="create()">
      </div>

      <div>
        <input type="submit" value="Quitter" @click="quitter()">
      </div>

    </div>

    <!-- Modals Error and landing -->
    <ModalLanding v-if="flagModalLanding" :message="message" />
    <ModalErrors v-if="flagModalErrors" :errors="errors" />

  </div>

</template>

<script>

import Service from "../../../../Service";
import ModalLanding from "@/views/components/ModalLanding.vue"
import ModalErrors from "@/views/components/ModalErrors.vue"

export default {
  data() {
    return {
      flagModalLanding : false,
      flagModalErrors : false,
      message : null,
      errors : [],
      client: {
              raisonSocial : null,
              adresse : null,
              ville : null,
              codePostal : null,
              pays : null,
              email : null,
              ice : null,
              refClient : null,
              telephone : null,
              dateEntree : null
      }
    };
  },
 components: {
    ModalLanding,
    ModalErrors
 },
  methods: {

    // function finish Modal for delete landing or errors after message (init)
    finishModal() {
        setTimeout(() => {
            this.flagModalLanding = false;
            this.flagModalErrors = false;
            this.errors = [];
            this.message = null;
        }, 8000);
    },

    //crete Client
    create() {
          if(this.client.raisonSocial === null || this.client.raisonSocial === "" || this.client.adresse === null || this.client.adresse === "" || this.client.ville === null || this.client.ville === "" || this.client.codePostal === null || this.client.codePostal === "" || this.client.pays === null || this.client.pays === "" || this.client.email === null || this.client.email === "" || this.client.ice === null || this.client.ice === "" || this.client.refClient === null || this.client.refClient === "" || this.client.telephone === null || this.client.telephone === "" || this.client.dateEntree === null || this.client.dateEntree === "") {
            this.flagModalErrors = true;
            this.errors.push("Veuillez saisir tout les champs");
            this.finishModal();
          } else {

                // handel fix Inputs
                if(this.fixInputs(this.client)) {

                    // send all inputs to serve for create
                    Service.createClient(this.client)
                    .then((response) => {

                            // echec response
                            if(response.data.status === false) {
                              this.flagModalError = true;
                              this.errors.push(response.data.msg)
                              this.finishModal();
                            }

                            // succes response
                            if(response.data.status === true) {

                                  // handel landing Modal
                                  this.flagModalLanding = true;
                                  this.message = response.data.msg;

                                  // for empty all inputs
                                  this.client.adresse = null;
                                  this.client.codePostal = null; 
                                  this.client.email = null;
                                  this.client.ice = null;
                                  this.client.pays = null;
                                  this.client.raisonSocial = null;
                                  this.client.refClient = null;
                                  this.client.telephone = null;
                                  this.client.ville = null;
                                  this.client.dateEntree = null;

                                  //for delete landing after succes
                                  this.finishModal();
                                  // Re-generate Reference Client 
                                  this.generateRefClient();
                            }

                    })
                    .catch((error) => {
                      this.flagModalError = true;
                      this.errors.push(error.message);
                    });
                }
          }

    },

    // for quitter page
    quitter() {
      return this.$router.go("/");
    },

    // for Fix inputs -> delete spaces end & start
    fixInputs(inputs) {

      for (const key in inputs) {
        // delete space in start
        const fixInputStart = String(inputs[key]).trimStart();
        // delete space in end
        const fixInputEnd = String(fixInputStart).trimEnd();
        // fix all probleme
        this.client[key] = fixInputEnd;
      }    

      return true;
    },

    // generate Reference Client
    generateRefClient() {
      // get refernce last client
      Service.getRefClient()
      .then((result) => {

        var today = new Date();
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        
        if(result.data.clients == 0)
        {
          const count = 0;
          this.client.refClient = 'C' + parseInt(count) + '|'+ mm + '|' + yyyy;

        } else {

          const count = result.data.clients[0].refClient.slice(
                      result.data.clients[0].refClient.indexOf('C') + 1,
                      result.data.clients[0].refClient.lastIndexOf('|'),
          );

            this.client.refClient = 'C' + (parseInt(count) + 1) + '|'+ mm + '|' + yyyy;
        }

      })
      .catch((error) => {
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
      });
    }



  },

  created(){

    // handel function for generate new Reference Client
    this.generateRefClient()


  }

};
</script>

<style scoped>
.creationClient {
  width: 100%;
  padding:5px;
}

.creationClient h3 {
  width: 100%;
  margin:auto;
  color: white;
  background: linear-gradient(346deg, rgba(207,31,33,1) 0%, rgba(24,86,161,1) 100%);    text-align: center;
  text-align: center;


}
.succes {
  background-color: #69cd5b;
  color: white;
  padding: 10px;
  width: 100%;
  height: fit-content;
}

.echec {
  background-color: RED;
  color: white;
  padding: 10px;
  width: 100%;
  height: fit-content;
}

.formCreation {
  padding:20px 0;
  width: 100%;

  display: flex;
  flex-direction: row;

  justify-content: space-between;
  flex-wrap: wrap;

}

.formCreation div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 48%;
}
.formCreation div label {

  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
  color :#494949;
}
.formCreation div input {
  height: 40px;

  margin-bottom: 5px;
  border: 1px solid #243064;
  padding:5px;
}.formCreation div input:focus-within {
  outline: 1px solid #cf1f21 ;
  border:0;

}

.formCreation div select {
  height: 40px;
  margin-left:10px;
}

.formCreation div:nth-child(11) input{
    background-color: green;
    color: white;
    border: 0;
    margin-top: 30px;
    cursor: pointer;
}
.formCreation div:nth-child(12) input {
    background-color: red;
    color: white;
    border: 0;
    margin-top: 30px;
    cursor: pointer;
}



#app > div > div > div.menu-content > div.content > div {
  background-color: white;
}

#app > div > div > div.menu-content > div.content > div > h3 {
    padding: 15px;
}

#app > div > div > div.menu-content > div.content > div > div > div > label {
  font-size: 15px;
}



</style>