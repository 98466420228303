<template>
  <div class="creationClient">

    <h3>RECHERCHE UNE RAPPORT PAR NUMERO D'AFFAIRE</h3>



    <div class="formCreation">

      <div>
        <label for="Numéro Affaire">Numéro Affaire</label>
        <input type="text" v-model="numeroAffaire">
      </div>

      <!-- <div>
        <label for="Nom de fichier">Nom de fichier</label>
        <input type="text" v-model="rapport.filename">
      </div> -->

      <div>
        <p>Affaire Id :  {{ affaire._id }}</p>
        <p>Apporteur D'affaire :  {{ affaire.apporteurAffaire }}</p>
        <p>ID de Client :  {{ affaire.clientId }}</p>
        <p>ID de Interlocuteur :  {{ affaire.interlocuteurId }}</p>
        <p>Nom de fichier Intervention :  {{ affaire.renseignerIntervention }}</p>
        <p>Nom de fichier Bon Commande :  {{ affaire.bonCommande }}</p>
        <p>Numéro de fichier Bon Commande :  {{ affaire.numeroBonCommande }}</p>
        <p>Nom de fichier Devis :  {{ affaire.nameFileAffaire }}</p>
        <p>Nom de fichier Bordereau d'envoi :  {{ affaire.be }}</p>
        <p>Date de fichier Bordereau d'envoi :  {{ new Date(affaire.dateBE).toLocaleDateString() }}</p>
        <p>Nom de fichier Facture :  {{ affaire.facture }}</p>
        <p>Date de fichier Facture :  {{ new Date(affaire.dateFacture).toLocaleDateString() }}</p>
        <p>Date de fichier Emise :  {{ affaire.emise }}</p>
        <p>Etat d'import Rapport :  {{ affaire.importRapport }}</p>
        <p> 
            <ul>
                <li>Reference Rapport :</li>
            </ul>
            <ul  v-for="(item, index) in affaire.refRapport" :key="index">
                <li>{{ item }} </li>
            </ul>
        </p>
        <p> 
            <ul>
                <li>Etat Envoyer Rapport :</li>
            </ul>
            <ul  v-for="(item, index) in affaire.sendRapport" :key="index">
                <li>{{ item }} </li>
            </ul>
        </p>

        <p>Etat d'Envoyer Rapport :  {{ new Date(affaire.dateSendRapport).toLocaleDateString() }} </p>
        <p>Paiement :  {{ affaire.Paiement }} </p>

      </div>

      <div>
        <input type="submit" value="Recherche Affaire" @click="recherche()">
      </div>

      <div>
        <input type="submit" value="Quitter" @click="quitter()">
      </div>
      
      <div>
        <input type="submit" value="Supprimer Affaire" @click="supprimer()">
      </div>


    </div>

    <!--  Start Info Delete Model   -->
      <DeleteModel v-if="flagModelDelete == true" :text="textDeleteModel" @closeDeleteModel="closeDeleteModel()" @validerDeleteModel="validerDeleteModel()" />
    <!--  End Info Delete Model   -->

  </div>
</template>

<script>
import DeleteModel from "../../../../views/components/DeleteModel.vue";
import Service from '../../../../Service';
export default {
  data() {
    return {
      flagModelDelete : false,
      textDeleteModel : null,
      traitement : false,
      matricule : null,
      msgAffaire : null,
      affaire : {
        _id : null,
      },
      numeroAffaire : null

    };
  },


  components : {
    DeleteModel
  },

  computed : {
  },

  methods: {

    recherche() {
        Service.getAffaireByNumeroAffaire(this.numeroAffaire)
          .then((result) => {
             this.affaire = result.data.affaire;
          })
          .catch((error) => {
              console.log(error)
        });
    },

    display(filename) {
        Service.displayRapport(filename)
          .then((result) => {
            console.log(result);
          })
          .catch((error) => {
              console.log(error)
        });
    },

    closeDeleteModel() {
      this.flagModelDelete = false;
    },

    validerDeleteModel() {
      this.flagModelDelete = false;
      Service.deleteAffaire(this.affaire._id)
        .then((result) => {
            if(result) {
                this.affaire._id = null;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    },

    supprimer() {
        this.flagModelDelete = true;
        this.textDeleteModel = "Êtes-vous sûr de supprimer ?";
    }

  },

  created() {

      if(!sessionStorage.getItem("token"))
      {
        this.$router.push("/");
      } 

  }

}
</script>

<style scoped>


.creationClient {
  width: 100%;
  padding:5px;

}

.creationClient h3 {
  width: 100%;
  margin:auto;
  color: white;
  background: linear-gradient(346deg, rgba(207,31,33,1) 0%, rgba(24,86,161,1) 100%);    text-align: center;
  text-align: center;


}
.succes {
  background-color: #69cd5b;
  color: white;
  padding: 10px;
  width: 100%;
  height: fit-content;
}

.echec {
  background-color: RED;
  color: white;
  padding: 10px;
  width: 100%;
  height: fit-content;
}

.formCreation {
  padding:20px 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1,1fr);
  justify-items: center;
  align-items: center;


}

.formCreation div {
  display: flex;
  flex-direction: column;
  width: 60%;
}
.formCreation div label {
  margin-left:10px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
  color :#494949;
}
.formCreation div input,textarea {
  height: 40px;
  margin-left:10px;
  margin-bottom: 5px;
  border: 1px solid #243064;

}

.formCreation div select {
  height: 40px;
  margin-left:10px;
}

.formCreation div:nth-child(10) input{
  cursor: pointer;
}

.formCreation div:nth-child(26) input{
    background-color: green;
    color: white;
    border: 0;
    margin-top: 30px;
    cursor: pointer;
}

.formCreation div:nth-child(27) input {
    background-color: red;
    color: white;
    border: 0;
    margin-top: 30px;
    cursor: pointer;
}

.formCreation div:nth-child(28) input {
    background-color: blue;
    color: white;
    border: 0;
    margin-top: 30px;
    cursor: pointer;
}



#app > div > div > div.menu-content > div.content > div {
  background-color: white;
}

#app > div > div > div.menu-content > div.content > div > h3 {
    padding: 15px;
}

#app > div > div > div.menu-content > div.content > div > div > div > label {
  font-size: 15px;
}



.form-control {
    display: block;

    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.form-control::-webkit-file-upload-button {
    padding: 10px;
    margin: -.375rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end: .75rem;
    color: rgb(58, 58, 58);
    font-weight: 600;
    background-color: #e9e9e9;
    pointer-events: none;
    border:0;

    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}
.warningjrs{
  background-color: #ffc6c6;
  width: 100%;
  margin:10px 9px;
  padding:10px;
  border-radius: 10px;
  display: flex;
  color:red;
  align-items: center;
  font-weight: 600;
}

.warningjrs svg{
  margin-right: 5px;
}

#app > div > div > div.menu-content > div.content > div > div > div:nth-child(3) > input[type=submit] {
  background-color: green;
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  border: 0px;
}

#app > div > div > div.menu-content > div.content > div > div > div:nth-child(4) > input[type=submit] {
  background-color: blue;
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  border: 0px;
}

#app > div > div > div.menu-content > div.content > div > div > div:nth-child(5) > input[type=submit] {
  background-color: red;
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  border: 0px;
}

</style>

