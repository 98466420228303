<template>

  <div class="creationClient">

    <h3><i class="fa-solid fa-circle-info"></i> Information d'offre</h3>

    <h3 class="titre-client">Client :</h3>


    <div class="formCreation">


      <div>
        <label for="adresse">Raison sociale / Nom</label>
        <select v-model="client._id" @change="selectClient" :disabled="falgRaisonSocial">
          <option v-for="(client, index) in clients" :key="index" :value="client._id">{{ client.raisonSocial }}</option>
        </select>
      </div>

      <div>
        <label for="adresse">Adresse</label>
        <input type="text" disabled v-model="client.adresse">
      </div>

      <div>
        <label for="ville">Ville</label>
        <input type="text" disabled v-model="client.ville">
      </div>

      <div>
        <label for="codePostal">Code Postal</label>
        <input type="text" disabled v-model="client.codePostal">
      </div>

      <div>
        <label for="pays">Pays</label>
        <input type="text" disabled  v-model="client.pays">
      </div>

      <div>
        <label for="nom">E-mail</label>
        <input type="text" disabled v-model="client.email">
      </div>

      <div>
        <label for="nom">ICE</label>
        <input type="text" disabled v-model="client.ice">
      </div>

      <div>
        <label for="nom">Référence client</label>
        <input type="text" disabled v-model="client.refClient">
      </div>

      <div>
        <label for=""></label>
        <input type="hidden" v-model="client._id">
      </div>

      <div>
        <label for=""></label>
        <input type="hidden">
      </div>

      <div>
        <label for="">-{{ msgChangeDate }}</label>
        <input type="button" :value="valueClient" @click="changeInfoClient">
      </div>

    </div>

    <h3 class="titre-interlocuteur">Interlocuteur</h3>


    <div class="formCreation">

        <div>
            <label for="nom">Les interlocuteurs</label>
            <select v-model="interlocuteur._id" @change="selectInterlocuteur" :disabled="falgInterlocuteur">
              <option v-for="interlocuteur in interlocuteurs" :key="interlocuteur._id" :value="interlocuteur._id" > {{ interlocuteur.nom + " "+interlocuteur.prenom }}</option>
            </select>
        </div>

        <div>
            <label for="nom">Nom</label>
            <input type="text" disabled v-model="interlocuteur.nom">
        </div>

        <div>
            <label for="adresse">Prénom </label>
            <input type="text" disabled v-model="interlocuteur.prenom">
        </div>

        <div>
            <label for="ville">E-mail</label>
            <input type="text" disabled v-model="interlocuteur.email">
        </div>

        <div>
            <label for="codePostal">Fonction</label>
            <input type="text" disabled v-model="interlocuteur.fonction">
        </div>

        <div>
            <label for="pays">Téléphone</label>
            <input type="text" disabled v-model="interlocuteur.telephone">
        </div>

        <div>
            <label for="_id"></label>
            <input disabled type="hidden" v-model="interlocuteur._id">
        </div>

        <div>
            <label for="clientId"></label>
            <input disabled type="hidden" v-model="interlocuteur.clientId">
        </div>

        <div>
          <label for="">---------</label>
          <input type="button" :value="valueInterlocuteur" @click="changeInfoInterlocuteur">
        </div>

    </div>


    <h3  class="titre-interlocuteur">Numéro d'offre & Apporteur d'offre & Date d'offre</h3>

    <div class="formCreation">

      <div>
        <label for="Numéro d'offre">Numéro d'offre</label>
        <input type="text" :disabled="flagApporteurNumeroAffaireDateAffaire" v-model="numeroAffaire">
      </div>

      <div>
        <label for="Apporteur d'offre" >Apporteur d'offre</label>
        <select v-model="apporteurAffaire" :disabled="flagApporteurNumeroAffaireDateAffaire">
          <option v-for="item in apparteurAffaires" :value="item.name" :key="item.name" >{{ item.name }}</option>
        </select>
      </div>

      <div>
        <label for="Date d'offre">Date d'offre</label>
        <input type="date" :disabled="flagApporteurNumeroAffaireDateAffaire" v-model="dateAffaire">
      </div>

      <div>
        <label for="">---------</label>
        <input type="button" :value="valueApporteurNumeroAffaireDateAffaire" @click="changeApporteurNumeroAffaireDateAffaire">
      </div>

    </div>

    <h3  class="titre-interlocuteur">Mission(s)</h3>

      <div class="formCreation missionsec" v-for="(mission, i) in missions" :key="i">

            <div>
              <label for="Type de Mission">Type de Mission</label>
              <!-- <input type="text"  :value="mission.typeMission"> -->
              <select v-model="missions[i].typeMission" @change="selectMission($event, i)">

                  <optgroup label="Installations électriques">
                    <option v-for="mission in ArrayTypeMissionIE" :key="mission.name" :value="mission.prev+'_'+mission.type+'_'+mission.name+'_'+mission.categorie"> {{ mission.name }}</option>
                  </optgroup>

                  <optgroup label="Appareil et accessoires levage">
                    <option v-for="mission in ArrayTypeMissionAAL" :key="mission.name" :value="mission.prev+'_'+mission.type+'_'+mission.name+'_'+mission.categorie"> {{ mission.name }}</option>
                  </optgroup>


                  <optgroup label="Installation d’ascenseur">
                    <option v-for="mission in ArrayTypeMissionID" :key="mission.name" :value="mission.prev+'_'+mission.type+'_'+mission.name+'_'+mission.categorie"> {{ mission.name }}</option>
                  </optgroup>

                  <optgroup label="Dispositif incendie">
                    <option v-for="mission in ArrayTypeMissionDI" :key="mission.name" :value="mission.prev+'_'+mission.type+'_'+mission.name+'_'+mission.categorie"> {{ mission.name }}</option>
                  </optgroup>

                  <optgroup label="Appareils à pression de gaz et vapeur">
                    <option v-for="mission in ArrayTypeMissionAPGV" :key="mission.name" :value="mission.prev+'_'+mission.type+'_'+mission.name+'_'+mission.categorie"> {{ mission.name }}</option>
                  </optgroup>

                  <optgroup label="Contrôle machines et engins de chantiers">
                    <option v-for="mission in ArrayTypeMissionCMEC" :key="mission.name" :value="mission.prev+'_'+mission.type+'_'+mission.name+'_'+mission.categorie"> {{ mission.name }}</option>
                  </optgroup>

                  <optgroup label="Contrôles risques au travail">
                    <option v-for="mission in ArrayTypeMissionCRT" :key="mission.name" :value="mission.prev+'_'+mission.type+'_'+mission.name+'_'+mission.categorie"> {{ mission.name }}</option>
                  </optgroup>

                  <optgroup label="Environnement">
                    <option v-for="mission in ArrayTypeMissionENV" :key="mission.name" :value="mission.prev+'_'+mission.type+'_'+mission.name+'_'+mission.categorie"> {{ mission.name }}</option>
                  </optgroup>

                  <optgroup label="Formations réglementaires incendie">
                    <option v-for="mission in ArrayTypeMissionI" :key="mission.name" :value="mission.prev+'_'+mission.type+'_'+mission.name+'_'+mission.categorie"> {{ mission.name }}</option>
                  </optgroup>

                  <optgroup label="Formations réglementaires electricité">
                    <option v-for="mission in ArrayTypeMissionE" :key="mission.name" :value="mission.prev+'_'+mission.type+'_'+mission.name+'_'+mission.categorie"> {{ mission.name }}</option>
                  </optgroup>
                  <option value="Autre"> Autre </option>
                </select>
            </div>

            <div v-if="flagAutreMission">
              <label for="Type de Mission">Type de Mission</label>
              <input type="text" v-model="missions[i].typeMission">
            </div>

            <div v-if="flagAutreMission">
              <label for="" ></label>
              <input type="hidden">
            </div>

            <div>
              <label for="Appareil et accessoir de levage" >Code Mission</label>
              <input type="text" v-model="missions[i].codeMission">
            </div>


            <div>
              <label for="Appareil et accessoir de levage" >Prix</label>
              <input type="text" v-model="missions[i].prix">
            </div>

            <div>
              <label for="Devis">Devis</label>
                <select v-model="missions[i].devis">
                    <option v-for="devis in ArrayDevis" :key="devis.code" :value="devis.code"> {{ devis.name }}</option>
                </select>
            </div>

            <div class="equipement">

                  <table v-for="(value, index) in mission.equipement" :key="index">

                    <tr>
                      <th></th>
                      <th>Référence Rapports (Equipement): {{ missions[i].equipement[index].titre }}</th>
                    </tr>

                    <tr>
                      <td>
                        <input type="text" v-model="missions[i].equipement[index].titre">
                        <button class="supEq" @click="supEquipement(i, index)">Supprimer</button>
                      </td>
                      <td>
                          <input type="text" v-for="(ref, ind) in value.refRapport" :key="ind" v-model="missions[i].equipement[index].refRapport[ind]">
                          <button class="addRef" @click="addRefRapport(i, index)">Ajouter une reference rapport</button>
                          <button class="delRef" @click="deleteRefRapport(i, index)">Supprimer une reference rapport</button>
                      </td>
                    </tr>
                  </table>

                  <div>
                    <button class="addEq" @click="addEquipement(i)">Ajouter Equipement</button>
                    <button class="delEq" @click="deleteEquipement(i)">Supprimer Equipement</button>
                  </div>

            </div>

            <div>
              <button class="updateMission" @click="changeMissionEquipement(mission._id ,i)">Changer Mission</button>
              <button class="supMission" @click="supprimerMissionEquipement(mission._id, i)">Supprimer Mission</button>
            </div>
      </div>

      <h3 class="titre-interlocuteur">Devis</h3>
      <div class="formCreation">
        <input  type="file" multiple="multiple" class="form-control" placeholder="Fichier PDF" ref="file" @change="previewFile"/>
        <button class="updateMission" @click="updateDevis()">Change Devis</button>
      </div>

      <h3 class="titre-interlocuteur">Intervention</h3>
      <div class="formCreation">
        <button class="updateMission" @click="deleteIntervention()">Supprimer Intervention</button>
      </div>

      <h3 class="titre-interlocuteur">Bon de Commande</h3>
      <div class="formCreation">
        <button class="updateMission" @click="deleteBonCammande()">Supprimer Le Bon de Commande</button>
      </div>




  </div>
</template>

<script>
import Service from '../../../../Service';

export default {
  data() {
    return {
        flagAutreMission : false,
        valueClient : "change",
        valueInterlocuteur : "change",
        valueApporteurNumeroAffaireDateAffaire : "change",
        valueMissionEquipement : "change",
        msgChangeDate : null,
        idAffaire : null,
        apporteurAffaire : null,
        numeroAffaire : null,
        oldNumeroAffaire : null,
        dateAffaire : null,
        falgRaisonSocial : true,
        falgInterlocuteur : true,
        flagApporteurNumeroAffaireDateAffaire : true,
        flagMissionEquipement : true,
        tableDisabledRefRapport : [],
        clients : [],
        client : {
            raisonSocial : null,
            adresse: null,
            ville: null,
            codePostal: null,
            pays: null,
            email: null,
            ice: null,
            refClient: null,
            _id: null
        },
        interlocuteurs : [],
        interlocuteur : {
            nom : null,
            prenom : null,
            email : null,
            fonction : null,
            telephone : null,
            _id : null,
            clientId :null
        },
        mission : {
          codeMission : null,
          typeMission : null,
          typeRapport : null,
          categorie : null
        },
        apparteurAffaires : [],
        missions: [],

            ArrayTypeMissionIE : [
                  { prev : "ELEC-VRI" , name : "Vérification réglementaire initial d’installation électrique", type	 : "Installations électriques"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "ELEC-VRP" , name : "Vérification réglementaire périodique d’installation électrique",type : "Installations électriques"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "ELEC-VRPPV" , name : "Vérification réglementaire périodique d’installation photovoltaique", type : "Installations électriques"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "ELEC-VRPPP" , name : "Vérification réglementaire périodique d’installation parafoudre - paratonnere",type : "Installations électriques"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "ELEC-VLRVR" , name : "Vérification  de levée des réserves suite vérification  réglementaire d’installation électrique", type : "Installations électriques"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "ELEC-VTH" , name : "Vérification d'installation électrique par thermographie infrarouge sans Q19", type : "Installations électriques"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "ELEC-VTHQ19" , name : "Vérification d'installation électrique par thermographie infrarouge avec Q19",type : "Installations électriques"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "ELEC-VAR" , name : "Vérification d'installation électrique par analyseur de réseau",type : "Installations électriques"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "ELEC-D" , name : "Diagnostique d’une installation électrique",type : "Installations électriques"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "ELEC-AT" , name : "Assistance technique installation électrique",type : "Installations électriques"  ,categorie : "Contrôle réglementations installations techniques" }
                ],

            ArrayTypeMissionAAL : [
                  { prev : "LEV-VRMRS" , name : "Vérification réglementaire de mise ou remise en service d’appareils et accessoires de levage", type : "Appareil et accessoires levage"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "LEV-VRP" , name : "Vérification réglementaire périodique d’appareils et accessoires de levage", type : "Appareil et accessoires levage"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "LEV-VLRVR" , name : "Vérification de levée des réserves suite Vérification réglementaire d’appareils et accessoires de levage", type : "Appareil et accessoires levage"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "LEV-D" , name : "Diagnostique d’appareils et accessoires de levage", type : "Appareil et accessoires levage"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "VET-QN" , name : "Vérification d'un équipement de travail - Quais niveleurs", type : "Equipement de travail"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "VET-PP" , name : "Vérification d'un équipement de travail - Portes et portail", type : "Equipement de travail"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "VET-EPI" , name : "Vérification d'un équipement de travail - Equipement de Protection Individuel", type : "Equipement de travail"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "VET-RY" , name : "Vérification d'un équipement de travail - Rayonnage", type : "Equipement de travail"  ,categorie : "Contrôle réglementations installations techniques" }
                ],

            ArrayTypeMissionID : [
               { prev : "ASC-VDT" , name : "Vérification du dossier technique d’une installation d’ascenseur ou monte-charge accompagné", type : "Installation d’ascenseur"  ,categorie : "Contrôle réglementations installations techniques" },
               { prev : "ASC- STI" , name : "Suivi des travaux d’installation d’ascenseur ou monte-charge accompagné",type : "Installation d’ascenseur"  ,categorie : "Contrôle réglementations installations techniques" },
               { prev : "ASC-VMRS" , name : "Vérification réglementaire de mise ou remise en service d’une installation d’ascenseur ou monte-charge accompagné",type : "Installation d’ascenseur"  ,categorie : "Contrôle réglementations installations techniques" },
               { prev : "ASC-VRP" , name : "Vérification réglementaire périodique d’une installation d’ascenseurs ou monte-charge accompagné",type : "Installation d’ascenseur"  ,categorie : "Contrôle réglementations installations techniques" },
               { prev : "ASC-VLRVR" , name : "Vérification  de levé de reserves suite vérification réglementaire périodique d’une installation d’ascenseurs ou monte-charge accompagné",type : "Installation d’ascenseur"  ,categorie : "Contrôle réglementations installations techniques" },
               { prev : "ASC-AST" , name : "Assistance technique ascenseur",type : "Installation d’ascenseur"  ,categorie : "Contrôle réglementations installations techniques" },
               { prev : "ASC-D" , name : "Diagnostique ascenseur", type : "Installation d’ascenseur"  ,categorie : "Contrôle réglementations installations techniques" },
            ],

            ArrayTypeMissionDI : [
                  { prev : "INC- VTSSI" , name : "Vérification  triennale d'un Système de Sécurité Incendie" ,type : "Dispositif incendie" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "INC- VPSSI" , name : "Vérification  périodique d'un Système de Sécurité Incendie" ,type : "Dispositif incendie" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "INC- VSDF" , name : "Vérification  du système de désenfumage" ,type : "Dispositif incendie" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "INC- VMSS" , name : "Vérification  des moyens de secours simples" ,type : "Dispositif incendie" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "INC- VGC" , name : "Vérification  des appareils de cuisson et de remise en températures" ,type : "Dispositif incendie" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "INC- AUD" , name : "Audit de mise ou remise en conformité réglementaire des dispositifs incendie" ,type : "Dispositif incendie" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "INC- DDI" , name : "Diagnostique des dispositifs incendie" ,type : "Dispositif incendie" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "INC- AT" , name : "Assistance technique dispositif incendie" ,type : "Dispositif incendie" ,categorie : "Contrôle réglementations installations techniques" },
            ],

            ArrayTypeMissionAPGV : [
                  { prev : "PRS-VQRQ" , name : "Vérification  d'un appreil à pression gas/vapeur pour qualification / requalification " ,type : "Appareils à pression de gaz et vapeur" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "PRS-VRP" , name : "Vérification  réglementaire d'un appreil à pression gas/vapeur" ,type : "Appareils à pression de gaz et vapeur" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "PRS-D" , name : "Diagnostique d'un appreil à pression gas/vapeur" ,type : "Appareils à pression de gaz et vapeur" ,categorie : "Contrôle réglementations installations techniques" },
            ],

            ArrayTypeMissionCMEC : [
                  { prev : "MAC-VGPT" , name : "Vérification Générale Périodique Trimestriel machinee" ,type : "Vérification machines et engins de chantiers" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "MAC-VGPA" , name : "Vérification Générale Périodique Annuelle machiner" ,type : "Vérification machines et engins de chantiers" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "MAC-VCCE" , name : "Vérification de la conformité CE machine" ,type : "Vérification machines et engins de chantiers" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "MAC-AR" , name : "Analyses des risque d'une machine" ,type : "Vérification machines et engins de chantiers" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "MAC-D" , name : "Diagnostique d'un appreil à pression gas/vapeur" ,type : "Vérification machines et engins de chantiers" ,categorie : "Contrôle réglementations installations techniques" },
            ],

            ArrayTypeMissionCRT : [
                  { prev : "BRUIT-VDBD" , name : "Vérification  des doses de bruits absorbées par les travailleurs par dosimétrie" , type : "Vérification des risques au travail" ,categorie : "Contrôle des risques travailleur" },
                  { prev : "BRUIT-VDBCT" , name : "Vérification  des doses de bruits absorbées par les travailleurs code du travail" , type : "Vérification des risques au travail" ,categorie : "Contrôle des risques travailleur" },
                  { prev : "BRUIT-CRT" , name : "Elaboration de la cartographie bruit" ,type : "Vérification des risques au travail" ,categorie : "Contrôle des risques travailleur" },
                  { prev : "ECRM-MTE" , name : "Mesures des taux d’éclairement au poste du travail" , type : "Vérification des risques au travail" , categorie : "Contrôle des risques travailleur" },
                  { prev : "AIR-MQA" , name : "Mesure de la qualité de l’air intérieur" , type : "Vérification des risques au travail" , categorie : "Contrôle des risques travailleur" },
                  { prev : "EAU-POT" , name : "Analyse de la potabilité de l’eau" , type : "Vérification des risques au travail" , categorie : "Contrôle des risques travailleur" },
                  { prev : "EAU-LEG" , name : "Analyse des légionnelles" , type : "Vérification des risques au travail" , categorie : "Contrôle des risques travailleur" },
            ],

            ArrayTypeMissionENV : [
                  { prev : "ENVIR-ARL" , name : "Analyse des rejets liquides" ,type : "Environnement" ,categorie : "Contrôle environnementaux" },
                  { prev : "ENVIR-ARAT" , name : "Analyse des rejets atmosphérique" ,type : "Environnement" ,categorie : "Contrôle environnementaux" },
                  { prev : "ENVIR-MNS" , name : "Mesures se la nuisance sonore" ,type : "Environnement" ,categorie : "Contrôle environnementaux" },
                  { prev : "ENVIR-AQA" , name : "Analyse de la qualité de l’air environnant" ,type : "Environnement" ,categorie : "Contrôle environnementaux" }
            ],

            ArrayTypeMissionI : [

                  { prev : "EVAC-INC" , name : "Evacuation : guide-files et serre-files" ,type : "Formations réglementaires incendie" ,categorie : "Formation reglementaires" },
                  { prev : "EPI-INC" , name : "Equipier Première Intervention" ,type : "Formations réglementaires incendie" ,categorie : "Formation reglementaires" },
                  { prev : "ESI-INC" , name : "Equipier Seconde Intervention" ,type : "Formations réglementaires incendie" ,categorie : "Formation reglementaires" },
                  { prev : "SST-INC" , name : "Sauveteur Secouriste du Travail" ,type : "Formations réglementaires incendie" ,categorie : "Formation reglementaires" },
                  { prev : "PF-INC" , name : "Permis feu" ,type : "Formations réglementaires incendie" ,categorie : "Formation reglementaires" },
                  { prev : "SSI1-INC" , name : "Exploitation d’un Système de Sécurité Incendie" ,type : "Formations réglementaires incendie" ,categorie : "Formation reglementaires" },

            ],

            ArrayTypeMissionE : [

              { prev : "HAB-001" , name : "Préparation à l’habilitation électrique, personnel non électricien ind. B0-H0-H0V" ,type : "Formations réglementaires electricité" ,categorie : "Formation reglementaires" },
              { prev : "HAB-002" , name : "Préparation à l’habilitation électrique, personnel non électricien ind B0-H0-H0V" ,type : "Formations réglementaires electricité" ,categorie : "Formation reglementaires" },
              { prev : "HAB-003" , name : "Préparation à l’habilitation électrique, personnel électricien Basse tension" ,type : "Formations réglementaires electricité" ,categorie : "Formation reglementaires" },
              { prev : "HAB-004" , name : "Préparation à l’habilitation électrique, personnel électricien Basse et/ou haute tension (HTA)" ,type : "Formations réglementaires electricité" ,categorie : "Formation reglementaires" },
              { prev : "HAB-005" , name : "Préparation à l’habilitation électrique, personnel effectuant des verifications, des mesurages, des manoeuvres es basse et/ou haute tesnsion - Ind. BE-HE verification, mesurage et manoeuvre" ,type : "Formations réglementaires electricité" ,categorie : "Formation reglementaires" },
              { prev : "HAB-006" , name : "Recyclage" , type : "Formations réglementaires electricité" ,categorie : "Formation reglementaires" }

            ],

            ArrayDevis : [

              {"name":"MAD","code":"MAD"},
              {"name":"Euro","code":"EUR"},
              {"name":"US Dollar","code":"USD"}

            ]
    }
  },
    components: {
  },

  props : {
    infoAffaire : Array,
  },

  methods : {


    // preview file for update Devis
    previewFile() {
      this.file = this.$refs.file.files[0];
    },
    // update Devis
    updateDevis() {
          Service.UpdateDevis(this.file, this.idAffaire)
          .then((result) => {

            console.log(result)
              setTimeout(() => {

                    this.$router.go(this.$router.currentRoute);
              }, 20000);
          })
          .catch((error) => {
              console.error(`HTTP error: ${error.name} => ${error.message}`);
          });
    },

    deleteIntervention() {
      Service.DeleteIntervention(this.idAffaire)
      .then(() => {
        this.$router.go(this.$router.currentRoute)
      })
      .catch((error) => {
        console.log(error);
      });
    },


    deleteBonCammande() {

        Service.DeleteBonCammande(this.idAffaire)
        .then(() => {
          this.$router.go(this.$router.currentRoute)
        })
        .catch((error) => {
          console.log(error);

        });
    },

    addRefRapport(i, index) {
        const quantite = parseInt(this.missions[i].equipement[index].quantite);
        this.missions[i].equipement[index].quantite = String(quantite + 1);
        this.missions[i].equipement[index].refRapport.push("");
        this.missions[i].equipement[index].sendRapport.push(false);
    },
    
    deleteRefRapport(i, index) {
        const quantite = parseInt(this.missions[i].equipement[index].quantite);
        this.missions[i].equipement[index].quantite = String(quantite - 1);
        this.missions[i].equipement[index].refRapport.pop();
        this.missions[i].equipement[index].sendRapport.pop();
    },

    addEquipement(i) {

        const form =  { 
            titre: "",
            quantite: "1",
            refRapport: [""],
            sendRapport: [false]
         }

        this.missions[i].equipement.push(form);
    },

    supEquipement(i, index) {
        this.missions[i].equipement.splice(index, 1);
        console.log(this.missions[i].equipement);
    },

    deleteEquipement(i) {
        this.missions[i].equipement.pop();
    },

    selectMission(event, i) {

        const allvalue = event.target.value.split("_", 4);
        this.missions[i].codeMission = allvalue[0];

        if(allvalue[0] == "Autre")
        {
          this.flagAutreMission = true;
          this.flagDisabledCodeMission = false;
          this.missions[i].typeMission = null;
          this.missions[i].codeMission = null;
          this.missions[i].typeRapport = "Autres";
          this.missions[i].categorie = "Autres";
        } else {
          this.flagAutreMission = false;
          this.flagDisabledCodeMission = true;
          this.missions[i].codeMission = allvalue[0];
          this.missions[i].typeMission = allvalue[2];
          this.missions[i].typeRapport = allvalue[1];
          this.missions[i].categorie = allvalue[3];
        }

    },

    selectInterlocuteur() {
      Service.getInterlocuteur(this.interlocuteur._id)
      .then((result) => {
          this.interlocuteur = result.data.interlocuteur;
      })
      .catch((error) => {
          console.log(error.message);
      });
    },

    selectClient() {

            Service.selectClientInterlocuteur(this.client._id)
            .then((result) => {
               this.client = result.data.client;
                Service.selectClientInterlocuteur(this.client._id)
                .then((result) => {
                  // Get Interlocuteurs with client
                  this.interlocuteurs = result.data.interlocuteurs;
                })
                .catch((error) => {
                    console.log(error.message);
                });
            })
            .catch((error) => {
               console.log(error.message);
            });
    },

   changeInfoClient() {

        this.falgRaisonSocial = !this.falgRaisonSocial;
        if(this.valueClient == "sauvgarder") {

            Service.UpdateClientAffaire(this.numeroAffaire, this.client._id)
            .then((result) => {

                if(result.data.cas == true) {
                  this.valueClient = "change";
                }

                // change ref rapport after change client
                this.missions.map((el) => {
                    el.equipement.map((element) => {
                        element.refRapport.forEach((val, index) => {
                          let indexLastSpecfic = val.lastIndexOf("|");
                          let last = val.length;
                          const refRapoortDate = val.slice(indexLastSpecfic + 1, last);
                          const newNumeroAffaire = val.replace(refRapoortDate, result.data.nameClient);
                          element.refRapport[index] = newNumeroAffaire
                        });
                    });
                });

            })
            .catch((error) => {
                console.log(error);
            });

        } else {
          this.valueClient = "sauvgarder";
        }

   },


   changeInfoInterlocuteur() {

        this.falgInterlocuteur = !this.falgInterlocuteur;

        if(this.valueInterlocuteur == "sauvgarder") {

            Service.UpdateInterlocuteurAffaire(this.numeroAffaire, this.interlocuteur._id)
            .then((result) => {
                if(result.data.cas == true) {
                  this.valueInterlocuteur = "change";
                }
            })
            .catch((error) => {
                console.log(error);
            });

        } else {

          this.valueInterlocuteur = "sauvgarder";
          Service.selectClientInterlocuteur(this.client._id)
            .then((result) => {
               // Get Interlocuteurs with client
               this.interlocuteurs = result.data.interlocuteurs;
            })
            .catch((error) => {
                console.log(error.message);
            });
        }
   },

   changeApporteurNumeroAffaireDateAffaire() {
        this.flagApporteurNumeroAffaireDateAffaire = !this.flagApporteurNumeroAffaireDateAffaire;
        if(this.valueApporteurNumeroAffaireDateAffaire == "sauvgarder") {

                Service.ChangeDateAffaire(this.dateAffaire, this.numeroAffaire, this.apporteurAffaire, this.oldNumeroAffaire)
                .then((response) => {
                  if(response.data.msg == true) {
                      this.msgChangeDate = "La date a été modifiée avec succès"
                      this.valueApporteurNumeroAffaireDateAffaire = "change";
                  }
                })
                .catch((error) => {
                  console.log(error.message);
                  this.msgChangeDate = "Malheureusement, le changement de date a échoué"
                });

        } else {
          this.valueApporteurNumeroAffaireDateAffaire = "sauvgarder";
        }
   },

   supprimerMissionEquipement(missionId, i) {
        Service.DeleteMission(missionId)
        .then((result) => {
            if(result.data.cas == true) {
              this.missions.splice(i , 1);
            }
        })
        .catch((error) => {
            console.log(error);
        });

   },

   changeMissionEquipement(missionId ,i) {

        Service.UpdateReferenceRapport(this.missions[i].equipement, missionId , this.missions[i].codeMission, this.missions[i].prix, this.missions[i].typeMission, this.missions[i].devis, this.missions[i].categorie, this.missions[i].typeRapport)
        .then((result) => {

          if(result.data.cas == true) {
              this.$router.go(this.$router.currentRoute)
          }

        })
        .catch((error) => {
                console.log(error);
        });

   },


  },

  created() {


        // get  all client
        Service.readClient()
        .then(async (result) => {
          this.clients = result.data.clients.sort((a, b) => a.raisonSocial.localeCompare(b.raisonSocial));
        })
        .catch((error) => {
              this.error = error.message;
              console.error(`HTTP error: ${error.name} => ${error.message}`);
              throw "fail request at: GET /refreshtime";
        });

        // get all Interlocuteurs
        Service.readInterlocuteur()
        .then((result) => {
            this.interlocuteurs = result.data.interlocuteurs;
        })
        .catch((error) => {
              this.error = error.message;
              console.error(`HTTP error: ${error.name} => ${error.message}`);
              throw "fail request at: GET /refreshtime";
        });


        // init client
        this.client._id = this.infoAffaire[0].clientId;
        this.client.raisonSocial = this.client._id;

        this.idAffaire = this.infoAffaire[0]._id;
        this.apporteurAffaire = this.infoAffaire[0].apporteurAffaire;
        this.numeroAffaire = this.infoAffaire[0].numeroAffaire;
        this.oldNumeroAffaire = this.infoAffaire[0].numeroAffaire;
        this.dateAffaire = this.infoAffaire[0].date;
        const d = new Date();
        d.setFullYear(new Date(this.dateAffaire).getUTCFullYear());
        d.setDate(new Date(this.dateAffaire).getDate());
        d.setMonth(new Date(this.dateAffaire).getMonth());
        this.dateAffaire = d.toISOString().slice(0, 10);


        // get client
        Service.selectClient(this.infoAffaire[0].clientId)
        .then((result) => {
            this.client = result.data.client
        })
        .catch((err) => {
            console.log(err.message)
        });


        // read admins (Apporteur d'affaire)
        Service.readAdmin()
        .then((result) => {
          result.data.admins.forEach((el) => {
            if(el.apporteur == true) {
                this.apparteurAffaires.push({
                  name : `${el.nom} ${el.prenom}`,
                  id : el._id
                });
            }
          });
        })
        .catch((error) => {
          console.log(error);
        })


        // select Interlocuteur
        Service.getAffaireByNumeroAffaire(this.numeroAffaire)
        .then((result) => {
            this.interlocuteur._id = result.data.affaire.interlocuteurId;
            Service.getInterlocuteur(this.interlocuteur._id)
            .then((result) => {
                this.interlocuteur = result.data.interlocuteur;
            })
            .catch((error) => {
                console.log(error);
            });
        })
        .catch((error) => {
            console.log(error.message)
        });


        // get missions
        console.log(this.infoAffaire[0].numeroAffaire)
        Service.getMission(this.infoAffaire[0].numeroAffaire)
        .then((res) => {
          console.log(res)
          res.data.missions.forEach(element => {
              this.missions.push(element);
          });
        })
        .catch((err) => {
          console.log(err);
        });
  }

};
</script>

<style scoped>

.addRef {
    height: 40px;
    margin-top: 10px;
    border: 0px;
    background-color: green;
    color: white;
    cursor: pointer;
}

.delRef {
    height: 40px;
    margin-top: 10px;
    border: 0px;
    background-color: red;
    color: white;
    cursor: pointer;
}

.addEq {
    height: 40px;
    margin-top: 10px;
    border: 0px;
    background-color: green;
    color: white;
    cursor: pointer;
}

.delEq {
    height: 40px;
    margin-top: 10px;
    border: 0px;
    background-color: red;
    color: white;
    cursor: pointer;
}

.updateMission {
    height: 40px;
    margin-top: 10px;
    border: 0px;
    background-color: rgb(0, 4, 255);
    color: white;
    cursor: pointer;
    padding: 5px;
}

.supMission {
    height: 40px;
    margin-top: 10px;
    border: 0px;
    background-color: rgb(255, 6, 6);
    color: white;
    cursor: pointer;
    padding: 5px;
}

.creationClient {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.creationClient h3:nth-child(1) {
  width: 100%;
  margin:5px 0;
  color: white;
  background: linear-gradient(346deg, rgba(207,31,33,1) 0%, rgba(24,86,161,1) 100%);    text-align: center;
  text-align: center;
  padding: 15px;

}
.creationClient .titre-client {
    width: fit-content;
    height: fit-content;
    padding: 5px;
    color: rgb(65, 65, 65);
    background-color: #dddddd;
    width: 100%;
    text-align: center;

}
.creationClient .titre-interlocuteur {
  width: fit-content;
    height: fit-content;
    padding: 5px;
    color: rgb(65, 65, 65);
    background-color: #dddddd;
    width: 100%;
    text-align: center;

}
.succes {
  background-color: #69cd5b;
  color: white;
  padding: 10px;
  width: 100%;
  height: fit-content;
}

.echec {
  background-color: RED;
  color: white;
  padding: 10px;
  width: 100%;
  height: fit-content;
}

.formCreation {
  padding:20px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

#app > div > div > div.menu-content > div.content > div > div > h3.titre-client {
  color: white;
}

#app > div > div > div.menu-content > div.content > div > div > h3:nth-child(4) {
  color: white;
}

#app > div > div > div.menu-content > div.content > div > div > h3:nth-child(6) {
  color: white;
}

#app > div > div > div.menu-content > div.content > div > div > h3:nth-child(8) {
  color: white;
}

#app > div > div > div.menu-content > div.content > div > div > h3:nth-child(10) {
  color: white;
}

.formCreation div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 48%;
}
.formCreation div label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
  color :#494949;
}
.formCreation div input {
  height: 40px;
  margin-bottom: 5px;
  border: 1px solid #243064;
  padding:5px;
}.formCreation div input:focus-within {
  outline: 1px solid #cf1f21 ;
  border:0;

}

.formCreation div input:focus-within {
  outline: 1px solid #cf1f21 ;
  border:0;
}


.formCreation div select {
  height: 40px;
}

.missionsec{
  border-bottom: 2px dashed rgb(194, 11, 11);
}

#app > div > div > div.menu-content > div.content > div > div > div:nth-child(7) > div:nth-child(4) > input[type=button] {
  cursor: pointer;
  background-color: green;
  color: white;
  border: 0px;
}


div.equipement {
  width: 100%;
}

.equipement > table {
  width: 100%;
}

.equipement > table > tr {
  text-align: center;
  width: 100%;
}

.equipement > table > tr > td > input {
  width: 100%;
}

.equipement > table > tr > td:nth-child(2) {
  display: flex;
  flex-direction: column;
}

#app > div > div > div.menu-content > div.content > div > div > h3:nth-child(12) {
  color: white;
}


#app > div > div > div.menu-content > div.content > div > div > div > div.equipement > table {
  margin-top: 35px;
  margin-bottom: 35px;
}

.supEq {
  cursor: pointer;
  background-color: rgb(248, 7, 7);
  color: white;
  border: 0px;
  padding: 10px;
}




</style>