<template>
  <div class="etat-recrutement-inspecteur-chart">
    <h4>ÉTAT DE RECRUTEMENT</h4>
    <canvas id="myEtatRecrutement"></canvas>
  </div>
</template>

<script>
import Service from "../../../../../../Service";
import Chart from "chart.js";

export default {

  name: "etat-recrutement-inspecteur-chart",

  data() {
    return {
      annee : null,
    };
  },

  props: {
    selectYear : Number,
    selectMois : Number,
  },

  methods: {},

  created() {
  },

  mounted() {

      // get info Indicateur by current
      Service.CalculRecrutement()
      .then((result) => {

          const years = [];
          const datasets = [];
          const labels = ['entrée', 'sortie'] 
          const sortir = [];
          const entre = [];

          result.data.tableRecrutement.forEach((element) => {
             years.push(element._id);
             entre.push(element.entre);
             sortir.push(element.sortir);
          });

          const mergeEntreeSortie = [...entre, ...sortir];

          // set colors background color
          for(let i = 0; i < 2; i++) {
            const randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1));
            const a = randomBetween(0, 255);
            const b = randomBetween(0, 255);
            const c = randomBetween(0, 255);

            const values = mergeEntreeSortie.slice(0, result.data.tableRecrutement.length);
            mergeEntreeSortie.splice(0, result.data.tableRecrutement.length);

            datasets.push({
                data: values,
                label: labels[i],
                borderColor: `rgb(${a},${b},${c})`,
                fill: false,
                tension: 0.9
              });
            }



      const ctx = document.getElementById("myEtatRecrutement");
      var myEtatRecrutement = new Chart(ctx, {
            type: 'line',
            data: {
                labels: years,
                datasets: datasets
            },
        });

          return myEtatRecrutement;
      })
      .catch((error) => {
          console.log(error.message)
      });

  }


};
</script>

<style scoped>

.etat-recrutement-inspecteur-chart > h4 {
  width: 100%;
    justify-content: center;
    display: flex;
    color: #1e1ed3;
    font-size: x-large;
    opacity: 0.6;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
}

</style>