<template>
  <div class="indicateur">

    <div class="titre">
      <h2>LES INDICATEUR AFFAIRES</h2>
    </div>

    <!-- <div class="text">
      <span><i class="fa-regular fa-lightbulb"></i>Note :</span>
      Administrative des lorganise GTHCONSULT, concerne l’ensemble des tâches et des formalités
      administratives pour se confirme au respect des procédures de qualité, cette gestion administrative
      désigne l’ensemble des actions à mettre en œuvre pour permettre l’entreprise d’atteindre objectives
      stratégies.
    </div> -->

    <div class="filter">
      <label for="nom"> 
        <i class="fa-solid fa-magnifying-glass" style="font-size: 20px;"></i> Rechercher par des années et mois:
      </label>
      <select v-model="annee" @change="onChangeAnnee">
        <option v-for="item in maxAnnee" :key="item" :value="item"> {{ item }} </option>
      </select>
      <select v-model="mois" @change="onChangeMois">
        <option v-for="item in maxMois" :key="item" :value="item"> {{ item }} </option>
      </select>
    </div>

    <div class="statstique">
        <EtatAvancementAffaire :selectYear="annee" :selectMois="mois"  v-if="flagEtatAvancementAffaire == true"/>
        <EtatRapporteurAffaire :selectYear="annee" :selectMois="mois"  v-if="flagEtatRapporteurAffaire == true"/>
        <EtatSituationFinancierAffaire :selectYear="annee" :selectMois="mois"  v-if="flagEtatSituationFinancierAffaire == true"/>
    </div>


  </div>
</template>

<script>
import EtatAvancementAffaire from "./charts/EtatAvancementAffaire.vue";
import EtatRapporteurAffaire from "./charts/EtatRapporteurAffaire.vue";
import EtatSituationFinancierAffaire from "./charts/EtatSituationFinancierAffaire.vue";
export default {
  name: "indicateur",
  data() {
    return {
      flagEtatAvancementAffaire : false,
      flagEtatRapporteurAffaire : false,
      flagEtatSituationFinancierAffaire : false,
      maxAnnee: [],
      maxMois: [],
      mois: null,
      annee: null
    }
  },

  components : {
    EtatAvancementAffaire,
    EtatRapporteurAffaire,
    EtatSituationFinancierAffaire
  },

  methods: {

      onChangeMois() {

          this.flagEtatAvancementAffaire = false;
          this.flagEtatRapporteurAffaire = false;
          this.flagEtatSituationFinancierAffaire = false;
          this.$nextTick(() => {
            this.flagEtatAvancementAffaire = true;
            this.flagEtatRapporteurAffaire = true;
            this.flagEtatSituationFinancierAffaire = true;
          });
      },

      onChangeAnnee() {

          // Removing my-component from the DOM
          this.flagEtatProduction = false;
          this.$nextTick(() => {
            // Adding the component back in
            this.flagEtatProduction = true;
          });
      }

  },

  mounted() {

    // get year anee current
    const today = new Date();
    this.annee = today.getUTCFullYear();
    this.mois = today.getMonth() + 1;

    for (let i = this.annee; i >= 2019; i--) {
      this.maxAnnee.push(i);
    }

    for (let i = 12; i >= 1; i--) {
      this.maxMois.push(i);
    }

    if(this.annee != null && this.mois != null) {
          this.flagEtatAvancementAffaire = true;
          this.flagEtatRapporteurAffaire = true;
          this.flagEtatSituationFinancierAffaire = true;
    }

    
  }

}
</script>

<style scoped>


.class-1 {
  background-color: red;
}

.indicateur>.text {
  background-color: #fff27a;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}

.text span {
  font-weight: bold;
}

.titre h2 {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
  color: white;
  background: linear-gradient(346deg, rgba(207, 31, 33, 1) 0%, rgba(24, 86, 161, 1) 100%);
  text-align: center;
  padding: 10px;
  font-size: 25px;
}


.indicateur>.filter {

  background-color: rgb(238, 238, 238);
  display: flex;
  margin: 0 10px;
  padding: 10px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}



.indicateur>.filter>select {

  padding: 10px;
  margin-left: 10px;
  border: 1px solid #bebebe;
  border-radius: 20px;
}


.indicateur>.statstique {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 10px;
  margin: 0 5px 0px 5px;
  border-radius: 10px;

}




legend {
  font-weight: bold;
  color: #243064;
}

.statstique div ul {
  max-height: 90px;
  overflow-y: auto;
  line-height: 30px;
}

.statstique div ul li {
  background-color: rgb(245, 245, 245);
  margin: 5px;
  padding: 0 4px;
  color:red;
  font-weight: 500;
  border-radius: 20px;
}

.indicateur>.statstique>div>p:nth-child(1) {
  font-size: 20px;
  border-bottom: 1px solid gray;
  padding: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
  text-align: center;
  background-color: #243064;
  font-weight: 700;
  transition: 0.3s ease-out;
}

.indicateur>.statstique>div:hover p:nth-child(1){
  background-color: #cf1f21;

}

.indicateur>.statstique div:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.74);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

div p span {
  font-weight: bold;
  color:#243064;
}





fieldset {
  border: 1px solid rgb(201, 201, 201);
  margin: 6px;

}


.fa-check {
  font-size: 10px;
}

svg {
  color: #243064;
}

.detailbutton{
  color:rgb(6, 92, 126) !important;
  text-decoration: underline;
  float: right;
  margin-right: 5px;
  transition: 0.2s;
}

.detailbutton:hover{
  color:rgb(130, 14, 197) !important;
  letter-spacing: 1px;

}

.detailbutton svg{
  color:rgb(130, 14, 197) !important;
  text-decoration: underline !important;
}

.detailbutton:hover svg{
  color:rgb(6, 92, 126) !important;
}

.indicateur>.statstique  {

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;

}

</style>