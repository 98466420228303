<template>
    <div class="parent">

        <div class="header">
            <img src="" alt="" srcset="">
            <din class="titre"></din>
            <din class="reference"></din>
        </div>

        <div class="body">
                <div class="presentation">presentation</div>
                <div class="info-client"></div>
                <div class="question"></div>
        </div>

        <div class="footer">

        </div>

    </div>
</template>

<script>
export default {
    name : "EnqueteSatisfaction",
    data() {
        return {

        }
    }
}
</script>

<style scoped>
    .parent {
        height: 100%;
        width: 100%;
    }
</style>