<template>
  <div class="creationClient">

    <h3>STATSTIQUE PRODUCTION</h3>
        <div class="annee">
          <select v-model="selectYear" @change="onChangeYear">
              <option v-for="annee in annees" :value="annee" :key="annee">{{ annee }}</option>
          </select>
        </div>
        <div class="parent">

            <div class="child">
                <h4>L'état de production</h4>
                <div>
                    <EtatAffaires :selectYear="selectYear" v-if="falgEtatAffaires == true" />
                    <!-- <SelectEtatAffaires :selectYear="selectYear" v-if="falgSelectEtatAffaires == true" /> -->
                </div>
            </div>

            <div class="child">
                <h4>Nombre d'affaire par mois</h4>
                <div>
                    <MoisAffaires :selectYear="selectYear" v-if="falgMoisAffaires == true" />
                    <!-- <SelectMoisAffaires :selectYear="selectYear" v-if="falgSelectMoisAffaires == true" /> -->
                </div>
            </div>

            <div class="child">
                <h4>Les number de mission  par mois</h4>
                <InspecteursMois :selectYear="selectYear" v-if="falgInspecteursMois == true" />
                <!-- <SelectInspecteursMois :selectYear="selectYear" v-if="falgSelectInspecteursMois == true" /> -->
            </div>

            <div class="child">
                <h4>Les nombre des missions par des affaires a chaque mois</h4>
                <AffairesMissions :selectYear="selectYear" v-if="falgAffairesMissions == true" />
                <!-- <SelectAffairesMissions :selectYear="selectYear" v-if="falgSelectAffairesMissions == true" /> -->
            </div>

        </div>

  </div>

</template>

<script>
import EtatAffaires from "./charts/EtatAffaires.vue";
import MoisAffaires from "./charts/MoisAffaires.vue";
import InspecteursMois from "./charts/InspecteursMois.vue";
import AffairesMissions from "./charts/AffairesMissions.vue";

export default {
  name: "Indicateur",
  components: {
    EtatAffaires,
    MoisAffaires,
    InspecteursMois,
    AffairesMissions
  },

  data() {
    return {
      annee : null,
      selectYear : null,
      falgEtatAffaires : false,
      falgMoisAffaires : false,
      falgInspecteursMois : false,
      falgAffairesMissions : false,
    };
  },

  methods: {
    onChangeYear() {

          // Removing my-component from the DOM
          this.falgEtatAffaires = false;
          this.$nextTick(() => {
            // Adding the component back in
            this.falgEtatAffaires = true;
          });

          this.falgMoisAffaires = false;
          this.$nextTick(() => {
            // Adding the component back in
            this.falgMoisAffaires = true;
          });

          this.falgInspecteursMois = false;
          this.$nextTick(() => {
            // Adding the component back in
            this.falgInspecteursMois = true;
          });
          
          this.falgAffairesMissions = false;
          this.$nextTick(() => {
            // Adding the component back in
            this.falgAffairesMissions = true;
          });

    }
  },

  computed : {

    annees() {
      let years = [];
      for (var i = this.annee; i >= 2019; i--) {
        years.push(i);
      }
      return years;
    }
  },

  created() {

      this.selectYear = new Date().getFullYear();
      this.annee = new Date().getFullYear();

      if(this.selectYear != null) {
        this.falgEtatAffaires =  true;
        this.falgMoisAffaires =  true;
        this.falgInspecteursMois =  true;
        this.falgAffairesMissions =  true;
      }
     
  }

  

};
</script>

<style>

.creationClient {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.creationClient > h3 {
  width: 100%;
  margin:auto;
  color: white;
  background: linear-gradient(346deg, rgba(207,31,33,1) 0%, rgba(24,86,161,1) 100%);    text-align: center;
  text-align: center;
}

.parent {
  width: 100%;
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.parent .child {
    border: 4px solid white;
}

.parent > .child > h3 {
    margin-top: 20px;
    color: white;
    text-align: center;
}

</style>