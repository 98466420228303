<template>

    <div class="gestionInspecteur">

        <h3>INITIALISER LE COMPTEUR N°AFFAIRE & N° RAPPORT</h3>

        <div class="rechercher-table">

            <div>

                <div class="rechercher">
                    <label for="LA DERNIÈRE N°AFFAIRE">LA DERNIÈRE N°AFFAIRE <span>{{ errorAffaire }}</span> <span>{{ succesAffaire }}</span></label>
                    <input type="text" v-model="refAffaire" :disabled="flagDisabledRefAffaire" :placeholder="textRefAffaire">
                    <input class="buttonRefAffaire" type="button" value="Changer N°AFFAIRE" @click="changeRefAffaire" v-if="flagDisabledRefAffaire">
                    <input class="buttonSavedRefAffaire" type="button" value="Enregistrer N°AFFAIRE" @click="savedRefAffaire" v-if="!flagDisabledRefAffaire">
                    <input class="buttonAnnulerRefAffaire" type="button" value="Annuler" @click="annulerRefAffaire" v-if="!flagDisabledRefAffaire">
                </div>

                <div class="rechercher">
                    <label for="LA DERNIÈRE N° RAPPORT">LA DERNIÈRE N° RAPPORT<span> {{ errorRapport }} {{ succesRapport }}</span></label>
                    <input type="text" v-model="refRapport" :disabled="flagDisabledRefRapport" :placeholder="textRefRapport">
                    <input class="buttonRefRapport" type="button" value="Changer N°RAPPORT" @click="changeRefRapport" v-if="flagDisabledRefRapport">
                    <input class="buttonSavedRefRapport" type="button" value="Enregistrer N°RAPPORT" @click="savedRefRapport" v-if="!flagDisabledRefRapport">
                    <input class="buttonAnnulerRefRapport" type="button" value="Annuler" @click="annulerRefRapport" v-if="!flagDisabledRefRapport">

                </div>

            </div>

        </div>


  </div>

</template>

<script>
import Service from "../../../../Service";
export default {
  name: "gestionAdmin",
  components: {
  },
  data() {
    return {
        errorAffaire : null,
        succesAffaire : null,
        errorRapport : null,
        succesRapport : null,
        refAffaire : null,
        refRapport : null,
        fakeReffAffaire : null,
        fakeRefRapport : null,
        flagDisabledRefAffaire : true,
        flagDisabledRefRapport : true,
        textRefAffaire : null,
        textRefRapport : null,
    };
  },

  methods: {


    changeRefAffaire() {
        this.flagDisabledRefAffaire = false;
        this.fakeReffAffaire = this.refAffaire;
        this.refAffaire = null;
        this.textRefAffaire = "saisir svp le compteur (Nomber Entier naturel)  EX: 0,1, ..., 121, ...";
    },

    changeRefRapport() {
        this.flagDisabledRefRapport = false;
        this.fakeRefRapport = this.refRapport;
        this.refRapport = null;
        this.textRefRapport = "saisir svp le compteur (Nomber Entier naturel)  EX: 0,1, ..., 121, ...";
    },

    savedRefAffaire() {
        this.flagDisabledRefAffaire = true;

        Service.CheckRefAffaire(this.refAffaire)
        .then((result) => {

            if(result.data.cas == true) {
                this.refAffaire = result.data.exemple;
                this.succesAffaire = result.data.cas;
                // errorRapport : null,
                // succesRapport : null,
            }

            if(result.data.cas == false) {
                this.errorAffaire = result.data.cas;
            }
            
        })
        .catch((error) => {
            console.log(error.message);
        });
    },

    savedRefRapport() {
        this.flagDisabledRefRapport = true;

        Service.CheckRefRapport(this.refRapport)
        .then((result) => {

            if(result.data.cas == true) {
                this.refRapport = result.data.exemple;
                this.succesRapport = result.data.cas;
            }

            if(result.data.cas == false) {
                this.errorRapport = result.data.cas;
            }
            
        })
        .catch((error) => {
            console.log(error.message);
        });
    },

    annulerRefAffaire() {
        this.flagDisabledRefAffaire = true;
        this.refAffaire = this.fakeReffAffaire;
    },

    annulerRefRapport() {
        this.flagDisabledRefRapport = true;
        this.refRapport = this.fakeRefRapport;
    }

  }

,
//clients
  created() {

      Service.getLastRefAffaireORLastRefRapport()
      .then((result) => {
        this.refAffaire = result.data.lastRef[0].LastAffaire;
        this.refRapport = result.data.lastRef[0].LastRapport;
      })
      .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
      });



      
  }


};
</script>

<style scoped>

.succes {
  background-color: green;
  color: white;
  padding: 10px;
  height: fit-content;
  width: 100%;
}

.echec {
  background-color: red;
  color: white;
  padding: 10px;
  height: fit-content;
  width: 100%;
}

.gestionInspecteur {
  width: 100%;
  height: 100%;
  padding-top: 0px;
  padding-left: 0px;
  margin: 0px;
  padding: 2px;
  position: relative;
}
.gestionInspecteur h3 {
  width: 100%;
  margin:auto;
  color: white;
  background: linear-gradient(346deg, rgba(207,31,33,1) 0%, rgba(24,86,161,1) 100%);    text-align: center;
  text-align: center;
  padding:15px;
  margin-bottom: 20px;
}
.rechercher-table {
  width: 100%;
}

.gestionInspecteur > .rechercher-table > div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.gestionInspecteur > .rechercher-table > div > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.gestionInspecteur > .rechercher-table > div > div > label{
    margin-top: 10px;
    margin-bottom: 10px;
}


.gestionInspecteur > .rechercher-table > div > div > input {
  width: 80%;
  height: 50px;
  padding: 5px;
  margin-bottom: 8px;
}

.gestionInspecteur > .rechercher-table > div > div > .buttonRefAffaire {
    background-color: blue;
    color: white;
    border: 0px;
    cursor: pointer;
}

.gestionInspecteur > .rechercher-table > div > div > .buttonRefRapport {
    background-color: blue;
    color: white;
    border: 0px;
    cursor: pointer;
}

.gestionInspecteur > .rechercher-table > div > div > .buttonSavedRefAffaire {
    background-color: green;
    color: white;
    border: 0px;
    cursor: pointer;
}

.gestionInspecteur > .rechercher-table > div > div > .buttonSavedRefRapport {
    background-color: green;
    color: white;
    border: 0px;
    cursor: pointer;
}

.gestionInspecteur > .rechercher-table > div > div > .buttonAnnulerRefAffaire {
    background-color: red;
    color: white;
    border: 0px;
    cursor: pointer;
}

.gestionInspecteur > .rechercher-table > div > div > .buttonAnnulerRefRapport {
    background-color: red;
    color: white;
    border: 0px;
    cursor: pointer;
}






</style>