<template>

    <div class="gestionInspecteur">

      <h3  v-if="flagEditAgenda == false">FICHES DES AGENDAS</h3>

      <p v-if="succes" :class="{ succes: succes }">
        {{ msg }}
      </p>

      <p v-if="echec" :class="{ echec: echec }">
        {{ msg }}
      </p>

      <div class="rechercher-table" v-if="flagEditAgenda == false">

            <div class="rechercher">
                <input type="text" v-model="rechercher" placeholder="Recherche une agenda">
            </div>

            <table id="inspecteurs">
              <tr>
                <th></th>
                <th>Agenda</th>
                <th>Fix & Errors</th>
              </tr>
              <tr v-for="(item, i) in filterAgendas" :key="i">
                <td><input type="checkbox" :value="item._id" v-model="checkedAgendas" style="width: 20px;"></td>
                <td>
                     <li> ID de Agenda : {{ item._id }}</li>
                     <li> Date de Agenda : {{ (item.number + 1) +"/"+item.mois+"/"+item.annee }}</li>
                     <div v-for="(element, k) in item.listCalendrier" :key="k">
                            <li> annee : {{ element.annee }}</li>
                            <li> client : {{ element.client }}</li>
                            <li> countInput {{ element.countInput }}</li>
                            <li> date : {{ element.date }}</li>
                            <li> disabled :  {{ element.disabled }}</li>
                            <li> flagSauvgarder :  {{ element.flagSauvgarder }}</li>
                            <li> horaire : {{ element.horaire[0].start +" - "+ element.horaire[0].fin }}</li>
                            <li> inspecteur {{ element.inspecteur[0].name }}</li>
                            <li> jour : {{ element.jour }}</li>
                            <li> lieu : {{ element.lieu }}</li>
                            <li> matricule:  {{ element.matricule }}</li>
                            <li> mois : {{ element.mois }}</li>
                            <li> notification : {{ element.notification }}</li>
                            <li> number : {{ element.number }}</li>
                            <li> titre : {{ element.titre }}</li>
                            <li> valider : {{ element.valider }}</li>
                            <li> _id : {{ element._id }}</li>
                            <li> -------------------------------------------</li>
                     </div>
                </td>
                <td>
                    <input type="button" value="fiX error" @click="fixError(item.number, item.mois, item.annee, item._id)">
                </td>
              </tr>
            </table>


      </div>

      <ul class="pagination"  v-if="flagEditAgenda == false">
         <button><i class="fa-solid fa-backward"></i></button>
        <li v-for="n in numberPage" :key="n" @click="selectPage(n)"><p>{{ n }}</p></li>
        <button><i class="fa-solid fa-forward"></i></button>
      </ul>



    <!--  Start Edit SousTraitance   -->
      <!-- <EditRapport :agendaId="agendaId" v-if="flagEditAgenda == true" /> -->
    <!--  End Edit SousTraitance   -->

    <!--  Start Info Delete Model   -->
      <DeleteModel v-if="flagEditAgenda == true" :text="textDeleteModel" @closeDeleteModel="closeDeleteModel()" @validerDeleteModel="validerDeleteModel()" />
    <!--  End Info Delete Model   -->

  </div>

</template>

<script>
import Service from "../../../../Service";
import DeleteModel from "../../../../views/components/DeleteModel.vue";
export default {
  name: "gestionAdmin",
  components: {
    DeleteModel
  },
  data() {
    return {
      rapportDelete : null,
      agendaId : null,
      flagModelDelete : false,
      textDeleteModel : null,
      pageIndex : 0,
      itemsPerPage : 50,
      numberPage: null,
      succes: false,
      echec: false,
      msg: null,
      rechercher: null,
      preAgendas : [],
      agendas: [],
      flagEditAgenda: false,
      checkedAgendas : [],
      calendriers : []
    };
  },

  computed : {
      filterAgendas() {
            return this.agendas.filter((item) => {
                return item;
            });
      }
  },

  methods: {

    fixError(number, mois, annee, agendaId) {

      Service.SelectJourMoisAnneeCalendrier(number, mois, annee, agendaId)
      .then((response) => {
          console.log(response.data.result);
      })
      .catch((error) => {
        this.msg = error.message;
        console.error(`HTTP error: ${error.name} => ${error.message}`);
        throw "fail request at: GET /refreshtime";

      });

    }

    // selectPage(value) {
    //   const select = value - 1;
    //   this.agendas = [];
    //   for(let i = select * this.itemsPerPage; i < (select * this.itemsPerPage) + this.itemsPerPage; i++) {
    //       this.agendas.push(this.preAgendas[i]);
    //   }
    // },

    // update() {
    //   Service.updateFournisseur(this.fournisseur, this.fournisseurId)
    //   .then((response) => {
    //       this.traitement = true;
    //       this.msgAgendas = "Veuillez patienter quelques secondes pour Agendas";
    //       if(response) {
    //           setTimeout(() => {
    //               return this.$router.go(this.$router.currentRoute);
    //           }, 10000)
    //       }
    //   })
    //   .catch((error) => {
    //       this.error = error.message;
    //       console.error(`HTTP error: ${error.name} => ${error.message}`);
    //       throw "fail request at: GET /refreshtime";
    //   });
    // },

    // closeDeleteModel() {
    //   this.flagModelDelete = false;
    // },

    // validerDeleteModel() {

    //   this.flagModelDelete = false;

    //   if(this.checkedAgendas.length > 0) {

    //       // // Delete in Fron-end
    //       for(let i = 0; i < this.checkedAgendas.length; i++ ) {

    //         const index = this.agendas.findIndex(object => {
    //           return object._id === this.checkedAgendas[i][0];
    //         });
    //         // //delete in front end
    //         this.agendas.splice(index, 1);
    //       }

    //       // delete in db backend
    //       Service.deleteRapports(this.checkedAgendas)
    //         .then((response) => {
    //             console.log(response);
    //         })
    //         .catch((error) => {

    //             this.msg = error.message;
    //             console.error(`HTTP error: ${error.name} => ${error.message}`);
    //             throw "fail request at: GET /refreshtime";

    //       });

    //   } else {
            
    //         this.rapports.splice(this.rapportDelete, 1);
    //         Service.deleteRapport(this.rapportId, this.filename)
    //         .then((result) => {
    //             this.msg = result.data.msg;
    //         })
    //         .catch((error) => {

    //             this.msg = error.message;
    //             console.error(`HTTP error: ${error.name} => ${error.message}`);
    //             throw "fail request at: GET /refreshtime";

    //         });

    //   }
    // },

    // resendEmail(rapportId) {

    //     Service.ResendEmail(rapportId)
    //       .then((result) => {
    //           console.log(result.data);
    //       })
    //       .catch((error) => {
    //           console.log(error)
    //     });
    // },


    // delete more one admins
    // deleteRapports() {
    //   this.flagModelDelete = true;
    //   this.textDeleteModel = "Êtes-vous sûr de supprimer ces rapports";
    // },

    // delete one admin
    // deleteRapport(i, itemId, filename) {
    //   this.flagModelDelete = true;
    //   this.textDeleteModel = "Êtes-vous sûr de supprimer ce rapport";
    //   this.rapportDelete = i;
    //   this.rapportId = itemId
    //   this.filename = filename
    // },

    // edit one admin
    // editRapport(rapportId) {
    //     this.flagEditRapport = true;
    //     this.rapportId = rapportId;
    // },




    },
//clients
  created() {
    
      Service.readAgendas()
      .then((result) => {
        result.data.agendas.forEach((element) => {
            this.agendas.push(element);
        });
      })
      .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
      });

  }

};
</script>

<style scoped>
.succes {
  background-color: green;
  color: white;
  padding: 10px;
  height: fit-content;
  width: 100%;
}
.echec {
  background-color: red;
  color: white;
  padding: 10px;
  height: fit-content;
  width: 100%;
}
.gestionInspecteur {
  width: 100%;
  height: 100%;
  padding-top: 0px;
  padding-left: 0px;
  margin: 0px;
  padding: 2px;
  position: relative;
}
.gestionInspecteur h3 {
  width: 100%;
  margin:auto;
  color: white;
  background: linear-gradient(346deg, rgba(207,31,33,1) 0%, rgba(24,86,161,1) 100%);    text-align: center;
  text-align: center;
  padding:15px;
  margin-bottom: 20px;
}
pecteur .rechercher-table {
  width: 100%;
}
.gestionInspecteur .rechercher-table .rechercher {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}
.gestionInspecteur .rechercher-table .rechercher input {
  width: 50%;
  height: 40px;
  outline: 0;
  border: 1px solid #243064;
  font-size: 16px;
  background-color: white;
  padding: 5px;
  color :#243064;
}
.gestionInspecteur .rechercher-table table  {
  width: 100%;
}
.gestionInspecteur .rechercher-table table tr  {
  width: 100%;
}
.gestionInspecteur .rechercher-table table tr th {
    background-color: #243064;
    color: white;
    border-right: 1px solid white;
    padding: 10px 4px;
}
table{
  border-collapse: collapse;
}
.gestionInspecteur .rechercher-table table tr td {
    color: black;
    padding: 5px;
}

.gestionInspecteur .rechercher-table table tr:nth-child(even) {
background-color: #f8f8f8;
}
.gestionInspecteur .rechercher-table table tr td a {
  cursor: pointer;

  margin-right: 10px;
}
.fa-trash {
  color: red;
}.fa-trash:hover {
  color: rgb(230, 88, 88);
}
.fa-pen-to-square {
  color: rgb(255, 187, 0);
}
.fa-circle-check {
  color: green;
}
.gestionInspecteur .rechercher-table ul {
  width: 100%;
  height: fit-content;
  text-align: center;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.gestionInspecteur .rechercher-table ul li {
  color:black;
 margin-left: 5px;
 cursor: pointer;
 font-size: 18px;
}
.gestionInspecteur .rechercher-table ul li:hover {
  color:red;
 margin-left: 5px;
 transition: 0.3s;
}
.gestionInspecteur .rechercher-table .deleteAll {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.gestionInspecteur .rechercher-table .deleteAll input {
  width: fit-content;
  padding: 10px;
  height: 40px;
  background-color: red;
  color: white;
  border: 0;
  cursor: pointer;
}

#inspecteurs > tr > td:nth-child(10) > a:nth-child(1) > svg  {
  color: red;
  font-size: 20px;
}
#inspecteurs > tr > td:nth-child(10) > a:nth-child(2) > svg  {
  color: blue;
  font-size: 20px;
}
#inspecteurs > tr > td:nth-child(10) > a:nth-child(3) > svg  {
  color: green;
  font-size: 20px;
}
#app > div > div > div.menu-content > div.content > div > h3 {
    background-color: #ff0000d4;
    padding: 15px;
}
#app > div > div > div.menu-content > div.content {
  background-color: white;
}
#inspecteurs > tr > td {
    color: #000000;
    border-bottom: 1px solid #acacac;
  text-transform: capitalize;
}
#app > div > div > div.menu-content > div.content > div {
  background-color: white;
}
#inspecteurs > tr > td:nth-child(8) > a:nth-child(1) > svg {
  color: red;
}
#inspecteurs > tr > td:nth-child(8) > a:nth-child(3) > svg {
  color: green;
}
#inspecteurs > tr > td:nth-child(8) > a:nth-child(4) > svg {
  color: red;
}
#inspecteurs > tr > td:nth-child(8) > a:nth-child(5) > svg {
  color: orange;
}

.pagination{
  display: flex;
  align-items: center;
  justify-content: center;
  margin:10px auto;
  width: fit-content;
  flex-wrap: wrap;
  padding:5px 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.pagination p{
  border:1px solid rgb(151, 151, 151);
  margin: 0 4px;
  padding:5px 10px;
  border-radius: 20px;
  cursor: pointer;
}

.pagination p:hover , button:hover{
    background-color: #243064;
    color:white;
}

.pagination button {
  border:0;
  padding:5px;
  border:1px solid rgb(170, 170, 170);
  margin-left: 15px;
  color:gray;
  cursor: pointer;
}

.pagination button:nth-child(1) {
    margin-right: 15px;
    margin-left: 0;
}

#inspecteurs > tr > td:nth-child(2) > div {
  margin-left: 50px;
}
  


</style>