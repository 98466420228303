<template>
  <div class="etat-rendement-inspecteurs-chart">
    <h4>État Rendement Inspecteurs</h4>
    <canvas id="myEtatRendementInspecteurs"></canvas>
  </div>
</template>

<script>
import Service from "../../../../../Service";
import Chart from "chart.js";
export default {

  name: "rendement-inspecteurs-chart",

  data() {
    return {
      annee : null,
    };
  },

  props: {
    selectYear : Number,
    selectMois : Number,
  },

  methods: {},

  created() {
  },

  mounted() {

      // get info Indicateur by current
      Service.indicteurEtatRendementInspecteurs(this.selectYear, this.selectMois)
      .then((result) => {

                // inctance arry
                const inspecteurs = [];
                const values = [];
                const tauxTotal = 26;

                // liste rendement
                result.data.forEach((element) => {
                    if(element.rendement == 100) {
                      values.push(parseFloat((element.rendement * element.value) / tauxTotal).toFixed(2));
                    } else {
                      const newValeur = (((100 + (100 - element.rendement)) * element.value) / 100);
                      values.push(parseFloat((element.rendement * newValeur) / tauxTotal).toFixed(2));
                    }
                });

                // liste inspecteurs
                result.data.forEach((element) => {
                    inspecteurs.push(element.inspecteur);
                });

                const data = {
                      labels: inspecteurs,
                      datasets: [{
                        label: "taux de rendement de inspecteur",
                        data: values,
                        backgroundColor: [
                          'rgba(229, 229, 229, 0.9)'
                        ],
                        borderWidth: 1
                      }]
                };

                const config = {
                  type: 'bar',
                  data: data,
                  options: {
                    scales: {
                      xAxes: [{ 
                        stacked: true,   
                        barThickness: 50,  // number (pixels) or 'flex'
                        maxBarThickness: 50
                      }],
                      yAxes: [{
                        stacked:true
                      }],
                    }
                  },
                };

                const ctx = document.getElementById("myEtatRendementInspecteurs");
                var myEtatRendementInspecteurs = new Chart(ctx, config);
                return myEtatRendementInspecteurs;
      })
      .catch((error) => {
          console.log(error.message)
      });

  }


};
</script>

<style scoped>

.etat-rendement-inspecteurs-chart > h4 {
  width: 100%;
    justify-content: center;
    display: flex;
    color: #1e1ed3;
    font-size: x-large;
    opacity: 0.6;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
}

</style>