<template>
  <div class="creationClient">

    <h3>DEMANDE ABSENCE</h3>

    <div class="formCreation">
      <div>
        <label for="Date de demande Congé">Date de demande Congé</label>
        <input type="text" v-model="absence.date" disabled>
      </div>
      <div class="p" style="width:100%; margin:10px 0;text-align:center;font-weight: bold; color:black;background-color: #e9e9e9; ;padding:7px;">Informations Personnelles </div>

      <div>
        <label for="Nom">Nom</label>
        <input type="text" v-model="absence.nom" disabled>
      </div>

      <div>
        <label for="Prénom">Prénom</label>
        <input type="text" v-model="absence.prenom" disabled>
      </div>
      <div class="p" style="width:100%; margin:10px 0;text-align:center;font-weight: bold; color:black;background-color: #e9e9e9; ;padding:7px;">Informations Techniques </div>

      <div>
        <label for="Département">Département</label>
          <select v-model="absence.departement">
            <option v-for="(item, index) in absence.listDepartement" :key="index" :value="item" > {{ item  }}</option>
        </select>
      </div>

      <div>
        <label for="Fonction">Fonction</label>
        <input type="text" v-model="absence.fonction">
      </div>
      <div class="p" style="width:100%; margin:10px 0;text-align:center;font-weight: bold; color:black;background-color: #e9e9e9; ;padding:7px;">Informations Abscence </div>

      <div>
        <label for="Type d'absence">Type d'absence</label>
        <select v-model="absence.typeAbsence" @change="selectTypeConge">
            <option v-for="(item, index) in absence.listTypeAbsence" :key="index" :value="item.name"> {{ item.name }}</option>
        </select>
      </div>


      <div v-if="this.absence.typeAbsence == 'Autres'">
        <label for="Motif">Motif</label>
        <textarea type="text" v-model="absence.motif"></textarea>
      </div>

      <div>
        <label for="Document preuve">Document preuve</label>
        <input class="form-control" type="file" multiple="multiple" placeholder="Télécharger Document Preuve" ref="file" @change="previewFile">
      </div>

      <div>
        <label for="Du">Sortir</label>
        <input type="date" v-model="absence.duree.start">
      </div>

      <div>
        <label for="Au">Reprise</label>
        <input type="date" v-model="absence.duree.fin" @change="selectAU">
      </div>

      <!-- <div v-if="absence.nomberJoursOuvrables != null">
        <p class="warningjrs" v-if="absence.nomberJoursOuvrables < absence.nomberJours"><i class="fa-solid fa-triangle-exclamation"></i> Veuillez corriger svp les jours autorisés : {{ absence.nomberJoursOuvrables }} jours</p>
        <label for="Nomber de Jours">Nomber de Jours</label>
        <input type="number" v-model="absence.nomberJours" disabled>
      </div> -->

      <div v-if="absence.nomberJoursOuvrables == null">
        <label for="Nomber de Jours">Nomber de Jours</label>
        <input type="number" v-model="absence.nomberJours" disabled>
      </div>

      <!-- <div v-if="absence.typeAbsence != 'Autres' && absence.typeAbsence != 'Examen ou Formation' && absence.typeAbsence != 'Maladie' && absence.typeAbsence != 'Congé annuel' && absence.typeAbsence != null && absence.typeAbsence != ''">
        <label for="Nomber de Jours ouvrables">Nomber de Jours Ouvrables</label>
        <input type="number" v-model="absence.nomberJoursOuvrables" disabled>
      </div> -->

      <div>
        <input class="demander" type="submit" value="Demander" @click="create">
      </div>

      <div>
        <input class="quitter" type="submit" value="Quitter" @click="quitter">
      </div>


    </div>

    <!-- Modals Error and landing -->
    <ModalLanding v-if="flagModalLanding" :message="message" />
    <ModalErrors v-if="flagModalErrors" :errors="errors" />

  </div>
</template>

<script>
import Service from '../../../../../Service';
import ModalLanding from "@/views/components/ModalLanding.vue"
import ModalErrors from "@/views/components/ModalErrors.vue"

export default {
  data() {
    return {
      errors : [],
      flagModalLanding : false,
      flagModalErrors : false,
      msgConge : null,
      absence : {
          date : null,
          nom : null,
          prenom : null,
          departement : null,
          fonction : null,
          typeAbsence : null,
          motif: null,
          pj: null,
          nomberJours : null,
          duree : {
            start : null,
            fin : null
          },
          nomberJoursOuvrables : null,
          decision : [
            "Accordé",
            "Non Accordé"
          ],
          preciseDates : null,
          motifRefuse: null,
          listTypeAbsence : [
            { name : "Congé annuel", duree : null },
            { name : "Congé mariage", duree : 4 },
            { name : "Congé mariage d'un enfant", duree : 2 },
            { name : "Décès d'un conjoint, d'un enfant, d'un petit enfant", duree : 3 },
            { name : "Décès d'un des parents, grand-père ou grand-mère", duree : 3 },
            { name : "Décèsd d'un frère, d'une sœur", duree : 2 },
            { name : "Décès d'un proche", duree : 1 },
            { name : "Circoncision", duree : 2 },
            { name : "Convocation Tribunal", duree : 1 },
            { name : "Opération chirurgicale du conjoint ou d'un enfant à charge", duree : 2 },
            { name : "Accompagner un patient au cabinet du médecin", duree : 1 },
            { name : "Congé maternité", duree : 98 },
            { name : "Maladie", duree: null },
            { name : "Examen ou Formation", duree : null },
            { name : "Autres", duree : null }
          ],

          listDepartement : [
            "PRODUCTION",
            "ADMINISTRATIVE",
            "TECHNIQUE",
            "QUALITÉ"
          ],

          dateAccorder : null
      },
      flagDaysConge : false,
      flagDemandeConge : false,
      mois : [
                { i : 1, nom : "Janvier"},
                { i : 2, nom : "Février"},
                { i : 3, nom : "Mars"},
                { i : 4, nom : "Avril"},
                { i : 5, nom : "Mai"},
                { i : 6, nom : "Juin"},
                { i : 7, nom : "Juillet"},
                { i : 8, nom : "Août"},
                { i : 9, nom : "Septembre"},
                { i : 10, nom : "Octobre"},
                { i : 11, nom : "Novembre"},
                { i : 12, nom : "Décembre"}
      ],
      succes: false,
      echec: false,
      error : null,
      year : null,
      moisSelect : null,
      days : [],
      recuperer : [],
      countDaysSelect : 0,
      styleTrue: {
        backgroundColor : '#4908f7'
      },
      styleFalse: {
        backgroundColor : 'white'
      }
    };
  },

  components : {
    ModalLanding,
    ModalErrors
  },

  computed : {
    filterDays() {
      return this.days.filter((item) => {
         return item;
      });
    }
  },

  methods: {

    quitter() {
      this.$router.push("/da");
    },

    // function finish Modal for delete landing or errors after message (init)
    finishModal() {
        setTimeout(() => {
            this.flagModalLanding = false;
            this.flagModalErrors = false;
            this.errors = [];
            this.message = null;
        }, 8000);
    },

    fixText(value) {
          // delete space in start
          const fixInputStart = String(value).trimStart();
          // delete space in end
          const fixInputEnd = String(fixInputStart).trimEnd();
          // fix all problem
          return fixInputEnd;
    },

    // for Fix probleme spaces inputs -> delete spaces end & start
    fixInputsEmpty() {
      const falg = (this.absence.date == null || this.absence.departement == null || this.absence.duree.start == null || this.absence.duree.fin == null || this.absence.fonction == null || this.absence.typeAbsence == null) ? true : false;
      return falg;
    },

    // for Fix probleme spaces inputs -> delete spaces end & start
    fixInputsSpace() {

        if(this.absence.departement != null) {
          this.absence.departement = this.fixText(this.absence.departement);
        }

        if(this.absence.fonction != null) {
          this.absence.fonction = this.fixText(this.absence.fonction);
        }

        if(this.absence.typeAbsence != null) {
          this.absence.typeAbsence = this.fixText(this.absence.typeAbsence);
        }
    },


    create() {


      if(this.fixInputsEmpty(this.absence)) {
        this.flagModalErrors = true;
        this.errors.push("Veuillez saisir tout les champs");
        this.finishModal();
      } else {
        // handel Fix inputs
        this.fixInputsSpace();
        // handel flag
        this.flagModalLanding  = true;
        // send to back-end
        Service.createConge(this.absence, this.file, this.matricule)
        .then(() => {
          setTimeout(() => {
              this.$router.go(this.$router.currentRoute);
          }, 8000);
        })
        .catch((error) => {
          this.flagModalError = true;
          this.errors.push(error);
          this.finishModal();
        });
      }



    },

    selectAU() {

      // le cas de conge annuel
      if(this.absence.typeAbsence == "Congé annuel") {

                const calculeDurre = (number) => {
                // la date de debut
                const dateDu = new Date(this.absence.duree.start);
                // la date de fin
                const dateAu = new Date(this.absence.duree.fin);

                // function for get days between tow dates
                var getDaysArray = function(start, end) {
                    for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
                        arr.push(new Date(dt));
                    }
                    return arr;
                };

                // les jours que choisir
                var daylist = getDaysArray(new Date(dateDu),new Date(dateAu));
                daylist.map((v)=> v.toISOString().slice(0,10)).join("");


                // config date for become simple leran and stocker in tab
                const joursChoisir = [];
                daylist.map((el) => {
                  const x = new Date(el).toLocaleDateString()
                  var datearray = x.split("/");
                  var newdate = datearray[1] + '/' + datearray[0] + '/' + datearray[2];
                  joursChoisir.push(newdate);
                });


                console.log(joursChoisir)

                // tableau de jours feries pour stocker apres
                const daylistFeries = [];
                // la date annee au meme annee
                if(dateDu.getFullYear() == dateAu.getFullYear()) {

                  Service.selectJourFeries(dateDu.getFullYear(), null)
                  .then((result) => {

                      if(result.data.feries != null) {

                          // prend tout les jours feries a DB au meme annee
                          this.feries = result.data.feries.feries;
                          console.log(this.feries)

                          // stocker tout les jours feries
                          var joursFeries = [];
                          for(let i = 0; i < this.feries.length; i++) {
                            var daysFeries = getDaysArray(new Date(this.feries[i].dateDebut), new Date(this.feries[i].dateFin));
                            daysFeries.map((v)=>v.toISOString().slice(0,10)).join("");
                            
                            daysFeries.forEach((el) => {
                              joursFeries.push(new Date(el).toLocaleDateString())
                            });
                          }

                          console.log(joursFeries);


                          // filter for delete dimanch et samedi pour les admin et dimanche pour l' inspecteur que existe les jours feries entre samedi et/ ou dimanche

                          joursFeries.forEach((el) => {
                            const today = new Date(el);
                            if(number == 2) {

                              if(today.getDay() == 6) {
                                if(today instanceof Date) {
                                    const x = new Date(el).toLocaleDateString();
                                    daylistFeries.push(x);
                                }
                              }

                              if(today.getDay() == 0) {
                                if(today instanceof Date) {
                                    const x = new Date(el).toLocaleDateString();
                                    daylistFeries.push(x);
                                }
                              }

                            }

                            if(number == 1) {

                              if(today.getDay() == 0) {
                                if(today instanceof Date) {
                                    const x = new Date(el).toLocaleDateString();
                                    daylistFeries.push(x);
                                }
                              }
                            }

                          });

                          console.log(daylistFeries);

                      }

                      // is counter jour feries
                      var counterJourFeries = 0
                      for(let i = 0; i < daylistFeries.length; i++) {
                        for(let j = 0; j < joursChoisir.length; j++) {
                              if(daylistFeries[i] == joursChoisir[j]) {
                                counterJourFeries = counterJourFeries + 1;
                              }
                        }
                      }

                      var days = getDaysArray(new Date(dateDu), new Date(dateAu));
                      var counterDays = 0;

                      if(number == 2) {

                            days.map((v)=>v.toISOString().slice(0,10)).join("");
                            days.forEach((el) => {
                                var dt = new Date(el);
                                if(dt.getDay() != 0 && dt.getDay() != 6) {
                                  counterDays++;
                                }
                            });

                            this.absence.nomberJours = counterDays - 1 - counterJourFeries;
                      }

                      if(number == 1) {

                          days.map((v)=>v.toISOString().slice(0,10)).join("");
                          days.forEach((el) => {
                              var dt = new Date(el);
                              if(dt.getDay() != 0) {
                                counterDays++;
                              }
                          });

                          this.absence.nomberJours = counterDays - 1 - counterJourFeries;
                      }

                  })
                  .catch((error) => {
                      console.log(error);
                  });

                } 

                if(dateDu.getFullYear() != dateAu.getFullYear()) {

                  Service.selectJourFeries(dateDu.getFullYear(), dateAu.getFullYear())
                  .then((result) => {

                      if(result.data.feries != null) {

                          this.feries = result.data.feries.feries;
                          var joursFeries = [];
                          for(let i = 0; i < this.feries.length; i++) {
                            var daysFeries = getDaysArray(new Date(this.feries[i].dateDebut),new Date(this.feries[i].dateFin));
                            daysFeries.map((v)=>v.toISOString().slice(0,10)).join("");
                            daysFeries.forEach((el) => {
                              console.log(new Date(el).toLocaleDateString())
                              joursFeries.push(new Date(el).toLocaleDateString())
                            });
                          }

                          // filter for delete dimanch et samedi pour les admin et dimanche pour l' inspecteur
                          joursFeries.forEach((el) => {
                            const today = new Date(el);
                            if(number == 2) {

                              if(today.getDay() == 6) {
                                if(today instanceof Date) {
                                    const x = new Date(el).toLocaleDateString();
                                    daylistFeries.push(x);
                                }
                              }

                              if(today.getDay() == 0) {
                                if(today instanceof Date) {
                                    const x = new Date(el).toLocaleDateString();
                                    daylistFeries.push(x);
                                }
                              }

                            }

                            if(number == 1) {

                              if(today.getDay() == 0) {
                                if(today instanceof Date) {
                                    const x = new Date(el).toLocaleDateString();
                                    daylistFeries.push(x);
                                }
                              }
                            }

                          });

                      }

                      // is counter jour feries
                      var counterJourFeries = 0
                      for(let i = 0; i < daylistFeries.length; i++) {
                        for(let j = 0; j < joursChoisir.length; j++) {
                              if(daylistFeries[i] == joursChoisir[j]) {
                                counterJourFeries = counterJourFeries + 1;
                              }
                        }
                      }

                      var days = getDaysArray(new Date(dateDu), new Date(dateAu));
                      var counterDays = 0;

                      if(number == 2) {

                            days.map((v)=>v.toISOString().slice(0,10)).join("");
                            days.forEach((el) => {
                                var dt = new Date(el);
                                if(dt.getDay() != 0 && dt.getDay() != 6) {
                                  counterDays++;
                                }
                            });

                            this.absence.nomberJours = counterDays - 1 - counterJourFeries;
                      }

                      if(number == 1) {

                          days.map((v)=>v.toISOString().slice(0,10)).join("");
                          days.forEach((el) => {
                              var dt = new Date(el);
                              if(dt.getDay() != 0) {
                                counterDays++;
                              }
                          });

                          this.absence.nomberJours = counterDays - 1 - counterJourFeries;
                      }

                  })
                  .catch((error) => {
                      console.log(error);
                  });

                }


                }

                Service.getAdmin(this.matricule)
                .then((res) => {

                if(res.data.admin.status == "inspecteur") {
                  calculeDurre(1)
                }

                if(res.data.admin.status == "admin") {

                  calculeDurre(2)

                }


                })
                .catch((error) => {
                    console.log(error)
                });
      }

    },


    previewFile() {
        this.file = this.$refs.file.files[0];
    },



    selectTypeConge() {
      const index = this.absence.listTypeAbsence.findIndex((el) => el.name === this.absence.typeAbsence);
      this.absence.nomberJours = this.absence.listTypeAbsence[index].duree;
    }

  },

  created() {

      if(!sessionStorage.getItem("token"))
      {
        this.$router.push("/");
      } else {

      // get info User
      this.absence.nom = sessionStorage.getItem("nom");
      this.absence.prenom = sessionStorage.getItem("prenom");
      this.matricule = sessionStorage.getItem("id");

      // check permet Conge is true our false
      Service.PermetConge(this.matricule)
      .then((response) => {
          if(response.data.result == false) {
            this.$router.push("/");
          }
      })
      .catch((error) => {
          console.log(error.message);
      });

      // get date demande
      this.absence.date = new Date().toLocaleString()

    }

  }

}
</script>

<style scoped>


.creationClient {
  width: 100%;
  padding:5px;

}

.creationClient h3 {
  width: 100%;
  margin:auto;
  color: white;
  background: linear-gradient(346deg, rgba(207,31,33,1) 0%, rgba(24,86,161,1) 100%);    text-align: center;
  text-align: center;


}
.succes {
  background-color: #69cd5b;
  color: white;
  padding: 10px;
  width: 100%;
  height: fit-content;
}

.echec {
  background-color: RED;
  color: white;
  padding: 10px;
  width: 100%;
  height: fit-content;
}

.formCreation {
  padding:20px 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1,1fr);
  justify-items: center;
  align-items: center;


}

.formCreation div {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.formCreation div ul {
  margin-left: 100px;
}

.formCreation div p {
  margin-left: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: large;
}
.formCreation div label {
  margin-left:10px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
  color :#494949;
}
.formCreation div input,textarea {
  height: 40px;
  margin-left:10px;
  margin-bottom: 5px;
  border: 1px solid #243064;

}

.formCreation div select {
  height: 40px;
  margin-left:10px;
}

.demander{
    background-color: green;
    color: white;
    border: 0;
    margin-top: 10px;
    cursor: pointer;
}
.quitter {
    background-color: red;
    color: white;
    border: 0;
    margin-top: 10px;
    cursor: pointer;
}



#app > div > div > div.menu-content > div.content > div {
  background-color: white;
}

#app > div > div > div.menu-content > div.content > div > h3 {
    padding: 15px;
}

#app > div > div > div.menu-content > div.content > div > div > div > label {
  font-size: 15px;
}


.form-control {
    display: block;

    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.form-control::-webkit-file-upload-button {
    padding: 10px;
    margin: -.375rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end: .75rem;
    color: rgb(58, 58, 58);
    font-weight: 600;
    background-color: #e9e9e9;
    pointer-events: none;
    border:0;

    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}
.warningjrs{
  background-color: #ffc6c6;
  width: 100%;
  margin:10px 9px;
  padding:10px;
  border-radius: 10px;
  display: flex;
  color:red;
  align-items: center;
  font-weight: 600;
}

.warningjrs svg{
  margin-right: 5px;
}
</style>

