<template>
    <div class="creationClient">
  
      <h3>MODIFIER UNE OFFRE D'EMPLOI</h3>
  
      <Traitement :msg="msgAdmin" v-if="traitement == true"/>
  
      <p v-if="echec" :class="{ echec: echec }">
          {{ error }}
      </p>
  
      <div class="formCreation">
  
        <div>
          <label for="Titre de L'offre">Titre de L'offre</label>
          <input type="text" v-model="titre">
        </div>

        <div>
          <label for="Domaine">Domaine</label>
          <select v-model="domaine">
            <option v-for="item in domaines" :key="item.id" :value="item.name"> {{ item.name }}</option>
          </select>
        </div>

        <div>
          <label for="Expérience">Expérience</label>
          <select v-model="experience">
            <option v-for="item in experiences" :key="item.id" :value="item.name"> {{ item.name }}</option>
          </select>
        </div>

        <div>
          <label for="Type d'entente">Type d'entente</label>
          <select v-model="typeTravail">
            <option v-for="item in typeTravails" :key="item.id" :value="item.name"> {{ item.name }}</option>
          </select>
        </div>
  

        <div>
          <label for="Prénom">Missions</label>
          <textarea class="missions" type="text" v-model="missions"></textarea>
        </div>
  
        <div>
          <label for="Description">Description</label>
          <textarea class="description" v-model="description "></textarea>
        </div>
  
        <div>
          <label for="Qualifications">Qualifications</label>
          <textarea class="qualifications" type="text" v-model="qualifications"></textarea>
        </div>

        <div>
          <label for="Profils">Profils</label>
          <textarea class="profils" type="text" v-model="profils"></textarea>
        </div>
  
  
        <div>
          <label for="Date limite de dépôt de candidature">Date limite de dépôt de candidature</label>
          <input type="date" v-model="dateLimiteCandidature">
        </div>
  
        <div>
          <label for="Salaire">Salaire</label>
          <input type="text" v-model="salaire">
        </div>

        <div>
          <label for="Localisation">Localisation</label>
          <input type="text" v-model="localisation">
        </div>


        <div>
          <input type="submit" value="Modifier l'offre" @click="update()">
        </div>
  
        <div>
          <input type="submit" value="Quitter" @click="quitter()">
        </div>
  
      </div>
  
    </div>
  </template>
  
  <script>
  import Service from "../../../../../Service";
  import Traitement from "../../Affaire/Traitement.vue"
  
  export default {
    data() {
      return {
        titre : null,
        domaine : null,
        experience : null,
        missions : null,
        description : null,
        qualifications : null,
        profils : null,
        dateLimiteCandidature : null,
        salaire : null,
        localisation : null,
        succes: false,
        echec: false,
        error : null,
        traitement : false,
        typeTravail : null,
        domaines : [
          { id : 1, name : "Administration" },
          { id : 2, name : "Agroalimentaire et alimentaire" },
          { id : 3, name : "Artisanat - métiers d'art" },
          { id : 4, name : "Audiovisuel et Infographie" },
          { id : 5, name : "Audit comptabilité gestion" },
          { id : 6, name : "Aéronautique et espace" },
          { id : 7, name : "Banque assurance" },
          { id : 8, name : "Biologie chimie pharmacie" },
          { id : 9, name : "Bâtiment - Travaux publics" },
          { id : 10, name : "Commerce International" },
          { id : 11, name : "Commerce distribution" },
          { id : 12, name : "Communication" },
          { id : 13, name : "Création" },
          { id : 14, name : "Culture" },
          { id : 15, name : "Digital" },
          { id : 16, name : "Développement Durable" },
          { id : 17, name : "Electricité" },
          { id : 18, name : "Enseignement" },
          { id : 19, name : "Entrepreneuriat" },
          { id : 20, name : "Environnement" },
          { id : 21, name : "Evénementiel" },
          { id : 22, name : "Finance" },
          { id : 23, name : "Fonction publique" },
          { id : 24, name : "Gestion des entreprises et des organisations" },
          { id : 25, name : "Immobilier" },
          { id : 26, name : "Industrie" },
          { id : 27, name : "Information et Multimédia" },
          { id : 28, name : "Informatique télécom web" },
          { id : 29, name : "Innovation" },
          { id : 30, name : "Journalisme" },
          { id : 31, name : "Langues" },
          { id : 32, name : "Logistique" },
          { id : 33, name : "Management" },
          { id : 34, name : "Marketing publicité" },
          { id : 35, name : "Mécanique" },
          { id : 36, name : "Médical" },
          { id : 37, name : "Métiers du Politique" },
          { id : 38, name : "Météorologie" },
          { id : 39, name : "Presse et Médias" },
          { id : 40, name : "Ressources humaines" },
          { id : 41, name : "Social" },
          { id : 42, name : "Tourisme" },
          { id : 43, name : "Économie" },
        ],
        experiences : [
          { id : 1, name : "Débutant(e)"},
          { id : 2, name : "Intermédiaire(e)"},
          { id : 3, name : "Avancé(e)"},
          { id : 4, name : "Expert(e)"}
        ],
        typeTravails : [
          { id : 1, name : "Télétravail"},
          { id : 2, name : "Travail sur site"}
        ]
      };
  
    },
  
    components : {
      Traitement
    },

    props : {
        offreId : String
    },
  
    methods: {
  
      // create Admin
      update() {

        Service.updateOffreEmploi(this.titre, this.missions, this.description, this.qualifications, this.profils, this.dateLimiteCandidature, this.salaire, this.localisation, this.domaine, this.experience, this.typeTravail, this.offreId)
        .then((response) => {
                
                this.traitement = true;
                this.msgAdmin = "Veuillez patienter quelques secondes pour modifier l'offre d'emploi";
                var finish = false;
  
                this.titre = null;
                this.missions = null;
                this.description = null;
                this.qualifications = null;
                this.profils = null;
                this.dateLimiteCandidature = null;
                this.salaire = null;
                this.localisation = null;
                this.domaine = null;
                this.experience = null;
                this.typeTravail = null;

  
                if(response) {
                  setTimeout(() => { console.log("wait"); }, 5000);
                  finish = true
                }
  
                if(finish) {
                  setTimeout(() => {
                    this.msgAdmin = null;
                    this.traitement = false;
                  }, 5000);
                }
  
        })
        .catch((error) => {
            this.error = error.message;
            console.error(`HTTP error: ${error.name} => ${error.message}`);
            throw "fail request at: GET /refreshtime";
        })
      }
    },


  
    created(){


            Service.getOffre(this.offreId)
            .then((result) => {
                this.titre = result.data.offre.titre;
                this.domaine = result.data.offre.domaine;
                this.experience = result.data.offre.experience;
                this.missions = result.data.offre.missions;
                this.description = result.data.offre.description;
                this.qualifications = result.data.offre.qualifications;
                this.profils = result.data.offre.profils;
                this.dateLimiteCandidature = new Date(result.data.offre.dateLimiteCandidature).toISOString().slice(0, 10);
                this.salaire = result.data.offre.salaire;
                this.localisation = result.data.offre.localisation;
                this.typeTravail = result.data.offre.typeTravail;
            })
            .catch((error) => {
                console.log(error);
            });
    }
  
  
  };
  </script>
  
  <style scoped>
  .creationClient {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .creationClient h3 {
    width: 100%;
    margin:auto;
    color: white;
    background: linear-gradient(346deg, rgba(207,31,33,1) 0%, rgba(24,86,161,1) 100%);    text-align: center;
    text-align: center;
  }
  .succes {
    background-color: #69cd5b;
    color: white;
    padding: 10px;
    width: 100%;
    height: fit-content;
  }
  .echec {
    background-color: RED;
    color: white;
    padding: 10px;
    width: 100%;
    height: fit-content;
  }
  .formCreation {
    padding:20px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .formCreation div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 48%;
  }
  .formCreation div label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 700;
    color :#494949;
  }
  .formCreation div input {
    height: 40px;
    margin-bottom: 5px;
    border: 1px solid #243064;
    padding:5px;
  }.formCreation div input:focus-within {
    outline: 1px solid #cf1f21 ;
    border:0;
  }
  .formCreation div input:focus-within {
    outline: 1px solid #cf1f21 ;
    border:0;
  }
  .formCreation div select {
    height: 40px;
  }
  #app > div > div > div.menu-content > div.content > div > div > div.formInspecteur {
    width: 100%;
  }
  #app > div > div > div.menu-content > div.content > div > div > div.formInspecteur div:last-child {
    border-bottom: 2px dashed rgb(255, 0, 0);
    margin-bottom: 10px;
  }
  #app > div > div > div.menu-content > div.content > div > div > div > .addinspec {
      background-color: rgb(161, 0, 80);
      color: white;
      border: 0;
      margin-top: 20px;
      padding:15px;
      cursor: pointer;
      margin-bottom: 10px;
      transition: 0.4s;
  }
  #app > div > div > div.menu-content > div.content > div > div > div > .addinspec:hover {
      background-color: rgb(143, 93, 118);
  }
  #app > div > div > div.menu-content > div.content > div > div > div > .addinspec svg {
   margin-right: 10px;
   font-size: 16px;
  }
  
  .formCreation div select {
    height: 40px;
  }


  #app > div > div > div.menu-content > div.content > div > div > div > div:nth-child(13) > input[type=submit] {
      background-color: red;
      color: white;
      border: 0;
      margin-top: 20px;
      cursor: pointer;
  }


#app > div > div > div.menu-content > div.content > div > div > div > div:nth-child(12) > input[type=submit] {
      background-color: green;
      color: white;
      border: 0;
      margin-top: 50px;
      cursor: pointer;
}
  
  #app > div > div > div.menu-content > div.content > div {
    background-color: white;
  }

  .inspeclabel label{
    margin: 10px 0;
    color:rgb(63, 63, 63) !important;
    background-color: #e4e4e4;
    padding:5px;
  }
  #app > div > div > div.menu-content > div.content > div > h3 {
      padding: 15px;
  }

  .description , .missions {
    height: 300px;
  }

  .qualifications, .profils {
    height: 100px;
  }
  
  </style>