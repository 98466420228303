<template>

  <div class="gestionInspecteur">

    <h3 v-if="flagEditTicket == false">LISTE DES TICKET</h3>

    <p v-if="succes && flagEditTicket == false" :class="{ succes: succes }">
      {{ msg }}
    </p>

    <p v-if="echec && flagEditTicket == false" :class="{ echec: echec }">
      {{ msg }}
    </p>

    <div class="rechercher-table" v-if="flagEditTicket == false">

      <div class="rechercher">
        <input type="text" v-model="rechercher" placeholder="Recherche un Ticket">
      </div>

      <table id="inspecteurs">
        <thead>
          <tr>
            <th> </th>
            <th>Demandeur</th>
            <th>Récepteur</th>
            <th>Titre</th>
            <th>Message</th>
            <!-- <th>Télécharger P.J</th> -->
            <th>Cas</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in filterTickets" :key="item._id">

            <td><input type="checkbox" :value="item.filename" v-model="checkedTickets" style="width: 20px;"></td>

            <td>{{ item.email }}</td>

            <td>{{ item.recepteur }}</td>

            <td :title="item.titre">{{ item.titre != null ? item.titre : '--' }}</td>

            <td :title="item.message">{{ item.message != null ? item.message.slice(10) : '--' }}</td>

            <!-- <td>
              <a @click="displayTicket(item.filename)" title="Supprimer"><i class="fa-solid fa-download"></i></a>
            </td> -->

            <td>{{ item.case }}</td>

            <td>
              <a @click="deleteTicket(item.filename, i)" title="Supprimer"><i class="fa-solid fa-trash"></i></a>
              <a v-show="item.case == 'ouvert'" @click="chackeTicket(item._id, 'fermé')" title="fermé"><i class="fa-solid fa-check"></i></a>
              <a v-show="item.case == 'fermé'" @click="chackeTicket(item._id, 'ouvert')" title="ouvert"><i class="fa-solid fa-xmark"></i></a>
            </td>

          </tr>
        </tbody>
      </table>

    </div>


    <ul class="pagination" v-if="flagEditTicket == false">
      <button @click="selectPageLeft()"><i class="fa-solid fa-backward"></i></button>
      <li v-for="n in numberPage" :key="n" @click="selectPage(n)">
        <p v-show="numberPageSelect[n - 1] == false">{{ n }}</p>
        <p v-show="numberPageSelect[n - 1] == true" style="background-color: #0300db; color: white;">{{ n }}</p>
      </li>
      <button @click="selectPageRight()"><i class="fa-solid fa-forward"></i></button>
    </ul>


    <!--  Start Info Delete Model   -->
    <DeleteModel v-if="flagModelDelete == true" :text="textDeleteModel" @closeDeleteModel="closeDeleteModel()"
      @validerDeleteModel="validerDeleteModel()" />
    <!--  End Info Delete Model   -->
  </div>

</template>

<script>
import Service from "../../../../../Service";
import DeleteModel from "../../../../../views/components/DeleteModel.vue";

export default {
  name: "gestionEtalonage",
  components: {
    DeleteModel
  },
  data() {
    return {
      numberPageSelect: [],
      flagModelDelete: false,
      textDeleteModel: null,
      filenameDelete: null,
      iDelete: null,
      pageIndex: 0,
      itemsPerPage: 10,
      numberPage: null,
      preTickets: [],
      tickets: [],
      succes: false,
      echec: false,
      msg: null,
      rechercher: null,
      checkedTickets: [],
      flagEditTicket: false,
      ticketsId: null,
    };
  },

  computed: {
    filterTickets() {
      return this.tickets.filter((item) => {
        if (!this.rechercher) {
          return item
        }
        return !item.email.toLowerCase().toString().indexOf(this.rechercher.toLowerCase().toString()) ||
          !item.recepteur.toLowerCase().toString().indexOf(this.rechercher.toLowerCase().toString()) ||
          !item.titre.toLowerCase().toString().indexOf(this.rechercher.toLowerCase().toString()) ||
          !item.message.toLowerCase().toString().indexOf(this.rechercher.toLowerCase().toString());

      });
    }
  },
  methods: {

    selectPageLeft() {

      for (let i = 0; i < this.numberPage; i++) {
        this.numberPageSelect[i] = false;
      }

      this.numberPageSelect[0] = true;
      this.tickets = [];

      for (let i = 0 * this.itemsPerPage; i < (0 * this.itemsPerPage) + this.itemsPerPage; i++) {
        this.absence.push(this.preTickets[i]);
      }

    },

    selectPageRight() {

      for (let i = 0; i < this.numberPage; i++) {
        this.numberPageSelect[i] = false;
      }

      const indice = this.numberPage - 1;
      this.numberPageSelect[indice] = true;
      this.tickets = [];

      for (let i = indice * this.itemsPerPage; i < (indice * this.itemsPerPage) + this.itemsPerPage; i++) {
        this.tickets.push(this.preTickets[i]);
      }

    },

    selectPage(value) {

      const select = value - 1;
      // change current select for change color
      for (let i = 0; i < this.numberPage; i++) {
        this.numberPageSelect[i] = false;
      }

      this.numberPageSelect[select] = true;
      this.tickets = [];

      for (let i = select * this.itemsPerPage; i < (select * this.itemsPerPage) + this.itemsPerPage; i++) {
        this.tickets.push(this.preTickets[i]);
      }
    },

    // display Certificate Competence
    displayTicket(filename) {
      Service.displayTicket(filename)
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });

    },

    closeDeleteModel() {
      this.flagModelDelete = false;
    },

    validerDeleteModel() {

      this.flagModelDelete = false;
      if (this.checkedTickets.length > 0) {
        console.log(false);
      } else {
        this.tickets.splice(this.iDelete, 1);
        Service.deleteTicket(this.filenameDelete)
          .then((result) => {
            this.msg = result.data.msg;
          })
          .catch((error) => {
            this.msg = error.message;
            console.error(`HTTP error: ${error.name} => ${error.message}`);
            throw "fail request at: GET /refreshtime";
          });

      }
    },

    // delete more one Competence (Competence)
    deletetickets() {
      this.flagModelDelete = true;
      this.textDeleteModel = "Êtes-vous sûr de supprimer tout ?";
    },

    // delete one Competence
    deleteTicket(filename, i) {
      this.flagModelDelete = true;
      this.textDeleteModel = "Êtes-vous sûr de supprimer ticket ?";
      this.filenameDelete = filename;
      this.iDelete = i;
    },

    chackeTicket(id, status) {

      Service.chackeTicket(id, status)
        .then(() => {
            const find = this.tickets.findIndex((el) => el._id == id);
            this.tickets[find]["case"] = status;
        })
        .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });
    }



  },

  created() {


    Service.readtickets()
      .then((result) => {

        result.data.tickets.forEach(element => {
          this.preTickets.push(element);
        });

        this.numberPage = Math.ceil(this.preTickets.length / this.itemsPerPage);

        for (let i = 0; i < this.numberPage; i++) {
          this.numberPageSelect.push(false);
        }

        this.numberPageSelect[0] = true;

        for (let i = this.pageIndex * this.itemsPerPage; i < (this.pageIndex * this.itemsPerPage) + this.itemsPerPage; i++) {
          this.tickets.push(this.preTickets[i]);
        }

      })
      .catch((error) => {
        this.msg = error.message;
        console.error(`HTTP error: ${error.name} => ${error.message}`);
        throw "fail request at: GET /refreshtime";
      });

  }




};
</script>

<style scoped>
.succes {
  background-color: green;
  color: white;
  padding: 10px;
  height: fit-content;
  width: 100%;
}

.echec {
  background-color: red;
  color: white;
  padding: 10px;
  height: fit-content;
  width: 100%;
}

.gestionInspecteur {
  width: 100%;
  height: 100%;
  padding: 2px;
  position: relative;
}

.gestionInspecteur h3 {
  width: 100%;
  height: fit-content;
  padding: 5px;
  margin: 0;
  color: white;
  background-color: #243064;
  text-align: center;
  margin-bottom: 10px;
}

pecteur .rechercher-table {
  width: 100%;
}

.gestionInspecteur .rechercher-table .rechercher {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;

}

.gestionInspecteur .rechercher-table .rechercher input {
  width: 50%;
  height: 40px;
  outline: 0;
  border: 1px solid #243064;
  font-size: 16px;
  background-color: white;
  color: #243064;
}

.gestionInspecteur .rechercher-table table {
  width: 100%;
}

.gestionInspecteur .rechercher-table table tr {
  width: 100%;
}

.gestionInspecteur .rechercher-table table tr th {
  background-color: #243064;
  color: white;
  border-right: 1px solid white;
  padding: 10px 0;
}

.gestionInspecteur .rechercher-table table tr td {

  color: black;
  padding: 5px;
  text-transform: capitalize;
}

.gestionInspecteur .rechercher-table table tr td a {
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}

.fa-trash {
  color: red;
}

.fa-trash:hover {
  color: rgb(230, 88, 88);
}

.fa-pen-to-square {
  color: rgb(255, 196, 0);
}

.fa-circle-check {
  color: green;
}

.fa-file-pdf {
  color: rgb(18, 133, 168);
  font-size: 19px;
}


.gestionInspecteur .rechercher-table ul {
  width: 100%;
  height: fit-content;
  text-align: center;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gestionInspecteur .rechercher-table ul li {
  color: black;
  margin-left: 5px;
  cursor: pointer;
  font-size: 18px;
}

.gestionInspecteur .rechercher-table ul li:hover {
  color: red;
  margin-left: 5px;
  transition: 0.3s;
}


.gestionInspecteur .rechercher-table .deleteAll {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.gestionInspecteur .rechercher-table .deleteAll input {
  width: fit-content;
  padding: 10px;
  height: 40px;
  background-color: red;
  color: white;
  border: 0;
  cursor: pointer;
}


#inspecteurs>tr>td:nth-child(10)>a:nth-child(1)>svg {
  color: red;
  font-size: 20px;
}

table {
  border-collapse: collapse;
}

#inspecteurs>tr>td:nth-child(10)>a:nth-child(2)>svg {
  color: blue;
  font-size: 20px;
}

#inspecteurs>tr>td:nth-child(10)>a:nth-child(3)>svg {
  color: green;
  font-size: 20px;
}

#app>div>div>div.menu-content>div.content>div>h3 {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;

  color: white;
  background: linear-gradient(346deg, rgba(207, 31, 33, 1) 0%, rgba(24, 86, 161, 1) 100%);
  text-align: center;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 25px;
}

#app>div>div>div.menu-content>div.content {
  background-color: white;
}

#inspecteurs>tr>td {
  color: #000000;
  border-bottom: 1px solid #102170;
}

table tbody tr:nth-child(even) {
  background-color: rgb(240, 240, 240);
}

#app>div>div>div.menu-content>div.content>div {
  background-color: white;
}


#inspecteurs>tr>td:nth-child(8)>a:nth-child(1)>svg {
  color: red;
}

#inspecteurs>tr>td:nth-child(8)>a:nth-child(3)>svg {
  color: green;
}

#inspecteurs>tr>td:nth-child(8)>a:nth-child(4)>svg {
  color: red;
}

#inspecteurs>tr>td:nth-child(8)>a:nth-child(5)>svg {
  color: orange;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  width: fit-content;
  flex-wrap: wrap;
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.pagination p {
  border: 1px solid rgb(151, 151, 151);
  margin: 0 4px;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
}

.pagination p:hover,
button:hover {
  background-color: #243064;
  color: white;
}




.pagination button {
  border: 0;
  padding: 5px;
  border: 1px solid rgb(170, 170, 170);
  margin-left: 15px;
  color: gray;
  cursor: pointer;
}

.pagination button:nth-child(1) {
  margin-right: 15px;
  margin-left: 0;

}
</style>