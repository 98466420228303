<template>
  <div class="creationClient">

    <h3>MODIFIER ADMIN</h3>

    <Traitement :msg="msgAdmin" v-if="traitement == true"/>

    <p v-if="echec" :class="{ echec: echec }">
        {{ error }}
    </p>

    <div class="formCreation">

      <div>
        <label for="Nom">Nom</label>
        <input type="text" v-model="admin.nom" disabled>
      </div>

      <div>
        <label for="Prénom">Prénom</label>
        <input type="text" v-model="admin.prenom" disabled>
      </div>

      <div>
        <label for="Status">Status GTHCONSULT</label>
        <select v-model="admin.status">
          <option v-for="index in listeStatus" :key="index" :value="index"> {{ index }}</option>
        </select>
      </div>

      <div>
        <label for="Status">Status Compte</label>
        <select v-model="admin.statusCompte">
          <option value="true"> Activer </option>
          <option value="false">Désactiver</option>
        </select>
      </div>

      <div>
        <label for="Status">Rendement Ex: 0 jusqu'à 100 (هادي متقيسهاش الله يرضي على ولدي)</label>
        <input type="text" v-model="admin.rendement">
      </div>

      <div>
        <label for="Status"></label>
        <input type="hidden">
      </div>

      <div>
        <label for="Email">Email</label>
        <input type="text" v-model="admin.email">
      </div>

      <div>
        <label for="Password">Mote de passe</label>
        <input type="text" v-model="admin.password" disabled>
      </div>

      <div>
        <label for="Date d'embauche">Date d'embauche - (Il faut renseigner dans ce champ ce qui correspond à Collaborateur)</label>
        <input type="date" v-model="admin.dateEmbauche">
      </div>

      <div>
        <label for="Status">Date d'embauche Précédent (انا زعلان والله ياشيخ)</label>
        <input type="text" v-model="admin.dateEmbauchePrecedent">
      </div>

      <div>
        <label for="adresse">Matricule (Vous devez entrer un numéro unique pour chaque employé)</label>
        <input type="number" v-model="admin.matricule">
      </div>

      <div>
        <label for="apporteur"> Est-ce qu apporteur d'affaire ?</label>
        <select v-model="admin.apporteur">
          <option value="1">Oui</option>
          <option value="0">Non</option>
        </select>
      </div>


      <div>
        <input type="submit" value="Modifié une admin" @click="update()">
      </div>

      <div>
        <input type="submit" value="Quitter" @click="quitter()">
      </div>

    </div>

  </div>
</template>

<script>
import Service from "../../../../Service";
import Traitement from "../Affaire/Traitement.vue"

export default {
  data() {
    return {
      
      listeStatus : ["admin", "inspecteur"],
      traitement : null,
      msgAdmin : null,
      admin: {
              nom : null,
              prenom : null,
              email : null,
              password : null,
              status : null,
              dateEmbauche : null,
              matricule : null,
              apporteur : null,
              statusCompte : null,
              rendement : null,
              dateEmbauchePrecedent : null,
      },
      succes: false,
      echec: false,
      error : null
    };
  },

  components : {
    Traitement
  },

  props : {
    adminId : String,
  },

  methods: {

    // quitter Admin
    quitter() {
        window.location.reload()
    },

    // create Admin
    update() {

      if(this.admin.dateEmbauchePrecedent == "") {
        this.admin.dateEmbauchePrecedent = null;
      }

      Service.updateAdmin(this.admin, this.adminId)
      .then((response) => {

              this.traitement = true;
              this.msgAdmin = "Veuillez patienter quelques secondes pour modifier Admin";
              var finish = false;

              this.admin.nom = null;
              this.admin.prenom = null; 
              this.admin.email = null;
              this.admin.password = null;
              this.admin.status = null;
              this.admin.dateEmbauche = null;
              this.admin.matricule = null;
              this.admin.apporteur = null;
              this.statusCompte = null;
              this.rendement = 100;
              this.dateEmbauchePrecedent = null;

              if(response) {
                setTimeout(() => { console.log("wait"); }, 5000);
                finish = true
              }

              if(finish) {
                setTimeout(() => {
                  this.msgAdmin = null;
                  this.traitement = false;
                }, 5000);
              }

      })
      .catch((error) => {
          this.error = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
      })
    }

  },

created() {

    // get clients (raison Social)
    Service.getAdmin(this.adminId)
      .then((result) => {
         this.admin = result.data.admin;

         if(result.data.admin.apporteur == true) {
            this.admin.apporteur = 1;
         } else {
            this.admin.apporteur = 0;
         }

         this.admin.dateEmbauche = new Date(result.data.admin.dateEmbauche).toISOString().slice(0, 10);
      })
      .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
      });
  }


};
</script>

<style scoped>

.creationClient {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.creationClient h3 {
  width: 100%;
  margin:auto;
  color: white;
  background: linear-gradient(346deg, rgba(207,31,33,1) 0%, rgba(24,86,161,1) 100%);    text-align: center;
  text-align: center;
}

.succes {
  background-color: #69cd5b;
  color: white;
  padding: 10px;
  width: 100%;
  height: fit-content;
}

.echec {
  background-color: RED;
  color: white;
  padding: 10px;
  width: 100%;
  height: fit-content;
}

.formCreation {
  padding:20px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.formCreation div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 48%;
}

.formCreation div label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
  color :#494949;
}

.formCreation div input {
  height: 40px;
  margin-bottom: 5px;
  border: 1px solid #243064;
  padding:5px;
}

.formCreation div input:focus-within {
  outline: 1px solid #cf1f21 ;
  border:0;
}

.formCreation div input:focus-within {
  outline: 1px solid #cf1f21 ;
  border:0;
}

.formCreation div select {
  height: 40px;
}

#app > div > div > div.menu-content > div.content > div > div > div.formInspecteur {
  width: 100%;
}

#app > div > div > div.menu-content > div.content > div > div > div.formInspecteur div:last-child {
  border-bottom: 2px dashed rgb(255, 0, 0);
  margin-bottom: 10px;
}

#app > div > div > div.menu-content > div.content > div > div > div > .addinspec {
    background-color: rgb(161, 0, 80);
    color: white;
    border: 0;
    margin-top: 20px;
    padding:15px;
    cursor: pointer;
    margin-bottom: 10px;
    transition: 0.4s;
}

#app > div > div > div.menu-content > div.content > div > div > div > .addinspec:hover {
    background-color: rgb(143, 93, 118);
}

#app > div > div > div.menu-content > div.content > div > div > div > .addinspec svg {
 margin-right: 10px;
 font-size: 16px;
}

.formCreation div select {
  height: 40px;
}

#app > div > div > div.menu-content > div.content > div > div > div > div:nth-child(14) > input[type=submit] {
    background-color: red;
    color: white;
    border: 0;
    margin-top: 50px;
    cursor: pointer;
}

#app > div > div > div.menu-content > div.content > div > div > div > div:nth-child(13) > input[type=submit] {
    background-color: green;
    color: white;
    border: 0;
    margin-top: 50px;
    cursor: pointer;
}

#app > div > div > div.menu-content > div.content > div {
  background-color: white;
}
.inspeclabel label{
  margin: 10px 0;
  color:rgb(63, 63, 63) !important;
  background-color: #e4e4e4;
  padding:5px;
}
#app > div > div > div.menu-content > div.content > div > h3 {
    padding: 15px;
}

</style>