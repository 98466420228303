<template>

    <div class="gestionInspecteur">

      <h3 v-if="flagEditActualite == false" >LISTE DES OFFRES D' EMPLOI</h3>

      <p v-if="succes && flagEditActualite == false" :class="{ succes: succes }">
        {{ msg }}
      </p>


      <p v-if="echec && flagEditActualite == false" :class="{ echec: echec }">
        {{ msg }}
      </p>

      <div class="rechercher-table" v-if="flagEditActualite == false">

            <div class="rechercher">
                <input type="text" v-model="rechercher" placeholder="Recherche une actualite">
            </div>

            
            <div class="filter">
              <label for="nom"> 
                <i class="fa-solid fa-magnifying-glass" style="font-size: 20px;"></i> Rechercher par des années:
              </label>
              <select v-model="annee">
                <option value="tout"> Tout </option>
                <option v-for="item in maxAnnee" :key="item" :value="item"> {{ item }} </option>
              </select>
            </div>

            <table id="inspecteurs">
              <tr>
                <th> </th>
                <th>Référence de Photo (Privacy)</th>
                <th>Titre de L'actualité</th>
                <th>Auteur</th>
                <th>Actions</th>
              </tr>
              <tr v-for="(item, i) in filterActualites" :key="i">
                <td><input type="checkbox" :value="[i, item._id, item.filename]" v-model="checkedActualites" style="width: 20px;"></td>
                <td>{{ item.refPicture }}</td>
                <td>{{ item.titre }}</td>
                <td>{{ item.auteur }}</td>
                <td>
                  <a @click="deleteActualite(i, item._id, item.filename)"><i class="fa-solid fa-trash"></i></a>
                  <a @click="editActualite(item._id)"><i class="fa-solid fa-pen-to-square"></i></a>
                </td>
              </tr>
            </table>

            <div class="deleteAll" v-show="checkedActualites.length > 1">
              <input type="submit" value="Supprimer tout" @click="deleteActualites()">
            </div>

      </div>

      <ul class="pagination" v-if="flagEditActualite == false">
        <button @click="selectPageLeft()"><i class="fa-solid fa-backward"></i></button>
          <li v-for="n in numberPage" :key="n" @click="selectPage(n)">
            <p v-show="numberPageSelect[n-1] == false">{{ n }}</p>
            <p  v-show="numberPageSelect[n-1] == true" style="background-color: #0300db; color: white;">{{ n }}</p>
          </li>
        <button @click="selectPageRight()"><i class="fa-solid fa-forward"></i></button>
      </ul>

      <EditActualite :actualiteId="actualiteId" v-if="flagEditActualite == true" />

      <!--  Start Info Delete Model   -->
      <DeleteModel v-if="flagModelDelete == true" :text="textDeleteModel" @closeDeleteModel="closeDeleteModel()" @validerDeleteModel="validerDeleteModel()" />
      <!--  End Info Delete Model   -->

  </div>



</template>

<script>
import Service from "../../../../../src/Service";
import EditActualite from "./Edit/EditActualite.vue";
import DeleteModel from "../../../../views/components/DeleteModel.vue";

export default {
  name: "gestionEtalonage",
  components: {
    EditActualite,
    DeleteModel
  },
  data() {
    return {
      filename : null,
      flagEditActualite : false,
      numberPageSelect : [],
      actualiteDelete : null,
      iDelete : null,
      textDeleteModel : null,
      flagModelDelete : false,
      pageIndex : 0,
      itemsPerPage : 10,
      numberPage: null,
      preActualites : [],
      succes: false,
      echec: false,
      msg: null,
      rechercher: null,
      checkedActualites : [],
      actualiteId : null,
      actualites : [],
      maxAnnee : [],
      annee : null
    };
  },

  computed : {
    
    filterActualites() {
            return this.actualites.filter((item) => {

              if(this.annee == "tout") {

                  if(!this.rechercher) {
                    return item;
                  } else {

                    if(item != undefined) {
                      return !item.titre.toLowerCase().toString().indexOf(this.rechercher.toLowerCase().toString());
                    }

                  }

              } else {
                if(!this.rechercher) {
                  if(item != undefined) {
                      if(this.annee === new Date(item.date).getUTCFullYear()) {
                          return item;
                      }
                  }
                } else {
                    if(item != undefined) {
                      return !item.titre.toLowerCase().toString().indexOf(this.rechercher.toLowerCase().toString());
                    }
                }
              }

            });
      }
  },

  methods: {

    selectPage(value) {

        const select = value - 1;
        this.actualites = [];
        for(let i = select * this.itemsPerPage; i < (select * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.actualites.push(this.preActualites[i]);
        }

    },

    selectPageLeft() {

        for(let i = 0; i < this.numberPage; i++) {
              this.numberPageSelect[i] = false;
        }

        this.numberPageSelect[0] = true;

        this.actualites = [];
        for(let i = 0 * this.itemsPerPage; i < (0 * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.actualites.push(this.preActualites[i]);
        }
        
    },

    selectPageRight() {

        for(let i = 0; i < this.numberPage; i++) {
              this.numberPageSelect[i] = false;
        }

        const indice = this.numberPage - 1;
        this.numberPageSelect[indice] = true;
        this.actualites = [];
        for(let i = indice * this.itemsPerPage; i < (indice * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.actualites.push(this.preActualites[i]);
        }
    },


    closeDeleteModel() {
      this.flagModelDelete = false;
    },


    validerDeleteModel() {

        this.flagModelDelete = false;

        if(this.checkedActualites.length > 0) {

            const liste = [];
            const listeId = [];
            const listeFilename = [];

            // Delete in Fron-end
            for(let i = 0; i < this.checkedActualites.length; i++) {
              liste.push(this.checkedActualites[i][0]);
              listeId.push(this.checkedActualites[i][1]);
              listeFilename.push(this.checkedActualites[i][2]);
            }

            liste.forEach((el) => {
              this.actualites.splice(el, 1);
            });

            // delete in db backend
            Service.deleteActualite(listeId, listeFilename)
              .then((response) => {
                    console.log(response);
              })
              .catch((error) => {
                    this.msg = error.message;
                    console.error(`HTTP error: ${error.name} => ${error.message}`);
                    throw "fail request at: GET /refreshtime";
              });

        } else {

              this.actualites.splice(this.iDelete, 1);
              Service.deleteActualite(this.actualiteId, this.filename)
              .then((result) => {
                    this.msg = result.data.msg;
              })
              .catch((error) => {
                    this.msg = error.message;
                    console.error(`HTTP error: ${error.name} => ${error.message}`);
                    throw "fail request at: GET /refreshtime";
              });

        }
    },

    // delete more one Argement (Argement)
    deleteActualites() {
      this.flagModelDelete = true;
      this.textDeleteModel = "Êtes-vous sûr de supprimer tout ?";
    },

    // delete one Argement
    deleteActualite(i, actualiteId, filename) {
      this.flagModelDelete = true;
      this.textDeleteModel = "Êtes-vous sûr de supprimer ?";
      this.iDelete = i;
      this.actualiteId = actualiteId;
      this.filename = filename;
    },

    // edit one client
    editActualite(actualiteId) {
      this.flagEditActualite = true;
      this.actualiteId = actualiteId;
    }

  },

  created() {

      // get year anee current
      const today = new Date();
      this.annee = today.getUTCFullYear();

      for (let i = this.annee; i >= 2019; i--) {
        this.maxAnnee.push(i);
      }

      this.annee = "tout";

      Service.readActualites()
      .then((result) => {

            result.data.actualites.forEach(element => {
                this.preActualites.push(element);
            });

            this.numberPage = Math.ceil(this.preActualites.length / this.itemsPerPage);

            for(let i = 0; i < this.numberPage; i++) {
                this.numberPageSelect.push(false);
            }

            this.numberPageSelect[0] = true;

            for(let i = this.pageIndex * this.itemsPerPage; i < (this.pageIndex * this.itemsPerPage) + this.itemsPerPage; i++) {
                this.actualites.push(this.preActualites[i]);
            }

      })
      .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
      });

  }
};
</script>

<style scoped>

.gestionInspecteur{
  width: 100%;
  padding:5px;
}
.succes {
  background-color: green;
  color: white;
  padding: 10px;
  height: fit-content;
  width: 100%;
}

.echec {
  background-color: red;
  color: white;
  padding: 10px;
  height: fit-content;
  width: 100%;
}

.gestionInspecteur {
  width: 100%;
  height: 100%;
  padding-top: 0px;
  padding-left: 0px;
  margin: 0px;
  position: relative;
}
.gestionInspecteur h3 {
  width: 100%;
  height: fit-content;
  padding: 5px;
  margin: 0;
  color: white;
  background-color: #243064;
  text-align: center;
  margin-bottom: 10px;
}

pecteur .rechercher-table {
  width: 100%;
}
.gestionInspecteur .rechercher-table .rechercher {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;

}

.gestionInspecteur .rechercher-table .rechercher input {
  width: 50%;
  height: 40px;
  outline: 0;
  border: 1px solid #243064;
  font-size: 16px;
  background-color: white;
  color :#243064;
}

.gestionInspecteur .rechercher-table table  {
  width: 100%;
}
.gestionInspecteur .rechercher-table table tr  {
  width: 100%;
}
.gestionInspecteur .rechercher-table table tr th {
    background-color: #243064;
    color: white;
    padding: 5px;
}

.gestionInspecteur .rechercher-table table tr td {
    background-color: #ddd;
    color: black;
    padding: 5px;
}
.gestionInspecteur .rechercher-table table tr td a {
  cursor: pointer;

  margin-right: 5px;
}

.gestionInspecteur .rechercher-table ul {
  width: 100%;
  height: fit-content;
  text-align: center;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gestionInspecteur .rechercher-table ul li {
  color:black;
 margin-left: 5px;
 cursor: pointer;
 font-size: 18px;
}
.gestionInspecteur .rechercher-table ul li:hover {
  color:red;
 margin-left: 5px;
 transition: 0.3s;
}


.gestionInspecteur .rechercher-table .deleteAll {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.gestionInspecteur .rechercher-table .deleteAll input {
  width: fit-content;
  padding: 10px;
  height: 40px;
  background-color: red;
  color: white;
  border: 0;
  cursor: pointer;
}


#inspecteurs > tr > td:nth-child(10) > a:nth-child(1) > svg  {
  color: red;
  font-size: 18px;
}

#inspecteurs > tr > td:nth-child(10) > a:nth-child(2) > svg  {
  color: rgb(255, 166, 0);
  font-size: 18px;
}

#inspecteurs > tr > td:nth-child(10) > a:nth-child(3) > svg  {
  color: rgb(195, 0, 255);
  font-size: 22px;
}

#app > div > div > div.menu-content > div.content > div > h3 {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin:0;

    color: white;
    background: linear-gradient(346deg, rgba(207,31,33,1) 0%, rgba(24,86,161,1) 100%);    text-align: center;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 25px;
}

#app > div > div > div.menu-content > div.content {
  background-color: white;
}

#inspecteurs > tr > td {
    background-color: white;
    color: #243064;
    border-bottom: 1px solid #243064;
}

#app > div > div > div.menu-content > div.content > div {
  background-color: white;
  padding:2px;
}

#inspecteurs > tr > td:nth-child(5) > a:nth-child(1) > svg {
  color: red;
}

#inspecteurs > tr > td:nth-child(5) > a:nth-child(2) > svg {
  color: orange;
}



.pagination{
  display: flex;
  align-items: center;
  justify-content: center;
  margin:10px auto;
  width: fit-content;
  flex-wrap: wrap;
  padding:5px 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.pagination p{
  border:1px solid rgb(151, 151, 151);
  margin: 0 4px;
  padding:5px 10px;
  border-radius: 20px;
  cursor: pointer;
}

.pagination p:hover , button:hover{
background-color: #243064;
color:white;
}


.pagination button {
  border:0;
  padding:5px;
  border:1px solid rgb(170, 170, 170);
  margin-left: 15px;
  color:gray;
  cursor: pointer;
}

.pagination button:nth-child(1) {
    margin-right: 15px;
    margin-left: 0;
}


.filter {
  background-color: rgb(238, 238, 238);
  display: flex;
  margin: 0 10px;
  padding: 10px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.filter > select {
  padding: 10px;
  margin-left: 10px;
  border: 1px solid #bebebe;
  border-radius: 20px;
}

</style>