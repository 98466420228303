<template>
  <div class="creationClient">

    <h3><i class="fa-regular fa-file-lines"></i> CRÉATION UNE FICHE INTERVENTION</h3>

    <h3 class="titre">Informations client : </h3>

    <div class="informations">

      <div>
        <label for="adresse">Raison sociale / Nom</label>
        <input type="text" disabled v-model="client.raisonSocial">
      </div>

      <div>
        <label for="adresse">Adresse</label>
        <input type="text" disabled v-model="client.adresse">
      </div>

      <div>
        <label for="ville">Ville</label>
        <input type="text" disabled v-model="client.ville">
      </div>

      <div>
        <label for="codePostal">Code Postal</label>
        <input type="text" disabled v-model="client.codePostal">
      </div>

      <div>
        <label for="pays">Pays</label>
        <input type="text" disabled v-model="client.pays">
      </div>


      <div>
        <label for="nom">Référence client</label>
        <input type="text" disabled v-model="client.refClient">
      </div>

    </div>


    <h3 class="titre"> Informations d' affaire : </h3>

    <div class="informations-affaire">

        <div>
            <label for="nom">Numéro d'affaire</label>
            <input disabled type="text" v-model="affaire.numeroAffaire">
        </div>

    </div>

    <h3 class="titre"> Informations des missions : </h3>

    <div class="informations-missions">
        <div>
            <label for="nom">Liste des Missions</label>
            <input v-for="mission in missions" :key="mission._id" disabled type="text" v-model="mission.typeMission">
        </div>
    </div>


    <h3 class="titre">Informations de Interlocuteur : </h3>

    <div class="informations-interlocuteur">

        <div>
            <label for="nom">Nom</label>
            <input disabled type="text" v-model="interlocuteur.nom">
        </div>

        <div>
            <label for="adresse">Prénom </label>
            <input disabled type="text" v-model="interlocuteur.prenom">
        </div>

        <div>
            <label for="pays">Téléphone</label>
            <input disabled type="text" v-model="interlocuteur.telephone">
        </div>

    </div>


    <h3 class="titre"> Chargé d’affaire : </h3>

    <div class="Charge-affaire">

        <div>
            <label for="nom">Prénom et Nom</label>
            <input disabled type="text" v-model="chargeAffaire">
        </div>
    </div>

    <h3 class="titre"> Les date(s) d'interventions : </h3>

    <div class="date-interventions">
        <div>
            <label for="DE">DE</label>
            <input type="date" v-model="deDate">
        </div>
        <div>
            <label for="À">À</label>
            <input type="date" v-model="aDate" @change="handelGetInspecteurByDate()">
        </div>
    </div>

    <h3 class="titre"> Inspecteur(s) avec prestations interne: </h3>

    <div class="inspecteur">
        <div v-for="mission in missions" :key="mission._id">
            <input disabled type="text" v-model="mission.typeMission">
            <div>
              <ul v-for="ins in listeInspecteurs" :key="ins._id" :value="ins._id" class="list-inspecteurs">
                <li><input type="checkbox" :value="[ins._id, ins.nom, ins.prenom]" v-model="mission.inspecteurs"><p>{{ ins.nom+" "+ins.prenom }}</p></li>
              </ul>
            </div>
            <label for="La date de debut de controle">La date de debut de contrôle</label>
            <input type="date" v-model="mission.debut">
            <label for="La date de fin de contrôle">La date de fin de contrôle</label>
            <input type="date" v-model="mission.fin">
        </div>
    </div>

    <h3 class="titre"> Inspecteur(s) avec prestations Client (Externe): </h3>

    <div class="inspecteur">
        <div v-for="(libelle, index) in libelles" :key="libelle.name">
            <input disabled type="text" v-model="libelles[index].name">
            <div>
              <ul v-for="ins in listeInspecteurs" :key="ins._id" class="list-inspecteurs">
                <li><input type="checkbox" :value="[ins._id, ins.nom, ins.prenom]" v-model="libelles[index].inspecteurs"><p>{{ ins.nom+" "+ins.prenom }}</p></li>
              </ul>
            </div>
            <label for="La date de debut de controle">La date de debut de contrôle</label>
            <input type="date" v-model="libelle.debut">
            <label for="La date de fin de contrôle">La date de fin de contrôle</label>
            <input type="date" v-model="libelle.fin">
        </div>
    </div>

    <h3 class="titre"> Les commentaire(s) : </h3>

    <div class="commentaire">
        <div>
          <label for="À">Commentaire</label>
            <textarea v-model="commentaire" ></textarea>
        </div>
    </div>

    <h3 class="titre"> Générer la fiche d'intervention : </h3>

    <div class="inspecteur-missions">
        <div>
          <select v-model="inspecteur">
            <option v-for="insp in listeInspecteurs" :key="insp._id" :value="insp._id" > {{ insp.nom + " "+insp.prenom }}</option>
          </select>
        </div>
        <div>
            <input type="button" value="Générer la fiche d'intervention" @click="generateFicheInterventionInspecteur()">
        </div>
    </div>

    <h3 class="titre"> Finalement : </h3>

    <div class="enregistre">
        <input type="submit" value="Enregistrer" @click="enregistreIntervention()">
    </div>




    <ModalLanding v-if="flagModalLanding == true"/>
    <ModalErrors :errors="errors" v-if="flagModalErrors == true"/>



  </div>
</template>

<script>
import Service from "../../../../Service";
import ModalLanding from "../../../../views/components/ModalLanding.vue";
import ModalErrors from "../../../../views/components/ModalErrors.vue";

export default {
    props: {
      affaireId: String,
    },
    data() {
        return {
            flagModalLanding: false,
            flagModalErrors : false,
            errors : [],
            inspecteur : null,
            listeNomPrenomInspecteurs : [],
            listeInspecteurs : [],
            traitement : false,
            msgIntervention : null,
            flagAutreInspecteur : false,
            deDate: null,
            aDate: null,
            commentaire : null,
            chargeAffaire: null,
            missions: [],
            libelles : [],
            client: {
                    raisonSocial : null,
                    adresse : null,
                    ville : null,
                    codePostal : null,
                    pays : null,
                    email : null,
                    ice : null,
                    refClient : null,
                    _id : null
            },
            interlocuteur: {
                    nom : null,
                    prenom : null,
                    email : null,
                    codePostal : null,
                    fonction : null,
                    telephone : null,
                    clientId : null,
                    _id: null
            },
            mission: {
                    codeMission : null,
                    apparteurAffaire : null,
                    typeMission : null,
                    ht : null,
                    tva : null,
                    qte : null,
                    prix : null,
                    numeroAffaire : null,
            },
            libelle: {
                    name : null,
            },
            affaire : {
                numeroAffaire : null,
                apporteurAffaire : null,
                bonCommande : null,
                renseignerIntervention: null,
                importRapport: null
            },
            intervention :  {
                  inspecteur : null,
                  nameInterlocuteur : null,
                  telephoneInterlocuteur : null,
                  raisonSocial : null,
                  adresseClient : null,
                  commentaire: null,
                  deDate: null,
                  aDate: null,
                  nameInspecteur: null,
                  chargeAffaire: null,
                  missions: null,
                  interlocuteurId: null,
                  clientId: null,
                  numeroAffaire : null,
                  inspecteursMissions : [],
                  typeMissions : [],
                  codeMissions : [],
                  inspecteurlibelles : [],
                  dateInspecteurlibelles : []
            }
    };
},

 components: {
  ModalLanding,
  ModalErrors
 },

methods: {

  // download file extention DOCX
  downloadFile(response) {

      var newBlob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
      }

      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = "resume.docx";
      link.click();

      setTimeout(function() {
          window.URL.revokeObjectURL(data);
      }, 100);
  },

  // get inspecteurs with date calendriers
  handelGetInspecteurByDate() {

    this.listeInspecteurs = [];
    Service.getInspecteurByDate(this.deDate, this.aDate)
    .then((result) => {
        this.listeInspecteurs = result.data.inspecteursInfo;
    })
    .catch((error) => {
        console.log(error.message);
    });

  },

  // generate la fiche d'intervention de chaque inspecteur
  generateFicheInterventionInspecteur() {

    this.flagModalLanding = true;
    this.intervention.commentaire = this.commentaire;
    this.intervention.refClient = this.client.refClient;
    this.intervention.deDate =  this.deDate;
    this.intervention.aDate =  this.aDate;
    this.intervention.chargeAffaire =  this.chargeAffaire;
    this.intervention.numeroAffaire  =  this.affaire.numeroAffaire;
    this.intervention.nameInterlocuteur  =  this.interlocuteur.nom + " "+ this.interlocuteur.prenom;
    this.intervention.telephoneInterlocuteur  =  this.interlocuteur.telephone;
    this.intervention.adresseClient  =  this.client.adresse +","+ this.client.ville +" "+ this.client.codePostal +" "+ this.client.pays;
    this.intervention.raisonSocial  =  this.client.raisonSocial;
    this.intervention.inspecteur =  this.inspecteur;

      // liste Type Code Mission
      this.missions.forEach((element) => {
          this.intervention.codeMissions.push({
            "codeMission" : element.codeMission
          });
      });

      // liste Type Missions
      this.missions.forEach((element) => {
          this.intervention.typeMissions.push({
            "typeMission" : element.typeMission
          });
      });


    // select  libelles select by inspecteur select for generate la fiche Intervention
    this.libelles.forEach((el) => {
        el.inspecteurs.forEach((element) => {
            if(element[0] == this.intervention.inspecteur) {
              this.intervention.inspecteurlibelles.push(el.name);
            }
        });
    });

    console.log(this.libelles)

    this.libelles.forEach((el) => {
       this.intervention.dateInspecteurlibelles.push({
          debut : el.debut,
          fin : el.fin
       })
    });


    Service.generateFicheInterventionInspecteur(this.intervention)
    .then((result) => {

      this.intervention.typeMissions = [];
      this.intervention.codeMissions = [];
      this.intervention.inspecteurlibelles = [];
      this.intervention.dateInspecteurlibelles = [];
      this.intervention.inspecteur = null;

      if(result.data.cas == true) {
        Service.downloadFicheInterventionInspecteur()
        .then((response) => {
              this.flagModalLanding = false;
              this.downloadFile(response);
        })
        .catch((error) => {
             this.flagModalErrors = true;
             this.errors.push(error.message);
        });
      }
    })
    .catch((error) => {
      this.flagModalErrors = true;
      this.errors.push(error.message);
    });

  },
  
  enregistreIntervention() {

                  this.flagModalLanding = true;
                  this.intervention.affaireId = this.affaireId;
                  this.intervention.commentaire = this.commentaire;
                  this.intervention.refClient = this.client.refClient;
                  this.intervention.deDate =  this.deDate;
                  this.intervention.aDate =  this.aDate;
                  this.intervention.chargeAffaire =  this.chargeAffaire;
                  this.intervention.missions =  this.missions;
                  this.intervention.libelles =  this.libelles;
                  this.intervention.interlocuteurId =  this.interlocuteur._id;
                  this.intervention.clientId =  this.client._id;
                  this.intervention.numeroAffaire  =  this.affaire.numeroAffaire;
                  this.intervention.nameInterlocuteur  =  this.interlocuteur.nom + " "+ this.interlocuteur.prenom;
                  this.intervention.telephoneInterlocuteur  =  this.interlocuteur.telephone;
                  this.intervention.adresseClient  =  this.client.adresse +","+ this.client.ville +" "+ this.client.codePostal +" "+ this.client.pays;
                  this.intervention.raisonSocial  =  this.client.raisonSocial;

                  this.missions.forEach((element) => {

                      const listNomPrenom = [];

                      // liste Nom et Prenom avec format j.etttt
                      element.inspecteurs.forEach((el) => {
                          listNomPrenom.push(`${el[2].charAt(0)}.${el[1]}`)
                      });

                      // sommet all in liste
                      listNomPrenom.forEach((element) => {
                          this.listeNomPrenomInspecteurs.push(element);
                      });

                      // liste des ID inspecteurs
                      const listId = [];
                      element.inspecteurs.forEach((el) => {
                          listId.push(el[0])
                      });

                      // liste de Id inspecteurs
                      this.intervention.inspecteursMissions.push({
                        "inspecteurs" : listId
                      });

                  });

                  // liste Type Code Mission
                  this.missions.forEach((element) => {
                      this.intervention.codeMissions.push({
                        "codeMission" : element.codeMission
                      });
                  });

                  // liste Type Missions
                  this.missions.forEach((element) => {
                      this.intervention.typeMissions.push({
                        "typeMission" : element.typeMission
                      });
                  });

                  let uniqueChars = [...new Set(this.listeNomPrenomInspecteurs)];
                  const text = uniqueChars.toString();
                  const textWithoutSpace = text.split(',').join(', ');
                  this.intervention.nameInspecteur = textWithoutSpace;
      
        Service.enregistreIntervention(this.intervention)
        .then(() => {
            setTimeout(()=> {
                  this.flagModalLanding = false;
                  this.$router.go(this.$router.currentRoute);
            },10000);
        })
        .catch((error) => {
            if(error) {
                this.flagModalErrors = true;
                this.flagModalErrors.push(error.message);

                setTimeout(()=> {
                    this.flagModalErrors = false;
                },10000);
            }
        });

  },


},
created() {

    Service.readAdmin()
      .then((result) => {
          result.data.admins.forEach((element) => {
              if(element.statusCompte == true) {
                this.listeInspecteurs.push({
                  _id : element._id,
                  nom : element.nom,
                  prenom : element.prenom,
                  cheked : false
                });
              }
          });
      })
      .catch((error) => {
          console.log(error);
      });



    Service.getPrestationBonCommandes(this.affaireId)
    .then((result) => {
      this.libelles = result.data.libelles;
    })
    .catch((error) => {
      console.log(error.message);
    })


    Service.getAffaireById(this.affaireId)
    .then((result) => {
        this.affaire = result.data.affaire;
        this.chargeAffaire = result.data.affaire.apporteurAffaire;
        Service.getMission(result.data.affaire.numeroAffaire)
        .then((result) => {

              result.data.missions.forEach(element => {
                element.inspecteurs = [];
                this.missions.push(element);
              });

              Service.selectClient(result.data.missions[0].clientId)
              .then((result) => {
                this.client = result.data.client;
              })
              .catch((error) => {
                console.log(error.msg);
              });

              Service.getInterlocuteur(result.data.missions[0].interlocuteurId)
              .then((result) => {
                this.interlocuteur = result.data.interlocuteur;
              })
              .catch((error) => {
                console.log(error.msg);
              });
        })
        .catch((error) => {
             console.log(error.msg)
        })
    })
    .catch((error) => {
        console.log(error.msg)
    })
}

}
</script>

<style scoped>


.creationClient {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 3px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.creationClient h3:nth-child(1) {
  width: 100%;
  height: fit-content;
  padding: 5px;
  color: white;
  background-color: #243064;
  text-align: center;
}

.creationClient .titre {
  width: fit-content;
    height: fit-content;
    padding: 5px;
    color: white;
    width: 100%;
    text-align: center;
    background-color: #dddddd;
}

.succes {
  background-color: #69cd5b;
  color: white;
  padding: 10px;
  width: 100%;
  height: fit-content;
}

.echec {
  background-color: RED;
  color: white;
  padding: 10px;
  width: 100%;
  height: fit-content;
}


/* start information */
.informations {
  padding:20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.informations div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
}
.informations div label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
  color :#383838;
}


.informations div input {
  height: 40px;
  margin-bottom: 5px;
  border: 1px solid #696969;
  padding:5px;
}
.informations div input:focus-within {
  outline: 1px solid #cf1f21 ;
  border:0;

}
/* end information */


/* start informations d'affaire */
.informations-affaire {
  padding:20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.informations-affaire div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
}
.informations-affaire div label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
  color :#383838;
}


.informations-affaire div input {
  height: 40px;
  margin-bottom: 5px;
  border: 1px solid #696969;
  padding:5px;
}
.informations-affaire div input:focus-within {
  outline: 1px solid #cf1f21 ;
  border:0;

}
/* start informations d'affaire */


/* start informations Missions */
.informations-missions {
  padding:20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.informations-missions div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
}
.informations-missions div label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
  color :#383838;
}


.informations-missions div input {
  height: 40px;
  margin-bottom: 5px;
  border: 1px solid #696969;
  padding:5px;
}
.informations-missions div input:focus-within {
  outline: 1px solid #cf1f21 ;
  border:0;

}
/* fin informations Missions */



/* start informations Interlocuteur */
.informations-interlocuteur {
  padding:20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.informations-interlocuteur div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
}
.informations-interlocuteur div label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
  color :#383838;
}


.informations-interlocuteur div input {
  height: 40px;
  margin-bottom: 5px;
  border: 1px solid #696969;
  padding:5px;
}
.informations-interlocuteur div input:focus-within {
  outline: 1px solid #cf1f21 ;
  border:0;

}
/* fin informations Interlocuteur */

/* start informations Charge d' affaire */
.Charge-affaire {
  padding:20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.Charge-affaire div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
}
.Charge-affaire div label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
  color :#383838;
}


.Charge-affaire div input {
  height: 40px;
  margin-bottom: 5px;
  border: 1px solid #696969;
  padding:5px;
}
.Charge-affaire div input:focus-within {
  outline: 1px solid #cf1f21 ;
  border:0;

}
/* fin informations Charge d' affaire */


/* start informations date Intervention */
.date-interventions, .inspecteur-missions {
  padding:20px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}


.date-interventions div, .inspecteur-missions div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 40%;
}
.date-interventions div label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
  color :#383838;
}


.date-interventions div input {
  height: 40px;
  margin-bottom: 5px;
  border: 1px solid #696969;
  padding:5px;
}
.date-interventions div input:focus-within {
  outline: 1px solid #cf1f21 ;
  border:0;
}

.inspecteur-missions div select {
  height: 50px;
}

.inspecteur-missions div input {
  height: 50px;
  background-color: green;
  border-radius: 10px;
  cursor: pointer;
  color: white;
  border: 0px;
}
/* fin informations date Intervention */



/* start informations Charge d' affaire */
.inspecteur {
  padding:20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.inspecteur div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.inspecteur div div ul li  {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.inspecteur div div ul li > input  {
  margin-bottom: 0px;
  margin-left: 10px;
  margin-right: 10px;
}


.inspecteur div label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
  color :#383838;
}


.inspecteur > div > input {
  height: 40px;
  margin-bottom: 5px;
  border: 1px solid #696969;
  padding:5px;
  width: 80%;
}
.inspecteur div input:focus-within {
  outline: 1px solid #cf1f21 ;
  border:0;

}
/* fin informations Charge d' affaire */



/* start informations Charge d' affaire */
.commentaire {
  padding:20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.commentaire div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.commentaire div textarea {
  height: 50px;
}


/* fin informations Charge d' affaire */


.enregistre {
width: 100%;
 display: flex;
 justify-content: center;
}



.enregistre input {
  height: 40px;
  width: 30%;
  margin:10px auto;
  background-color: green;
  color: white;
  border: 0;
  cursor: pointer;
  border-radius: 10px;
}


textarea {
  padding:5px;
  font-size: 17px;
}

textarea:focus-within{
border:0;
outline:1px solid red;
}


</style>