<template>
  <div class="etat-depenser-inspecteur-chart">
    <h4>ÉTAT DE DÉPENSE PERSONNELLE</h4>
    <div>
        <select v-model="inspecteur" @change="handelSelectInspecteur">
            <option v-for="index in inspecteurs" :key="index._id" :value="index._id" > {{ index.nom+" "+index.prenom }} </option>
        </select>
    </div>
    <canvas id="myEtatDepenser"></canvas>
  </div>
</template>

<script>
import Service from "../../../../../../Service";
import Chart from "chart.js";

export default {

  name: "etat-depenser-inspecteur-chart",

  data() {
    return {
      inspecteur : null,
      inspecteurs :[],
      annee : null
    };
  },

  props: {
    selectYear : Number,
    selectMois : Number,
    selectInspecteur : String
  },

  methods: {
    handelSelectInspecteur() {
        this.$emit('select-inspecteur', this.inspecteur);
    }
  },

  created() {
      // Get all inspecteur and admin for caledrier
      Service.readAdmin()
        .then((response) => {
                this.inspecteurs = response.data.admins.filter(el =>  el.status != "sous-traitance");
        })
        .catch((error) => {
                console.log(error.message);
        });
  },

  mounted() {

      // get info Indicateur by current
      Service.CalculEtatDepenser(this.selectYear, this.selectMois, this.selectInspecteur)
      .then((result) => {
        const labels = [];
        const label = ["Avance", "Dotation", "frais déposé"]
        const avances = [];
        const dotations = [];
        const frais = [];
        const datasets = [];

        result.data.forEach((element) => {
            labels.push(element.mois);
            avances.push(element.avance);
            dotations.push(element.dotation);
            frais.push((-1) * element.fraisDepose);
        });

        const mergeAvancesDotationsFrais = [...avances, ...dotations, ...frais];
        // set colors background color
        for(let i = 0; i < 3; i++) {

            const randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1));
            const a = randomBetween(0, 255);
            const b = randomBetween(0, 255);
            const c = randomBetween(0, 255);

            const values = mergeAvancesDotationsFrais.slice(0, result.data.length);
            mergeAvancesDotationsFrais.splice(0, result.data.length);

                  datasets.push({
                    data: values,
                    label: label[i],
                    backgroundColor: `rgb(${a},${b},${c})`,
                  });
        
        }

        const data = {
            labels: labels,
            datasets: datasets
        };

        const config = {
            type: 'bar',
            data: data,
            options: {
                scales: {
                  xAxes: [{ 
                    stacked: true    
                  }],
                  yAxes: [{
                    stacked:true
                  }],
                }
              }
        };

        const ctx = document.getElementById("myEtatDepenser");
        var myEtatDepenser = new Chart(ctx, config);
        return myEtatDepenser;

      })
      .catch((error) => {
          console.log(error.message)
      });

  }


};
</script>

<style scoped>

.etat-depenser-inspecteur-chart {
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.etat-depenser-inspecteur-chart > h4 {
    width: 100%;
    justify-content: center;
    display: flex;
    color: #1e1ed3;
    font-size: x-large;
    opacity: 0.6;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
}

.etat-depenser-inspecteur-chart > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.etat-depenser-inspecteur-chart > div > select {
    padding: 10px;
    margin-left: 10px;
    border: 1px solid #bebebe;
    border-radius: 20px;
}

</style>