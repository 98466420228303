<template>
    <div class="creationClient">

        <h3><i class="fa-solid fa-person-circle-plus"></i>CRÉER UN TICKET</h3>

        <Traitement :msg="msgTicket" v-if="traitement == true" />

        <p v-if="echec" :class="{ echec: echec }">
            {{ error }}
        </p>

        <div class="formCreation">
            <div class="p"
                style="width:100%; margin:10px 0;font-weight: bold; color:black;background-color: #e9e9e9; ;padding:7px;">
                Informations Personnelles </div>

            <div>
                <label for="Nom">Nom</label>
                <input type="text" v-model="ticket.nom" disabled>
            </div>

            <div>
                <label for="Nom">Prénom</label>
                <input type="text" v-model="ticket.prenom" disabled>
            </div>

            <div>
                <label for="Nom">E-mail de Demandeur</label>
                <input type="text" v-model="ticket.email" disabled>
            </div>

            <div>
                <label for="Récepteur">Récepteur</label>
                <select v-model="ticket.recepteur">
                    <option v-for="item in recepteurs" :value="item.email" :key="item.email">{{ item.nom }} {{ item.prenom }}</option>
                </select>
            </div>

            
            <div>
                <label for="Nom">Objet</label>
                <input type="text" v-model="ticket.titre">
            </div>

            <div>
                <label for="Message">Message</label>
                <textarea v-model="ticket.message"></textarea>
            </div>

            <div>
                <label for="Media">Media (Vous pouvez ajouter n'importe quel média pour clarifier le problème)</label>
                <input type="file" class="form-control" multiple="multiple" placeholder="Télécharger Avatr" ref="media"
                    @change="previewMedia">
            </div>


            <div class="create">
                <input type="submit" value="Créer un Nouveau ticket" @click="create">
            </div>

            <div class="quitter">
                <input type="submit" value="Quitter" @click="quitter">
            </div>

        </div>

    </div>
</template>

<script>
import Service from "../../../../../Service";
import Traitement from "../../Affaire/Traitement.vue"

export default {
    data() {
        return {
            msgTicket: null,
            media: null,
            traitement: null,
            msgFicheSalarié: null,
            ticket: {
                nom: null,
                prenom: null,
                email: null,
                recepteur: null,
                titre : null,
                message: null,
                file: null,
            },
            succes: false,
            echec: false,
            error: null,
            recepteurs : []
        };
    },

    components: {
        Traitement
    },

    methods: {

        quitter() {
            this.$router.push("/da");
        },

        // preciew file
        previewMedia() {
            this.ticket.file = this.$refs.media.files[0];
        },

        // create Ticket
        create() {

            const flag = this.ticket.email != null && this.ticket.recepteur != null && this.ticket.titre != null && this.ticket.message != null && this.ticket.file != null;

            if (flag == false) {
                alert("Veuillez remplir le champ");
            } else {

                Service.createTicket(this.ticket.email, this.ticket.recepteur, this.ticket.titre,  this.ticket.message, this.ticket.file)
                    .then((response) => {

                        var finish = false;
                        this.traitement = true;
                        this.msgTicket = "Pour terminer le processus Veuillez de patienter;";
                        this.ticket.titre = null;
                        this.ticket.message = null;
                        this.ticket.email = null;
                        this.ticket.recepteur = null;
                        this.ticket.file = null;

                        if (response) {
                            setTimeout(() => { console.log("wait"); }, 5000);
                            finish = true
                        }

                        if (finish) {
                            setTimeout(() => {
                                this.msgTicket = null;
                                this.traitement = false;
                            }, 5000);
                        }
                    })
                    .catch((error) => {
                        this.error = error.message;
                        console.error(`HTTP error: ${error.name} => ${error.message}`);
                        throw "fail request at: GET /refreshtime";
                    });
            }

        }

    },

    created() {

        this.ticket.nom = sessionStorage.getItem("nom");
        this.ticket.prenom = sessionStorage.getItem("prenom");
        this.ticket.email = sessionStorage.getItem("email");

        Service.readAdminNotSousTritance()
        .then((result) => {
            result.data.admins.forEach((el) => {
                this.recepteurs.push({ 
                    nom : el.nom,
                    prenom : el.prenom,
                    email : el.email
                });
            })
        })
        .catch((error) => {
            console.log(error)
        })
    }


};
</script>

<style scoped>
.creationClient {
    width: 100%;
    padding: 5px;
}

.creationClient h3 {
    width: 100%;
    margin: auto;
    color: white;
    background: linear-gradient(346deg, rgba(207, 31, 33, 1) 0%, rgba(24, 86, 161, 1) 100%);
    text-align: center;
    text-align: center;
}

.succes {
    background-color: #69cd5b;
    color: white;
    padding: 10px;
    width: 100%;
    height: fit-content;
}

.echec {
    background-color: rgb(248, 51, 51);
    color: white;
    margin: 10px 0;
    padding: 10px;
    width: 80%;
    height: fit-content;
}

.formCreation {

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;

}

.formCreation div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.formCreation div label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 700;
    color: #494949;
}

.formCreation div input {
    height: 40px;
    margin-bottom: 5px;
    border: 1px solid #243064;
    padding: 5px;
}


.formCreation div input:focus-within {
    outline: 1px solid #cf1f21;
    border: 0;
}

.formCreation div select {
    height: 40px;
}

textarea {
    height: 200px;
}

.create,
.quitter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.create>input {
    background-color: green;
    color: white;
    width: 200px;
    border: 0px solid;
    margin-top: 5px;
    border-radius: 15px;
    cursor: pointer;
}

.quitter>input {
    background-color: red;
    color: white;
    width: 200px;
    border: 0px solid;
    margin-top: 5px;
    border-radius: 15px;
    cursor: pointer;
}
</style>