import { render, staticRenderFns } from "./GestionSousTraitance.vue?vue&type=template&id=2401fbd2&scoped=true&"
import script from "./GestionSousTraitance.vue?vue&type=script&lang=js&"
export * from "./GestionSousTraitance.vue?vue&type=script&lang=js&"
import style0 from "./GestionSousTraitance.vue?vue&type=style&index=0&id=2401fbd2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2401fbd2",
  null
  
)

export default component.exports