<template>
  <div class="indicateur">
    <div class="titre">
      <h2>LES INDICATEURS DES FACTURES</h2>
    </div>

    <div class="text">

      <span><i class="fa-regular fa-lightbulb"></i>Note :</span>
      Elle permet de gérer les factures, et de suivre de progrès de la gestion des règlements, relance des clients
      avec une vue centralisée et des fonctionnalités d’alertes gérer cas modalistes de paiements de nos clients.

    </div>


    <div class="filter">
      <label for="nom"><i class="fa-solid fa-magnifying-glass" style="font-size: 20px;"></i> Rechercher par des années: </label>
      <select v-model="annee" @change="onChangeAnnee">
        <option v-for="item in maxAnnee" :key="item" :value="item"> {{ item }} </option>
      </select>
      <select v-model="mois" @change="onChangeMois">
        <option v-for="item in maxMois" :key="item" :value="item"> {{ item }} </option>
      </select>
    </div>

    <div class="statstique">
        <EtatAvancementFacture :selectYear="annee" :selectMois="mois"  v-if="flagEtatAvancementFacture == true"/>
        <EtatSituationFacture :selectYear="annee" :selectMois="mois"  v-if="flagEtatSituationFacture == true"/>
        <EtatRetartdFacture :selectYear="annee" :selectMois="mois"  v-if="flagEtatRetartdFacture == true"/>

      <!-- <div>
        <p v-if="currentMounths == 1" style="background-color: red;">Janvier</p>
        <p v-if="currentMounths != 1">Janvier</p>
        <p><span><i class="fa-solid fa-file-invoice"></i> Total Facture:</span> {{ facture.factureJanvier }} </p>
        <p><span><i class="fa-solid fa-file-invoice-dollar"></i> En attente:</span> {{ facture.attenteJanvier }}</p>
        <p><span> <i class="fa-solid fa-hand-holding-dollar"></i> Émise:</span> {{ facture.emiseJanvier }}</p>
        <p><span><i class="fa-solid fa-cash-register"></i> En caissée :</span> {{ facture.paiementJanvier }} </p>
      </div> -->
    </div>
  </div>
</template>

<script>
import EtatAvancementFacture from "./charts/EtatAvancementFacture.vue";
import EtatRetartdFacture from "./charts/EtatRetartdFacture.vue";
import EtatSituationFacture from "./charts/EtatSituationFacture.vue";
export default {
  name: "indicateur",
  data() {
    return {
      flagEtatAvancementFacture : false,
      flagEtatRetartdFacture : false,
      flagEtatSituationFacture : false,
      maxAnnee: [],
      maxMois: [],
      mois: null,
      annee: null
    }
  },

  components : {
    EtatAvancementFacture,
    EtatRetartdFacture,
    EtatSituationFacture
  },

  methods: {

      onChangeMois() {
            this.flagEtatAvancementFacture = false;
            this.flagEtatRetartdFacture = false;
            this.flagEtatSituationFacture = false;
          this.$nextTick(() => {
            this.flagEtatAvancementFacture = true;
            this.flagEtatRetartdFacture = true;
            this.flagEtatSituationFacture = true;
          });
      },

      onChangeAnnee() {
            this.flagEtatAvancementFacture = false;
            this.flagEtatRetartdFacture = false;
            this.flagEtatSituationFacture = false;
          this.$nextTick(() => {
            this.flagEtatAvancementFacture = true;
            this.flagEtatRetartdFacture = true;
            this.flagEtatSituationFacture = true;
          });
      }
  },

  created() {

    // get year anee current
    const today = new Date();
    this.annee = today.getUTCFullYear();
    this.mois = today.getMonth() + 1;

    for (let i = this.annee; i >= 1970; i--) {
      this.maxAnnee.push(i);
    }

    for (let i = 12; i >= 1; i--) {
      this.maxMois.push(i);
    }


    if(this.annee != null && this.mois != null) {
        this.flagEtatAvancementFacture = true;
        this.flagEtatRetartdFacture = true;
        this.flagEtatSituationFacture = true;
    }

  }

}
</script>

<style scoped>

.indicateur {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.indicateur>.text {
  background-color: #fff27a;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}

.text span {
  font-weight: bold;
}

.titre h2 {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
  color: white;
  background: linear-gradient(346deg, rgba(207, 31, 33, 1) 0%, rgba(24, 86, 161, 1) 100%);
  text-align: center;
  padding: 10px;
  font-size: 25px;
}


.indicateur>.filter {

  background-color: rgb(238, 238, 238);
  display: flex;
  margin: 0 10px;
  padding: 10px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}



.indicateur>.filter>select {

  padding: 10px;
  margin-left: 10px;
  border: 1px solid #bebebe;
  border-radius: 20px;
}

.indicateur> .statstique {
      max-width: inherit;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
}




.fa-check {
  font-size: 10px;
}

svg {
  color: #243064;
}

</style>