<template>
  <div class="admin">
    <div class="container">

      <div class="header">
        <Nav />
      </div>

      <div class="menu-content">
        <div class="menu">
          <Menu
            @affairesMois="handelAffairesMois()"
            @numberInspecteurs="handelNumberInspecteurs()"
          />
        </div>
        <div class="content">

          <!--  -->
          <AffairesMois v-if="flagAffairesMois" />
          <NumberInspecteurs v-if="flagNumberInspecteurs" />
          <Indicateur v-if="flagIndicateur" />
          <!--  -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from "@/components/Admin/Menu.vue";
import Nav from "@/components/Admin/Nav.vue";

import AffairesMois from "@/components/Admin/content/statstique/production/AffairesMois.vue";
import NumberInspecteurs from "@/components/Admin/content/statstique/production/NumberInspecteurs.vue";
import Indicateur from "@/components/Admin/content/statstique/production/Indicateur.vue";

export default {
  name: "admin",
  data() {
    return {
      flagAffairesMois : false,
      flagNumberInspecteurs : false,
      flagIndicateur : true,
    };
  },
  components: {
    Menu,
    Nav,
    AffairesMois,
    NumberInspecteurs,
    Indicateur
  },

  methods: {

    handelAffairesMois() {
      this.flagAffairesMois = true;
      this.flagNumberInspecteurs = false;
      this.flagIndicateur = false;
    },

    handelNumberInspecteurs() {
      this.flagAffairesMois = false;
      this.flagNumberInspecteurs = true;
      this.flagIndicateur = false;
    }

  },


created() {

  if(!sessionStorage.getItem("token")) {
        this.$router.push("/")
  }

}



};
</script>

<style scoped>
.admin .container .header {
  width: 100%;
  height: 80px;
  padding: 0px;
  margin: 0px;
}

.admin .container .menu-content{
display: grid;
grid-template-columns: 20% 80%;
}


.admin .container .menu-content menu {
width: 20%;

}
.admin .container .menu-content .content {
  width: 100%;


}
</style>