<template>

    <div class="gestionInspecteur">

      <h3  v-if="flagEditRapport == false">FICHES DES RAPPORTS</h3>

      <p v-if="succes" :class="{ succes: succes }">
        {{ msg }}
      </p>

      <p v-if="echec" :class="{ echec: echec }">
        {{ msg }}
      </p>

      <div class="rechercher-table" v-if="flagEditRapport == false">

            <div class="rechercher">
                <input type="text" v-model="rechercher" placeholder="Recherche un rapport">
            </div>

            <div class="filter">
              <label for="nom"> 
                <i class="fa-solid fa-magnifying-glass" style="font-size: 20px;"></i> Rechercher par des années:
              </label>
              <select v-model="annee">
                <option value="tout"> Tout </option>
                <option v-for="item in maxAnnee" :key="item" :value="item"> {{ item }} </option>
              </select>
              <button class="telecharger" @click="downloadSuiviRapport">
                Télécharger suivi Rapport
              </button>
            </div>

            <table id="inspecteurs">
              <tr>
                <th> </th>
                <th> Numéro affaire</th>
                <th> Référence Rapport</th>
                <th> Désignation </th>
                <th> Date Intervention </th>
                <th> Date Production Contrôle </th>
                <th> Catégorie </th>
                <th> Type Rapport </th>
                <th> Responsable Client </th>
                <th> Nom de file </th>
                <th> Date </th>
                <th>Actions</th>
              </tr>
              <tr v-for="(item, i) in filterRapports" :key="i">
                <td><input type="checkbox" :value="[item._id, item.filename]" v-model="checkedRapports" style="width: 20px;"></td>
                <td>{{ item.numeroAffaire }}</td>
                <td>{{ item.referenceRapport }}</td>
                <td>{{ item.designation }}</td>
                <td>{{ new Date(item.dateIntervention).toLocaleDateString() }} </td>
                <td>{{ new Date(item.dateProductionControle).toLocaleDateString() }}</td>
                <td>{{ item.category }}</td>
                <td>{{ item.typeRapport }}</td>
                <td>{{ item.responsableClient }}</td>
                <td>{{ item.filename }}</td>
                <td>{{ new Date(item.date).toLocaleDateString() }}</td>
                <td>
                  <a @click="deleteRapport(i, item._id, item.filename)" title="Supprimer"><i class="fa-solid fa-trash"></i></a>
                  <a @click="editRapport(item._id)" title="Modifier"><i class="fa-solid fa-pen-to-square"></i></a>
                  <a v-show="item.confirmation == '1'" @click="notConfirm(item._id, i)" title="Non Confirmer"><i class="fa-solid fa-check"></i></a>
                  <a v-show="item.confirmation == '0'" @click="confirm(item._id, i)" title="Confirmer"><i class="fa-solid fa-xmark"></i></a>
                  <a @click="resendEmail(item._id)" title="Renvoyer Email"><i class="fa-solid fa-envelope" title="Renvoye Email"></i></a>
                  <a @click="showPdf(item.filename)" title="Telecharger"><i class="fa-regular fa-file-pdf"></i></a>
                </td>
              </tr>
            </table>

            <div class="deleteAll" v-show="checkedRapports.length > 1">
              <input type="submit" value="Supprimer tout" @click="deleteRapports()" title="Supprimer Tout">
            </div>


      </div>

      <ul class="pagination"  v-if="flagEditRapport == false">
            <button @click="selectPageLeft()"><i class="fa-solid fa-backward"></i></button>
              <li v-for="n in numberPage" :key="n" @click="selectPage(n)">
                <p v-show="numberPageSelect[n-1] == false">{{ n }}</p>
                <p  v-show="numberPageSelect[n-1] == true" style="background-color: #0300db; color: white;">{{ n }}</p>
              </li>
            <button @click="selectPageRight()"><i class="fa-solid fa-forward"></i></button>
      </ul>



    <!--  Start Edit SousTraitance   -->
      <EditRapport :rapportId="rapportId" v-if="flagEditRapport == true" />
    <!--  End Edit SousTraitance   -->

    <!--  Start Info Delete Model   -->
      <DeleteModel v-if="flagModelDelete == true" :text="textDeleteModel" @closeDeleteModel="closeDeleteModel()" @validerDeleteModel="validerDeleteModel()" />
    <!--  End Info Delete Model   -->

  </div>

</template>

<script>
import Service from "../../../../Service";
import EditRapport from "./EditRapport.vue";
import DeleteModel from "../../../../views/components/DeleteModel.vue";
export default {
  name: "gestionAdmin",
  components: {
    EditRapport,
    DeleteModel
  },
  data() {
    return {
      numberPageSelect : [],
      rapportDelete : null,
      rapportId : null,
      filename : null,
      flagModelDelete : false,
      textDeleteModel : null,
      pageIndex : 0,
      itemsPerPage : 50,
      numberPage: null,
      succes: false,
      echec: false,
      msg: null,
      rechercher: null,
      preRapports : [],
      rapports: [],
      flagEditRapport: false,
      checkedRapports : [],
      maxAnnee : [],
      annee : null
    };
  },

  computed : {
            filterRapports() {

            return this.rapports.filter((item) => {

              if(this.annee == "tout") {

                  if(!this.rechercher) {
                    return item;
                  } else {

                    if(item != undefined) {

                      const numeroAffaire = item.numeroAffaire != undefined ? item.numeroAffaire : "";
                      const dateIntervention = item.dateIntervention != undefined ? new Date(item.dateIntervention).toLocaleDateString() : "";
                      const dateProductionControle = item.dateProductionControle != undefined ? new Date(item.dateProductionControle).toLocaleDateString() : "";
                      const referenceRapport = item.referenceRapport != undefined ? item.referenceRapport : "";
                      const designation = item.designation != undefined ? item.designation : "";
                      const category = item.category != undefined ? item.category : "";
                      const typeRapport = item.typeRapport != undefined ? item.typeRapport : "";
                      const responsableClient = item.responsableClient != undefined ? item.responsableClient : "";
                      const filename = item.filename != undefined ? item.filename : "";


                      return !numeroAffaire.toString().toLowerCase().indexOf(this.rechercher.toLowerCase().toString()) ||
                      !referenceRapport.toString().toLowerCase().indexOf(this.rechercher.toLowerCase().toString()) ||
                      !designation.toString().toLowerCase().indexOf(this.rechercher.toLowerCase().toString()) ||
                      !category.toString().toLowerCase().indexOf(this.rechercher.toLowerCase().toString()) ||
                      !filename.toString().toLowerCase().indexOf(this.rechercher.toLowerCase().toString()) ||
                      !typeRapport.toString().toLowerCase().indexOf(this.rechercher.toLowerCase().toString()) ||
                      !responsableClient.toString().toLowerCase().indexOf(this.rechercher.toLowerCase().toString()) ||
                      !dateIntervention.indexOf(this.rechercher) ||
                      !dateProductionControle.indexOf(this.rechercher);

                    }

                  }
              } else {
                
                if(!this.rechercher) {
                  if(item != undefined) {
                      if(this.annee === new Date(item.dateProductionControle).getUTCFullYear()) {
                          return item;
                      }
                  }
                } else {
                    if(item != undefined) {

                      const numeroAffaire = item.numeroAffaire != undefined ? item.numeroAffaire : "";
                      const dateIntervention = item.dateIntervention != undefined ? new Date(item.dateIntervention).toLocaleDateString() : "";
                      const dateProductionControle = item.dateProductionControle != undefined ? new Date(item.dateProductionControle).toLocaleDateString() : "";
                      const referenceRapport = item.referenceRapport != undefined ? item.referenceRapport : "";
                      const designation = item.designation != undefined ? item.designation : "";
                      const category = item.category != undefined ? item.category : "";
                      const typeRapport = item.typeRapport != undefined ? item.typeRapport : "";
                      const responsableClient = item.responsableClient != undefined ? item.responsableClient : "";
                      const filename = item.filename != undefined ? item.filename : "";


                      return !numeroAffaire.toString().toLowerCase().indexOf(this.rechercher.toLowerCase().toString()) ||
                      !referenceRapport.toString().toLowerCase().indexOf(this.rechercher.toLowerCase().toString()) ||
                      !designation.toString().toLowerCase().indexOf(this.rechercher.toLowerCase().toString()) ||
                      !category.toString().toLowerCase().indexOf(this.rechercher.toLowerCase().toString()) ||
                      !filename.toString().toLowerCase().indexOf(this.rechercher.toLowerCase().toString()) ||
                      !typeRapport.toString().toLowerCase().indexOf(this.rechercher.toLowerCase().toString()) ||
                      !responsableClient.toString().toLowerCase().indexOf(this.rechercher.toLowerCase().toString()) ||
                      !dateIntervention.indexOf(this.rechercher) ||
                      !dateProductionControle.indexOf(this.rechercher);

                    }
                }
              
              }

            });
      }
  },
  methods: {

    downloadFileExcel(response) {
      var newBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = "document.xlsx";
      link.click();
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
      }, 100);
    },

    // download suivi Rapports
    downloadSuiviRapport() {
        Service.downloadSuiviRapport(this.annee)
        .then((result) => {
          console.log(result)
          if(result.data.msg == true) {
              // Generate suivi Rapports
              Service.generateSuiviRapport()
              .then((response) => {
                  this.downloadFileExcel(response);
              })
              .catch((error) => {
                  console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },



    selectPageLeft() {

        for(let i = 0; i < this.numberPage; i++) {
              this.numberPageSelect[i] = false;
        }

        this.numberPageSelect[0] = true;

        this.rapports = [];

        for(let i = 0 * this.itemsPerPage; i < (0 * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.rapports.push(this.preRapports[i]);
        }
        
    },

    selectPageRight() {

        for(let i = 0; i < this.numberPage; i++) {
              this.numberPageSelect[i] = false;
        }

        const indice = this.numberPage - 1;
        this.numberPageSelect[indice] = true;
        this.rapports = [];

        for(let i = indice * this.itemsPerPage; i < (indice * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.rapports.push(this.preRapports[i]);
        }
    },

    selectPage(value) {
        const select = value - 1;
        // change current select for change color
        for(let i = 0; i < this.numberPage; i++) {
              this.numberPageSelect[i] = false;
        }

        this.numberPageSelect[select] = true;
        this.rapports = [];

        for(let i = select * this.itemsPerPage; i < (select * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.rapports.push(this.preRapports[i]);
        }
    },

    update() {
      Service.updateFournisseur(this.fournisseur, this.fournisseurId)
      .then((response) => {
          this.traitement = true;
          this.msgAdmin = "Veuillez patienter quelques secondes pour enregister";
          if(response) {
              setTimeout(() => {
                  return this.$router.go(this.$router.currentRoute);
              }, 10000)
          }
      })
      .catch((error) => {
          this.error = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
      });
    },

    closeDeleteModel() {
      this.flagModelDelete = false;
    },

    validerDeleteModel() {

      this.flagModelDelete = false;

      if(this.checkedRapports.length > 0) {

          // // Delete in Fron-end
          for(let i = 0; i < this.checkedRapports.length; i++ ) {

            const index = this.rapports.findIndex(object => {
              return object._id === this.checkedRapports[i][0];
            });
            // //delete in front end
            this.rapports.splice(index, 1);
          }

          // delete in db backend
          Service.deleteRapports(this.checkedRapports)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {

                this.msg = error.message;
                console.error(`HTTP error: ${error.name} => ${error.message}`);
                throw "fail request at: GET /refreshtime";

          });

      } else {
            
            this.rapports.splice(this.rapportDelete, 1);
            Service.deleteRapport(this.rapportId, this.filename)
            .then((result) => {
                this.msg = result.data.msg;
            })
            .catch((error) => {

                this.msg = error.message;
                console.error(`HTTP error: ${error.name} => ${error.message}`);
                throw "fail request at: GET /refreshtime";

            });

      }
    },

    resendEmail(rapportId) {
        Service.ResendEmail(rapportId)
          .then((result) => {
              console.log(result.data);
          })
          .catch((error) => {
              console.log(error)
        });
    },

    // delete more one admins
    notConfirm(rapportId, index) {
        Service.NotConfirm(rapportId)
          .then((result) => {
             if(result) {
              this.rapports[index].confirmation = false;
             }
          })
          .catch((error) => {
              console.log(error)
        });
    },

    // delete more one admins
    confirm(rapportId, index) {
        Service.Confirm(rapportId)
          .then((result) => {
            if(result) {
              this.rapports[index].confirmation = true;
            }
          })
          .catch((error) => {
              console.log(error)
          });
    },

    // delete more one admins
    deleteRapports() {
      this.flagModelDelete = true;
      this.textDeleteModel = "Êtes-vous sûr de supprimer tout ?";
    },

    // delete one admin
    deleteRapport(i, itemId, filename) {
      this.flagModelDelete = true;
      this.textDeleteModel = "Êtes-vous sûr de supprimer ?";
      this.rapportDelete = i;
      this.rapportId = itemId
      this.filename = filename
    },

    // edit one admin
    editRapport(rapportId) {
        this.flagEditRapport = true;
        this.rapportId = rapportId;
    },

    showPdf(filename) {
        Service.displayRapport(filename)
          .then((result) => {
             console.log(result);
          })
          .catch((error) => {
              console.log(error)
        });
    }


    },
//clients
  created() {

      // get year anee current
      const today = new Date();
      this.annee = today.getUTCFullYear();

      for (let i = this.annee; i >= 2019; i--) {
        this.maxAnnee.push(i);
      }

      this.annee = "tout";

      Service.readRapports()
      .then((result) => {

          result.data.rapports.forEach(element => {
                  this.preRapports.push(element);
          });

          this.numberPage = Math.ceil(result.data.rapports.length / this.itemsPerPage);

          for(let i = 0; i < this.numberPage; i++) {
              this.numberPageSelect.push(false);
          }
          
          this.numberPageSelect[0] = true;

          for(let i = this.pageIndex * this.itemsPerPage; i < (this.pageIndex * this.itemsPerPage) + this.itemsPerPage; i++) {
              this.rapports.push(this.preRapports[i]);
          }

      })
      .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
      });
  }


};
</script>

<style scoped>
.succes {
  background-color: green;
  color: white;
  padding: 10px;
  height: fit-content;
  width: 100%;
}
.echec {
  background-color: red;
  color: white;
  padding: 10px;
  height: fit-content;
  width: 100%;
}
.gestionInspecteur {
  width: 100%;
  height: 100%;
  padding-top: 0px;
  padding-left: 0px;
  margin: 0px;
  padding: 2px;
  position: relative;
}
.gestionInspecteur h3 {
  width: 100%;
  margin:auto;
  color: white;
  background: linear-gradient(346deg, rgba(207,31,33,1) 0%, rgba(24,86,161,1) 100%);    text-align: center;
  text-align: center;
  padding:15px;
  margin-bottom: 20px;
}
pecteur .rechercher-table {
  width: 100%;
}
.gestionInspecteur .rechercher-table .rechercher {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}
.gestionInspecteur .rechercher-table .rechercher input {
  width: 50%;
  height: 40px;
  outline: 0;
  border: 1px solid #243064;
  font-size: 16px;
  background-color: white;
  padding: 5px;
  color :#243064;
}
.gestionInspecteur .rechercher-table table  {
  width: 100%;
}
.gestionInspecteur .rechercher-table table tr  {
  width: 100%;
}
.gestionInspecteur .rechercher-table table tr th {
    background-color: #243064;
    color: white;
    border-right: 1px solid white;
    padding: 10px 4px;
}
table{
  border-collapse: collapse;
}
.gestionInspecteur .rechercher-table table tr td {
    color: black;
    padding: 5px;
}

.gestionInspecteur .rechercher-table table tr:nth-child(even) {
background-color: #f8f8f8;
}
.gestionInspecteur .rechercher-table table tr td a {
  cursor: pointer;

  margin-right: 10px;
}
.fa-trash {
  color: red;
}.fa-trash:hover {
  color: rgb(230, 88, 88);
}
.fa-pen-to-square {
  color: rgb(255, 187, 0);
}
.fa-circle-check {
  color: green;
}
.gestionInspecteur .rechercher-table ul {
  width: 100%;
  height: fit-content;
  text-align: center;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.gestionInspecteur .rechercher-table ul li {
  color:black;
 margin-left: 5px;
 cursor: pointer;
 font-size: 18px;
}
.gestionInspecteur .rechercher-table ul li:hover {
  color:red;
 margin-left: 5px;
 transition: 0.3s;
}
.gestionInspecteur .rechercher-table .deleteAll {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.gestionInspecteur .rechercher-table .deleteAll input {
  width: fit-content;
  padding: 10px;
  height: 40px;
  background-color: red;
  color: white;
  border: 0;
  cursor: pointer;
}

#inspecteurs > tr > td:nth-child(10) > a:nth-child(1) > svg  {
  color: red;
  font-size: 20px;
}
#inspecteurs > tr > td:nth-child(10) > a:nth-child(2) > svg  {
  color: blue;
  font-size: 20px;
}
#inspecteurs > tr > td:nth-child(10) > a:nth-child(3) > svg  {
  color: green;
  font-size: 20px;
}
#app > div > div > div.menu-content > div.content > div > h3 {
    background-color: #ff0000d4;
    padding: 15px;
}
#app > div > div > div.menu-content > div.content {
  background-color: white;
}
#inspecteurs > tr > td {
    color: #000000;
    border-bottom: 1px solid #acacac;
  text-transform: capitalize;
}
#app > div > div > div.menu-content > div.content > div {
  background-color: white;
}
#inspecteurs > tr > td:nth-child(8) > a:nth-child(1) > svg {
  color: red;
}
#inspecteurs > tr > td:nth-child(8) > a:nth-child(3) > svg {
  color: green;
}
#inspecteurs > tr > td:nth-child(8) > a:nth-child(4) > svg {
  color: red;
}
#inspecteurs > tr > td:nth-child(8) > a:nth-child(5) > svg {
  color: orange;
}

.pagination{
  display: flex;
  align-items: center;
  justify-content: center;
  margin:10px auto;
  width: fit-content;
  flex-wrap: wrap;
  padding:5px 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.pagination p{
  border:1px solid rgb(151, 151, 151);
  margin: 0 4px;
  padding:5px 10px;
  border-radius: 20px;
  cursor: pointer;
}

.pagination p:hover , button:hover{
    background-color: #243064;
    color:white;
}

.pagination button {
  border:0;
  padding:5px;
  border:1px solid rgb(170, 170, 170);
  margin-left: 15px;
  color:gray;
  cursor: pointer;
}

.pagination button:nth-child(1) {
    margin-right: 15px;
    margin-left: 0;
}

.filter {
  background-color: rgb(238, 238, 238);
  display: flex;
  margin: 0 10px;
  padding: 10px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.filter > select {
  padding: 10px;
  margin-left: 10px;
  border: 1px solid #bebebe;
  border-radius: 20px;
}

.telecharger {
  border: 0px;
  background-color: green;
  color: white;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  cursor: pointer;
  font-size: large;

}


</style>