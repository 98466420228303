<template>
    <transition name="modal-fade">
      <div class="modal-backdrop">
        <div class="modal">
  
          <header class="modal-header">
            <slot name="header">
                Types de filtres            
            </slot>
            <button type="button" class="btn-close" @click="closeGenerateListeOffreModal">
              x
            </button>
          </header>
  
          <section class="modal-body">
            <ul>
                <li>
                    <input type="radio" value="prospection" v-model="valueType">
                    <span>Prospection</span><br>
                </li>
                <li>
                    <input type="radio" value="retune" v-model="valueType">
                    <span>Retune</span>
                </li>
            </ul>
          </section>
  
          <footer class="modal-footer">
            <button type="button" class="btn-green" @click="validerGenerateListeOffreModal">
              Oui
            </button>
            <button type="button" class="btn-red" @click="closeGenerateListeOffreModal">
              Non
            </button>
          </footer>
  
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    name: 'Modal',
    data() {
        return {
            valueType : null,
        }
    },

    methods: {

      closeGenerateListeOffreModal() {
        this.$emit('closeGenerateListeOffreModal');
      },

      validerGenerateListeOffreModal() {


        if(this.valueType == null) {
            alert("Veuilez vous saisir le type de filter");
        } else {
            this.$emit('validerGenerateListeOffreModal', this.valueType);
        }


      }
  
  
    }
  };
  </script>
  
  <style scoped>
  
    .modal-backdrop {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
  
    }
  
    .modal {
      background: #FFFFFF;
      min-width: 500px;
      max-width: 600px;
      max-height: 400px;
      overflow: auto;
    }
  
    .modal-header,
    .modal-footer {
      padding: 15px;
      display: flex;
    }
  
    .modal-header {
      position: relative;
      border-bottom: 1px solid #eeeeee;
      color: #fd0d0d;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      justify-content: center;
      text-transform: capitalize;
    }
  
    .modal-footer {
      border-top: 1px solid #eeeeee;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  
    .modal-body {

      position: relative;
      display: flex;
      flex-direction: column;
      padding: 0px 30px;
      font-size: 18px;
  
    }
  
  

    .btn-close {
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      font-size: 20px;
      padding: 10px;
      cursor: pointer;
      font-weight: bold;
      color: #4AAE9B;
      background: transparent;
    }
  
    .modal-footer > .btn-green {
      border: 0px;
      font-size: large;
      width: 100px;
      background-color: green;
      color: white;
      padding: 10px;
      cursor: pointer;
      margin-right: 5px;
    }
  
    .modal-footer > .btn-red {
      border: 0px;
      font-size: large;
      width: 100px;
      background-color: red;
      color: white;
      padding: 10px;
      cursor: pointer;
      margin-left: 5px;
    }

    .ajouter {
      border: 0px;
      font-size: large;
      width: 100px;
      background-color: blue;
      color: white;
      padding: 10px;
      cursor: pointer;
      margin-left: 5px;
      margin-top: 10px;
    }

    .modal-body ul li input:nth-child(1) {
      padding: 5px 15px;
      margin: 4px 10px;
    }

    .modal-body ul li input:nth-child(2) {
      padding: 5px 15px;
      margin: 4px 4px;
      width: 80%;
    }
    .modal-body ul li svg {
      margin-left: 5px;
      font-size: 20px;
      cursor: pointer;
    }
  
     
  </style>