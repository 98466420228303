<template>
  <div class="indicateur">

    <div class="titre">
      <h2>LES INDICATEURS RH</h2>
    </div>

    <div class="filter">

            <label for="year">Rechercher par des années: </label>
            <select v-model="annee" @change="onChangeYear">
                <option v-for="item in maxYears" :key="item" :value="item" > {{ item }} </option>
            </select>

            <label for="mois">ou des mois: </label>
            <select v-model="mois" @change="onChangeMois">
                <option v-for="item in maxMois" :key="item" :value="item" > {{ item }} </option>
            </select>

      </div>


      <div class="statstique">
        <EtatAbsence :selectYear="annee" :selectMois="mois"  v-if="flagEtatAbsence == true"/>
        <EtatRecrutement :selectYear="annee" :selectMois="mois"  v-if="flagEtatAbsence == true"/>
        <EtatCompetence :selectYear="annee" :selectMois="mois"  v-if="flagEtatCompetence == true"/>
        <EtatDepenser :selectYear="annee" :selectMois="mois" :selectInspecteur="inspecteur" @select-inspecteur="selectInspecteur"  v-if="flagEtatDepenser == true"/>
      </div>

  </div>
</template>

<script>
import Service from "../../../../../Service"
import EtatAbsence from "./Charts/EtatAbsence.vue"
import EtatRecrutement from "./Charts/EtatRecrutement.vue"
import EtatCompetence from "./Charts/EtatCompetence.vue"
import EtatDepenser from "./Charts/EtatDepenser.vue"

export default {
  name : "indicateur",
  data() {
    return {
        flagEtatDepenser :false,
        flagEtatCompetence : false,
        flagEtatRecrutement : false,
        flagEtatAbsence : false,
        maxMois: [],
        maxYears: [],
        mois : null,
        years : [],
        annee : null,
        inspecteur : null,
        matricule : null,
        inspecteurs : []
    }
  },

  components : {
    EtatAbsence,
    EtatRecrutement,
    EtatCompetence,
    EtatDepenser
  },

  methods : {

      selectInspecteur(payload) {
          this.inspecteur = payload;
          this.flagEtatDepenser = false;
          this.$nextTick(() => {
            this.flagEtatDepenser = true;
          });
      },

      onChangeYear() {
          this.flagEtatRecrutement = false;
          this.flagEtatAbsence = false;
          this.flagEtatCompetence = false;
          this.flagEtatDepenser = false;
          this.$nextTick(() => {
            this.flagEtatAbsence = true;
            this.flagEtatRecrutement = true;
            this.flagEtatCompetence = true;
            this.flagEtatDepenser = true;
          });
      },

      onChangeMois() {
          this.flagEtatRecrutement = false;
          this.flagEtatAbsence = false;
          this.flagEtatCompetence = false;
          this.flagEtatDepenser = false;
          this.$nextTick(() => {
            this.flagEtatAbsence = true;
            this.flagEtatRecrutement = true;
            this.flagEtatCompetence = true;
            this.flagEtatDepenser = true;
          });
      }

    },

    mounted() {

        // get year anee current
        const today = new Date();
        this.annee = today.getUTCFullYear();
        this.mois = today.getMonth() + 1;
        this.inspecteur = sessionStorage.getItem("id");

        Service.readAdmin()
        .then((result) => {
          result.data.admins.forEach(element => {
            this.inspecteurs.push(element);
          });
        })
        .catch((error) => {
            console.log(error);
        });


        for(let i = this.annee; i >= 2019 ; i--) {
              this.maxYears.push(i);
        }

        for(let i = 12; i >= 1 ; i--) {
              this.maxMois.push(i);
        }

        if(this.annee != null && this.mois != null) {
          this.flagEtatAbsence = true;
          this.flagEtatRecrutement = true;
          this.flagEtatCompetence = true;
          this.flagEtatDepenser = true;
        }

    }

}
</script>

<style scoped>
.indicateur {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.indicateur>.text {
  background-color: #fff27a;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}

.text span {
  font-weight: bold;
}

.titre h2 {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
  color: white;
  background: linear-gradient(346deg, rgba(207, 31, 33, 1) 0%, rgba(24, 86, 161, 1) 100%);
  text-align: center;
  padding: 10px;
  font-size: 25px;
}


.indicateur>.filter {

  background-color: rgb(238, 238, 238);
  display: flex;
  margin: 0 10px;
  padding: 10px;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.indicateur>.filter>select {
  padding: 10px;
  margin-left: 10px;
  border: 1px solid #bebebe;
  border-radius: 20px;
}
.indicateur>.filter>label {
  margin-left: 10px;
}

.indicateur>.statstique  {

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;

}


</style>